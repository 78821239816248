import swal from "sweetalert";
import { toast } from "react-toastify";
import React, { Component } from "react";
import CreateSharpIcon from '@material-ui/icons/CreateSharp';
import SearchSharpIcon from '@material-ui/icons/SearchSharp';
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';
import { PiNotePencil } from "react-icons/pi";
import { Card, FormControl, InputGroup, Breadcrumb, Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import axios from '../../../axios';
import apiError from '../../../error';
import Loader from "../../../component/loader/loader";
import { getDate, getCookie } from "../../../function";
import Doamin from '../../../component/domain/domain';
import SubDoamin from '../../../component/sub-domain/sub-domain';

export default class Landing_URL_List extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: [],
            count: 0,
            limit: 10,
            search: '',
            activePage: 1,
            modal: false,
            domain: '',
            subDomain: '',
            loading: true,
            landingUrlId: 0,
            landing_urls: [],
            userType: getCookie('userType'),
            userAgent: getCookie('userAgent')
        }
    }

    componentDidMount = () => {
        this.load();
    }

    load = () => {
        this.setState({ loading: true })
        axios.get('/api/pages/url', {
            params: {
                page: this.state.page,
                limit: this.state.limit,
                search: this.state.search
            }
        })
            .then(res => {
                var url = [];
                for (let i = 0; i < res.data.landing_urls.length; i++) {
                    if (!res.data.landing_urls[i].parentId) {
                        url.push({
                            id: res.data.landing_urls[i].id,
                            name: res.data.landing_urls[i].name,
                        })
                    }
                }
                this.setState({ loading: false, url: url, count: res.data.count, landing_urls: res.data.landing_urls });
            })
            .catch(error => {
                apiError(error);
                this.setState({ loading: false });
            })
    }

    handleDeleteURL = () => {
        swal({
            title: "Do you want to delete Landing URL?",
            icon: "warning",
            buttons: ['No', 'Yes'],
            dangerMode: true,
        })
            .then(async willSuccess => {
                if (willSuccess) {

                }
            })
    }

    showModal = () => {
        this.setState({ modal: true, landingUrlId: 0 });
    }

    hideModal = () => {
        this.setState({ modal: false, landingUrlId: 0 });
    }

    handleSubDomain = (e) => {
        this.setState({ subDomain: e.target.value })
    }

    handleLandingUrlId = (landingUrlId) => {
        this.setState({ landingUrlId: landingUrlId })
    }

    validURL = (str) => {
        var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!pattern.test(str);
    }

    handleSubmit = () => {
        if (this.state.landingUrlId === 0 || parseInt(this.state.landingUrlId, 10) === 0) {
            toast.error('Please select root domain first');
            return null;
        } else if (!this.state.subDomain || this.state.subDomain.length === 0) {
            toast.error('Please enter sub domain');
            return null;
        } else {
            this.setState({ loading: true });
            axios.post('/api/pages/subDomain', {
                subDomain: this.state.subDomain,
                landingUrlId: this.state.landingUrlId
            })
                .then(res => {
                    this.load();
                    toast.success("Subdomain added successfully");
                    this.setState({ loading: false, modal: false });
                })
                .catch(err => {
                    apiError(err);
                    this.setState({ loading: false });
                })
        }
    }

    handleCreate = () => {
        if (this.state.domain.length === 0) {
            toast.error("Please enter landing url");
            return;
        } else {
            this.setState({ loading: true })
            axios.post('/api/pages/url', { url: this.state.domain })
                .then(res => {
                    toast.success('Landing URL added successfully')
                    this.setState({ loading: false, domainModal: false })
                    if (res.data.isAdmin) {
                        this.load();
                    } else {
                        this.props.history.push(`/landing-url/${res.data.landingUrlId}`)
                    }
                })
                .catch(err => {
                    apiError(err)
                    this.setState({ loading: false })
                })
        }
    }

    deleteLandingUrl = (landingUrlId) => {
        this.setState({ loading: true })
        axios.delete(`/api/pages/delete?landingUrlId=${landingUrlId}`)
            .then(res => {
                toast.success('Landing URL deleted successfully')
                this.load();
            })
            .catch(err => {
                apiError(err)
                this.setState({ loading: false })
            })
    }

    removeDeceptive = (landingUrlId) => {
        this.setState({ loading: true })
        axios.get(`/api/pages/removeDeceptive/${landingUrlId}`)
            .then(res => {
                toast.success('Updated successfully')
                this.load();
            })
            .catch(err => {
                apiError(err)
                this.setState({ loading: false })
            })
    }

    render() {
        return (
            <div className="content-wrap">
                <div className="breadcrumbbar">
                    <div className="flexRow row">
                        <div className="col-md-4">
                            <div className="page-head d-flex align-items-center justify-content-between">
                                <h4 className="page-title">Landing URL</h4>
                            </div>
                        </div>

                        <div className="col-md-8 flexFilterButtons">
                            <div className="btn-container float-right d-flex align-items-center">
                                <Button className="btn btn-primary" onClick={this.showModal}>Add Sub Domain</Button>
                                <Button variant="btn btn-primary ml-2" onClick={e => this.setState({ domainModal: true })}>Add Domain URL</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="filter-wrapper">
                    <div className="alignItemsCenter row">
                        <div className="col-md-3 p-0">
                            <div className="positionSearchIcon">
                                <InputGroup className="search-wrap">
                                    <FormControl type="text" placeholder="Search" value={this.state.search} className="inputSearch mr-sm-2 form-control" onChange={(e) => this.setState({ search: e.target.value, activePage: 1 }, () => this.load())} /><SearchSharpIcon className="search-icon"></SearchSharpIcon>
                                </InputGroup>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="contentbar">
                    <div className="tableContainerWrapInner">
                        <Table>
                            <thead>
                                <tr>
                                    <th className="th-30">Url</th>
                                    <th>Valid From</th>
                                    <th>Valid Till</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.landing_urls.map((domain, index) => {
                                    let url = (domain.name).startsWith('http') ? domain.name : "http://" + domain.name
                                    return <tr key={index}>
                                        <td class="onlyForPinter">
                                            <a rel="noopener noreferrer" href={url} target="_blank">{url}</a>
                                        </td>

                                        <td>
                                            {getDate(domain.validFrom)}
                                        </td>

                                        <td>
                                            {domain.validTo ? getDate(domain.validTo) : '---'}
                                        </td>

                                        <td>
                                            <div className="commonDflex">
                                                <p>{domain.validTo ? <span style={{ color: "red" }}>Deceptive</span> : domain.isValid ? <span style={{ color: "green" }}>Verified</span> : <span style={{ color: "gray" }}>Not Verified</span>}</p>
                                            </div>
                                        </td>
                                        <td>
                                            {(domain.validTo || domain.isValid) ? null :
                                                <Button className="btn-primary-rgba p-2" onClick={e => this.props.history.push('/landing-url/' + domain.id)}><PiNotePencil className="table-icon"></PiNotePencil> </Button>
                                            }
                                            {!this.state.userAgent ?
                                                <Button className="btn-primary-rgba p-2" onClick={() => this.deleteLandingUrl(domain.id)} ><DeleteSharpIcon className="table-icon text-danger"></DeleteSharpIcon></Button>: null
                                            }
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </Table>
                    </div>

                    {this.state.loading ? <Loader></Loader> : null}

                    <SubDoamin
                        hideModal={this.hideModal}
                        landingUrl={this.state.url}
                        showModal={this.state.modal}
                        handleSubmit={this.handleSubmit}
                        handleSubDomain={this.handleSubDomain}
                        landingUrlId={this.state.landingUrlId}
                        handleLandingUrlId={this.handleLandingUrlId}
                    ></SubDoamin>
                    <Doamin
                        showModal={this.state.domainModal}
                        hideModal={e => this.setState({ domainModal: false })}

                        domain={this.state.domain}
                        handleDomain={e => this.setState({ domain: e.target.value })}

                        handleCreate={this.handleCreate}
                    ></Doamin>
                </div>
            </div>
        );
    }
}