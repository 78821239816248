import React, { Component } from "react";
import './spinner.css';

export default class Spinner extends Component {

    render() {
        const loading = (this.props.loading && this.props.loading === true)? true: false;
        return (
            <div className={loading? "spinner-border": "spinner-border d-none"} role="status"></div>
        );
    }
}