import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import { Link } from 'react-router-dom';
import React, { Component } from "react";
import { Card, Breadcrumb, Table, Button } from 'react-bootstrap';
import ArrowBackSharpIcon from '@material-ui/icons/ArrowBackSharp';

import logo from '../../../assets/kratikal.png';
import time from '../../../assets/camp_report/time.png';
import rate1 from '../../../assets/camp_report/rate1.png';
import rate2 from '../../../assets/camp_report/rate2.png';

import Loader from '../../../component/loader/loader';
import SMSSend from '../../../component/campaigns/charts/sms-send';
import EmailSend from '../../../component/campaigns/charts/email-send';
import SystemHack from '../../../component/campaigns/charts/system-hack';
import CallPicked from '../../../component/campaigns/charts/call-picked';
import MessageSent from '../../../component/campaigns/charts/message-send';
import EmailOpened from '../../../component/campaigns/charts/email-opened';
import LinkClicked from '../../../component/campaigns/charts/link-clicked';
import DataSubmitted from '../../../component/campaigns/charts/data-submitted';
import AttachmentOpened from '../../../component/campaigns/charts/attachment-opened'
import EmailReported from '../../../component/campaigns/charts/email-reported';
import CallInitiated from '../../../component/campaigns/charts/call-initiated';
import BreachReportGraph from '../../../component/campaigns/reports/breach-report';
import Replied from '../../../component/campaigns/charts/replied';

import axios from '../../../axios';
import apiError from '../../../error';
import { formateToLocalDate, getCookie, getDateWithoutTime } from '../../../function';

import './reports.css';
export default class CampaignReport extends Component {

    constructor(props){
        super(props);
        this.printReport = this.printReport.bind(this);
        this.reportRef = React.createRef();
        this.state = {
            status: {},
            Expired: 7,
            results: [],
            details: {},
            show: false,
            breachTime: 0,
            currentRisk: 0,
            evs_details: [],
            industryRisk: 0,
            incidentTime: 0,
            campaignName: '',
            toDate: new Date(),
            fromDate: new Date(),
            credentialHarvesting: false,
            campaignId: parseInt(this.props.match.params.campaignId, 10),
            simulationId: parseInt(this.props.match.params.simulationId, 10),

            time: [],
            value: [],
            isRepliedCampaign: false
        }
    }

    componentDidMount = () => {
        // this.evsScore();
        this.phishRate();
        this.loadGraph();
        this.loadTotal();
        this.getDetails();
        this.first5HackedUser();
        this.loadBreachAndIncident();
    }

    loadGraph = () => {
        axios.get('/api/campaigns/graph', {
            params: { 
                campaignId: this.state.campaignId,
                simulationId: this.state.simulationId 
            },
        })
        .then(res => {
            var value = [], time = [];
        for (let i = 0; i < res.data.results.length; i++) {
          if (res.data.results[i].time) {
            const formattedDate = formateToLocalDate(res.data.results[i].time);
            value.push(res.data.results[i].count);
            time.push(formattedDate);
            // time.push(res.data.results[i].time + ":00:00");
          }
        }
        this.setState({ value, time });
        })
        .catch(error => {
            apiError(error);
        })
    }

    evsScore = () => {
        axios.get('/api/simulation/evs', {
            params: { 
                campaignId: this.state.campaignId,
                simulationId: this.state.simulationId 
            },
        })
        .then(res => {
            this.setState({ evs_details: res.data.evs_details })
        })
        .catch(error => {
            apiError(error);
        })
    }

    loadTotal = () => {
        axios.get('/api/simulation/', {
            params: { 
                campaignId: this.state.campaignId,
                simulationId: this.state.simulationId 
            },
        })
        .then(res => {
            if(res.data.simulation){
                var status = {};
                for(var i=0; i<res.data.simulation.status.length; i++){
                    var data = res.data.simulation.status[i];
                    status[data['message']] = parseInt(data['count'], 10)? parseInt(data['count'], 10): 0;
                }
                this.setState({
                    status: status,
                    loading: false,
                    isRepliedCampaign: res.data.simulation.isRepliedCampaign,
                    attackType: (res.data.simulation.campaign && res.data.simulation.campaign.attack_type)? res.data.simulation.campaign.attack_type.name: ''
                })
            }
        })
        .catch(error => {
            apiError(error);
        })
    }

    loadBreachAndIncident = () => {
        axios.get('/api/results/breach/incedent', {
            params: { 
                campaignId: this.state.campaignId,
                simulationId: this.state.simulationId 
            },
        })
        .then(res => {
            if(res.data.result_be){
                this.setState({ breachTime: this.secondsToHms(res.data.result_be.breachTime)})
            }
            if(res.data.result_in){
                this.setState({ incedent: this.secondsToHms(res.data.result_in.incidentTime)})
            }
        })
        .catch(err => {
            apiError(err);
        })
    }

    getDetails = () => {
        axios.get('/api/campaigns/details', {
            params: { campaignId: this.state.campaignId, simulationId: this.state.simulationId },
        })
        .then(res => {
            const campaignStartDate = new Date(res.data.details.scheduledAt);
            let campaignCompleteDate = new Date(new Date(res.data.details.scheduledAt).getTime() + (res.data.Expired * 24 * 60 * 60 * 1000));
            if(res.data.details.completedAt){
                campaignCompleteDate = new Date(res.data.details.completedAt);
            }  
            const campaignExpireDate = new Date(new Date(res.data.details.scheduledAt).getTime() + (res.data.Expired * 24 * 60 * 60 * 1000));
            const campaignEndDate = campaignCompleteDate < campaignExpireDate ? campaignCompleteDate : campaignExpireDate;
            const campaign = res.data.details && res.data.details.campaign? res.data.details.campaign: null;
            let campaignName = "", credentialHarvesting = false;
            if(campaign){
                campaignName = campaign.name;
                if(campaign.assign_templates && campaign.assign_templates[0] && campaign.assign_templates[0].campaign_template){
                    credentialHarvesting = campaign.assign_templates[0].campaign_template.credential_harvesting;
                }
            }
            this.setState({ 
                toDate: campaignEndDate,
                fromDate: campaignStartDate,
                Expired: res.data.Expired,
                details: res.data.details, 
                campaignName: campaignName,
                credentialHarvesting: credentialHarvesting
            })
        })
        .catch(error => apiError(error))
    }

    phishRate = () => {
        axios.get('/api/campaigns/phishRate', {
            params: { campaignId: this.state.campaignId, simulationId: this.state.simulationId },
        })
        .then(res => {
            this.setState({
                currentRisk: res.data.currentRisk, 
                industryRisk: res.data.industryRisk
            })
        })
        .catch(error => apiError(error))
    }

    first5HackedUser = () => {
        axios.get('/api/results/first5', {
            params: { campaignId: this.state.campaignId, simulationId: this.state.simulationId },
        })
        .then(res => {
            this.setState({ results: res.data.results });
        })
        .catch(error => apiError(error))
    }

    secondsToHms = (d) => {
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);
    
        var hDisplay = h > 0 ? (h < 10 ? "0" : "") + h + " : ": "00 : ";
        var mDisplay = m > 0 ? (m < 10 ? "0" : "") + m + " : ": "00 : ";
        var sDisplay = s > 0 ? (s < 10 ? "0" : "") + s + "": "00";
        return hDisplay + mDisplay + sDisplay; 
    }

    printReport = () => {
        html2canvas(this.reportRef.current).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            let imageHeight = this.reportRef.current?.clientHeight;
            let imageWidth = this.reportRef.current?.clientWidth;
            let ratio = imageHeight / imageWidth;

            const pdf = new jsPDF({
                unit: 'pt',
                format: [canvas.width +40, canvas.height+40] 
            });

            let pdfWidth = pdf.internal.pageSize.getWidth() - 40;
            let pdfHeight = pdfWidth * ratio;

            pdf.addImage(imgData, "JPEG", 20, 0, pdfWidth, pdfHeight);
            pdf.save(`Report.pdf`);
        }).catch(err =>{
            apiError(err);
        });
    }

    render() {
        const TotalCount = this.state.status['Total']? this.state.status['Total']: 1;

        return (
            <div className="content-wrap">
                <div className="breadcrumbbar">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-head d-flex align-items-center justify-content-between">
                                <h4 className="page-title">Reports</h4>
                                <Breadcrumb className="mb-0">
                                    <Breadcrumb.Item href="#" active>Reports</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </div>
                    </div>
                </div>

                <Card>
                    <Card.Body>
                        <div className="page-content">
                            <div className="row  bg-light p-3 align-items-center m-0 mb-2">
                                <div className="col-md-3">
                                    <h6 className="tab-head mb-0">Reports</h6>
                                </div>
                                <div className="col-md-9 text-right">
                                    <Button variant="outline-secondary" onClick={e => this.props.history.goBack()} className="mr-2"><ArrowBackSharpIcon className="btn-icon"/>Back</Button>                                   
                                    <Button variant="primary" className="mr-2" onClick={this.printReport}>Download Report</Button>
                                </div>
                            </div>

                            <div className="col-md-12 mx-auto">
                                <div className="pdf-wrap" id="pdf-wrap" ref={this.reportRef}>
                                    <div className="row justify-content-between">
                                        <div className="col-sm-6">
                                            <Link className="pdf-logo" to=""> <img src={logo} alt=""></img> </Link>
                                        </div>
                                        <div className="col-sm-6 text-right">
                                            <Link className="comp-logo" to="#"> 
                                                {getCookie('logo')? <img style={{width: '100px'}} src={getCookie('logo')} alt=""></img>: null}
                                            </Link>
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <h3 className="main-head">TSAT {this.state.attackType} Simulation Report</h3>
                                    <div className="row mb-4">
                                        <div className="col-md-8">
                                            <div className="card-wrap">
                                                <h5>Phishing Campaign Performance Report</h5>
                                                The purpose of this report is to provide a detailed analysis of the employees’ susceptibility to a {this.state.attackType} attack. This report will help you analyse the attack susceptibility of your employees against {this.state.attackType}, which is the most prominent cyber-attack currently. Approximately 91% of the cyber-attacks are occurring due to the negligence of the employees by not paying attention to the email and clicking on fake links thereby submitting sensitive data. In this part of the campaign, a simulated attack is run on a set of users. Once the simulation is over, data is analysed by our team of experts.
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="card-wrap">
                                                <h6>Date Period :</h6>
                                                <p>{`${getDateWithoutTime(this.state.fromDate)} - ${getDateWithoutTime(this.state.toDate)}`}</p>
                                                <hr></hr>
                                                <h6>Duration : {Math.ceil((this.state.toDate - this.state.fromDate)/ (1000 * 60 * 60 * 24))} Days </h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row text-center justify-content-between my-4">
                                        {'Email Sent' in this.state.status?
                                            <div className="col-sm-2">
                                                <EmailSend 
                                                    value={this.state.status['Email Sent']}
                                                    percent={((this.state.status['Email Sent']*100)/TotalCount).toFixed(2)}
                                                ></EmailSend>
                                            </div>: ''
                                        }{'SMS Sent' in this.state.status?
                                            <div className="col-sm-2">
                                                <SMSSend 
                                                    value={this.state.status['SMS Sent']}
                                                    percent={((this.state.status['SMS Sent']*100)/TotalCount).toFixed(2)}
                                                ></SMSSend>
                                            </div>: ''
                                        }{'Message Sent' in this.state.status?
                                            <div className="col-sm-2">
                                                <MessageSent 
                                                    value={this.state.status['Message Sent']}
                                                    percent={((this.state.status['Message Sent']*100)/TotalCount).toFixed(2)}
                                                ></MessageSent>
                                            </div>: ''
                                    }   {'Call Initiated' in this.state.status?
                                            <div className="col-sm-2">
                                                <CallInitiated 
                                                    value={this.state.status['Call Initiated']}
                                                    percent={((this.state.status['Call Initiated']*100)/TotalCount).toFixed(2)}
                                                ></CallInitiated>
                                            </div>: ''
                                        }{'Call Picked' in this.state.status?
                                            <div className="col-sm-2">
                                                <CallPicked 
                                                    value={this.state.status['Call Picked']}
                                                    percent={((this.state.status['Call Picked']*100)/TotalCount).toFixed(2)}
                                                ></CallPicked>
                                            </div>: ''
                                        }{'Email Opened' in this.state.status?
                                            <div className="col-sm-2">
                                                <EmailOpened 
                                                    value={this.state.status['Email Opened']}
                                                    percent={((this.state.status['Email Opened']*100)/TotalCount).toFixed(2)}
                                                ></EmailOpened>
                                            </div>: ''
                                        }{'Link Clicked' in this.state.status?
                                            <div className="col-sm-2">
                                                <LinkClicked 
                                                    value={this.state.status['Link Clicked']}
                                                    percent={((this.state.status['Link Clicked']*100)/TotalCount).toFixed(2)}
                                                ></LinkClicked>
                                            </div>: ''
                                        }{'Data Submitted' in this.state.status?
                                            <div className="col-sm-2">
                                                <DataSubmitted 
                                                    value={this.state.status['Data Submitted']}
                                                    percent={((this.state.status['Data Submitted']*100)/TotalCount).toFixed(2)}
                                                ></DataSubmitted>
                                            </div>: ''
                                        }{'Attachment Opened' in this.state.status?
                                        <div className="col-sm-2">
                                            <AttachmentOpened 
                                                value={this.state.status['Attachment Opened']}
                                                percent={((this.state.status['Attachment Opened']*100)/TotalCount).toFixed(2)}
                                            ></AttachmentOpened>
                                        </div>: ''
                                        }{'System Hacks' in this.state.status?
                                            <div className="col-sm-2">
                                                <SystemHack 
                                                    value={this.state.status['System Hacks']}
                                                    percent={((this.state.status['System Hacks']*100)/TotalCount).toFixed(2)}
                                                ></SystemHack>
                                            </div>: ''
                                        }{'Replied' in this.state.status && this.state.isRepliedCampaign ?
                                            <div className="col-sm-2">
                                                <Replied
                                                    value={this.state.status['Replied']}
                                                    percent={((this.state.status['Replied'] * 100) / TotalCount).toFixed(2)}
                                                ></Replied>
                                            </div> : ''
                                        }{'Email Reported' in this.state.status && (this.state.attackType === "Phishing" || this.state.attackType === "Ransomware" || this.state.attackType === "Attachment" || this.state.attackType === "QRcode")?
                                            <div className="col-sm-2">
                                                <EmailReported 
                                                    value={this.state.status['Email Reported']}
                                                    percent={((this.state.status['Email Reported']*100)/TotalCount).toFixed(2)}
                                                ></EmailReported>
                                            </div>: ''
                                        }
                                    </div>
                                    {this.state.credentialHarvesting && 
                                        <div className="row mb-4">
                                            <div className="col-md-6">
                                                <div className="card-wrap">
                                                    <h3 className="mb-3">First 5 Hacked User</h3>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <Table borderless>
                                                                <tbody>
                                                                    {this.state.results ?
                                                                        this.state.results.map((result, index) => {
                                                                            return <tr key={`results${index}`}>
                                                                                <td><span className="circle">{(result.target.firstname).charAt(0)}</span></td>
                                                                                <td>{result.target.firstname} {result.target.lastname? result.target.lastname: ''} <p>{result.target.email}</p></td>
                                                                                <td>{result.breachTime} Sec</td>
                                                                            </tr>
                                                                        }): null
                                                                    }
                                                                </tbody>
                                                            </Table>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="card-wrap">
                                                <h6 className="mb-3">The graph here displays the trend of data submission during the campaign</h6>
                                                    {/* <Table borderless>
                                                        <tbody>
                                                            {this.state.evs_details ?
                                                                this.state.evs_details.map((evs_detail, index) => {
                                                                    const score = (evs_detail.score*100)/evs_detail.maximum;
                                                                    return <tr key={index}>
                                                                        <td><span className="circle">{(evs_detail.ev.target.firstname).charAt(0)}</span></td>
                                                                        <td>{evs_detail.ev.target.firstname} {evs_detail.ev.target.lastname? evs_detail.ev.target.lastname: ''}<p>{evs_detail.ev.target.email}</p></td>
                                                                        <td>{score.toFixed(2)}%</td>
                                                                    </tr>
                                                                }): null
                                                            }
                                                        </tbody>
                                                    </Table> */}
                                                    <BreachReportGraph
                                                        value={this.state.value}
                                                        time={this.state.time}
                                                    ></BreachReportGraph>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="row mb-4">
                                        <div className="col-md-6">
                                            <div className="card-wrap">
                                                <h3>Breach Time</h3>
                                                <h6>Breach Time denotes the time taken by the first employee to submit their data in the simulated phishing campaign</h6>
                                                <div className="d-flex time-wrap mt-3">
                                                    <img src={time} alt="time" className="img-fluid"></img>
                                                    <div className="timing">{this.state.breachTime? this.state.breachTime: '00 : 00 : 00'}</div>
                                                    <p className="mt-2 ml-2">Hrs</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card-wrap">
                                                <h3>Phish Risk Rate</h3>
                                                <h6>Phish risk rate indicates the threat posture of a company by analyzing the ratio of total email sent to data submitted</h6>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="d-flex time-wrap mt-3">
                                                            <img src={rate1} alt="time" className="img-fluid"></img>
                                                            <div className="timing">{this.state.currentRisk}% <p>Phish Risk</p></div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="d-flex time-wrap mt-3">
                                                            <img src={rate2} alt="time" className="img-fluid"></img>
                                                            <div className="timing">{this.state.industryRisk}% <p>Industry Rate</p></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="card-wrap">
                                                <h3>Conclusion</h3>
                                                <p>It can be safely concluded from the above data that out of the total 
                                                    {   
                                                        this.state.attackType === "Phishing"||this.state.attackType === "Ransomware"||this.state.attackType === "Attachment"? ` ${this.state.status['Email Sent']} emails sent`:
                                                        this.state.attackType === "Smishing"? ` ${this.state.status['Email Sent']} sms sent`:
                                                        this.state.attackType === "Vishing"? ` ${this.state.status['Email Sent']} call initiate`: null
                                                    }, 
                                                    { this.state.attackType === "Attachment" ?
                                                    ` ${this.state.status['Attachment Opened']} employees opened the attachment.`: ` ${this.state.status['Data Submitted']} employees submitted their data.`} This is a threatening situation for the organization since this indicates the lack of awareness in employees. Therefore, the percentage of people who were the victim of the attack comes out to be a whopping {this.state.currentRisk}%.</p> :
                                                     {/* {` ${this.state.status['Data Submitted']}`}  employees submitted their data. This is a threatening situation for the organization since this indicates the lack of awareness in employees. Therefore, the percentage of people who were the victim of the attack comes out to be a whopping {this.state.currentRisk}%.</p> */}
                                                <p>As per the {getCookie('username')} industry, if the percentage of the vulnerable employees in an organization is more than 'industry risk rate', then the organization is susceptible to cyber-attacks. In our case, the phish risk rate is {this.state.currentRisk}% and a low-scale attack can easily destroy the organization.</p>
                                                    
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
                { this.state.show? <Loader></Loader>: null }
            </div>
        );
    }
}