import React, { Component } from "react";
import {  Card, Breadcrumb } from 'react-bootstrap';

export default class CustomDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        }
    }
    render() {
        return (
            <div className="content-wrap">
                
                <div className="breadcrumbbar">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-head d-flex align-items-center justify-content-between">
                                <h4 className="page-title">Custom Dashboard</h4>
                                <Breadcrumb className="mb-0">
                                    <Breadcrumb.Item href="#" active>Custom Dashboard</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </div>
                    </div>
                </div>

                <Card>
                    <Card.Body>
                        <h6>Custom Dashboard</h6>
                    </Card.Body>
                </Card>
                
            </div>
        );
    }
}