import React, { Component } from "react";
import { toast } from "react-toastify";
import { Button, Form } from 'react-bootstrap';
import smartphone from "../../../assets/smartphone.png";
import emailIcon from "../../../assets/emailicon.png";
import axios from '../../../axios';
import apiError from '../../../error';

export default class Password extends Component {
    constructor(props){
        super(props);
        this.state = {
            oldPassword: '',
            newPassword: '',
            confirmPassword: ''
        }
    }

    handlePassword = () => {
        if(this.state.oldPassword.length === 0){
            toast.error("Current Password is required")
            return ;
        } else if(this.state.newPassword.length === 0){
            toast.error("New Password is required")
            return ;
        } else if(this.state.newPassword !== this.state.confirmPassword){
            toast.error("Password and confirm password is not match")
            return ;
        } 
        if(this.state.newPassword === this.state.oldPassword){
            toast.error('New Password can\'t same as Current Password');
            return ;
        }
        axios.patch('/api/auth/changePassword', {
            password: this.state.oldPassword,
            newPassword: this.state.newPassword,
            confirmPassword: this.state.confirmPassword
        })
        .then(res => {
            this.setState({
                password: '',
                newPassword: '',
                confirmPassword: ''
            })
            toast.success("Password updated successfully");
            window.location = '/logout';
        })
        .catch(err => {
            apiError(err);
        })
    }

    render() {
        return (
            <div className="tab-wrap">
                <div className='tabHeading'>
                        <h6>Change Password</h6>
                        <p>2FA enhances account security by preventing unauthorised access. </p>
                 </div>

                 <div className="noteSection">
                    <p><b>Note:</b> Password has to be between 8 to 15 characters which contain at least one numeric digit, one uppercase, one lowercase letter and one special character($@!%*#?&)</p>
                 </div>

                    <div className="col-md-4 pl-0">
                        <div className='profile-form'>
                            <Form>
                                <Form.Group>
                                    <Form.Label>Current Password</Form.Label>
                                    <Form.Control type="password" placeholder="Current Password" value={this.state.oldPassword} onChange={e => this.setState({oldPassword: e.target.value})} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>New Password</Form.Label>
                                    <Form.Control type="password" placeholder="New Password" value={this.state.newPassword} onChange={e => this.setState({newPassword: e.target.value})}/>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Confirm Password</Form.Label>
                                    <Form.Control type="password" placeholder="Confirm Password" value={this.state.confirmPassword} onChange={e => this.setState({confirmPassword: e.target.value})}/>
                                </Form.Group>
                                <Button variant="success" type="button" onClick={this.handlePassword}>Save</Button> 
                            </Form>
                        </div>
                    </div>


                    <div className='profile-form'>
                             <div class="tabHeading">
                                <h6>Two Factor Authentication Status</h6>
                                <p>2FA enhances account security by preventing unauthorised access.</p>
                            </div>


                            <div className="col-md-7 pl-0">
                                <div className="authenticCard">
                                    <div className="dFlex">
                                        <div className="leftIcon">
                                            <img src={smartphone} alt="template" className="img-fluid"></img>
                                        </div>
                                        <div className="rightContent">
                                            <h6>Two factor authentication status <span className="switchBages disable">Disable</span></h6>
                                            <p>Enable Two-Factor Authentication (2FA) for all users associated with this account. They will receive a code sent to their
                                                respective email addresses email enhancing security during the sign-in process.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="enableToggle">
                                        <h6>Enable this feature to set 2FA method</h6>
                                        <div className="toggleBtn">
                                         <Form.Switch type="switch" id="custom-switch21" label="Enable" />
                                        </div>

                                    </div>

                                </div>

                                <div className="authenticCard">
                                    <div className="dFlex">
                                        <div className="leftIcon">
                                            <img src={emailIcon} alt="template" className="img-fluid"></img>
                                        </div>
                                        <div className="rightContent">
                                            <h6>Two factor e-mail authentication status <span className="switchBages enableBages">Enable</span></h6>
                                            <p>Enable Two-Factor Authentication (2FA) for all users associated with this account. They will receive a code sent to their
                                                respective email addresses email enhancing security during the sign-in process.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="enableToggle">
                                        <h6>Enable this feature to set 2FA method</h6>
                                        <div className="toggleBtn">
                                            
                                         <Form.Switch type="switch" id="custom-switch21" label="Enable" />
                                        </div>

                                    </div>

                                </div>
                            </div>
                            
                        </div>


            </div>
        );
    }
}