import React, { Component } from "react";

import './hackrecord.css'

export default class HackRecord extends Component {
    render() {
        return (
            <tr>
                <td style={{width:'20%'}}>{this.props.hackrecord.Name}</td>
                <td style={{width:'20%'}}>{this.props.hackrecord.BreachDate}</td>
                <td style={{width:'60%'}} id={this.props.id}></td>
            </tr>
        );
    }
}