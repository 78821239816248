import React, {Component} from 'react';
import { Modal, Form, Button } from 'react-bootstrap';

export default class Domain extends Component {
    render() {
        return (
            <Modal show={this.props.showModal} onHide={this.props.hideModal}>
                <Modal.Header closeButton>
                     <div>
                         <h5>Add landing url Domain</h5>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Form.Label>Please enter domain (Without <span style={{color: 'green'}}>http://</span> or <span style={{color: 'green'}}>https://</span>)</Form.Label>
                        <Form.Control type="text" placeholder="Please enter domain" value={this.props.domain} onChange={this.props.handleDomain}/>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn closeBtn' onClick={this.props.hideModal}>Close</Button>
                    <Button className='btn btn-success' onClick={this.props.handleCreate}>Create</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}