import React, { Component } from "react";
import ApexCharts from 'apexcharts'

export default class CallInitiated extends Component {
    state = { chart: null }

    componentDidMount = () => {
        this.load();
    }

    load = () => {
        let value = this.props.value? this.props.value: 0;
        let options = {
            series: [this.props.percent],
            chart: {
                height: 200,
                type: 'radialBar',
                offsetY: -10
            },
            colors: ['#287150'],
            tooltip: {
                enabled: true,
                style: {
                    backgroud: 'white',
                    fontSize: '12px',
                    fontFamily: undefined
                },
                y: {
                    formatter: (value) => { return value + "%" },
                }
            },
            plotOptions: {
                radialBar: {
                    startAngle: -180,
                    endAngle: 180,
                    dataLabels: {
                        name: {
                            fontSize: '14px',
                            offsetY: 90,
                            fontWeight: 400,
                            color:"#000"
                        },
                        value: {
                            offsetY: -10,
                            fontSize: '22px',
                            color: undefined,
                            formatter: function (val) {
                                return value;
                            }
                        }
                    }
                }
            },
           
            labels: ['Call Initiated'],
        }
        var chart = new ApexCharts(document.querySelector("#callInitiated"), options);
        chart.render();
        this.setState({ chart: chart })
    }

    componentDidUpdate = (prevProps) => {
		if(this.props.percent !== prevProps.percent){
			if(this.state.chart) this.state.chart.destroy();
			this.load();
		}
	}
    
    render() {
        return (
            <div id="callInitiated"></div>
        );
    }
}