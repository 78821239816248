import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';

import container from './container';
import { getCookie } from './function';
import Login from './container/login/login'
import InvalidURL from './container/nopath';
import Logout from './container/logout/logout';
import AppSumo from './container/appsumo/app-sumo';
import Register from './container/register/register';
import LoginSSO from './container/login-sso/login-sso';
import BluePage from './container/blue-page/blue-page';
import Awareness from './container/awareness/awareness';
import BluePage1 from './container/blue-page-1/blue-page-1';
import BluePage2 from './container/blue-page-2/blue-page-2';
import InvalidURLWithNoAuth from './container/nopath-unAuth';
import ResetPassword from './container/resetpassword/reset-password';
import DeleteAccount from './container/delete-account/delete-account';
import ForgotPassword from './container/forgot-password/forgot-password';
import ProductGuide from './container/product-guide/product-guide';
import ChangeLog from './container/change-log/change-log';

import { appType } from './function';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-css-only/css/bootstrap.css';
export default class App extends Component {
	constructor(){
		super();
		this.state = {
			appType: appType()
		}
	}
	render() {
		return (
			<BrowserRouter>
				<Switch>
					<Route path='/login' exact component = { Login } />
					<Route path='/login/:token' exact component = { LoginSSO } />
					<Route path='/logout' exact component = { Logout } />
					{this.state.appType === "SaaS" && <Route path='/register' exact component = { Register } />}
					<Route path='/404' exact component = { InvalidURLWithNoAuth } />
					<Route path='/reset/:email/:token' exact component = { ResetPassword } />
					<Route path='/forgot-password' exact component = { ForgotPassword } />
					<Route path='/awareness' exact component = { BluePage } />
					<Route path='/awareness-1' exact component = { BluePage1 } />
					<Route path='/awareness-2' exact component = { BluePage2 } />
					<Route path='/awareness-3' exact component = { Awareness } />
					{this.state.appType === "SaaS" && <Route path='/appsumo/:promocode' exact component = { AppSumo } />}
					<Route path='/delete-account/:key' exact component = { DeleteAccount } />
					<Route path='/change-log' exact component = { ChangeLog } />
					{!getCookie('token')?<Redirect to="/login"></Redirect>:null}
					<Route path='/invalid' exact component={ InvalidURL }/>
					<Route path='/product-guide' exact component = { ProductGuide } />

					<Route path='/' component = { container }/>
				</Switch>
				<ToastContainer position="top-right" autoClose={5000} />
			</BrowserRouter>
		);
	}
}

