import React, { Component } from 'react';
import { Card, Breadcrumb } from 'react-bootstrap';

import Dummy from '../../../component/reports/time-spend/dummy';
import CategoryLanding from '../../../component/reports/time-spend/category-landing';
import CategoryReponseChart from '../../../component/reports/breach-incidents/categoryResponse-chart';
import UpdateSharpIcon from '@material-ui/icons/UpdateSharp';

import axios from '../../../axios';
import apiError from '../../../error';
import './time-spend.css';

export default class TimeSpend extends Component {
    constructor(props){
        super(props);
        this.state = {
            loading: false,
            attackTypes: [],
            attackTypeId: 1,
            average: '00:00',
            categoryResponce: {},
            categoryTimeSpend: {}
        }
        this.loadAttackType();
    }

    loadAttackType = () => {
        axios.get('/api/attacktypes', {})
        .then(res => {
            if(res.data.attackTypes && res.data.attackTypes.length){
                this.setState({ 
                    attackTypes: res.data.attackTypes, 
                    attackTypeId: res.data.attackTypes[0].id,
                }, () => {
                    this.handleRefresh();
                });
            }
        })
        .catch(err => apiError(err))
    }  
    
    handleRefresh = () => {
        this.timeSpend();
        this.categoryTimeSpend();
        this.timeSpendCampaign();
        this.categoryResponceBreachIncident();
    }

    fmtMSS = (s) => {
        return (s-(s%=60))/60+(9<s?':':':0')+s
    }

    timeSpend = async() => {
        await this.setState({ loading: true })
        axios.get('/api/pdfreport/timeSpend', {
            params: { attackTypeId: this.state.attackTypeId }
        })
        .then(res => {
            let average = Math.ceil(parseInt(res.data.results.timeSpend, 10)/parseInt(res.data.results.total, 10))
            this.setState({ loading: false, average: this.fmtMSS(average)});
        })
        .catch(err => {
            apiError(err);
            this.setState({ loading: false })
        })
    }

    timeSpendCampaign = async() => {
       /*  axios.get('/api/pdfreport/timeSpendCampaign', {
            params: { attackTypeId: this.state.attackTypeId }
        })
        .then(res => {
            
        })
        .catch(err => {
            apiError(err);
        }) */
    }

    categoryTimeSpend = async() => {
        await this.setState({ loading: true })
        axios.get('/api/pdfreport/categoryTimeSpend', {
            params: { attackTypeId: this.state.attackTypeId }
        })
        .then(res => {
            let series = [], category = [], categoryTimeSpend = {};
            for(let i=0; i<res.data.results.length; i++){
                let data = JSON.parse(JSON.stringify(res.data.results[i]));
                if(data.timeSpend !== '0') series.push(Math.ceil(parseInt(data.timeSpend)/60))
                else series.push(0)
                category.push(data.campaign_template.category.name)
            }
            categoryTimeSpend['series'] = series;
            categoryTimeSpend['category'] = category;
            this.setState({ loading: false, categoryTimeSpend: categoryTimeSpend });
        })
        .catch(err => {
            apiError(err);
            this.setState({ loading: false })
        })
    }

    categoryResponceBreachIncident = () => {
        axios.get('/api/pdfreport/categoryResponceBreachIncident', {})
        .then(res => {
            let categoryResponce = {}, breachTime = [], incidentTime = [], category = [];
            for(let i=0; i<res.data.results.length; i++){
                category.push(res.data.results[i].campaign_template.category.name);
                let bt = Math.ceil((parseInt(res.data.results[i].breachTime, 10)*100)/parseInt(res.data.results[i].simulation.completeTime, 10))
                breachTime.push(bt);
                let it = Math.ceil((parseInt(res.data.results[i].incidentTime, 10)*100)/parseInt(res.data.results[i].simulation.completeTime, 10))
                incidentTime.push(it);
            }
            categoryResponce['category'] = category;
            categoryResponce['breachTime'] = breachTime;
            categoryResponce['incidentTime'] = incidentTime;
            this.setState({categoryResponce: categoryResponce});
        })
        .catch(err => apiError(err) )
    }

    render() {
        return (
            <div className="content-wrap">
                <div className="breadcrumbbar">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-head d-flex align-items-center justify-content-between">
                                <h4 className="page-title">Time Spend Reports</h4>
                                <Breadcrumb className="mb-0">
                                    <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
                                    <Breadcrumb.Item href="#" active>Time Spend Reports</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </div>
                    </div>
                </div>
                <Card>
                    <Card.Body>
                        <div className="page-content" id="graph-wrap">
                            <h6 className="tab-head mb-0 bg-light p-3">Time Spend on Loading Page</h6>
                            <div className="row graph-row">
                                <div className="col-md-4 right-border d-flex align-items-center justify-content-center">
                                    <div className="bg-light clock-wrap">
                                        <div>
                                            <h6>Average Time Spend</h6>
                                            <h2>{this.state.average}</h2>
                                            <UpdateSharpIcon className="clock-icon"></UpdateSharpIcon>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 right-border">
                                    <h6 className="mb-3">Drop off time / Hack Time</h6>
                                    <CategoryReponseChart
                                        categoryResponce={this.state.categoryResponce}
                                    ></CategoryReponseChart>
                                </div>
                                <div className="col-md-4">
                                    <h6 className="mb-3">Category Landing Page</h6>
                                    <div id="landing"></div>
                                    {Object.keys(this.state.categoryTimeSpend).length?
                                        <CategoryLanding
                                            categoryTimeSpend={this.state.categoryTimeSpend}
                                        ></CategoryLanding>: ''
                                    }
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-md-12">
                                    <h6>Dummy Data</h6>
                                    <div id="dummy"></div>
                                    <Dummy></Dummy>
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        );
    }
}