import React, { Component } from 'react';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';

export default class TestWhatsAppSMSModal extends Component{
    
    render(){
        return (
            <Modal show={this.props.show} onHide={this.props.handleEmailModal} autoFocus keyboard>
                <Modal.Header>
                    <Modal.Title> Send Test SMS </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Label>Please enter phone no.</Form.Label>
                    <PhoneInput
                        country={'us'}
                        value={this.props.emails}
                        onChange={phone => this.props.handlePhone({target: {value: phone}})}
                    ></PhoneInput>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.handleSMSModal}>Close</Button>
                    <Button variant="primary" disabled={this.props.loading} onClick={this.props.sendTestSMS}> { this.props.loading? <Spinner as="span" className="mr-2" animation="border" size="sm" role="status" aria-hidden="true" />: null } Send</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}