import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { Modal, Table } from 'react-bootstrap';
import VisibilitySharpIcon from '@material-ui/icons/VisibilitySharp';

import { getDate } from '../../../function';
import '../../campaigns/campaign/campaign.css';

export default class DetailModal extends Component {

    render() {
        let names = "";
        const simulation = this.props.details;
        const groupName = this.props.groupName;
        groupName.map((element) => {
            let name = element.group.name
            names += name
            return name;
        })
        return (
            <Modal show={this.props.detailModal} className="detail-modal right-modal right" onHide={() => this.props.detailHandle()} dialogClassName="modal-90w" aria-labelledby="example-custom-modal-styling-title">
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        <h5 className="mb-0">Campaign Details</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table borderless>
                        {simulation && simulation.campaign ?
                            <tbody>
                                <tr>
                                    <td>Campaign Name</td>
                                    <td>{simulation.campaign.name}</td>
                                </tr>
                                <tr>
                                    <td>Create Date</td>
                                    <td>{getDate(simulation.createdAt)}</td>
                                </tr>
                                <tr>
                                    <td>Launch Date</td>
                                    <td>{getDate(simulation.scheduledAt)}</td>
                                </tr>
                                <tr>
                                    <td>Frequency</td>
                                    <td>{simulation.campaign.frequency}</td>
                                </tr>
                                {simulation.campaign.assign_templates ?
                                    <>
                                        <tr>
                                            <td className="v-top">Campaign Templates</td>
                                            <td>
                                                <ul className="table-ul">
                                                    {simulation.campaign.assign_templates.map((template, index) => {
                                                        return template.campaign_template ? <li key={'template-' + index}><span>{template.campaign_template.name}</span> <Link to={"/templates/preview/" + template.campaign_template.id} target="_blank"><VisibilitySharpIcon className="list-icon"></VisibilitySharpIcon></Link></li> : null
                                                    })}
                                                </ul>

                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Time Frame</td>
                                            <td>{simulation.delay > 1 ? simulation.delay + " min" : "1 sec"}</td>
                                        </tr>
                                        <tr>
                                            <td>Target Group</td>
                                            <td>{names}</td>
                                        </tr>
                                    </> : null
                                }
                            </tbody> : null
                        }
                    </Table>
                </Modal.Body>
            </Modal>
        );
    }
}