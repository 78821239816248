import React, { Component } from 'react';
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import FilterListSharpIcon from '@material-ui/icons/FilterListSharp';
import { Card, Breadcrumb, Table, ProgressBar, Button, Form } from 'react-bootstrap';

import Loader from '../../../component/loader/loader';
import ReportModal from '../../../component/reports/report-filter/report-filter';
import ClickRateGrapgh from '../../../component/reports/click-reports/click-rate-graph';
import CategoryClickResponse from '../../../component/reports/click-reports/category-click-response';

import axios from '../../../axios';
import apiError from '../../../error';
import avg from '../../../assets/avg.png';
import total from '../../../assets/total.png';
import country from '../../../assets/country.png';

import './click-reports.css';
export default class ClickReports extends Component {
    constructor(props){
        super(props);
        this.printReport = this.printReport.bind(this);
        this.reportRef = React.createRef();
        this.state = {
            clicks: 0,
            country: 0,
            average: 0,
            clickRate: '',
            loading: true,
            totalClicks: 0,
            attackTypes: [],
            countryWise: [],
            totalCountry: 0,
            attackTypeId: 1,
            reportModal: false,
            attacktypeName: {},
            isDataLoaded: false,
            categoryClickRate: {},
            groupWiseClickRate: {},
            countryWiseClickRate: [],
            credentialHarvesting: 'NA',

            campaigns: [],
            campaignIds: [],
            selectedCampaignIds: [],

            totalEmails: 1,
            averageClick: 0,
            endDate: new Date(),
            startDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),

            locations: [],
            location: '',
        }
        this.loadAttackType();
    }

    loadAttackType = () => {
        axios.get('/api/attacktypes', {})
        .then(res => {
            if(res.data.attackTypes && res.data.attackTypes.length){
                let attacktypeName = {};
                for(let i=0; i<res.data.attackTypes.length; i++){
                    attacktypeName[res.data.attackTypes[i].id] = res.data.attackTypes[i].name
                }   
                this.setState({ 
                    attacktypeName: attacktypeName,
                    attackTypes: res.data.attackTypes, 
                    attackTypeId: res.data.attackTypes[0].id,
                }, () => {
                    this.loadCampaigns();
                });
            }
        })
        .catch(err => apiError(err))
    }  
    
    handleRefresh = () => {
        this.clickRate();
        this.countryCount();
        this.categoryClickRate();
        this.groupWiseClickRate();
        this.countryWiseClickRate();
        this.loadLocation();
    }

    clickRate = async() => {
        await this.setState({ loading: true })
        axios.post('/api/pdfreport/clickRate', {
            location: this.state.location,
            attackTypeId: this.state.attackTypeId,
            selectedCampaignIds: this.state.selectedCampaignIds,
            credentialHarvesting: this.state.credentialHarvesting
        })
        .then(res => {
            this.setState({ 
                loading: false, 
                totalEmails: isNaN(parseInt(res.data.totalEmails, 10))? 0: parseInt(res.data.totalEmails, 10),
                totalClicks: isNaN(parseInt(res.data.totalClicks, 10))? 0: parseInt(res.data.totalClicks, 10)
            })
        })
        .catch(err => {
            apiError(err);
            this.setState({ loading: false })
        })
    }

    categoryClickRate = async() => {
        await this.setState({ loading: true })
        axios.post('/api/pdfreport/categoryClickRate', {
            location: this.state.location,
            attackTypeId: this.state.attackTypeId,
            selectedCampaignIds: this.state.selectedCampaignIds,
            credentialHarvesting: this.state.credentialHarvesting
        })
        .then(res => {
            let categoryClickRate = {}, click = [], category = [];
            for(let i=0; i<res.data.results.length; i++){
                let data = JSON.parse(JSON.stringify(res.data.results[i]));
                category.push(data.campaign_template.category.name);
                click.push(parseInt(data['linkclicked'], 10));
            }
            categoryClickRate['click'] = click;
            categoryClickRate['category'] = category;
            this.setState({ loading: false, categoryClickRate: categoryClickRate })
        })
        .catch(err => {
            apiError(err);
            this.setState({ loading: false })
        })
    }

    groupWiseClickRate = async() => {
        await this.setState({ loading: true })
        axios.post('/api/pdfreport/groupWiseClickRate', {
            location: this.state.location,
            attackTypeId: this.state.attackTypeId,
            selectedCampaignIds: this.state.selectedCampaignIds,
            credentialHarvesting: this.state.credentialHarvesting
        })
        .then(res => {
            let groupWiseClickRate = {}, click = [], name = [];
            for(let i=0; i<res.data.results.length; i++){
                let data = JSON.parse(JSON.stringify(res.data.results[i]));
                name.push(data.group.name);
                click.push(parseInt(data['linkclicked'], 10));
            }
            groupWiseClickRate['name'] = name;
            groupWiseClickRate['click'] = click;
            this.setState({ loading: false, groupWiseClickRate: groupWiseClickRate })
        })
        .catch(err => {
            apiError(err);
            this.setState({ loading: false })
        })
    }

    countryWiseClickRate = async() => {
        await this.setState({ loading: true })
        axios.post('/api/pdfreport/countryWiseClickRate', {
            location: this.state.location,
            attackTypeId: this.state.attackTypeId,
            selectedCampaignIds: this.state.selectedCampaignIds,
            credentialHarvesting: this.state.credentialHarvesting
        })
        .then(res => {
            this.setState({ countryWiseClickRate: res.data.results, totalCountry: res.data.totalCountry })
        })
        .catch(err => {
            apiError(err);
            this.setState({ loading: false })
        })
    }

    countryCount = async() => {
        await this.setState({ loading: true })
        axios.post('/api/pdfreport/countryCount', {
            location: this.state.location,
            attackTypeId: this.state.attackTypeId,
            selectedCampaignIds: this.state.selectedCampaignIds,
            credentialHarvesting: this.state.credentialHarvesting
        })
        .then(res => {
            this.setState({ 
                loading: false,
                clicks: res.data.clicks,
                country: res.data.country,
            })
        })
        .catch(err => {
            apiError(err);
            this.setState({ loading: false })
        })
    }

    loadCampaigns = async() => {
        await this.setState({ loading: true })
        axios.get('/api/campaigns/list', {
            params: { 
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                attackTypeId: this.state.attackTypeId 
            }
        })
        .then(res => {
            var campaigns = [], selectedCampaignIds = [];
            for(var i=0; i<res.data.campaigns.length; i++){
                campaigns.push({
                    value: res.data.campaigns[i].id,
                    label: res.data.campaigns[i].name
                })
                selectedCampaignIds.push(res.data.campaigns[i].id)
            }
            this.setState({ 
                    loading: false, 
                    campaigns: campaigns, 
                    campaignIds: campaigns,
                    selectedCampaignIds: selectedCampaignIds, 
                }, () => {
                    this.handleRefresh();
            });
        })
        .catch(err => {
            apiError(err);
            this.setState({ campaigns: [], loading: false, selectedCampaignIds: [] })
        })
    }

    loadLocation = async() => {
        axios.post('/api/pdfreport/location', {
            endDate: this.state.endDate,
            startDate: this.state.startDate,
            attackTypeId: this.state.attackTypeId,
            selectedCampaignIds: this.state.selectedCampaignIds,
            credentialHarvesting: this.state.credentialHarvesting 
        })
        .then(res => {
            this.setState({ locations: res.data.results });
        })
        .catch(err => {
            apiError(err);
        })
    }
    
    handleSelect = (startDate, endDate) => {
        this.setState({ startDate: startDate, endDate: endDate }, () => this.loadCampaigns())
    }

    handleCampaigns = (e) => {
        let selectedCampaignIds = [];
        for(let i=0; i<e.length; i++){
            selectedCampaignIds.push(e[i].value)
        }
        this.setState({ selectedCampaignIds: selectedCampaignIds, campaignIds: e})
    }

    downloadReport = async() => {
        const input = document.getElementById("report");
        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            this.downloadBase64File(imgData, 'report.png');
        })
    }

    downloadBase64File = (base64Data, fileName) => {
        const downloadLink = document.createElement("a");
        const linkSource = base64Data;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }


    printReport = () => {
        html2canvas(this.reportRef.current).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            let imageHeight = this.reportRef.current?.clientHeight;
            let imageWidth = this.reportRef.current?.clientWidth;
            let ratio = imageHeight / imageWidth;

            let orientation = 'portrait';

            if(ratio < 1){
                orientation = 'landscape';
            }

            const pdf = new jsPDF({
                orientation: orientation,
                unit: 'pt',
                format: [canvas.width + 40, canvas.height + 40 ],
            });
            
            pdf.addImage(imgData, "JPEG", 20, 20, canvas.width, canvas.height);
            pdf.save(`Click-Reports.pdf`);
        }).catch(err =>{
            apiError(err);
        });
    }

    render() {
        return (
            <div className="content-wrap">
                <div className="breadcrumbbar">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-head d-flex align-items-center justify-content-between">
                                <h4 className="page-title">Click Reports</h4>
                                <Breadcrumb className="mb-0">
                                    <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
                                    <Breadcrumb.Item href="#" active>Click Reports</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </div>
                    </div>
                </div>
                <Card>
                    <Card.Body>
                        <div className="page-content" id="graph-wrap">
                            <div className="row bg-light py-2 align-items-center">
                                <div className="col-md-3">
                                    <h6 className="tab-head mb-0">Click Reports</h6>
                                </div>
                                <div className="col-md-9 text-left">
                                    <div className="d-flex justify-content-end">
                                        <Form.Group controlId="exampleForm.ControlSelect1" className="mr-2 mb-0">
                                            <Form.Control as="select" value={this.state.attackTypeId} onChange={e => this.setState({attackTypeId: e.target.value}, () => this.loadCampaigns())}>
                                                {this.state.attackTypes.map((attacktype, index) => {
                                                    return <option key={index} value={attacktype.id}>{attacktype.name}</option>
                                                })}
                                            </Form.Control>
                                        </Form.Group>
                                        <Button variant="primary" className="mr-2" onClick={this.printReport}>Download Report</Button>
                                        <Button variant="dark" onClick={() => this.setState({reportModal: true})}><FilterListSharpIcon className="btn-icon" />Filter</Button>
                                    </div>
                                </div>
                            </div>
                            <div id='report' ref={this.reportRef}>
                                <div className="row my-5">
                                    <div className="col-md-4">
                                        <div className="click-block">
                                            <div className="click-rate">
                                                <h5>Total Click</h5>
                                                <h1>{isNaN(this.state.totalClicks)? 0: this.state.totalClicks}</h1>
                                            </div>
                                            <div className="total-img">
                                                <img src={total} alt="total-click" className="img-fluid"></img>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="click-block">
                                            <div className="click-rate">
                                                <h5>Average Click Rate</h5>
                                                <h1>{!isNaN(((this.state.totalClicks*100)/this.state.totalEmails).toFixed(2))? ((this.state.totalClicks*100)/this.state.totalEmails).toFixed(2): 0}</h1>
                                            </div>
                                            <div className="avg-img">
                                                <img src={avg} alt="total-click" className="img-fluid"></img>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="click-block">
                                            <div className="click-rate">
                                                <h5>Country Click</h5>
                                                <h1>{this.state.country}</h1>
                                            </div>
                                            <div className="country-img">
                                                <img src={country} alt="total-click" className="img-fluid"></img>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row graph-row">
                                    <div className="col-md-4 right-border">
                                        <h6 className="mb-3">Category Click Response</h6>
                                        <CategoryClickResponse categoryClickRate={this.state.categoryClickRate}></CategoryClickResponse>
                                    </div>
                                    <div className="col-md-4 right-border">
                                        <h6 className="mb-3">Group Wise Click Rate Graph</h6>
                                        <ClickRateGrapgh groupWiseClickRate={this.state.groupWiseClickRate}></ClickRateGrapgh>
                                    </div>
                                    <div className="col-md-4">
                                        <h6 className="mb-3">Top 5 Countries Click Rate</h6>
                                        {this.state.clicks? 
                                            <Table className="top-countries mt-5">
                                                <tbody>
                                                    {this.state.countryWiseClickRate.map((result, index) => {
                                                        return parseInt(result.linkclicked, 10) > 0? 
                                                            <tr key={index}>
                                                                <td>{result.countryCode? result.countryCode: 'Unknown'}</td>
                                                                <td>{parseInt(result.linkclicked, 10)}</td>
                                                                <td className="w-50"><ProgressBar variant="success" now={(((parseInt(result.linkclicked, 10))*100)/this.state.clicks)}/></td>
                                                            </tr>: null
                                                    })}
                                                </tbody>
                                            </Table>: null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
                <ReportModal 
                    /* for date range picker */
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    handleSelect={this.handleSelect}
                    /* for attack type */
                    attackTypes={this.state.attackTypes}
                    attackTypeId={this.state.attackTypeId}
                    handleAttackType={e => this.setState({attackTypeId: e.target.value}, () => this.loadCampaigns())}
                    /* for campaigns */
                    campaigns={this.state.campaigns}
                    selectCampaigns={this.state.campaignIds}
                    handleCampaigns={this.handleCampaigns}
                    /* for modal */
                    reportModal={this.state.reportModal} 
                    reportHandle={e=>this.setState({reportModal: false})}
                    /* for credencial harvesting */
                    credentialHarvesting={this.state.credentialHarvesting}
                    handleHarvesting={e => this.setState({credentialHarvesting: e.target.value})}
                    /* for location */
                    location={this.state.location}
                    locations={this.state.locations}
                    handleLocation={e => this.setState({ location: e.target.value })}
                    /* clear filtes */
                    clearFilters={e=>this.setState({
                        campaignIds: [],
                        totalEmails: 1,
                        averageClick: 0,
                        reportModal: false,
                        endDate: new Date(),
                        selectedCampaignIds: [],
                        credentialHarvesting: 'NA',
                        startDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
                    }, () => this.loadCampaigns()) }
                    applyFilter={e => this.setState({reportModal: false}, () => this.handleRefresh())}
                ></ReportModal>
                {this.state.loading? <Loader></Loader>: null}
            </div>
        );
    }
}