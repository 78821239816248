import React, { Component } from "react";
import {  Card, Breadcrumb } from 'react-bootstrap';

import './lms.css'

export default class LMS extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        }
    }
    render() {
        return (
            <div className="content-wrap">
                
                <div className="breadcrumbbar">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-head d-flex align-items-center justify-content-between">
                                <h4 className="page-title">Learning Management System</h4>
                                <Breadcrumb className="mb-0">
                                    <Breadcrumb.Item href="#" active>Learning Management System</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </div>
                    </div>
                </div>

                <Card>
                    <Card.Body>
                        <h6>this will be redirect in new tab</h6>
                    </Card.Body>
                </Card>
                
            </div>
        );
    }
}