import React, { Component } from "react";
import { Accordion, Card, Button } from "react-bootstrap";
import ArrowForwardSharpIcon from "@material-ui/icons/ArrowForwardSharp";
import ChevronRightSharpIcon from "@material-ui/icons/ChevronRightSharp";

import ReplyModal from "./reply-modal";
import { getDate } from "../../../function";

export default class Results extends Component {
	state = { show: false };

	onButtonClick = (eventId) => {
		document.getElementById(eventId).click();
	};

	render() {

		let count = 1;
		const result = this.props.result;
		const events = result && result.events ? result.events : [];
		const target = result && result.target ? result.target : {};
		const status = this.props.statusFilter === "all" ? result.status : this.props.statusFilter;
		
		return (
			<div>
				<Accordion.Toggle
					as={Card.Header}
					eventKey={this.props.index}
					className={this.props.activeRow ? "active-toggle" : ""}
					onClick={(e) => this.props.handleActiveCampaign(result.id)}
				>
					<div className="row">
						<div className="col-md-1 expandGrid">
							<ChevronRightSharpIcon className="right2-arrow"></ChevronRightSharpIcon>
						</div>

						<div className="col-md-3 tablenameEmail">
							<h6>{target.firstname} {target.lastname ? target.lastname : ""}</h6>
							<span>{target.email}</span>
						</div>

						<div className="col-md-2">{target.phone ? target.phone : "---"}</div>
						<div className="col-md-2">{target.position ? target.position : "---"}</div>

						{status === "Email Sent" || status === "SMS Sent" || status === "Call Initiated" || status === "Sending" || status === "Message Sent" ? (
							<div className="col-md-2">
								<span className="badge badge-pill badge-soft-primary">{status}</span>
							</div>
						) : null}
						{status === "Email Opened" ? (
							<div className="col-md-2">
								<span className="badge badge-pill badge-soft-secondry">{status}</span>
							</div>
						) : null}
						{status === "Link Clicked" || status === "Call Picked" ? (
							<div className="col-md-2">
								<span className="badge badge-pill badge-soft-warning">{status}</span>
							</div>
						) : null}
						{status === "Data Submitted" || status === "Error" || status === "System Hacked" || status === "Attachment Opened" ? (
							<div className="col-md-2">
								<span className="badge badge-pill badge-soft-danger">{status}</span>
							</div>
						) : null}
						{status === "Email Reported" ? (
							<div className="col-md-2">
								<span className="badge badge-pill badge-soft-primary">{status}</span>
							</div>
						) : null}
						{status === "Replied" ? (
							<div className="col-md-2">
								<span className="badge badge-pill badge-soft-secondry">{status}</span>
							</div>
						) : null}
						{/* <div className="col-md-1">{result.countryCode ? result.countryCode : "---"}</div> */}
						{/* <div className="col-md-1">{result.target.department? result.target.department.name : "---"}</div> */}
						<div className="col-md-2">
							<p>{result.target.location ? result.target.location.name : "---"} <span>{result.countryCode ? result.countryCode : "---"}</span></p>
						</div>
					</div>
				</Accordion.Toggle>
				<Accordion.Collapse eventKey={this.props.index}>
						<div className="col-md-12 activity-list activityTable">
							<h6 className="mb-0 activity-head">
								<span>{target.email}</span> Activity Timeline
							</h6>
							{events.map((event, index) => {
								try {
									var details = event.message === "Error" && event.details ? JSON.parse(JSON.stringify(event.details)) : event.details
									return (
										<div className="activities-history mt-4 col-md-12" key={"event-" + index}>
											<div className={events.length - 1 === index ? "activities-history-list last-child" : "activities-history-list"}>
												<div className="activities-history-item">
													<div className="row align-items-center">
														<h6 className="col-md-2">
															Activity {count++} <ArrowForwardSharpIcon className="arrow"></ArrowForwardSharpIcon>
														</h6>
														{event.message === "Email Sent" ||
															event.message === "SMS Sent" ||
															event.message === "Call Initiated" ||
															event.message === "Sending" ||
															event.message === "Message Sent" ? (
															<div className="col-md-2">
																<span className="badge badge-pill badge-soft-primary">{event.message}</span>
															</div>
														) : null}
														{event.message === "Email Opened" ? (
															// <div className="col-md-2"><span className="badge badge-pill badge-soft-success"  style={{display: 'inline-flex'}}>{event.message}{isGateway? <p style={{color: 'red', marginBottom: '2px', marginLeft: '4px'}}>{isGateway}</p>: null}</span></div>:null
															<div className="col-md-2">
																<span className="badge badge-pill badge-soft-success" style={{ display: "inline-flex" }}>
																	{event.message}
																</span>
															</div>
														) : null}
														{event.message === "Link Clicked" || event.message === "Call Picked" ? (
															<div className="col-md-2">
																<span className="badge badge-pill badge-soft-warning" style={{ display: "inline-flex" }}>
																	{event.message}
																</span>
															</div>
														) : null}
														{event.message === "Data Submitted" ||
															event.message === "Error" ||
															event.message === "System Hacked" ||
															event.message === "Attachment Opened" ? (
															<div className="col-md-2">
																<span className="badge badge-pill badge-soft-danger">{event.message}</span>
															</div>
														) : null}
														{event.message === "Email Reported" ? (
															<div className="col-md-2">
																<span className="badge badge-pill badge-soft-primary">{event.message}</span>
															</div>
														) : null}
														{event.message === "Replied" ? (
															<div className="col-md-2">
																<span className="badge badge-pill badge-soft-success">{event.message}</span>
															</div>
														) : null}
														<h6 className="mb-0 col-md-6 d-flex align-items-center">
															{getDate(event.createdAt)}
															{details && Object.keys(details).length && (
																	event.message === "Error" ||
																	event.message === "Data Submitted" ||
																	event.message === "System Hacked" //||
																	// event.message === "Replied"
																) ? 
																<Button variant="success" className="ml-4" onClick={(e) => this.onButtonClick(event.id)}>
																	View Details
																</Button>
															: null}
															{event.message === "Replied" && result.uIds && result.uIds.length && result.uIds[0].buffer ? (
																<Button variant="success" className="ml-2" onClick={(e) => this.setState({ show: true })}>
																	View Reply Details
																</Button>
															) : null}
														</h6>
														{details &&
															Object.keys(details).length && (
																event.message === "Error" ||
																event.message === "Data Submitted" ||
																event.message === "System Hacked" //||
																// event.message === "Replied"
															) ? 
															<div>
																<Accordion className="accordion2">
																	<Card>
																		<Accordion.Toggle eventKey={index + ":" + index} className="d-none" id={event.id}></Accordion.Toggle>
																		<Accordion.Collapse eventKey={index + ":" + index}>
																			<Card.Body>
																				{typeof details === 'object'? 
																					Object.keys(details).map((key, index) => {
																						return (
																							<div className="row" key={"details-" + index}>
																								<p className="col-sm-3">{key}</p>
																								<p className="col-sm-9">{details[key]}</p>
																							</div>
																						);
																					}):
																					<div className="row">
																						<p className="col-sm-3">Message</p>
																						<p className="col-sm-9">{details}</p>
																					</div>
																				}
																			</Card.Body>
																		</Accordion.Collapse>
																	</Card>
																</Accordion>
															</div>
														: null}
													</div>
												</div>
											</div>
										</div>
									);
								} catch (err) {
									console.log(err);
									return null;
								}
							})}
							<ReplyModal
								show={this.state.show}
								handleClose={(e) => this.setState({ show: false })}
								buffer={result && result.uIds && result.uIds.length && result.uIds[0].buffer}
							></ReplyModal>
						</div>
				</Accordion.Collapse>
			</div>
		);
	}
}
