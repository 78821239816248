import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import DoneAllSharpIcon from '@material-ui/icons/DoneAllSharp';
import MoreVertSharpIcon from '@material-ui/icons/MoreVertSharp';

import round from '../../../assets/round.png';
import template from '../../../assets/temp.png';
export default class Gamified extends Component{
    
    render(){
        return (
            <div className="tabContent-block">
                <div className="row justify-content-between d-flex align-items-center mb-3">
                    <div className="col-md-6">
                        <h6 className="tab-head">Game</h6>
                    </div>
                    <div className="col-md-6 text-right">
                    <Button variant="success" type="button">
                        <DoneAllSharpIcon className="btn-icon"></DoneAllSharpIcon> Save </Button>
                    </div>
                </div>  
                <div className="row">
                    <div className="col-md-6">
                        <Form.Group>
                            <Form.Label>Game</Form.Label>
                            <Form.Control as="select">
                                <option>Select Game</option>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                            </Form.Control>
                        </Form.Group>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="tempt-img-box">
                                    <Link to="/home" className="img-link">
                                        <div className="content-overlay">
                                            <img src={template} alt="template" className="img-fluid"></img>
                                        </div>
                                    </Link>
                                    <div className="box-foot d-flex justify-content-between align-items-center">
                                        <h6 className="temp-head"><img src={round} alt="template" className="img-fluid"></img> Gmae Template</h6>
                                        <Button className="btn-dots p-0 m-0"><MoreVertSharpIcon></MoreVertSharpIcon></Button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="tempt-img-box">
                                    <Link to="/home" className="img-link">
                                        <div className="content-overlay">
                                            <img src={template} alt="template" className="img-fluid"></img>
                                        </div>
                                    </Link>
                                    <div className="box-foot d-flex justify-content-between align-items-center">
                                        <h6 className="temp-head"><img src={round} alt="template" className="img-fluid"></img> Game Template</h6>
                                        <Button className="btn-dots p-0 m-0"><MoreVertSharpIcon></MoreVertSharpIcon></Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 text-right">
                    <Button variant="success" type="button">
                    <DoneAllSharpIcon className="btn-icon"></DoneAllSharpIcon> Save </Button>
                </div>
            </div> 
        )
    }
}