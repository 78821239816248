import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { GoogleLogin } from "react-google-login";

import "./gsuite-modal.css";

export default class GSuiteModal extends Component {
    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={() => this.props.showHandle()}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title">
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        <h6 className="mb-0">Import from G-Suite</h6>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Import all users associated with your G-suite admin account by logging into the account through
                        the button given below.
                    </p>
                    <div className="text-center">
                        <GoogleLogin
                            clientId="766097122404-rnla1a14fgrl92rcotqbdhkb015134ha.apps.googleusercontent.com"
                            buttonText="Sign up using Google"
                            className="login-with-google-btn"
                            scope="https://www.googleapis.com/auth/admin.directory.user.readonly https://www.googleapis.com/auth/admin.directory.group.readonly"
                            onSuccess={this.props.responseGoogle}
                            onFailure={this.props.responseGoogle}
                            cookiePolicy={"single_host_origin"}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}
