import React, { Component } from "react";
import ApexCharts from 'apexcharts'

export default class Dept extends Component {
	constructor(props) {
		super(props);
		this.state = { chart: null }
	}

	componentDidMount = () => {
		this.load();
	}

	load = () => {
		let options = {
			series: [{
				name: 'repeatOffenders',
				data: this.props.byDepartment['repeatOffenders']? this.props.byDepartment['repeatOffenders']: []
			}],
			chart: {
				type: 'bar',
				height: 350,
				toolbar: {
					show: false
				}
			},
			plotOptions: {
				bar: {
					horizontal: false,
					dataLabels: {
						position: 'top',
					},
				}
			},
			dataLabels: {
				enabled: true,
				offsetX: -6,
				style: {
					fontSize: '12px',
					colors: ['#fff']
				}
			},
			stroke: {
				show: true,
				width: 1,
				colors: ['#fff']
			},
			xaxis: {
				labels: {
					show: false
				},
				categories: this.props.byDepartment['name']? this.props.byDepartment['name']: []
			},
		}
		var chart = new ApexCharts(document.querySelector("#dept"), options);
		chart.render();
		this.setState({chart: chart});
	}

	componentDidUpdate = (prevProps) => {
		if(this.props.byDepartment !== prevProps.byDepartment){
			if(this.state.chart) this.state.chart.destroy();
			this.componentDidMount();
		}
	}

	render() {
		return (
			<div id="dept"></div>
		);
	}
}