import React, { Component } from "react";
import ApexCharts from 'apexcharts'

export default class MonthlyRisk extends Component {
	constructor(props) {
		super(props);
		this.state = {
			chart: null,
		};
	}

	componentDidMount = () => {
		this.load();
	}

	load = () => {
		var options = {
			series: [{
				name: "Link Clicked",
				data: this.props.simulationRisk['linkclicked']? this.props.simulationRisk['linkclicked']: []
			}, {
				name: "Data Submitted",
				data: this.props.simulationRisk['datasubmitted']? this.props.simulationRisk['datasubmitted']: []
			}],
			chart: {
				height: 300,
				type: 'bar',
				toolbar: {
					show: false
				}
			},
			colors: ['#F9C546', '#f04b6f'],
			dataLabels: {
				enabled: false
			},
			plotOptions: {
				bar: {
				  horizontal: false,
				  columnWidth: '55%',
				  endingShape: 'rounded'
				},
			},
			stroke: {
				show: true,
				width: 2,
				colors: ['transparent']
			},
			fill: {
				opacity: 1
			},
			grid: {
				position: 'front'
			},
			xaxis: {
				type: 'datetime',
				categories: this.props.simulationRisk.date? this.props.simulationRisk.date: [],
			},
			markers: {
				size: 5,
			}
		}
		var chart = new ApexCharts(document.querySelector("#risk"), options);
		if(chart) chart.render();
		this.setState({chart: chart});
	}

	componentDidUpdate = (prevProps) => {
		if(this.props.simulationRisk !== prevProps.simulationRisk){
			if(this.state.chart) this.state.chart.destroy();
			this.load();
		}
	}

	render() {
		return (
			<div id="risk"></div>
		);
	}
}