import axios from 'axios';
import { getCookie } from './function';

const BASE_URL = process.env.REACT_APP_API_URL;

const instance = axios.create({ baseURL: BASE_URL })

instance.defaults.headers.common['Authorization'] = getCookie('token');
instance.defaults.headers.common['Referrer-Policy'] = 'origin';
instance.defaults.headers.common['X-Content-Type-Options'] = 'nosniff';
instance.defaults.headers.common['Content-Security-Policy'] = 'default-src none';
instance.defaults.headers.common['Strict-Transport-Security'] = ' max-age=63072000; includeSubDomains; preload'; 

export default instance;