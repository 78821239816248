import React, { Component } from "react";
import Tooltip from 'react-bootstrap/Tooltip';
import DateTimePicker from 'react-datetime-picker';
import { Modal, Button, Form } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

export default class ScheduledModal extends Component {
    render(){
        return (
            <Modal show={this.props.showModal} className="chooseModal" onHide={() => this.props.showHandle()} dialogClassName="modal-90w" aria-labelledby="example-custom-modal-styling-title">
                <Modal.Header closeButton>
                    <h5>Select Scheduled Time</h5>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Schedule</Form.Label>
                        <DateTimePicker
                            minDate={new Date()}
                            value={this.props.date}
                            onChange={this.props.handleDateTime}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.showHandle}> Close </Button>
                    <OverlayTrigger
                        placement='top'
                        overlay={<Tooltip id='tooltip-disabled'>{new Date(this.props.date) < new Date()? 'Schedule Button will be enable when scheduled time is grater than current time': 'Schedule Campaign'}</Tooltip>}>
                        <span className="d-inline-block">
                            <Button variant="primary" disabled={new Date(this.props.date) < new Date()} onClick={this.props.runCampaign}> Schedule </Button>
                        </span>
                    </OverlayTrigger>
                </Modal.Footer>
            </Modal>
        );
    }
}