import swal from 'sweetalert';
import { toast } from "react-toastify";
import React, { Component } from "react";
import Tooltip from 'react-bootstrap/Tooltip';
import { Button, Form } from 'react-bootstrap';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import TwoFa from './two-fa';
import axios from '../../../axios';
import apiError from '../../../error';
import { setCookie } from '../../../function';
import Loader from '../../../component/loader/loader';

export default class Profile extends Component {
    constructor(props){
        super(props);
        this.state = {
            day: 0,
            email: '',
            lastname: '',
            firstname: '',
            otpType: null,
            loading: true,
            disabled: true,
            twoFactor: false,
            dayDisabled: true,

            modal: false,
        }
    }

    componentDidMount = () => {
        this.loadProfile();
        this.loadDay();
    }
    
    loadProfile = () => {
        this.setState({ loading: true })
        axios.get('/api/user', {})
        .then(res => {
            this.setState({
                loading: false,
                email: res.data.user.email,
                firstname: res.data.user.firstname,
                lastname: res.data.user.lastname,
                twoFactor: res.data.user.twoFactor,
                otpType: res.data.user.twoFactor? res.data.user.otpType: null,
            })
        })
        .catch(err => {
            apiError(err);
            this.setState({ loading: false });
        })
    }

    handleProfile = () => {
        if(this.state.firstname.length === 0 || (this.state.firstname).trim().length === 0 ){
            toast.error("Please enter first name");
            return false;
        }
        if(this.state.lastname.length === 0 || (this.state.lastname).trim().length === 0 ){
            toast.error("Please enter lastname");
            return false;
        }
        axios.patch('/api/user', {
            firstname: this.state.firstname,
            lastname: this.state.lastname,
        })
        .then(res => {
            toast.success("profile updated successfully");
            setCookie('firstname', this.state.firstname, 30000);
        })
        .catch(err => {
            apiError(err);
        })
    }

    loadDay = () => {
        axios.get('/api/meta/days', {})
        .then(res => {
            this.setState({ day: res.data.day })
        })
        .catch(err => {
            apiError(err);
        })
    }

    handleDay = () => {
        axios.post('/api/meta/days', {
            day: this.state.day
        })
        .then(res => {
            toast.success("Day updated successfully");
        })
        .catch(err => {
            apiError(err);
        })
    }

    deleteAccount = () => {
        swal({
            title: "Do you want to delete this Account?",
            icon: "info",
            text: "If you delete account you will not recover your data!",
            buttons: [ 'No', 'Yes' ],
        })
        .then(willSuccess => {
            if (willSuccess) {
                this.setState({ loading: true })
                axios.post('/api/user/disabled', {})
                .then(r => {
                    toast.success('Please check your mail for confirmation!');
                    this.setState({ loading: false })
                })
                .catch(err => {
                    apiError(err);
                    this.setState({ loading: false })
                })
            }
        })
    }

    handleOtpType = (otpType) => {
        this.setState({ otpType: otpType })
    }

    handleClose = () => {
        if(!this.state.otpType){
            toast.error('Please select one of them');
        } else {
            this.setState({ modal: false })
        }
    }

    handleUpdate = (status) => {
        if(status === false || this.state.otpType === 'email'){
            this.setState({ loading: true, twoFactor: status })
            axios.patch('/api/user/two-fa', {
                twoFactor: status
            })
            .then(r => {
                if(status){
                    toast.success("Two Factor enable successfully")
                } else {
                    toast.success("Two Factor disable successfully")
                }
                this.setState({ modal: false, loading: false });
                this.loadProfile();
            })
            .catch(err => {
                apiError(err);
                this.setState({ loading: false })
            })
        } else {
            this.setState({ modal: false });
            this.props.nextPath('/settings/auth');
        }
    }

    render() {
        return (
            <div className="tab-wrap">
                 <div className='tabHeading'>
                        <h6>User Information</h6>
                        <p>Displays basic information associ </p>
                     </div>
                    <div className='col-md-4 pl-0'>
                            <div className='profile-form'>
                                <Form>
                                        <Form.Group>
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control type="text" placeholder="First name" value={this.state.firstname} onChange={e => this.setState({firstname: e.target.value, disabled: false})} />
                                        </Form.Group>
                                        
                                        <Form.Group>
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control type="text" placeholder="Last name" value={this.state.lastname} onChange={e => this.setState({lastname: e.target.value, disabled: false})}/>
                                        </Form.Group>
                                        {this.state.twoFactor && 
                                            <Form.Group>
                                                <Form.Label style={{marginTop: '35px'}}>Two Factor is Enabled through <span style={{color: 'green'}}>{this.state.otpType==='email'? "Email": "Mobile App"}</span></Form.Label>
                                            </Form.Group>
                                        }
                                
                                        <Form.Group>
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control type="email" placeholder="Enter email" disabled={true} value={this.state.email} />
                                        </Form.Group>
                                    <div className='formBtn'>
                                        <Button variant="success" className="btn btn-success mr-2" type="button" disabled={this.state.disabled} onClick={this.handleProfile} >Save</Button>
                                            {/* <Button variant="warning" className="mr-2" type="button" onClick={this.deleteAccount} >Delete Account</Button> */}
                                            <Button variant="primary" className="" type="button" onClick={e=> this.setState({ modal: true })} >Two Factor Authentication</Button>
                                    </div>
                                        
                                </Form>
                            </div>
                    </div> 

                        <div className='profile-form'>
                             <div class="tabHeading">
                                <h6>Delete Account</h6>
                                <p>Deleting your accoutn will remove all your content, messages and data associated with it</p>
                            </div>
                            <Button className="deleteBtn" onClick={this.deleteAccount}>I want to delete my account</Button>
                        </div>
                      

{/* 
                        <div className='profile-form'>
                            <div className='tabHeading'>
                                <h6>Automatic Campaign Expired 
                                    <OverlayTrigger
                                        placement='top'
                                        overlay={<Tooltip id='top'>Auto Compeletion of simulation campaign</Tooltip>}>
                                        <HelpOutlineIcon className="tooltip-icon"></HelpOutlineIcon>
                                    </OverlayTrigger> : </h6>
                                    <p>This information is used while generating report also</p>
                                </div>

                                <Form>
                                    <Form.Group>
                                        <Form.Label>Days</Form.Label>
                                        <Form.Control type="number" min="1" max="7" placeholder="campaign expired after day" value={this.state.day} onChange={e => this.setState({day: e.target.value, dayDisabled: false})}/>
                                        </Form.Group>
                                    <Button variant="success" disabled={this.state.dayDisabled} type="button" onClick={this.handleDay}>Save</Button>
                                    
                                </Form>
                        </div> */}
                 

                     {/* <div className='d-none'>
                        <div className='tabHeading'>
                            <h6>Time Zone : </h6>
                        </div>

                        <Form>
                            <Form.Group>
                                <Form.Label>Time zone code</Form.Label>
                                    <Form.Control as="select">
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                    </Form.Control>
                                </Form.Group>
                            <Button className="btn btn-success" type="button">Save</Button>
                        </Form>
                     </div> */}
                                


                    <TwoFa
                        show={this.state.modal}
                        otpType={this.state.otpType}
                        handleUpdate={this.handleUpdate}
                        handleOtpType={this.handleOtpType}
                        handleClose={e => this.setState({ modal: false })}
                    ></TwoFa>
                    {this.state.loading? <Loader></Loader>: null}
                   
            </div>
        );
    }
}