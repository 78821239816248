import React, { Component } from "react";
import ApexCharts from 'apexcharts'

export default class EVSChart extends Component {
	constructor(props) {
		super(props);
		this.state = { chart: null };
	}

	load = () => {
		let options = {
			series: [this.props.percent],
			chart: {
				type: 'radialBar',
				height: 320,
				width: 270,
				offsetY: -20,
				sparkline: {
					enabled: true
				}
			},
			colors: ["#F83F38"],
			plotOptions: {
				radialBar: {
					hollow: {
						margin: 15,
						size: "60%"
					},
					startAngle: -90,
					endAngle: 90,
					track: {
						background: "#e7e7e7",
						strokeWidth: '80%',
						margin: 15,
					},
					dataLabels: {
						name: {
							show: false
						},
						value: {
							offsetY: -10,
							fontSize: '24px'
						}
					}
				}
			},
			grid: {
				padding: {
					top: -10
				}
			},
			stroke: {
				lineCap: "round",
			}
		}
		var chart = new ApexCharts(document.querySelector("#evsChart"), options);
		chart.render();
		this.setState({ chart: chart })
	}

	componentDidUpdate = (prevProps) => {
		if (this.props.percent !== prevProps.percent) {
			if (this.state.chart) {
				this.state.chart.destroy();
			}
			this.load();
		}
	}

	render() {
		return (
			<div id="evsChart"></div>
		);
	}
}