import { Component } from "react";
import ApexCharts from 'apexcharts'

export default class PhishRateLine extends Component {
	constructor(props) {
		super(props);
		this.state = {
			chart: ''
		}
	}
	componentDidMount = () => {
		let options = {
			series: [{
				name: "Industry Rate",
				data: [10, 41, 35, 51, 49]
			}],
			chart: {
				height: 50,
				type: 'line',
				zoom: {
					enabled: false
				},
				sparkline: { enabled: true },
				toolbar: {
					show: false,
					enabled: false
				}
			},
			dataLabels: {
				show: false,
				enabled: false
			},
			stroke: {
				curve: 'smooth',
				width: 2,
			},

			tooltip: {
				enabled: false
			},

			grid: {
				show: false,
				enabled: false
			},
		}
		var chart = new ApexCharts(document.querySelector("#line"), options);
		chart.render();
	}

	render() {
		return '';
	}
}