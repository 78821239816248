import React, { Component } from "react";
import swal from "sweetalert";
import { toast } from "react-toastify";
import Pagination from "react-js-pagination";
import AddIcon from '@material-ui/icons/Add';
import SyncIcon from '@material-ui/icons/Sync';
import Tooltip from "@material-ui/core/Tooltip";
import AddSharpIcon from "@material-ui/icons/AddSharp";
import MoreVertSharpIcon from "@material-ui/icons/MoreVertSharp";
import SearchSharpIcon from '@material-ui/icons/SearchSharp';
import { PiCloudArrowDownLight, PiPlus } from "react-icons/pi";


import { FormControl, Button, InputGroup, Card, Breadcrumb, Form, Dropdown, Table } from 'react-bootstrap';

import axiosRequest from 'axios';
import axios from '../../../axios';
import apiError from '../../../error';
import Loader from '../../../component/loader/loader';
import Spinner from '../../../component/spinner/spinner';
import ADPassword from '../../../component/department/adPassword';
import Department from '../../../component/department/department';
import GSuiteModal from '../../../component/rightside-modal/gsuite-modal';
import AzureModal from '../../../component/rightside-modal/azure-modal';
import GroupHierarchy from '../../../component/rightside-modal/group-hierarchy';
import ADGroups from '../../../component/rightside-modal/right-side-group-ad';

import './department.css';
var cancelRequest = undefined, CancelToken = axiosRequest.CancelToken;

export default class DepartmentList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            count: 0,
            limit: 10,
            search: '',
            groups: [],
            activePage: 1,
            loading: true,
            finalArray: [],
            syncLoading: false,
            showHierarchy: false,

            password: '',
            adModal: false,
            adLoading: false,
            showAdPassword: false,

            adGroups: [],
            groupModal: false,

            gsuiteModal: false,
            azureModal: false
        }
    }

    componentDidMount = () => {
        this.load();
        this.loadParentGroup();
        this.loadADPassword()
    }

    nextPath = path => this.props.history.push(path);

    load = async () => {
        // await this.setState({ loading: true })
        if (cancelRequest) cancelRequest();
        axios.get('/api/groups', {
            params: {
                page: this.state.activePage,
                limit: this.state.limit,
                search: this.state.search
            },
            cancelToken: new CancelToken(function executor(c) { cancelRequest = c })
        })
            .then(async (res) => {
                await this.setState({
                    loading: false,
                    count: res.data.count,
                    groups: res.data.groups,
                })
            })
            .catch(error => {
                if (!axiosRequest.isCancel(error)) {
                    this.setState({ groups: [], count: 0, loading: false })
                    apiError(error);
                }
            })
    }

    loadParentGroup = () => {
        axios.get('/api/groups/parentGroup', {})
            .then(async (res) => {
                var finalArray = []; var dataObj = {};
                res.data.groups.forEach(group => {
                    if (!dataObj[group.parentId]) {
                        dataObj[group.parentId] = [];
                    }
                    dataObj[group.parentId].push(group);
                });
                finalArray = await this.setTreeJson([], 'p', dataObj[0], dataObj);
                this.setState({ finalArray: finalArray });
            })
            .catch(error => {
                apiError(error);
            })
    }

    handleDeleteGroup = (groupId) => {
        swal({
            title: "Do you want to delete group?",
            icon: "warning",
            buttons: ['No', 'Yes'],
            dangerMode: true,
        })
            .then(async willSuccess => {
                if (willSuccess) {
                    await this.setState({ loading: true })
                    axios.delete('/api/groups/' + groupId, {})
                        .then(r => {
                            this.setState({ loading: false })
                            toast.success("Group deleted successfully");
                            this.load();
                        })
                        .catch(error => {
                            this.setState({ loading: false })
                            apiError(error);
                        })
                }
            });
    }

    loadADPassword = () => {
        axios.get('/api/meta/ad', {})
            .then(res => {
                if (res.data && res.data.data && res.data.data.value) {
                    var value = JSON.parse(res.data.data.value);
                    this.setState({ showAdPassword: !value.savePassword })
                }
            })
            .catch(err => {
                apiError(err);
            })
    }

    setTreeJson = (obj, t, group, dataObj) => {
        if (group && group.length > 0) {
            group.forEach(g => {
                var temp = {
                    "text": `<a href="/department/groups/${g.id}">${g.name}  ( ${g.targets} Targets )</a>`,
                    "id": g.id
                }
                if (dataObj && dataObj[g.id]) {
                    temp["state"] = { "opened": true }
                    temp["children"] = this.setTreeJson([], 'c', dataObj[g.id], dataObj);
                }
                obj.push(temp);
            })
        }
        return obj;
    }

    syncGroups = async () => {
        await this.setState({ syncLoading: true, loading: true })
        axios.get('/api/groups/sync', {})
            .then(r => {
                var count = 1;
                toast.success("Group sync will be done soon");
                var interval = setInterval(() => {
                    this.load();
                    if (count === 5) {
                        clearInterval(interval);
                    }
                    count = count + 1;
                }, 5000)
                this.setState({ syncLoading: false })
            })
            .catch(error => {
                apiError(error);
                this.setState({ syncLoading: false, loading: false })
            })
    }

    handleADSAVE = async () => {
        if ((this.state.password && this.state.password.length) || !this.state.showAdPassword) {
            await this.setState({ adLoading: true })
            axios.post('/api/groups/check/AD', {
                password: this.state.password
            })
                .then(res => {
                    this.loadAdGroups();
                    toast.success(res.data.message)
                    this.setState({ adLoading: false, adModal: false })
                })
                .catch(err => {
                    apiError(err);
                    this.setState({ adLoading: false })
                })
        } else {
            toast.error('Please enter password');
        }
    }

    handleShowModal = () => {
        if (this.state.showAdPassword) {
            this.setState({ adModal: true })
        } else {
            this.handleADSAVE();
        }
    }

    loadAdGroups = async () => {
        await this.setState({ loading: true })
        axios.get('/api/groups/active/directory/groups', {
            password: this.state.password
        })
            .then(res => {
                this.setState({loading: false, groupModal: true, adGroups: res.data.groups })
            })
            .catch(err => {
                apiError(err);
                this.setState({ loading: false })
            })
    }

    handleGSuiteModal = () => {
        this.setState({ gsuiteModal: !this.state.gsuiteModal })
    }
    handleAzureModal = () => {
        this.setState({ azureModal: !this.state.azureModal })
    }

    responseGoogle = async (response) => {
        try{
            if (response && response.accessToken && response.accessToken.length > 0) {
                this.setState({ loading: true })
    
                let googleAdminSettingsObject = {
                    accessToken: response.accessToken,
                    email: response.profileObj.email,
                } 
                //fetching group Id if Present
                let setting  = await axios.get('/api/company/getGoogleAdminSettings')
                if(setting.data.groupEnabled){
                    googleAdminSettingsObject["groupId"] = setting.data.groupId
                } 
                await axios.post('/api/groups/g-suite', googleAdminSettingsObject);
                this.load();
                this.handleGSuiteModal();
                this.setState({ gsuiteModal: false });
                toast.success("G-Suite user list  will be updated soon");
               
            }else{
                throw Error('Invalid Response from googleAdmin')
            }
        }catch(err){
            apiError(err);
            this.setState({ loading: false })
        }
    }

   responseAzure = (error, response) => {
    if (!error && response && response.accessToken && response.accessToken.length) {
        this.setState({ loading: true })
        axios.post('/api/groups/azure', {
            accessToken: response.accessToken,
            adminEmail: response.account.username ? response.account.username:null,
            groupId: response.groupId? response.groupId: null
        })
        .then(res => {
            this.load();
            this.handleAzureModal();
            this.setState({ azureModal: false });
            toast.success("Azure user list  will be updated soon");
        })
        .catch(err => {
            //hacky fix
            //this code is  redundant
            let errString = err.response.data.errors[0];
            if (errString.includes('License Limit Exceeded')) {
                const regex = /file:(.+)/;
                const match = errString.match(regex);
                let fileName;
                if (match && match[1]) {
                    fileName = match[1].trim();

                    // Triggering a download
                    const link = document.createElement('a');
                    link.href = `${process.env.REACT_APP_API_URL}/staticpra/failedcsv/${fileName}`; // Assuming fileName is the full path to the file
                    link.download = 'failed-users.csv';   // This will make it download using the original file name. You can provide a name if you want to rename the downloaded file.
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
                err.response.data.errors[0] = 'License Limit Exceeded';
            }
            apiError(err);
            this.setState({ loading: false })
        })
    } else {
        console.log(error); // Log the error to diagnose issues
    }
}

    render() {
        return (
            <div className="content-wrap">


                 {/**********************
                   Breadcrumbbar
                  **********************/}
                <div className="breadcrumbbar">
                    <div className="flexRow row">
                        <div className="col-md-4">
                            <div className="page-head d-flex align-items-center justify-content-between">
                            <h4 className="page-title">Groups</h4>
                            </div>
                        </div>

                        <div className="col-md-8 flexFilterButtons">
                            <div className="btn-container float-right d-flex align-items-center">

                        
                                    <Dropdown className="dropdownIcon">
                                            <Dropdown.Toggle id="dropdown-basic" className="btn btn-trans dropdown-toggle">
                                            <PiCloudArrowDownLight className="ti-cloud-down tiIcon"/>Import Groups
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                    <Dropdown.Item onClick={this.handleGSuiteModal}>Import from G-Suite</Dropdown.Item>
                                                    <Dropdown.Item onClick={this.handleAzureModal}>Import With Azure</Dropdown.Item>
                                                    <Dropdown.Item onClick={this.handleShowModal}>Import from AD</Dropdown.Item>
                                                    <Dropdown.Item onClick={this.syncGroups}>Sync from TLMS</Dropdown.Item>
                                                </Dropdown.Menu>
                                          </Dropdown>

                                          <Button
                                            variant="primary"
                                            onClick={e => this.nextPath('/department/groups/0')}>
                                            <AddSharpIcon className="btn-icon"></AddSharpIcon>
                                            <span className="btn-text">Create Group</span>
                                        </Button>
                            </div>
                        </div>
                    </div>
                </div>
            {/*******************
            Breadcrumbbar End
             *******************/}


            {/**********************
                     Filter
             **********************/}
             <div className="filter-wrapper">
                <div className="alignItemsCenter row">
                    <div className="col-md-3 p-0">
                        <div className="positionSearchIcon">
                            <InputGroup>
                                 <FormControl type="text" placeholder="Search" value={this.state.search} className="inputSearch mr-sm-2 form-control" onChange={e => this.setState({ search: e.target.value, activePage: 1 }, () => { this.load() })} /><SearchSharpIcon className="search-icon"></SearchSharpIcon>
                             </InputGroup>
                        </div>    
                     </div>

                     <div className="col-sm-9 p-0">
                        <div className="rightFilters">
                        <Form.Group controlId="exampleForm.ControlSelect1" onChange={(e) => this.setState({ limit: parseInt(e.      target.value, 10), activePage: 1 }, () => this.load())} className="d-inline-block">
                                        <Form.Control as="select" className="mr-2 btn form-control">
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </Form.Control>
                                    </Form.Group>

                                    <Tooltip title="Refresh" placement="top"><Button className="mr-2 btn disable-primary-btn" onClick={this.load}><SyncIcon className="btn-icon"></SyncIcon></Button></Tooltip>

                                    {/* <Button variant="warning" onClick={e => this.setState({ showHierarchy: true })}>Groups Hierarchy</Button> */}
                           
                            
                            </div>
                     </div>
                
                </div>
             </div>

              {/**********************
                End
             **********************/}





        <div className="contentbar">
                 <div className="tableContainerWrapInner">
                            {/* <div className="row justify-content-between align-items-center mini-head">
                                <div className="col-md-3">
                                    <InputGroup className="search-wrap"><FormControl type="text" placeholder="Search" value={this.state.search} className="mr-sm-2" onChange={e => this.setState({ search: e.target.value, activePage: 1 }, () => { this.load() })} /><SearchSharpIcon className="search-icon"></SearchSharpIcon></InputGroup>
                                </div>
                                <div className="col-md-9 text-right">
                                    
                                    <Button variant="success" className="mr-2" onClick={this.handleGSuiteModal}>Import With G-Suite</Button>
                                    <Button variant="success" className="mr-2" onClick={this.handleAzureModal}>Import With Azure</Button>
                                    <Button variant="success" disabled={this.state.adLoading} className="mr-2" onClick={this.handleShowModal}><Spinner loading={this.state.adLoading}></Spinner>Import With AD</Button>
                                  
                                    <Button variant="success" className="mr-2" onClick={e => this.nextPath('/department/groups/0')}><AddIcon className="btn-icon"></AddIcon>Create Group</Button>


                                    <Button variant="success" className="mr-2" onClick={this.syncGroups}>{this.state.syncLoading ? <Spinner loading={true}></Spinner> : <SyncIcon className="btn-icon"></SyncIcon>}Sync</Button>
                                    <Button variant="warning" onClick={e => this.setState({ showHierarchy: true })}>Groups Hierarchy</Button>
                                </div>
                            </div> */}

                                <Table responsive>
                                        <thead className="bg-light">
                                            <tr>
                                                <th>Group Name</th>
                                                <th>Created At</th>
                                                <th>Members</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>   
                                        
                                {this.state['groups'] && this.state['groups'].length ?
                                    this.state['groups'].map((group, index) => {
                                        return <Department
                                            key={index}
                                            group={group}
                                            index={index}
                                            nextPath={this.nextPath}
                                            handleDeleteGroup={this.handleDeleteGroup}
                                        ></Department>
                                    }) : <h6 className="text-center py-3">No groups found</h6>
                                }
                         
                                </Table>  
                                <div className="pagination-Wrap">
                                    <div className="row">
                                        <div className="col-md-6">
                                            {!this.state['loading'] ?
                                                <Pagination
                                                    itemClass="page-item"
                                                    linkClass="page-link"
                                                    activePage={this.state.activePage}
                                                    itemsCountPerPage={this.state.limit}
                                                    totalItemsCount={this.state.count}
                                                    onChange={(pageNumber) => this.setState({ activePage: pageNumber }, () => this.load())}
                                                        /> : null
                                                }
                                        </div>
                                    </div>
                                </div>
                       </div>
                     
                <GroupHierarchy
                    nextPath={this.nextPath}
                    finalArray={this.state.finalArray}
                    showHierarchy={this.state.showHierarchy}
                    handleClose={e => this.setState({ showHierarchy: false })}
                ></GroupHierarchy>
                <ADPassword
                    adModal={this.state.adModal}
                    password={this.state.password}
                    adLoading={this.state.adLoading}
                    handleADSAVE={this.handleADSAVE}
                    handleAdModal={e => this.setState({ adModal: false })}
                    handlePassword={e => this.setState({ password: e.target.value })}
                ></ADPassword>
                <ADGroups
                    show={this.state.groupModal}
                    adGroups={this.state.adGroups}
                    handleAdGroup={e => this.setState({ groupModal: false })}
                ></ADGroups>
                <GSuiteModal
                    show={this.state.gsuiteModal}
                    showHandle={this.handleGSuiteModal}
                    responseGoogle={this.responseGoogle}
                ></GSuiteModal>
                <AzureModal
                    show={this.state.azureModal}
                    showHandle={this.handleAzureModal}
                    responseAzure={this.responseAzure}
                ></AzureModal>
                {this.state.loading ? <Loader /> : null}
            </div>
            </div>
        );
    }
}