import React, { Component } from "react";
// import { ProgressBar } from 'react-bootstrap';

import { getRandomColor } from '../../../function';
import '../evs.css'
export default class EVStable extends Component {
    
    render() {

        const evs = this.props.evs, score = parseInt(this.props.evs.percent);
        const evsColor = getRandomColor(evs.target.firstname);
        var fullname = evs.target.firstname + " ";
        fullname += evs.target.lastname?evs.target.lastname:'';

        return (
                <tr>
                    <td>
                    <div class="flexTableData">
                        <div class="circle">
                             <p class="circle-inner">{evsColor && evsColor.name?evsColor.name: fullname.charAt(0)}</p>
                        </div>
                        <div>
                            <h6>{fullname}</h6>
                            </div>
                    </div>
                    </td>
                    <td>{evs.target.email}</td>
                    <td>{evs.target.phone?evs.target.phone:'---'}</td>
                    <td>
                        {/* <ProgressBar now={score} variant="danger" animated> */}
                            {(score>=66)?<p style={{"color": "red"}}>{Math.trunc(score)}%</p>:(score>=33)?<p style={{"color": "#F1B44C"}}>{Math.trunc(score)}%</p>:<p style={{"color": "#82b8f5"}}>{Math.trunc(score)}%</p>}
                        {/* </ProgressBar> */}
                    </td>
                    <td>
                        <a className="viewCss" onClick={e => this.props.nextPath('/evs/'+evs.id)}>View</a>
                    </td>
                </tr>
        );
    }
}