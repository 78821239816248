import React, { Component } from 'react';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import { Modal, Form, Button } from 'react-bootstrap';
import AddIcon from '@material-ui/icons/AddCircle'
import Delete from '@material-ui/icons/Delete';
import './test-campaign-email.css';
import { toast } from 'react-toastify';

export default class Preview extends Component {
    constructor() {
        super();

        this.targetDetails = [];
        this.lfirstname = [''];
        this.llastname = [''];
        this.lemails = [''];

        this.state = {
            tgDetails: this.targetDetails,
            lfirstname: this.lfirstname,
            llastname: this.llastname,
            lemails: this.lemails,
            firstname: "",
            lastname: "",
            emails: "",
            phone: "",
            isPhoneNumber: false,
            tdlength: this.lemails.length || this.lfirstname.length || this.llastname.length,
            //FORM VALIDATION
            formError: {
                firstname: 'First Name must be 3 characters long!',
                lastname: 'Last Name must be 2 characters long!',
                email: 'Email is not valid!'
            },
            displayFormErr: {
                firstname: '',
                lastname: '',
                email: ''
            },
            submittedFormError: {
                firstname: 'First Name must be 3 characters long!',
                lastname: 'Last Name must be 2 characters long!',
                email: 'Email is not valid!'
            },
            displaySubmittedFormErr: {
                firstname: '',
                lastname: '',
                email: ''
            }
        }

        this.handleFirstname = this.handleFirstname.bind(this);
        this.handleLastname = this.handleLastname.bind(this);
        this.handleEmails = this.handleEmails.bind(this);
        this.testCampaignTemplt = this.testCampaignTemplt.bind(this);
        this.queueNewForm = this.queueNewForm.bind(this);
        this.deleteQueuedForm = this.deleteQueuedForm.bind(this);
    }


    handleFirstname(e) {
        this.handleValidation(e, "LiveForm");
        this.lfirstname[this.state.tdlength - 1] = e.target.value;
        this.setState({
            firstname: this.lfirstname[this.state.tdlength - 1],
        })
    }
    handleLastname(e) {
        this.handleValidation(e, "LiveForm");
        this.llastname[this.state.tdlength - 1] = e.target.value;
        this.setState({
            lastname: this.llastname[this.state.tdlength - 1]
        })
    }
    handleEmails(e) {
        this.handleValidation(e, "LiveForm");
        this.lemails[this.state.tdlength - 1] = e.target.value;
        this.setState({
            emails: this.lemails[this.state.tdlength - 1]
        })
    }

    handlePhoneNums(e) {
        this.setState({isPhoneNumber: true});
        this.handleValidation(e, "LiveForm");
        this.lemails[this.state.tdlength - 1] = e.target.value;
        this.setState({
            emails: this.lemails[this.state.tdlength - 1]
        })
    }

    handleValidation(e, type) {
        let errors = {
            firstname: '',
            lastname: '',
            email: ''
        }

        if (typeof (e.target.value) === Number) { return; }
        if(this.state.isPhoneNumber) {
            errors.email = this.state.phone.length > 0 ? 'Phone number is not valid': '';  
        } else {
            const validEmailRegex = RegExp(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i);
            switch (e.target.name) {
                case 'firstname':
                    errors.firstname =
                        e.target.value.length < 3
                            ? 'First Name must be 3 characters long!'
                            : '';
                    break;
                case 'lastname':
                    errors.lastname =
                        e.target.value.length < 2
                            ? 'Last Name must be 2 characters long!'
                            : '';
                    break;
                case 'email':
                    errors.email =
                        validEmailRegex.test(e.target.value)
                            ? ''
                            : 'Email is not valid!';
                    break;
                default:
                    break;
            }
        }
        if (type === "LiveForm") {
            this.setState({
                formError: errors,
                displayFormErr: errors,
            })
        }
        if (type === "SubmittedForm") {
            this.setState({
                submittedFormError: errors,
                displaySubmittedFormErr: errors,
            })
        }
    }

    queueNewForm() {
        let error = {
            firstname: this.state.formError.firstname,
            lastname: this.state.formError.lastname,
            email: this.state.formError.email
        }
        if (this.props.attackType === "Smishing" || this.props.attackType === "Vishing" || this.props.attackType === "WhatsApp" ) {
            if(this.state.emails.length === 0){
                this.setState({
                    formError: {
                        firstname: '',
                        lastname: '',
                        emails: ''
                    }
                })
                error = { firstname: '', lastname: '', email: '' };
                toast.error('Please enter phone no')
            }
        }
        if (this.state.formError.firstname.length !== 0) { this.setState({ displayFormErr: error }) }
        else if (this.state.formError.lastname.length !== 0) { this.setState({ displayFormErr: error }) }
        else if (this.state.formError.email.length !== 0) { this.setState({ displayFormErr: error }) }
        else {
            this.targetDetails.push({
                firstname: this.state.firstname,
                lastname: this.state.lastname,
                emails: this.state.emails
            });
            this.lemails.push('');
            this.lfirstname.push('');
            this.llastname.push('');

            this.setState({
                tgDetails: this.targetDetails,
                lfirstname: this.lfirstname,
                llastname: this.llastname,
                lemails: this.lemails,
                firstname: "",
                lastname: "",
                emails: "",
                tdlength: this.lemails.length || this.lfirstname.length || this.llastname.length,
                formError: {
                    firstname: 'First Name must be 3 characters long!',
                    lastname: 'Last Name must be 2 characters long!',
                    email: 'Email is not valid!'
                },
                submittedFormError: {
                    firstname: '',
                    lastname: '',
                    email: ''
                },
                displayFormErr: {
                    firstname: '',
                    lastname: '',
                    email: ''
                },
                displaySubmittedFormErr: {
                    firstname: '',
                    lastname: '',
                    email: ''
                }
            })
        }
    }

    deleteQueuedForm(e, idx) {
        this.targetDetails.splice(idx, 1);

        this.lfirstname.splice(idx, 1);
        this.llastname.splice(idx, 1);
        this.lemails.splice(idx, 1);

        this.setState({
            tgDetails: this.targetDetails,
            lfirstname: this.lfirstname,
            llastname: this.llastname,
            lemails: this.lemails,
            firstname: "",
            lastname: "",
            emails: "",
            tdlength: this.lemails.length || this.lfirstname.length || this.llastname.length,
            displayFormErr: {
                firstname: '',
                lastname: '',
                email: ''
            }
        })

    }

    testCampaignTemplt() {
        if(this.state.firstname.length > 0 && this.state.emails.length > 0){
            this.targetDetails.push({
                firstname: this.state.firstname,
                lastname: this.state.lastname,
                emails: this.state.emails
            });
            this.props.testCampaign(this.targetDetails);

            this.lemails.push('');
            this.lfirstname.push('');
            this.llastname.push('');
            
            this.setState({
                tgDetails: this.targetDetails,
                lfirstname: this.lfirstname,
                llastname: this.llastname,
                lemails: this.lemails,
                firstname: "",
                lastname: "",
                emails: "",
                tdlength: this.lemails.length || this.lfirstname.length || this.llastname.length
            })
        }
        else if(this.state.firstname.length === 0 && this.state.emails.length === 0 && this.state.emails.length === 0){
            // When current entries are empty but some receiver details are already queued
            this.props.testCampaign(this.targetDetails);
        } 
        // else if(this.lemails.length >= 0){
        //     console.log(this.lemails);
        //     this.targetDetails.push({
        //         firstname: this.state.firstname,
        //         lastname: this.state.lastname,
        //         emails: this.state.emails
        //     });
        //     console.log(this.targetDetails);
        //     this.props.testCampaign(this.targetDetails);
        // } 
        else if(this.state.isPhoneNumber && this.state.emails.length > 0) {
            this.targetDetails.push({
                firstname: this.state.firstname,
                lastname: this.state.lastname,
                emails: this.state.emails
            })
            this.props.testCampaign(this.targetDetails);
            this.lemails.push('');
            this.lfirstname.push('');
            this.llastname.push('');

            this.setState({
                tgDetails: this.targetDetails,
                lfirstname: this.lfirstname,
                llastname: this.llastname,
                lemails: this.lemails,
                firstname: "",
                lastname: "",
                emails: "",
                tdlength: this.lemails.length || this.lfirstname.length || this.llastname.length
            })
        }
        else if(this.targetDetails.length !== 0) {
            //Do Nothing
        }else {
            this.targetDetails = [];
    
            this.lemails = [''];
            this.lfirstname = [''];
            this.llastname = [''];
    
            this.setState({
                tgDetails: this.targetDetails,
                lfirstname: this.lfirstname,
                llastname: this.llastname,
                lemails: this.lemails,
                tdlength: this.lemails.length || this.lfirstname.length || this.llastname.length
            })
        }
    }

    handleSubmittedFirstname(e, idx) {
        this.handleValidation(e, "SubmittedForm");
        const error = {
            firstname: this.state.submittedFormError.firstname,
            lastname: this.state.submittedFormError.lastname,
            email: this.state.submittedFormError.email
        }
        if (error.firstname.length !== 0 || error.lastname.length !== 0 || error.email.length !== 0) {
            this.setState({ displaySubmittedFormErr: error })
        }

        this.targetDetails[idx].firstname = e.target.value;
        this.lfirstname[idx] = e.target.value;

        this.setState({
            tgDetails: this.targetDetails,
            lfirstname: this.lfirstname
        })

    }

    handleSubmittedLastname(e, idx) {

        this.handleValidation(e, "SubmittedForm");
        const error = {
            firstname: this.state.submittedFormError.firstname,
            lastname: this.state.submittedFormError.lastname,
            email: this.state.submittedFormError.email
        }
        if (error.firstname.length !== 0 || error.lastname.length !== 0 || error.email.length !== 0) {
            this.setState({ displaySubmittedFormErr: error })
        }

        this.targetDetails[idx].lastname = e.target.value;
        this.llastname[idx] = e.target.value;

        this.setState({
            tgDetails: this.targetDetails,
            llastname: this.llastname
        })
    }

    handleSubmittedEmails(e, idx) {

        this.handleValidation(e, "SubmittedForm");
        const error = {
            firstname: this.state.submittedFormError.firstname,
            lastname: this.state.submittedFormError.lastname,
            email: this.state.submittedFormError.email
        }
        if (error.firstname.length !== 0 || error.lastname.length !== 0 || error.email.length !== 0) {
            this.setState({ displaySubmittedFormErr: error })
        }

        this.targetDetails[idx].emails = e.target.value;
        this.lemails[idx] = e.target.value;

        this.setState({
            tgDetails: this.targetDetails,
            lemails: this.lemails
        })
    }

    render() {
        const attackType = this.props.attackType;

        return (
            <Modal size="lg" show={this.props.showModal} onHide={this.props.showHandle}>
                <Modal.Body>
                    {(attackType === "Phishing" || attackType === "Ransomware" || attackType === "Attachment" || attackType === "QRcode") ?
                        <div>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                {this.targetDetails.map((target, idx) => (
                                    <div className='d-flex mt-2 w-100 align-items-start' key={idx} >

                                        <div className="mx-2 d-flex flex-column" style={{ flex: 3.15 }}>
                                            <Form.Control type="text" name="firstname" value={this.state.lfirstname[idx]} placeholder="Firstname" onChange={(e) => this.handleSubmittedFirstname(e, idx)} required validate="true" />
                                            {idx === this.state.tdlength-2 && this.state.displaySubmittedFormErr.firstname.length > 0 && (
                                                <div className='errorInfo'>
                                                    {this.state.displaySubmittedFormErr.firstname}
                                                </div>
                                            )}
                                        </div>

                                        <div className="mr-2 d-flex flex-column" style={{ flex: 3.15 }}>
                                            <Form.Control type="text" name="lastname" value={this.state.llastname[idx]} placeholder="Lastname" onChange={(e) => this.handleSubmittedLastname(e, idx)} />
                                            {idx === this.state.tdlength-2 && this.state.displaySubmittedFormErr.lastname.length > 0 && (
                                                <div className='errorInfo'>
                                                    {this.state.displaySubmittedFormErr.lastname}
                                                </div>
                                            )}
                                        </div>

                                        <div className="d-flex flex-column" style={{ flex: 5 }}>
                                            <Form.Control type="email" placeholder="Email-Id" name="email" value={this.state.lemails[idx]} onChange={(e) => this.handleSubmittedEmails(e, idx)} required validate="true" />
                                            {idx === this.state.tdlength-2 && this.state.displaySubmittedFormErr.email.length > 0 && (
                                                <div className='errorInfo'>
                                                    {this.state.displaySubmittedFormErr.email}
                                                </div>
                                            )}
                                        </div>

                                        <div className='d-flex justify-content-center align-items-start' style={{ flex: 1.3, paddingTop: '4px' }} >
                                            <Button variant="outline-secondary" id="delete-btn" onClick={e => this.deleteQueuedForm(e, idx)} style={{ color: 'white', borderColor: 'white', padding: 0 }} title={'Delete'} >
                                                <Delete style={{ color: "red", fontSize: '22px' }} />
                                            </Button>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div className='live-form d-flex mt-3 w-100' style={{ height: '4rem' }}>

                                <div className="mx-2 d-flex flex-column" style={{ flex: 3.15 }}>
                                    <Form.Control type="text" name="firstname" value={this.state.firstname} onChange={this.handleFirstname} placeholder="Firstname" />
                                    {this.state.displayFormErr.firstname.length > 0 && (
                                        <div className='errorInfo'>
                                            {this.state.displayFormErr.firstname}
                                        </div>
                                    )}
                                </div>

                                <div className="mr-2 d-flex flex-column" style={{ flex: 3.15 }}>
                                    <Form.Control type="text" name="lastname" value={this.state.lastname} onChange={this.handleLastname} placeholder="Lastname" />
                                    {this.state.displayFormErr.lastname.length > 0 && (
                                        <div className='errorInfo'>
                                            {this.state.displayFormErr.lastname}
                                        </div>
                                    )}
                                </div>

                                <div className="d-flex flex-column" style={{ flex: 5 }}>
                                    <Form.Control type="email" placeholder="Email-Id" name="email" value={this.state.emails} onChange={this.handleEmails} />
                                    {this.state.displayFormErr.email.length > 0 && (
                                        <div className='errorInfo'>
                                            {this.state.displayFormErr.email}
                                        </div>
                                    )}
                                </div>


                                <div className='d-flex justify-content-center align-items-start' style={{ flex: 1.3, paddingTop: '4px' }} >
                                    <Button variant="outline-secondary" id="add-btn" onClick={this.queueNewForm} style={{ color: 'white', borderColor: 'white', padding: 0 }} title={'Add'} >
                                        <AddIcon style={{ color: "darkcyan", fontSize: '28px' }} />
                                    </Button>
                                </div>

                            </div>
                        </div> :
                        <div>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                {this.targetDetails.map((target, idx) => (
                                    <div className='d-flex mt-2 w-100 align-items-start' key={idx} >
                                        <div className="mx-2 d-flex flex-column" style={{ flex: 12 }}>
                                            <PhoneInput
                                                country={'in'}
                                                inputProps={{
                                                    name: 'email',
                                                    required: true
                                                }}
                                                value={this.state.lemails[idx]}
                                                isValid={(value, country) => {
                                                    if (value.match(/12345/)) {
                                                        return 'Invalid value: ' + value + ', ' + country.name;
                                                    } else if (value.match(/1234/)) {
                                                        return false;
                                                    } else {
                                                        return true;
                                                    }
                                                }}
                                                onChange={phone => this.handleSubmittedEmails({ target: { value: phone } }, idx)}
                                            ></PhoneInput>
                                        </div>
                                        <div className='d-flex justify-content-center align-items-end' style={{ flex: 1, paddingTop: '4px' }} >
                                            <Button variant="outline-secondary" id="delete-btn" onClick={e => this.deleteQueuedForm(e, idx)} style={{ color: 'white', borderColor: 'white', padding: 0 }} title={'Delete'} >
                                                <Delete style={{ color: "red", fontSize: '22px' }} />
                                            </Button>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div className='live-form d-flex mt-3 w-100' style={{ height: '4rem' }}>
                                <div className="mx-2 d-flex flex-column" style={{ flex: 12 }}>
                                    <Form.Label>Please enter phone number</Form.Label>


                                    <PhoneInput
                                        country={'in'}
                                        inputProps={{
                                            name: 'email',
                                            required: true,
                                            autoFocus: true
                                        }}
                                        value={this.state.emails}
                                        isValid={(value, country) => {
                                            if (value.match(/12345/)) {
                                                return 'Invalid value: ' + value + ', ' + country.name;
                                            } else if (value.match(/1234/)) {
                                                return false;
                                            } else {
                                                return true;
                                            }
                                        }}
                                        onChange={phone => this.handlePhoneNums({ target: { value: phone } })}
                                        // onChange={phone => this.handleEmails({ target: { value: phone } })}
                                    ></PhoneInput>
                                </div>
                                <div className='d-flex justify-content-center align-items-end' style={{ flex: 1, paddingBottom: '3px' }} >
                                    <Button variant="outline-secondary" id="add-btn" onClick={this.queueNewForm} style={{ color: 'white', borderColor: 'white', padding: 0 }} title={'Add'} >
                                        <AddIcon style={{ color: "darkcyan", fontSize: '28px' }} />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer style={{ padding: '0.55rem' }}>
                    <Button variant="warning" onClick={this.props.showHandle}>Close</Button>
                    <Button variant="success" onClick={this.testCampaignTemplt}>Send</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}