import React, {Component} from 'react';
import { Modal, Image } from 'react-bootstrap';

import DiwaliImage from '../../assets/diwali-image-new.gif';

export default class DiwaliModal extends Component {
    render() {
        return (
            <Modal show={this.props.showModal} onHide={this.props.hideModal} id="diwali-modal">
                <Modal.Body >
                    <div>
                        <Image src={DiwaliImage} className="img-fluid radius"></Image>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}