import React, { Component } from 'react';
import { Modal, Form } from 'react-bootstrap';
import MultiSelect from "react-multi-select-component";

class CampaignTemplateFilter extends Component {

	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Modal className="info-filter detail-modal right filterModal" show={this.props.show} onHide={this.props.showHandle} autoFocus keyboard>
				<Modal.Header closeButton className='sidebarSm'>
					<Modal.Title id="example-custom-modal-styling-title">
						<h5 className="mb-0">Filters</h5>
						{/* <a href="javascript:void(0);" onclick="resetQuizFilter();" className="reset_filters_store">Reset Filters</a> */}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="rightFilters">
						<Form.Group controlId="templatetype" className="mr-2">
							<label>Template Type</label>
							<Form.Control as="select" value={this.props.type} onChange={e => this.props.handleTemplateType(e.target.value)}>
								<option value="All Templates">All Templates</option>
								<option value="My Templates">Custom Templates</option>
								<option value="System Templates">System Templates</option>
								<option value="Community Templates">Community Templates</option>
							</Form.Control>
						</Form.Group>

						<Form.Group controlId="language" className="mr-2">
							<label>Language</label>
							<Form.Control as="select" value={this.props.languageId} onChange={e => this.props.handleLanguage(parseInt(e.target.value, 10))}>
								<option value="0">Select Language</option>
								{this.props.languages.map((lang, index) => {
									return <option key={`language-${index}`} value={lang.id}>{lang.name}</option>
								})}
							</Form.Control>
						</Form.Group>

						<Form.Group controlId="attacktype" className="mr-2">
							<label>Attack Type</label>
							<Form.Control as="select" value={this.props.attackTypeId} onChange={e => this.props.handleAttackType(parseInt(e.target.value, 10))}>
								<option value="0">Select Attack Type</option>
								{this.props.attackTypes.map((attack, index) => {
									return <option key={`attacktype-${index}`} value={attack.id}>{attack.name}</option>
								})}
							</Form.Control>
						</Form.Group>

						<Form.Group className="mr-2">
							<label>Category</label>
							<MultiSelect
								className="multi-select-categories"
								clearSearch="Clear Search"
								options={this.props.categories}
								value={this.props.categoryId}
								onChange={this.props.handleCategory}
								overrideStrings={{ selectSomeItems: "Select categories" }}
							/>
						</Form.Group>
					</div>
				</Modal.Body>
			</Modal>
		);
	}
}

export default CampaignTemplateFilter;
