import React, {Component} from 'react';
import { toast } from 'react-toastify';
import { Modal, Button, Table, Form } from 'react-bootstrap';
import GetAppSharpIcon from '@material-ui/icons/GetAppSharp';
import { IoCloudUploadOutline } from "react-icons/io5";
import axios from '../../axios';
import apiError from '../../error';
import Loader from '../loader/loader'
import sampleFile from '../../assets/sample_csv_file.csv';
import './rightside-modal.css'
export default class UploadCSVModal extends Component {
    constructor(props){
        super(props);
        this.state = {
            loading: false,
            groupId: this.props.groupId,
            parentId: this.props.parentId,
        }
        this.inputFile = React.createRef();
    }

    componentDidUpdate = (prevProps) => {
		if(this.props.groupId !== prevProps.groupId){
			this.setState({ groupId: this.props.groupId })
		}
    }

    createGroup = (e) => {
        if(!this.props.groupName && this.props.groupName.length === 0){
            toast.error("Please enter group name first");
            return false;
        }
        this.setState({ loading: true });
        axios.post('/api/groups', {
            name: this.props.groupName,
            parentId: this.props.parentId,
        }, {})
        .then(res => {
            this.props.handleGroupId(res.data.groupId);
            this.props.nextPath('/department/groups/'+res.data.groupId);
            this.setState({ groupId: res.data.groupId, loading: false });
            this.uploadFile(e);
        })
        .catch(err => {
            this.setState({ loading: false });
            apiError(err);
        })
    }

    handleGroupFileUpload = (e) => {
        if( e && e.target && e.target.files ) {
            e.persist();
            this.validateFileFormat(e);
        }
    }
    
    validateFileFormat = (e) => {
        const fileToRead = e.target.files[0];
        var reader = new FileReader(), error = false;     
        reader.readAsText(fileToRead);
        reader.onload = (event) => {
            const csv = event.target.result;
            var allTextLines = csv.split(/\r\n|\n/);
            if(allTextLines.length <= 1){
                toast.error('Empty csv file is not allowed');
            } else {
                var lines = allTextLines[0].split(',');
                var goodUsers = ["firstname", "lastname", "email", "phone", "position", "countrycode", "department", "location", "managerEmail"];
                // var goodUsers = ["firstname", "lastname", "email", "phone", "position", "countrycode", "ismanager", "managerEmail"];
                for(var i=0; i<lines.length; i++){
                    if(goodUsers.indexOf(lines[i]) === -1) {
                        error = true;
                        break;
                    }
                }
                if(error){
                    toast.error('Invalid format recieved in csv file. Please check valid format in sample csv file');
                } else {
                    this.uploadFile(e);  
                }
            }
        };
        reader.onerror = () => {
            toast.error('Invalid file recieved or something went wrong');
        };
    }

    uploadFile = (e) => {
        console.log('Uploading file',e.target.files[0]);
        if(this.state.groupId && parseInt(this.state.groupId, 10)){
            e.persist();
            var formData = new FormData();
            formData.append('file', e.target.files[0] );
            axios.post('/api/groups/file/'+this.state.groupId, formData, {
                headers: {
                    "Content-type": "multipart/form-data",
                } 
            })
            .then(res => {
                this.props.CSVHandle();
                this.props.nextPath('/department/groups');
                toast.success("file uploaded successfully and data will reflect in sometimes");
            })
            .catch(err => {
                apiError(err);
            })
        } else { 
            this.createGroup(e);
            return false;
        }
    }

    onButtonClick = () => {
        this.inputFile.current.click();
    };

    render() {
        return (
            <Modal className="info-filter right mainfooterModal creatGroupModal" show={this.props.show} onHide={this.props.CSVHandle} autoFocus keyboard>
                <Modal.Header closeButton>
                     <div>
						<h5>Upload a CSV</h5>
						<p>Fill the user details.</p>
					</div>
                </Modal.Header>
                <Modal.Body>
                   <div className="col-md-12">
                        <div className="uploadBlock">
                            <IoCloudUploadOutline className="upload-icon"></IoCloudUploadOutline>
                            <h6>Choose the file to be imported</h6>
                            <p>You can upload CSV files to add bulk users</p>

                             <div className="emailAttachment">
                                    <Form.Group>
                                         <Form.Label>Upload Email Attachment</Form.Label>
                                         <Form.File id="exampleFormControlFile1" accept=".csv" ref={this.inputFile} onChange={this.handleGroupFileUpload} />
                                    </Form.Group>
                                </div>
                            <hr style={{ 'width': '20%',  'margin': '5px auto', 'border-top': '3px solid rgba(0, 0, 0, 0.1)' }}></hr>
                            <div>
                            <a variant="link" className="blue-text" href={sampleFile}>Click here to download sample CSV file</a>
                            </div>
                        </div>
                   </div>
                    {/* <Table>
                        <thead>
                            <tr>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Email</th>
                                <th>CountryCode</th>
                                <th>Phone</th>
                                <th>Position</th>
                                <th>Department</th>
                                <th>Location</th>
                                <th>managerEmail</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>abc</td>
                                <td>xyz</td>
                                <td>xyz@domain.com</td>
                                <td>1</td>
                                <td>234567890</td>
                                <td>Developer</td>
                                <td>Development</td>
                                <td>US</td>
                                <td>shawn@domain.com</td>
                            </tr>
                            <tr>
                                <td>abc</td>
                                <td>xyz</td>
                                <td>xyz@domain.com</td>
                                <td>1</td>
                                <td>1234567890</td>
                                <td>Developer</td>
                                <td>Development</td>
                                <td>India</td>
                                <td>shawn@domain.com</td>
                            </tr>
                        </tbody>
                    </Table> */}
                </Modal.Body>
                {this.state.loading? <Loader></Loader>: null }

                <div class="modalFooter">
                    <div class="row">
                        <div class="col-md-12 text-right"><button type="button" class="btn closeBtn">Cancel</button>
                        <button disabled="" type="button" class="btn btn-success ml-2 btn btn-primary" onClick={this.onButtonClick}><div class="spinner-border d-none" role="status"></div>Upload CSV </button>
                </div></div></div>
            </Modal>
        );
    }
}