import React, { Component } from "react";
import swal from "sweetalert";
import { toast } from "react-toastify";
import Badge from "react-bootstrap/Badge";
import Table from 'react-bootstrap/Table';
import Pagination from "react-js-pagination";
import Tooltip from "react-bootstrap/Tooltip";
import { LiaFilterSolid } from "react-icons/lia";
import CancelIcon from "@material-ui/icons/Cancel";
import DehazeIcon from "@material-ui/icons/Dehaze";
import AddSharpIcon from "@material-ui/icons/AddSharp";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import SearchSharpIcon from "@material-ui/icons/SearchSharp";
import MoreVertSharpIcon from "@material-ui/icons/MoreVertSharp";
import { FormControl, Button, InputGroup, Form, Dropdown, } from "react-bootstrap";

import axiosRequest from "axios";
import axios from "../../../axios";
import apiError from "../../../error";
import Loader from "../../../component/loader/loader";
import { getDate, getCookie } from "../../../function";
import CampaignPreview from "../../../component/create-template/campaign-preview/campaign-preview";
import CampaignTemplateFilter from "../../../component/create-template/campaign-template-filter/campaign-template-filter";

import Vishing from "../../../assets/campaign-thumbnail-vishing.png";
import Phishing from "../../../assets/campaign-thumbnail-phishing.png";
import Smishing from "../../../assets/campaign-thumbnail-smishing.png";
import WhatsApp from "../../../assets/campaign-thumbnail-whatsapp.png";
import Ransomware from "../../../assets/campaign-thumbnail-ransomware.png";

import "./campaign-template.css";

var cancelRequest = undefined, CancelToken = axiosRequest.CancelToken;

export default class CampaignTemplate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			count: 0,
			limit: 12,
			search: "",
			isType: true,
			loading: true,
			languages: [],
			languageId: 0,
			imagePath: "",
			categoryId: [],
			categories: [],
			categoryIds: [],
			attackTypeId: 0,
			attackTypes: [],
			campaignTemplates: [],
			type: "All Templates",
			userAgent: getCookie("userAgent"),
			withTraining: false,
			showFilter: false,
			showPreview: false,
			campaignTemplateId: null
		};
	}

	componentDidMount = () => {
		this.checkValidToken();
	};

	checkValidToken = () => {
		axios
			.post("/api/auth/check-token", {})
			.then((res) => {
				this.load();
				this.loadLanguage();
				this.loadCategory();
				this.loadAttackType();
			})
			.catch((err) => apiError(err));
	};

	loadAttackType = () => {
		axios
			.get("/api/attacktypes", {})
			.then((res) => {
				this.setState({ attackTypes: res.data.attackTypes });
			})
			.catch((err) => apiError(err));
	};

	load = () => {
		if (cancelRequest) cancelRequest();
		axios
			.get("/api/campaign/template", {
				params: {
					type: this.state.type,
					limit: this.state.limit,
					search: this.state.search,
					page: this.state.activePage,
					languageId: this.state.languageId,
					categoryIds: this.state.categoryIds,
					attackTypeId: this.state.attackTypeId,
					withTraining: this.state.withTraining,
				},
				cancelToken: new CancelToken(function executor(c) {
					cancelRequest = c;
				}),
			})
			.then((res) => {
				this.setState({
					loading: false,
					count: res.data.count,
					imagePath: res.data.path,
					campaignTemplates: res.data.campaign_templates,
				});
			})
			.catch((error) => {
				if (!axiosRequest.isCancel(error)) {
					this.setState({ campaigns: [], count: 0, loading: false });
					apiError(error);
				}
			});
	};

	loadCategory = () => {
		axios
			.get("/api/campaign/template/category", {})
			.then((res) => {
				let categoryValue = [];
				for (var i = 0; i < res.data.categories.length; i++) {
					categoryValue.push({
						label: res.data.categories[i].name,
						value: res.data.categories[i].id,
					});
				}
				this.setState({ categories: categoryValue });
			})
			.catch((err) => apiError(err));
	};

	loadLanguage = () => {
		axios
			.get("/api/campaign/template/language", {})
			.then((res) => {
				this.setState({ languages: res.data.languages });
			})
			.catch((err) => apiError(err));
	};

	nextPath = (path) => {
		localStorage.removeItem("response");
		localStorage.removeItem("backgroundHeader");
		localStorage.removeItem("logoPosition");
		this.props.history.push(path);
	};

	handleCategory = (e) => {
		let categoryIds = [];
		for (let i = 0; i < e.length; i++) {
			categoryIds.push(e[i].value);
		}
		this.setState(
			{ categoryIds: categoryIds, categoryId: e, activePage: 1 },
			() => {
				this.load();
			}
		);
	};

	deleteCampaignTemplate = (campaignTemplateId) => {
		swal({
			title: "Do you want to delete CampaignTemplate?",
			icon: "warning",
			buttons: ["No", "Yes"],
			dangerMode: true,
		}).then((willSuccess) => {
			if (willSuccess) {
				axios
					.delete("/api/campaign/template/" + campaignTemplateId, {})
					.then((r) => {
						toast.success("CampaignTemplate deleted successfully");
						this.load();
					})
					.catch((error) => {
						apiError(error);
					});
			}
		});
	};

	copyCampaignTemplate = (campaignTemplateId) => {
		swal({
			title: "Do you want to clone CampaignTemplate?",
			icon: "info",
			buttons: ["No", "Yes"],
		}).then(async (willSuccess) => {
			if (willSuccess) {
				await this.setState({ loading: true });
				axios
					.post("/api/campaign/template/clone/" + campaignTemplateId, {})
					.then((res) => {
						this.load();
						this.setState({ loading: false });
						toast.success("Campaign template added successfuly");
						this.props.history.push(
							"/templates/" + res.data.campaignTemplateId
						);
					})
					.catch((error) => {
						this.setState({ loading: false });
						apiError(error);
					});
			}
		});
	};

	launchCampaignTemplate = (campaign) => {
		let url = "/templates/launch/" + campaign.simulation_type.name + "/" + campaign.attack_type.name + "/" + campaign.credential_harvesting;
		let search = "?type=" + campaign.type + "&category=" + campaign.categoryId + "&ctID=" + campaign.id;
		this.props.history.push({
			pathname: url,
			search: search,
		});
	};

	handleCategoryRemove = (categoryId) => {
		let index = this.state.categoryIds.indexOf(categoryId);
		if(index > -1){
			let categoryIds = this.state.categoryIds;
			categoryIds.splice(index, 1);
			this.setState({ categoryIds }, () => {
				this.load();
			})
		}
	}

	render() {
		let languageName = null;
		this.state.languages.map((lang) => {
			if(lang.id === this.state.languageId){
				languageName = lang.name;
			}
		})
		let attackTypeName = null;
		this.state.attackTypes.map((attack) => {
			if(attack.id === this.state.attackTypeId){
				attackTypeName = attack.name;
			}
		})
		console.log(this.state);
		return (
			<div className="content-wrap">
				<div className="breadcrumbbar">
					<div className="flexRow row">
						<div className="col-md-4">
							<div className="page-head d-flex align-items-center justify-content-between">
								<h4 className="page-title">Campaign Templates</h4>
							</div>
						</div>

						<div className="col-md-8 flexFilterButtons">
							<div className="btn-container float-right d-flex align-items-center">
								<Button
									variant="secondary"
									onClick={() => this.nextPath("/templates/ai")}
									className="mr-2"
								>
									<AddSharpIcon className="btn-icon"></AddSharpIcon>AI Template{" "}
									<sup>(Beta)</sup>
								</Button>
								<Button
									variant="primary"
									onClick={() => this.nextPath("/templates/0")}
								
								>
									<AddSharpIcon className="btn-icon"></AddSharpIcon>Create Template
								</Button>
							</div>
						</div>
					</div>
				</div>
				<div className="filter-wrapper">
					<div className="alignItemsCenter row">
						<div className="col-md-3 p-0">
							<div className="positionSearchIcon">
								<InputGroup className="search-wrap">
									<FormControl
										type="text"
										placeholder="Search"
										value={this.state.search}
										onChange={(e) =>
											this.setState(
												{ search: e.target.value, activePage: 1 },
												() => this.load()
											)
										}
										className="inputSearch mr-sm-2 form-control"
									/>
									<SearchSharpIcon className="search-icon"></SearchSharpIcon>
								</InputGroup>
							</div>
						</div>

						<div className="col-sm-9 p-0">
							<div className="rightFilters">
								<div className="sort-filter-btn">
									<Form className="toggleTraining">
										<Form.Check
											onChange={(e) =>
												this.setState(
													{ withTraining: !this.state.withTraining },
													() => this.load()
												)
											}
											type="switch"
											id="custom-switch"
										/>
										<div>
											<p>With Training</p>
										</div>
									</Form>
								</div>
								<div className="sort-filter-btn">
									{!this.state["isType"] ? (
										<OverlayTrigger
											placement="top"
											overlay={ <Tooltip className="d-none" id="top">Grid View</Tooltip>}
										>
											<DehazeIcon
												onClick={() => this.setState({ isType: !this.state["isType"] }) }
											></DehazeIcon>
										</OverlayTrigger>
									) : (
										<OverlayTrigger
											placement="top"
											overlay={
												<Tooltip className="d-none" id="top"> List View </Tooltip>
											}
										>
											<ViewModuleIcon
												onClick={() => this.setState({ isType: !this.state["isType"] })}
											></ViewModuleIcon>
										</OverlayTrigger>
									)}
								</div>

								<div className="sort-filter-btn">
									<a
										href="javascript:void(0)"
										class="filter-btn"
										onClick={() => this.setState({ showFilter: true })}
									>
										<LiaFilterSolid></LiaFilterSolid> Filters
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="contentbar tempGrids">
					{this.state.type !== 'All Templates'? 
						<span pill className="mr-2 primaryBadge mb-2 d-inline-block" variant="primary">
							{this.state.type === 'My Templates'? 'Custom Templates': this.state.type} {' '}
							<CancelIcon className="training-icon" onClick={() => this.setState({ type: 'All Templates', activePage: 1 }, () => this.load())}></CancelIcon>
						</span>: null
					}
					{languageName? 
						<span pill className="mr-2 primaryBadge mb-2 d-inline-block" variant="primary">
							{languageName} {' '}
							<CancelIcon className="training-icon" onClick={() => this.setState({ languageId: 0, activePage: 1 }, () => this.load())}></CancelIcon>
						</span>: null
					}
					{attackTypeName? 
						<span pill className="mr-2 primaryBadge mb-2 d-inline-block" variant="primary">
							{attackTypeName} {' '}
							<CancelIcon className="training-icon" onClick={() => this.setState({ attackTypeId: 0, activePage: 1 }, () => this.load())}></CancelIcon>
						</span>: null
					}
					{this.state.categories.map((category, index) => { 
						return this.state.categoryIds.indexOf(category.value) > -1?
							<span key={index} pill className="mr-2 primaryBadge mb-2 d-inline-block" variant="primary">
								{category.label} {' '}
								<CancelIcon className="training-icon" onClick={() => this.handleCategoryRemove(category.value)}></CancelIcon>
							</span>: null
					})}
					<div className="page-content">
						{this.state.isType ? (
							<div className="row">
								{this.state.campaignTemplates.map((campaign, index) => {
									const image =
										campaign.template && campaign.template.thumbnail? 
										this.state.imagePath + "/" + campaign.template.thumbnail : null;
									return (
										<div className="col-md-3 tempCard" key={"grid-" + index}>
											<div className="Campaign-Card-Box">
												<div className="Card-img-link">
													<Badge className="badge topBadge">
														{campaign?.simulation_type?.name ?? ''}
													</Badge>
													<div className="cardImg addCursor" onClick={() => {
														this.setState({
															showPreview: true,
															campaignTemplateId: campaign.id
														})
													}}>
														<img
															alt="template"
															className="img-fluid"
															src={
																campaign.attack_type.name !== "WhatsApp" && image ? image: 
																campaign.attack_type.name === "Phishing"? Phishing: 
																campaign.attack_type.name === "Smishing"? Smishing: 
																campaign.attack_type.name === "Ransomware"? Ransomware: 
																campaign.attack_type.name === "WhatsApp"? WhatsApp: Vishing
															}
														></img>
													</div>
												</div>
												<div className="cardFooter">
													<div className="cardBody">
														<div className="titleName">
															<h2 className="addCursor" onClick={() => {
																this.setState({
																	showPreview: true,
																	campaignTemplateId: campaign.id
																})
															}}>{campaign.name}</h2>
															<h6>{getDate(campaign.createdAt)}</h6>
														</div>
														<Dropdown
															className="action-dropdown dropdownIcon"
															ref={this.selectRef}
														>
															<Dropdown.Toggle
																className="btn-only"
																id="dropdown-basic"
															>
																<MoreVertSharpIcon className="dot-icon"></MoreVertSharpIcon>
															</Dropdown.Toggle>
															<Dropdown.Menu>
																{campaign.type === "My Templates" ? (
																	<Dropdown.Item
																		onClick={(e) =>
																			this.nextPath("/templates/" + campaign.id)
																		}
																	>
																		Edit
																	</Dropdown.Item>
																) : null}
																<Dropdown.Item
																	onClick={() => {
																		this.setState({
																			showPreview: true,
																			campaignTemplateId: campaign.id
																		})
																	}}
																>
																	Preview
																</Dropdown.Item>
																<Dropdown.Item
																	onClick={(e) =>
																		this.copyCampaignTemplate(campaign.id)
																	}
																>
																	Clone
																</Dropdown.Item>
																<Dropdown.Item
																	onClick={(e) =>
																		this.launchCampaignTemplate(campaign)
																	}
																>
																	Create Campaign
																</Dropdown.Item>
																{campaign.type === "My Templates" ||
																	this.state.userAgent ? (
																	<Dropdown.Item
																		onClick={(e) =>
																			this.deleteCampaignTemplate(campaign.id)
																		}
																	>
																		Delete
																	</Dropdown.Item>
																) : null}
															</Dropdown.Menu>
														</Dropdown>
													</div>

													<div className="cardfooterBox">
														<Badge className="badge badgeOne">{campaign.attack_type.name + ""}</Badge>
														<Badge className="badge badgeTwo">{campaign.type === "My Templates"
															? "Custom Template"
															: campaign.type}</Badge>
													</div>
												</div>
											</div>
										</div>
									);
								})}
							</div>
						) : (
							<div className="tableContainerWrapInner">
								<Table>
									<thead>
										<tr>
											<th className="th-30">Name</th>
											<th>Initiated on</th>
											<th>category</th>
											<th>Credential Harvesting</th>
											<th>Attack type</th>
											<th>Action</th>
										</tr>
									</thead>

									<tbody>
										{this.state.campaignTemplates.map((campaign, index) => {
											const image = campaign.template && campaign.template.thumbnail ?
												this.state.imagePath + "/" + campaign.template.thumbnail: null;
											return <tr key={"list-" + index}>
												<td class="onlyForPinter">
													<div className="flexTableData">
														<div className="companyLogoIcon">
															<img
																alt="template"
																className="img-fluid"
																src={
																	campaign.attack_type.name !== "WhatsApp" && image ? image: 
																	campaign.attack_type.name === "Phishing"? Phishing: 
																	campaign.attack_type.name === "Smishing"? Smishing: 
																	campaign.attack_type.name === "Ransomware"? Ransomware: 
																	campaign.attack_type.name === "WhatsApp"? WhatsApp: Vishing
																}
															></img>
														</div>
														<div><h6>{campaign.name}</h6></div>
													</div>
												</td>
												<td>
													<div className="timeBox">
														<p>{getDate(campaign.createdAt)}</p>
													</div>
												</td>
												<td>
													<span class="badge secondaryBadge">
														{campaign.type === "My Templates" ? "Custom Template": campaign.type}
													</span>
												</td>
												<td className="td-center">
													{campaign.credential_harvesting ? <p className="yes-badge">Yes</p> : <p className="no-badge">No</p> }
												</td>
												<td><span class="badge primaryBadge"> {campaign.attack_type.name + ""}</span></td>
												<td>
													<div className="col-md-1" ref={this.selectRef}>
														<Dropdown className="action-dropdown dropdownIcon">
															<Dropdown.Toggle
																className="btn-only"
																id="dropdown-basic"
															>
																<MoreVertSharpIcon className="dot-icon"></MoreVertSharpIcon>
															</Dropdown.Toggle>
															<Dropdown.Menu>
																{campaign.type === "My Templates" ? (
																	<Dropdown.Item
																		onClick={(e) =>
																			this.nextPath("/templates/" + campaign.id)
																		}
																	> Edit </Dropdown.Item>
																) : null}
																<Dropdown.Item
																	onClick={() => {
																		this.setState({
																			showPreview: true,
																			campaignTemplateId: campaign.id
																		})
																	}}
																	>Preview</Dropdown.Item>
																<Dropdown.Item
																	onClick={(e) =>
																		this.copyCampaignTemplate(campaign.id)
																	}
																	>Clone</Dropdown.Item>
																<Dropdown.Item
																	onClick={(e) =>
																		this.launchCampaignTemplate(campaign)
																	}
																	>Create Campaign</Dropdown.Item>
																{campaign.type === "My Templates" ? (
																	<Dropdown.Item
																		onClick={(e) =>
																			this.deleteCampaignTemplate(campaign.id)
																		}
																	>Delete</Dropdown.Item>
																) : null}
															</Dropdown.Menu>
														</Dropdown>
													</div>
												</td>
											</tr>
										})}
									</tbody>
								</Table>
							</div>
						)}
					</div>
					<div className="pagination-Wrap">
						<div className="row">
							<div className="col-md-6">
								{!this.state["loading"] ? 
									<Pagination
										itemClass="page-item"
										linkClass="page-link"
										activePage={this.state.activePage}
										itemsCountPerPage={this.state.limit}
										totalItemsCount={this.state.count}
										onChange={(pageNumber) =>
											this.setState({ activePage: pageNumber }, () => this.load())
										}
									/> : <Loader />
								}
							</div>
							<div className="col-md-6 text-right d-flex align-items-center footer-pg">
								<div className="w-100 pgCount"><p> {this.state.count} Templates Available</p> </div>
							</div>
						</div>
					</div>

					{this.state.loading ? <Loader></Loader> : null}

					<CampaignTemplateFilter
						show={this.state.showFilter}
						type={this.state.type}
						attackTypes={this.state.attackTypes}
						attackTypeId={this.state.attackTypeId}
						languages={this.state.languages}
						languageId={this.state.languageId}
						categories={this.state.categories}
						categoryId={this.state.categoryId}
						handleCategory={this.handleCategory}
						handleTemplateType={type => { this.setState({ type, activePage: 1 }, () => this.load()) }}
						handleLanguage={languageId => { this.setState({ languageId, activePage: 1 }, () => this.load()) }}
						handleAttackType={attackTypeId => { this.setState({ attackTypeId, activePage: 1 }, () => this.load()) }}
						showHandle={() => this.setState({ showFilter: false })}
					></CampaignTemplateFilter>
					{this.state.showPreview? 
						<CampaignPreview
							show={this.state.showPreview}
							campaignTemplateId={this.state.campaignTemplateId}
							handleModal={() => this.setState({ showPreview: false })}
						></CampaignPreview>: ''
					}
				</div>
			</div>
		);
	}
}
