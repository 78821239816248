import React, { Component } from 'react';

import axios from '../../axios';
import { eraseCookie } from '../../function';
import Loader from '../../component/loader/loader';
export default class Logout extends Component {
    componentWillMount = () => {

        eraseCookie('token');
        eraseCookie('logo');
        eraseCookie('userType');
        eraseCookie('username');
        eraseCookie('userAgent');
        eraseCookie('firstname');
        eraseCookie('billingType');
        eraseCookie('isDashboard');
        eraseCookie('accessLevel');

        localStorage['email'] = '';
        localStorage['lastname'] = '';
        localStorage['firstname'] = '';

        axios.get('/api/auth/logout', {})
        .then(r => {
            window.location = '/login';
        })
        .catch(error => {
            console.log(error);
            window.location = '/login';
        })

    }
    render(){ 
        return (
            <Loader></Loader>
        )
    }
}