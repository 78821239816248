import swal from "sweetalert";
import Compress from "compress.js";
import { toast } from "react-toastify";
import React, { Component } from "react";
import { IoInformation } from "react-icons/io5";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { Button, Form, Image as ImageComponent } from "react-bootstrap";

import axios from "../../../axios";
import apiError from "../../../error";
import Loader from "../../../component/loader/loader";
import { setCookie, getCookie } from "../../../function";

const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];

export default class Company extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: "",
            file: "",
            image: "",
            loading: false,
            disabled: true,
            companyName: "",
            userAgent: getCookie("userAgent"),
        };
        this.inputFile = React.createRef();
        this.inputFileBackup = React.createRef();
        this.inputDatabaseBackup = React.createRef();
        this.loadCompany();
    }

    loadCompany = () => {
        axios
            .get("/api/company", {})
            .then((res) => {
                if (res.data.logo) {
                    this.setState({
                        image: res.data.logo && res.data.logo.logo ? res.data.logo.path : null,
                        companyName: res.data.logo.name,
                    });
                    if (res.data.logo && res.data.logo.logo) {
                        setCookie("logo", res.data.logo.path, 30000);
                    }
                }
            })
            .catch((err) => {
                apiError(err);
            });
    };

    handleCompany = () => {
        if (!this.state.companyName || this.state.companyName.length === 0) {
            toast.error("please enter company name");
            return;
        }
        var formData = new FormData();
        formData.append("name", this.state.companyName);
        if (this.state.file) {
            formData.append("logo", this.state.file);
        }
        axios
            .post("/api/company", formData)
            .then((res) => {
                setCookie("logo", res.data.thumbnail, 30000);
                setCookie("username", this.state.companyName, 30000);
                toast.success("company updated successfully");
            })
            .catch((err) => {
                apiError(err);
            });
    };

    handleFile = (e) => {
        if (e && e.target && e.target.files && e.target.files.length) {
            if (!allowedTypes.includes(e.target.files[0].type)) return toast.error("Please upload valid file");
            //Remove exif data of only JPEG Image. Can be extended to other type later on.
            const compress = new Compress();
            const files = [...e.target.files];
            compress
                .compress(files, { resize: false, quality: 0.8 })
                .then((compressedFiles) => {
                    let uploadableFiles = [];
                    for (var i = compressedFiles.length - 1; i >= 0; i--) {
                        let file = Compress.convertBase64ToFile(compressedFiles[i].data, compressedFiles[i].ext);
                        let filename = Date.now() + compressedFiles[i].alt;
                        let filetype = compressedFiles[i].ext;
                        let filelastMod = files[i].lastModified;
                        uploadableFiles.push(new File([file], filename, { type: filetype, lastModified: filelastMod }));
                    }
                    let img = new Image();

                    img.onload = () => {
                        this.setState({ file: uploadableFiles[0], image: URL.createObjectURL(uploadableFiles[0]) });
                    };
                    img.src = compressedFiles[0].prefix + compressedFiles[0].data;
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    onButtonClick = () => {
        this.setState({ disabled: false });
        this.inputFile.current.click();
    };

    exportDatabase = () => {
        axios
            .get("/api/backup/exportdatabase", {})
            .then((res) => {
                toast.success(
                    "your backup start processing. and when done we will send backup your registered emailId"
                );
            })
            .catch((err) => {
                apiError(err);
            });
    };

    onButtonDatabaseClick = () => {
        swal({
            title: "If you want to import database then your previous result will be decarded",
            icon: "warning",
            text: "Still if you want then click on Yes otherwise No",
            buttons: ["No", "Yes"],
            dangerMode: true,
        })
            .then((willSuccess) => {
                if (willSuccess) {
                    this.inputDatabaseBackup.current.click();
                }
            })
            .catch((err) => console.log(err));
    };

    importDatabase = (e) => {
        this.setState({ loading: true });

        let formData = new FormData();
        formData.append("file", e.target.files[0]);

        axios
            .post("/api/backup/importdatabase", formData, {
                headers: {
                    "Content-type": "multipart/form-data",
                },
            })
            .then((r) => {
                this.setState({ loading: false });
            })
            .catch((error) => {
                apiError(error);
                this.setState({ loading: false });
            });
    };

    exportFile = () => {
        axios
            .get("/api/backup/exportfile", {})
            .then((res) => {
                toast.success(
                    "your backup start processing. and when done we will send backup your registered emailId"
                );
            })
            .catch((err) => {
                apiError(err);
            });
    };

    onButtonFileClick = () => {
        swal({
            title: "If you want to import file then your previous result will be decarded",
            icon: "warning",
            text: "Still if you want then click on Yes otherwise No",
            buttons: ["No", "Yes"],
            dangerMode: true,
        })
            .then((willSuccess) => {
                if (willSuccess) {
                    this.inputFileBackup.current.click();
                }
            })
            .catch((err) => console.log(err));
    };

    importFile = (e) => {
        this.setState({ loading: true });

        let formData = new FormData();
        formData.append("file", e.target.files[0]);

        axios
            .post("/api/backup/importfile", formData, {
                headers: {
                    "Content-type": "multipart/form-data",
                },
            })
            .then((r) => {
                this.setState({ loading: false });
            })
            .catch((error) => {
                apiError(error);
                this.setState({ loading: false });
            });
    };

    render() {
        return (
            <div className="tab-wrap">

                <div className='tabHeading'>
                    <h6>Company Information </h6>
                    <p>2FA enhances account security by preventing unauthorised access</p>
                 </div>

                 <div className='col-md-4 pl-0'>
                    <div className='profile-form'>
                         <Form>
                            <Form.Group>
                                <Form.Label>Company Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Company Name"
                                    value={this.state.companyName}
                                    onChange={(e) =>
                                        this.setState({ companyName: e.target.value, disabled: false })
                                    }
                                />
                            </Form.Group>


                            <div className="row">
                                <div className="col-md-4">
                                    <Form.Group>
                                        <Form.Label>
                                            Upload File
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip id="top">
                                                        This will be the Company name and Logo over the
                                                        dashboard{" "}
                                                    </Tooltip>
                                                }>
                                                <IoInformation className="tooltip-icon"></IoInformation>
                                            </OverlayTrigger>
                                        </Form.Label>

                                        <Form.File
                                            id="exampleFormControlFile1"
                                            label=""
                                            accept="image/*"
                                            ref={this.inputFile}
                                            onChange={this.handleFile}
                                            style={{ display: "none" }}
                                        />
                                       
                                        {this.state.image ? (
                                            <ImageComponent
                                                className="upload-img"
                                                src={this.state.image}
                                                alt="no image"></ImageComponent>
                                        ) : (
                                            ""
                                        )}

                                            <div className="upload-block"> </div>
                                        <Button
                                                variant="primary"
                                                className="mx-auto upload-btn"
                                                onClick={this.onButtonClick}>
                                                Upload a file
                                            </Button>
                                    </Form.Group>
                                </div>
                                <div className="col-md-6 align-items-center d-flex"></div>
                            </div>

                            <Button
                                variant="success"
                                type="button"
                                disabled={this.state.disabled}
                                onClick={this.handleCompany}>
                                Save
                            </Button>     
                        </Form>
                 </div>

                    {this.state.userAgent ? (
                        <div className="row mt-4">
                            <div className="col-md-3">
                                <h5>Database Backup : </h5>
                            </div>
                            <div className="col-md-9">
                                <Form.File
                                    id="exampleFormControlFile2"
                                    label=""
                                    accept=".sql"
                                    ref={this.inputDatabaseBackup}
                                    onChange={this.importDatabase}
                                    style={{ display: "none" }}
                                />
                                <Button className="mr-2" variant="success" onClick={this.onButtonDatabaseClick}>
                                    Import Database
                                </Button>
                                <Button className="mr-2" onClick={this.exportDatabase}>
                                    Export Database
                                </Button>
                            </div>
                        </div>
                    ) : null}
                        {this.state.userAgent ? (
                            <div className="row mt-4">
                                <div className="col-md-3">
                                    <h5>File Backup : </h5>
                                </div>
                                <div className="col-md-9">
                                    <Form.File
                                        id="exampleFormControlFile3"
                                        label=""
                                        accept=".zip"
                                        ref={this.inputFileBackup}
                                        onChange={this.importFile}
                                        style={{ display: "none" }}
                                    />
                                    <Button className="mr-2" variant="success" onClick={this.onButtonFileClick}>
                                        Import File
                                    </Button>
                                    <Button onClick={this.exportFile}>export File</Button>
                                </div>
                            </div>
                        ) : null}
                </div>


                {this.state.loading ? <Loader></Loader> : null}
            </div>
        );
    }
}
