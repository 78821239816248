import React, { Component } from "react";
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import vishing from '../../../assets/Vishing-r.png';
import phishing from '../../../assets/Phishing-r.png';
import smishing from '../../../assets/Smishing-r.png';
import cyberScam from '../../../assets/CyberScam-r.png';
import ransomeware from '../../../assets/Ransomeware-r.png';
import EmailSend from '../../../assets/evs1.png';
import EmailOpened from '../../../assets/evs2.png';
import LinkClicked from '../../../assets/evs3.png';
import DataSublitted from '../../../assets/evs4.png';
import CallPicked from '../../../assets/evs6.png';
import CallInitiated from '../../../assets/evs7.png';
import EmailReported from '../../../assets/evs8.png';
import SystemHack from '../../../assets/evs9.png';
import SMSSend from '../../../assets/evs10.png';

import { getDate } from '../../../function';

import '../evs.css'

export default class EVSCampaigns extends Component {
    render() {
        const campaign = this.props.campaign;
        const attackType = campaign.attack_type.name;
        const events = campaign.simulations[0] && campaign.simulations[0].status ? campaign.simulations[0].status : [];
        return (
            <tbody>
                <tr>
                    <td>
                        <div className="flexTableData">
                            <div className="campIcon">
                                {attackType === "Phishing" || attackType === "Attachment" ? <img src={phishing} alt="email" className=""></img> : null}
                                {attackType === "Smishing" ? <img src={smishing} alt="email" className=""></img> : null}
                                {attackType === "Vishing" ? <img src={vishing} alt="email" className=""></img> : null}
                                {attackType === "Ransomware" || attackType === "QRcode" ? <img src={ransomeware} alt="email" className=""></img> : null}
                                {attackType === "Cyber Scam" || attackType === "WhatsApp" ? <img src={cyberScam} alt="email" className=""></img> : null}
                            </div>
                            <h6 className="campName">{campaign.name}</h6>
                        </div>
                    </td>
                    <td>{getDate(campaign.createdAt)}</td>
                    <td>
                        <div className="activitySection">
                            {events.map((event, index) => {
                                if(!event.count || event.message === 'Total') return;
                                return <div className="rightBorder">
                                    <div className="click-to-top" key={index}>
                                        { event.count && event.message === "Email Sent" ?
                                            <OverlayTrigger
                                                placement='top'
                                                overlay={<Tooltip id='top'>Email Sent</Tooltip>}>
                                                <img src={EmailSend} alt="email" className="th-icon"></img>
                                            </OverlayTrigger> : null
                                        }
                                        { event.count && event.message === "Email Opened" ?
                                            <OverlayTrigger
                                                placement='top'
                                                overlay={<Tooltip id='top'>Email Opened</Tooltip>}>
                                                <img src={EmailOpened} alt="email" className="th-icon"></img>
                                            </OverlayTrigger> : null
                                        }
                                        { event.count && event.message === "Link Clicked" ?
                                            <OverlayTrigger
                                                placement='top'
                                                overlay={<Tooltip id='top'>Link Clicked</Tooltip>}>
                                                <img src={LinkClicked} alt="email" className="th-icon "></img>
                                            </OverlayTrigger> : null
                                        }
                                        { event.count && event.message === "Replied" ?
                                            <OverlayTrigger
                                                placement='top'
                                                overlay={<Tooltip id='top'>Replied</Tooltip>}>
                                                <img src={CallPicked} alt="email" className="th-icon"></img>
                                            </OverlayTrigger> : null
                                        }
                                        { event.count && event.message === "Data Submitted" ?
                                            <OverlayTrigger
                                                placement='top'
                                                overlay={<Tooltip id='top'>Data Submitted</Tooltip>}>
                                                <img src={DataSublitted} alt="email" className="th-icon"></img>
                                            </OverlayTrigger> : null
                                        }
                                        { event.count && event.message === "Email Reported" ?
                                            <OverlayTrigger
                                                placement='top'
                                                overlay={<Tooltip id='top'>Email Reported</Tooltip>}>
                                                <img src={EmailReported} alt="email" className="th-icon"></img>
                                            </OverlayTrigger> : null
                                        }
                                        { event.count && event.message === "SMS Sent" ?
                                            <OverlayTrigger
                                                placement='top'
                                                overlay={<Tooltip id='top'>SMS Send</Tooltip>}>
                                                <img src={SMSSend} alt="email" className="th-icon"></img>
                                            </OverlayTrigger> : null
                                        }
                                        { event.count && event.message === "Message Sent" ?
                                            <OverlayTrigger
                                                placement='top'
                                                overlay={<Tooltip id='top'>Message Sent</Tooltip>}>
                                                <img src={SMSSend} alt="email" className="th-icon"></img>
                                            </OverlayTrigger> : null
                                        }
                                        { event.count && event.message === "Call Initiated" ?
                                            <OverlayTrigger
                                                placement='top'
                                                overlay={<Tooltip id='top'>Call Initiated</Tooltip>}>
                                                <img src={CallInitiated} alt="email" className="th-icon"></img>
                                            </OverlayTrigger> : null
                                        }
                                        { event.count && event.message === "Call Picked" ?
                                            <OverlayTrigger
                                                placement='top'
                                                overlay={<Tooltip id='top'>Call Picked</Tooltip>}>
                                                <img src={CallPicked} alt="email" className="th-icon mr-2"></img>
                                            </OverlayTrigger> : null
                                        }
                                        { event.count && event.message === "System Hack" ?
                                            <OverlayTrigger
                                                placement='top'
                                                overlay={<Tooltip id='top'>System Hack</Tooltip>}>
                                                <img src={SystemHack} alt="email" className="th-icon mr-2"></img>
                                            </OverlayTrigger> : null
                                        }
                                    </div>
                                </div>
                            })}
                        </div>
                    </td>
                </tr>
            </tbody>
        );
    }
}