import React, { Component } from "react";
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';

import axios from '../../axios';
import apiError from "../../error";
import Spinner from '../../component/spinner/spinner';

import threatcop from '../../assets/threatcop-b.png'
import './forgotpassword.css'

export default class ForgotPassword extends Component {

    constructor(props){
        super(props);
        this.state = {
            email: '',
            loading: false,
            isVerify: false,

            companyId: 0,
            companyData: {},
            disableEmail: false,
            isValidEmail: false,
            showCompanyName: false
        }
    }

    handleValidate = () => {
        if(this.state.email && this.state.email.length > 0){
            this.setState({loading: true});
            axios.post('/api/auth/validateEmail', {
                email: this.state.email,
            }).then((res)=>{
                this.setState({ 
                    loading: false,
                    disableEmail: true, 
                    isValidEmail: true,
                    companies: res.data.company? res.data.company: [], 
                })
                if(res.data.company.length === 1){
                    let companyId = res.data.company && res.data.company.length? res.data.company[0].companyId: 0;
                    this.setState({ companyId: companyId });
                    this.handleReset();
                } else {
                    this.setState({showCompanyName: true})
                }
            })
            .catch(error => {
                this.setState({loading: false})
                apiError(error)
            })
        } else {
            toast.error('Please enter emailId');
        }
    }
    
    handleReset = () => {
        if(this.state.email.length === 0){
            toast.error("Please enter email Id")
            return ;
        }
        this.setState({ loading: true })
        axios.post('/api/auth/sendReset', {
            email: this.state.email,
            companyId: this.state.companyId
        })
        .then(res => {
            this.setState({ isVerify: true, loading: false });
        })
        .catch(err => {
            this.setState({ loading: false });
            apiError(err)
        })
    }

    render() {
        return (
            <div className="login-wrap" id="auth-section">
                <div className="row m-0">
                    
                    {/* <div className="col-sm-8 d-flex align-items-center left-wrap">
                        <div className="col-sm-9 mx-auto mid-blank">
                            <img src={loginImg} alt="email" className="login-img"></img>
                            <div className="text-wrap">
                                <h5>Digital Collection</h5>
                                <p>All important data and information of all  your tenants <br></br> in one place, completely digital.</p>
                            </div>
                        </div>
                    </div> */}

                    <div className="col-sm-4 right-wrap mx-auto">

                        <div className="col-sm-9 mx-auto p-0">
                            
                                <div className="content">
                                    <div className="text-center">
                                         <img src={threatcop} alt="email" className="threatcop-logo"></img>
                                    </div>
                                    {!this.state.isVerify?
                                        <div className="login-box">
                                           
                                            <h4>Forgot Password</h4>
                                            <Form.Group controlId="formBasicEmail">
                                                <label>Email Address</label>
                                                <Form.Control type="email" disabled={this.state.isValidEmail} value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })}  placeholder="Please enter your registered email id" />
                                            </Form.Group>
                                            {this.state.showCompanyName? 
                                                <Form.Group controlId="exampleForm.ControlSelect">
                                                    <Form.Control as="select" className="form-company" value={this.state.companyId} onChange={e => this.setState({companyId: e.target.value})}>
                                                        <option value="0">Select Company for forgot password</option>
                                                        {this.state.companies.map((company, index) => {
                                                            return <option key={index} value={parseInt(company.companyId, 10)}>{company.companyName}{`( ${company.type} )`}</option>
                                                        })}
                                                    </Form.Control>
                                                </Form.Group>: ''
                                            }
                                            {!this.state.isValidEmail?
                                                <Button className="btn-login w-100" disabled={this.state.loading} onClick={this.handleValidate}> <Spinner loading={this.state.loading}></Spinner> Next </Button>:
                                                <Button className="btn-login w-100" disabled={this.state.loading} onClick={this.handleReset}> <Spinner loading={this.state.loading}></Spinner> Next </Button>
                                            }
                                            <p className="mt-2">Already Registered? <a className="link-color ml-1" onClick={() => this.props.history.push('/login')}>Login Here</a></p>
                                        </div>:
                                        <div className="forget-msg">
                                            <h5>Forgot Password</h5>
                                            <h6>Password reset link has been send to your registered email Id</h6>
                                            <p>Please check your mail and reset password</p>
                                            <p>Click Here to<Button className="btn-link ml-1" onClick={() => this.props.history.push('/login')}>Login</Button></p>
                                        </div>
                                    }
                                </div>
                             
                        </div>


                    </div>

                   
                </div>
                <ToastContainer position="top-right" autoClose={5000}/>
            </div>
        );
    }
}