import React, { Component } from "react";
import { Link } from "react-router-dom";
import InputRange from "react-input-range";
import { Modal, Button } from "react-bootstrap";

import "./launch-modal.css";
import "react-input-range/lib/css/index.css";

export default class ConfiguredModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLectureSwitching: false,
			isCheckConfigure: false,
			isVideoSeeking: false,
			isQuizBeforeVideo: false,
			isQuizFailReminder: false,
			isUserCorrectAnswer: false,
			isVideoSpeedControls: false,
			quizPassingPercentage: 10,
		};
	}

	componentDidUpdate = (prevProps) => {
		if (this.props.options !== prevProps.options) {
			this.setState({
				isLectureSwitching: this.props.options.isLectureSwitching,
				isVideoSeeking: this.props.options.isVideoSeeking,
				isQuizBeforeVideo: this.props.options.isQuizBeforeVideo,
				isQuizFailReminder: this.props.options.isQuizFailReminder,
				isUserCorrectAnswer: this.props.options.isUserCorrectAnswer,
				isVideoSpeedControls: this.props.options.isVideoSpeedControls,
				quizPassingPercentage: this.props.options.quizPassingPercentage,
			});
		}
	};

	clickHandle = () => {
		let options = {
			isCheckConfigure: true,
			isVideoSeeking: this.state.isVideoSeeking,
			isQuizBeforeVideo: this.state.isQuizBeforeVideo,
			isLectureSwitching: this.state.isLectureSwitching,
			isQuizFailReminder: this.state.isQuizFailReminder,
			isUserCorrectAnswer: this.state.isUserCorrectAnswer,
			isVideoSpeedControls: this.state.isVideoSpeedControls,
			quizPassingPercentage: this.state.quizPassingPercentage,
		};
		this.props.setOptions(this.props.courseId, options);
		this.props.showHandle();
	};

	handleLectureSwitching = (e) => {
		this.setState({ isLectureSwitching: e.target.checked });
	};
	handleVideoSeeking = (e) => {
		this.setState({ isVideoSeeking: e.target.checked });
	};
	handleVideoSpeedControlsChange = (e) => {
		this.setState({ isVideoSpeedControls: e.target.checked });
	};
	handleQuizBeforeVideo = (e) => {
		this.setState({ isQuizBeforeVideo: e.target.checked });
	};
	handleQuizFailReminder = (e) => {
		this.setState({ isQuizFailReminder: e.target.checked });
	};
	handleUserCorrectAnswer = (e) => {
		this.setState({ isUserCorrectAnswer: e.target.checked });
	};
	handleVideoSpeedControls = (e) => {
		this.setState({ isVideoSpeedControls: e.target.checked });
	};

	render() {
		return (
			<Modal
				show={this.props.showModal}
				className="chooseModal"
				onHide={() => this.props.showHandle()}
				dialogClassName="modal-90w"
				aria-labelledby="example-custom-modal-styling-title"
			>
				<Modal.Header closeButton>
					<div id="example-custom-modal-styling-title">
						<h5>Course Settings</h5>
						<p>Configure course settings according to your preference</p>
					</div>
				</Modal.Header>
				<Modal.Body>
					<div className="form-group" id="course-setting-wrapper">
						<div className="cst-wrapper">
							<div className="row settings-Vsec">

								<div className="settingBox">
									<div class="d-flex align-items-center">
										<h5>Lecture Switching</h5> 
										<div className="custom-control custom-switch">
												<input
													type="checkbox"
													className="custom-control-input"
													name="setting1"
													id="setting1"
													autoComplete="off"
													value={true}
													checked={this.state.isLectureSwitching}
													onChange={this.handleLectureSwitching}
												/>
												<label className="custom-control-label" htmlFor="setting1"></label>
										</div>
									</div>
									<p className="lcCardP">Enable this feature to allow the learners to skip the lecture and jump to the next lecture.</p>
							</div>

							<div className="settingBox">
								<div class="d-flex align-items-center">
									<h5>Video Seeking</h5>
									<div className="custom-control custom-switch">
										<input
											type="checkbox"
											className="custom-control-input"
											name="setting2"
											id="setting2"
											autoComplete="off"
											value={true}
											checked={this.state.isVideoSeeking}
											onChange={this.handleVideoSeeking}
										/>
										<label className="custom-control-label" htmlFor="setting2"></label>{" "}
									</div>
								</div>
								<p className="lcCardP"> This feature enables the learner to forward the time frame of the lecture video. </p>
							</div>



							<div className="settingBox">
								<div class="d-flex align-items-center">
									<h5>Allow Quiz Before Video</h5>

									<div className="custom-control custom-switch">
										<input
											type="checkbox"
											className="custom-control-input"
											name="setting3"
											id="setting3"
											autoComplete="off"
											value={true}
											checked={this.state.isQuizBeforeVideo}
											onChange={this.handleQuizBeforeVideo}
										/>
										<label className="custom-control-label" htmlFor="setting3"></label>
									</div>
								</div>
								<p className="lcCardP"> Enable this feature to allow your learners to attempt the quiz before watching lecture videos. </p>
							</div>



							<div className="settingBox">
								<div class="d-flex align-items-center">
									<h5>Allow Quiz Failed Reminder</h5>

									<div className="custom-control custom-switch">
										<input
											type="checkbox"
											className="custom-control-input"
											name="setting4"
											id="setting4"
											autoComplete="off"
											value={true}
											checked={this.state.isQuizFailReminder}
											onChange={this.handleQuizFailReminder}
										/>
										<label className="custom-control-label" htmlFor="setting4"></label>
									</div>
								</div>
								<p className="lcCardP"> Enable this option to send the reminder email to the learners who have failed the quiz.</p>

							</div>

							<div className="settingBox">
								<div class="d-flex align-items-center">
									<h5>Allow Correct Answer Enforcement</h5>

									<div className="custom-control custom-switch">
										<input
											type="checkbox"
											className="custom-control-input"
											name="setting5"
											id="setting5"
											autoComplete="off"
											value={true}
											checked={this.state.isUserCorrectAnswer}
											onChange={this.handleUserCorrectAnswer}
										/>
										<label className="custom-control-label" htmlFor="setting5"></label>
									</div>
								</div>
								<p className="lcCardP"> Enable this option to let user select ONLY correct answer.</p>
							</div>
							
							<div className="settingBox">
								<div class="d-flex align-items-center">
									<h5>Video Speed Controls</h5>
									<div className="custom-control custom-switch">
										<input
											type="checkbox"
											className="custom-control-input"
											name="setting6"
											id="setting6"
											autoComplete="off"
											value={true}
											checked={this.state.isVideoSpeedControls}
											onChange={this.handleVideoSpeedControls}
										/>
										<label className="custom-control-label" htmlFor="setting6"></label>
									</div>
								</div>
								<p className="lcCardP"> Enable this option to give Learners controls to fast-forward the video. </p>
							</div>

						</div>

					<div className="row settings-Vsec hide-quiz mb-0">
								<div className="col-lg-5">
									<div className="page-separator">
										<div className="page-separator__text">Quiz Passing (%)</div>
									</div>
									<p className="lcCardP"> Set a passing % for Quiz </p>
								</div>
								<div className="col-lg-6">
									<InputRange
										maxValue={100}
										minValue={0}
										value={this.state.quizPassingPercentage}
										onChange={(value) => this.setState({ quizPassingPercentage: value })}
										onChangeComplete={(value) => console.log(value)}
									/>
								</div>
					</div>
				</div>
			</div>

			</Modal.Body>
				<div className="modal-footer">
					<div className="text-right">
								<Link to="#" onClick={this.handleNextPath}>
									<Button className="btn closeBtn mr-2" onClick={() => this.props.showHandle()}>
										{" "}
										Close{" "}
									</Button>
									<Button className='btn btn-success' onClick={() => this.clickHandle()}>
										{" "}
										Save Settings{" "}
									</Button>
								</Link>
					</div>				
				</div>
			</Modal>
		);
	}
}
