import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import DepartmentList from './department-list/department-list';
import DepartmentEdit from './department-edit/department-edit';
import UserList from './user-list/user-list';

export default class Department extends Component {
	render() {	
        const { match } = this.props;
		return (
            <Switch>
                <Route path={`${match.path}/groups`} exact component={ DepartmentList }/>
                <Route path={`${match.path}/user`} exact component={ UserList }/>
                <Route path={`${match.path}/groups/:id`} exact component={ DepartmentEdit }/>
                <Route render={() => (<Redirect to="/invalid" />)}/>
            </Switch>
		);
	}
}