import React, { Component } from "react";
import ApexCharts from 'apexcharts'

export default class BreachReportGraph extends Component {
	state = { chart: null }

	componentDidMount = () => {
		this.load();
	}

	load = () => {
		let options = {
			series: [{
				name: 'No of Hacked Users',
				data: this.props.value
			}],
			colors: ['#F1B44C', '#F46A6A'],
			chart: {
				height: 300,
				type: 'area',
				zoom: {
					enabled: true
				},
				toolbar: {
					show: false
				}
			},
			dataLabels: {
				enabled: false
			},
			stroke: {
				curve: 'smooth'
			},
			xaxis: {
				type: 'number',
				categories: this.props.time
			},
			yaxis: {
                title: {
                  text: 'No of Hacked Users'
                }
            },
			tooltip: {
				show: false,
				x: {
					format: 'dd/MM/yy HH:mm'
				},
			},
			grid: {
				show: false,
			}
		}
		var chart = new ApexCharts(document.querySelector("#breach"), options);
		chart.render();
		this.setState({chart: chart})
	}

	componentDidUpdate = (prevProps) => {
		if(this.props.value !== prevProps.value){
			if(this.state.chart) this.state.chart.destroy();
			this.load();
		}
	}

	render() {
		return (
			<div id="breach"></div>
		);
	}
}