import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { PublicClientApplication } from "@azure/msal-browser";
import axios from '../../axios'

class AzureModal extends Component {
    constructor(props) {
        super(props);
        this.pca = null;
    }

    handleLogin = async () => {
        let response  = await axios.get('/api/company/getAzureSettings')
        let clientId = response.data.clientId;
        let groupEnabled = response.data.groupEnabled;
        let groupId;
        if(groupEnabled){
            groupId = response.data.groupId
        }

        const msalConfig = {
            auth: {
                clientId 
            },
        };
        
        const loginRequest = {
            scopes: ["User.Read.All"] 
        };
        
        if (!this.pca) {
            this.pca = new PublicClientApplication(msalConfig);
        }

        try {
            const loginResponse = await this.pca.loginPopup(loginRequest);
            if (loginResponse) {
                //Now we get an accessToken
                let msalResponse = await this.pca.acquireTokenSilent({
                    ...loginRequest,
                    account: this.pca.getAccountByHomeId(loginResponse.account.homeAccountId)
                });
                // console.log("token==============>", msalResponse)
                if(msalResponse){
                    if(groupEnabled && groupId){
                        msalResponse = JSON.parse(JSON.stringify(msalResponse));
                        msalResponse = {...msalResponse, groupId}
                    }

                    this.props.responseAzure(null, msalResponse);
                }
            }
        } catch (err) {
            this.props.responseAzure(err, null);
        }
    }

    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.showHandle}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title">
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        <h6 className="mb-0">Import from Azure</h6>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Import all users associated with your Azure admin account by logging into the account through
                        the button given below.
                    </p>
                    <div className="text-center">
                        <Button variant="primary" onClick={this.handleLogin}>
                            Sign up using Azure
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export default AzureModal;
