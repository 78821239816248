import React, { Component } from "react";
import { toast } from "react-toastify";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { Modal, Form, Button } from "react-bootstrap";

import axios from "../../axios";
import apiError from "../../error";
import Spinner from "../spinner/spinner";

import "./rightside-modal.css";
import swal from "sweetalert";

export default class RightSideModal extends Component {
	state = {
		domains: [],
		loading: false,
		disableBtn: false,
		disabledEdit: true,
		statusCheck: false,
		phone: this.props.phone,
		email: this.props.email,
		targetId: this.props.targetId,
		lastname: this.props.lastname,
		overrule: false,
		overruleLoc: false,
		department: this.props.department,
		location: this.props.location,
		position: this.props.position,
		firstname: this.props.firstname,
	}

	componentDidMount = () => {
		this.loadDomain();
	};

	componentDidUpdate = (prevProps) => {
		if (this.props.email !== prevProps.email) {
			this.setState({
				disabledEdit: true,
				statusCheck: false,
				phone: this.props.phone,
				email: this.props.email,
				lastname: this.props.lastname,
				department: this.props.department,
				location: this.props.location,
				position: this.props.position,
				targetId: this.props.targetId,
				firstname: this.props.firstname,
				overrule: this.props.overrule,
				overruleLoc: this.props.overruleLoc,
			});
		}
	};

	loadDomain = () => {
		axios
			.get("/api/domain/", {})
			.then((res) => {
				if (res.data.domains && res.data.domains.length) {
					let domains = [];
					for (var i = 0; i < res.data.domains.length; i++) {
						let domain = res.data.domains[i];
						if (domain.isVerify) {
							domains.push(domain.name);
						}
					}
					this.setState({ domains: domains });
				}
			})
			.catch((err) => {
				apiError(err);
			});
	};

	handleValidate = () => {
		if (this.state.firstname.length === 0) {
			toast.error("Please enter first name");
			return false;
		} else if (this.state.email.length === 0) {
			toast.error("Please enter email Id");
			return false;
		} else if (this.state.domains.indexOf(this.state.email.split("@")[1]) === -1) {
			toast.error("Domain is not verified");
			return false;
		}
		return true;
	};

	clearData = () => {
		this.setState({
			firstname: "",
			lastname: "",
			email: "",
			phone: "",
			department: "",
			location: "",
			position: "",
			overrule: false,
			overruleLoc: false,
			targetId: "0",
		});
	};

	addUser = () => {
		if (this.handleValidate()) {
			this.setState({ loading: true, disableBtn: true});
			if(this.state.targetId && this.state.targetId !== "0"){
				this.setState({statusCheck: true})
			}
			else{
				this.setState({statusCheck: false})
			}
			axios
				.patch("/api/targets", {
					firstname: this.state.firstname,
					lastname: this.state.lastname,
					email: this.state.email,
					phone: this.state.phone,
					position: this.state.position,
					department: this.state.department,
					location: this.state.location,
					targetId: this.state.targetId,
					overrule: this.state.overrule,
					overruleLoc: this.state.overruleLoc,
				})
				.then((res) => {
					const { error } = res.data
					switch (error) {
						case "TARGET_DEPT_CONFLICT": {
							return swal({
								title: "Departments conflict occured",
								icon: "warning",
								text: "Are you sure you want to update department? This action will replace the department for this member with the new one.",
								buttons: ["Cancel", "Update",],
								confirmButton: "Update",
								dangerMode: true,
							})
								.then((willSuccess) => {
									if (willSuccess) {
										this.setState({ overrule: true, }, () => { this.addUser() });
									}
									else {
										swal("Cancelled", "Your action was cancelled.", "info");
										this.props.handleModal()
									}
									this.setState({ loading: false, disableBtn: false, statusCheck: true });
								}).catch(err => console.log("Cancelled department update request"))
						}
						case "TARGET_DEPT_DELETE_CONFIRMATION": {
							return swal({
								title: "Departments conflict occurred",
								icon: "warning",
								text: "Are you sure you want to remove this user from its associated department?",
								buttons: ["Cancel", "Yes Delete it!"],
								confirmButton: "Update",
								dangerMode: true,
							})
								.then(willSuccess => {
									if (willSuccess) { return this.setState({ overrule: true }, () => { this.addUser() }); }
									else {
										swal("Cancelled", "Your action was cancelled.", "info");
									}
									this.props.handleModal();
									this.setState({ loading: false, disableBtn: false, statusCheck: true });

								}).catch(err => null)
						}
						case "TARGET_LOCATION_CONFLICT": {
							return swal({
								title: "Location conflict occured",
								icon: "warning",
								text: "Are you sure you want to update location? This action will replace the location for this member with the new one.",
								buttons: ["Cancle", "Update"],
								confirmButton: "Update",
								dangerMode: true,
							})
								.then(willSuccess => {
									if (willSuccess) { return this.setState({ overruleLoc: true }, () => { this.addUser() }); }
									else {
										swal("Cancelled", "Your action was cancelled.", "info");
									}
									this.props.handleModal();
									this.setState({ loading: false, disableBtn: false, statusCheck: true });
								}).catch(err => null)
						}
						case "TARGET_LOCATION_DELETE_CONFIRMATION": {
							return swal({
								title: "Location conflict occurred",
								icon: "warning",
								text: "Are you sure you want to removed the location info for this user. This will be applied company wide.",
								buttons: ["Cancel", "Yes Delete it!",],
								confirmButton: "Update",
								dangerMode: true,
							})
								.then(willSuccess => {
									if (willSuccess) { return this.setState({ overruleLoc: true }, () => { this.addUser() }); }
									else {
										swal("Cancelled", "Your action was cancelled.", "info");
									}
									this.props.handleModal();
									this.setState({ loading: false, disableBtn: false, statusCheck: true});
								}).catch(err => null)
						}
						default: 
					}
					this.state.statusCheck ? toast.success("User updated successfully") : toast.success("User added successfully");
					this.clearData();
					this.props.load();
					this.props.handleModal();
					this.setState({ loading: false, disableBtn: false, statusCheck: false});
				})
				.catch((err) => {
					apiError(err);
					this.setState({ loading: false, disableBtn: false, statusCheck: false });
				});
		}
	};

	render() {
		return (
			<Modal className="info-filter right mainfooterModal right-modal-wrap" show={this.props.show} onHide={() => this.props.handleModal(false)} autoFocus keyboard>
				<Modal.Header closeButton>
					<div>
						<h5>Add User</h5>
						<p>Fill the user details.</p>
					</div>
				</Modal.Header>
				<Modal.Body>
					<div className="addUserDetail">
						<h6>Personal Details</h6>
						<div className="row">
							<div className="col-md-6">
								<Form.Label>
									First Name<span className="red-star">*</span>
								</Form.Label>
								<Form.Group controlId="formBasicEmail">
									<Form.Control
										type="email"
										autoComplete="new-password"
										value={this.state.firstname}
										onChange={(e) => this.setState({ firstname: e.target.value, disabledEdit: false })}
									/>
								</Form.Group>
							</div>

								<div className="col-md-6">
								<Form.Label>Last Name</Form.Label>
								<Form.Group controlId="formBasicEmail">
									<Form.Control
										type="email"
										autoComplete="new-password"
										value={this.state.lastname}
										onChange={(e) => this.setState({ lastname: e.target.value, disabledEdit: false })}
										placeholder="Last Name"
									/>
								</Form.Group>
								</div>

								<div className="col-md-6">
									<Form.Label>
										Email<span className="red-star">*</span>
										</Form.Label>
										<Form.Group controlId="formBasicEmail">
											<Form.Control
												type="email"
												autoComplete="new-password"
												value={this.state.email}
												disabled={this.props.updateEmail}
												onChange={(e) => this.setState({ email: e.target.value, disabledEdit: false })}
												placeholder="Please enter email"
											/>
										</Form.Group>
								</div>
								<div className="col-md-6">
									<Form.Label>Phone</Form.Label>
										<PhoneInput country={"in"} value={this.state.phone} onChange={(phone) => this.setState({ phone: phone, disabledEdit: false })}></PhoneInput>
								</div>
							</div>
						</div>
					
					

							<div className="addUserDetail">
								<h6>Official Details</h6>
									<div className="row">
										<div className="col-md-6">
											<Form.Label>Position</Form.Label>
												<Form.Group controlId="formBasicEmail">
													<Form.Control
														type="email"
														autoComplete="new-password"
														value={this.state.position}
														onChange={(e) => this.setState({ position: e.target.value, disabledEdit: false })}
														placeholder="Please enter position"
													/>
											</Form.Group>
										</div>

										<div className="col-md-6">
										<Form.Label>Department</Form.Label>
											<Form.Group controlId="formBasicEmail">
												<Form.Control
													type="email"
													autoComplete="new-password"
													value={this.state.department}
													onChange={(e) => this.setState({ department: e.target.value, disabledEdit: false })}
													placeholder="Please enter department"
												/>
											</Form.Group>
										</div>

										<div className="col-md-6">
											<Form.Label>Location</Form.Label>
											<Form.Group controlId="formBasicEmail">
												<Form.Control
													type="email"
													autoComplete="new-password"
													value={this.state.location}
													onChange={(e) => this.setState({ location: e.target.value, disabledEdit: false })}
													placeholder="Please enter location"
												/>
											</Form.Group>
										</div>
									</div>
								</div>
						
							</Modal.Body>
								<div className="modalFooter">
										<div className="row ">
											<div className="col-md-12 text-right">
												<button type="button" class="btn closeBtn">Cancel</button>
												<Button className='btn btn-success ml-2' onClick={this.addUser} disabled={this.state.disabledEdit}>
													<Spinner loading={this.state.loading}></Spinner>
													{this.props.targetId && this.props.targetId !== "0" ? "Update User" : "Add User "}
												</Button>
											</div>
										</div>
									</div>
						</Modal>
		);
	}
}
