import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import IamPolicy from './iampolicy/iampolicy';
import IamUserList from './iamusers-list/iamuser-list';
import IamUserEdit from './iamusers-edit/iamuser-edit';
import IamRolesList from './iamroles-list/iamroles-list';
import IamRolesEdit from './iamroles-edit/iamroles-edit';

export default class Campaign extends Component {
	render() {	
        const { match } = this.props;
		return (
            <Switch>
                <Route path={`${match.path}/users`} exact component={ IamUserList }/>
                <Route path={`${match.path}/users/:id`} exact component={ IamUserEdit }/>
                <Route path={`${match.path}/roles`} exact component={ IamRolesList }/>
                <Route path={`${match.path}/roles/:id`} exact component={ IamRolesEdit }/>
                <Route path={`${match.path}/policy`} exact component={ IamPolicy }/>
                <Route render={() => (<Redirect to="/invalid" />)}/>
            </Switch>
		);
	}
}