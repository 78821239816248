import React, { Component } from "react";
import { toast } from 'react-toastify';
import { Card, Breadcrumb, Button, Form } from 'react-bootstrap';

import axios from '../../axios';
import apiError from '../../error';
import { makeid } from '../../function';
import Spinner from '../../component/spinner/spinner';

export default class CreateClient extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            phone: '',
            lastname: '',
            firstname: '',
            industries: [],
            industryId: '0',
            loading: false,
            password: makeid(12),
        }
        this.loadIndustry();
    }

    validate = () => {
        if(this.state['firstname'].length === 0){
            toast.error('Please enter first name')
            return false;
        }
        if(this.state['email'].length === 0){
            toast.error('Please enter email Id');
            return false;
        }
        if(this.state.industryId === 0 || this.state.industryId === '0'){
            toast.error('Please select industry type')
            return false;
        }
        if(!this.state['firstname'].length || !this.state['email'].length) return false;
        else return true;
    }

    createClient = async () => {
        await this.setState({ loading: true })
        if(this.validate()){
            axios.post('/api/auth/createclient', {
                email: this.state.email,
                phone: this.state.phone,
                lastname: this.state.lastname,
                password: this.state.password,
                firstname: this.state.firstname,
                industryId: this.state.industryId
            })
            .then((res) => {
                toast.success("Client created successfully");
                this.setState({ loading: false });
                this.props.history.goBack();
            })
            .catch(error => {
                this.setState({ loading: false })
                apiError(error);
            })
        } else {
            this.setState({ loading: false })
        }
    }

    loadIndustry = () =>{
        axios.get('/api/auth/industry', {})
        .then(res => {
            this.setState({industries: res.data.industries});
        })
        .catch(err => {
            apiError(err);
        })
    }

    render() {
        return (
            <div className="content-wrap">
                <div className="breadcrumbbar">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-head d-flex align-items-center justify-content-between">
                                <h4 className="page-title">Create Client</h4>
                                <Breadcrumb className="mb-0">
                                    <Breadcrumb.Item href="#" active>Create Client</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </div>
                    </div>
                </div>
                <Card>
                    <Card.Body>
                        <div className="page-content">
                            <Form>
                                <div className="row">
                                    <div className="col-md-6">
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label>First Name*</Form.Label>
                                            <Form.Control type="text" autoComplete="new-password" value={this.state.firstname} onChange={e => this.setState({firstname: e.target.value})} placeholder="Enter first name" />
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control type="text" autoComplete="new-password" value={this.state.lastname} onChange={e => this.setState({lastname: e.target.value})} placeholder="Enter last name" />
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label>Email address*</Form.Label>
                                            <Form.Control type="email" autoComplete="new-password" value={this.state.email} onChange={e => this.setState({email: e.target.value})} placeholder="Enter email Id" />
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Group controlId="formBasicPassword">
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control type="text" disabled={true} autoComplete="new-password" value={this.state.password} onChange={e => this.setState({password: e.target.value})} placeholder="Enter Password" />
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Group controlId="formBasicPassword">
                                            <Form.Label>Phone</Form.Label>
                                            <Form.Control type="number" autoComplete="new-password" value={this.state.phone} onChange={e => this.setState({phone: e.target.value})} placeholder="Enter Phone no" />
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                            <Form.Label>Industry Type*</Form.Label>
                                            <Form.Control as="select" className="form-industry" value={this.state.industryId} onChange={e => this.setState({industryId: e.target.value})}>
                                                <option value="0">Select Industry Type*</option>
                                                {this.state.industries.map((industry, index) => {
                                                    return <option key={index} value={industry.id}>{industry.name}</option>
                                                })}
                                            </Form.Control>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-12 text-right">
                                        <Button variant="primary" type="button" onClick={this.createClient} disabled={this.state.loading}><Spinner loading={this.state.loading}></Spinner>Create</Button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        );
    }
}