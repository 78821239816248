import React, { Component } from "react";
import { Modal, Table } from 'react-bootstrap';
import VisibilitySharpIcon from '@material-ui/icons/VisibilitySharp';

import axios from '../../../axios';
import apiError from '../../../error';
import { getDate } from '../../../function';

import '../../campaigns/campaign/campaign.css';

export default class DetailModalLMS extends Component {

    handleLMS = async(url) => {
        axios.get('/api/lms', {
            params: { url: url }
        })
        .then(res => {
            window.open(res.data.url, 'myWindow');
        })
        .catch(err => {
            apiError(err);
        });
    }

    render() {
        const simulation = this.props.details;
        return (
            <Modal show={this.props.detailModal} className="detail-modal right-modal right" onHide={() => this.props.detailHandle()} dialogClassName="modal-90w" aria-labelledby="example-custom-modal-styling-title">
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        <h5 className="mb-0">Training Details</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table borderless>
                        {simulation?
                            <tbody>
                                <tr>
                                    <td>Campaign Name</td>
                                    <td>{simulation.name}</td>
                                </tr>
                                <tr>
                                    <td>Create Date</td>
                                    <td>{getDate(simulation.createdAt)}</td>
                                </tr>
                                {simulation.CampaignCourses && simulation.CampaignCourses.length?
                                    <tr>
                                        <td className="v-top">Courses</td>
                                        <td>
                                            <ul className="table-ul row">
                                                {simulation.CampaignCourses.map((template, index) => {
                                                    return template.Course? <li key={'template-'+index}><span>{template.Course.name}</span><VisibilitySharpIcon className="list-icon" onClick={e => this.handleLMS(`${this.props.previewURL}${template.Course.id}`)} ></VisibilitySharpIcon></li>: null
                                                })}
                                            </ul>

                                        </td>
                                    </tr>: null
                                }
                            </tbody>: null
                        }
                    </Table>
                </Modal.Body>
            </Modal>
        );
    }
}