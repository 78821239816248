import React, { Component } from "react";
import { Card, Breadcrumb, InputGroup, FormControl, Button, Table, Accordion } from 'react-bootstrap';

import SearchSharpIcon from '@material-ui/icons/SearchSharp';
import VisibilitySharpIcon from '@material-ui/icons/VisibilitySharp';
import ChevronRightSharpIcon from '@material-ui/icons/ChevronRightSharp';

import './iampolicy.css';
import axios from "../../../axios";
import apiError from "../../../error";
export default class IamPolicy extends Component {
    constructor(props){
        super(props);
        this.state = {
            policyId: 0,
            policies: [],
            search: '',
        }
        this.load();
    }

    load = () => {
        axios.get('/api/iam/list', {
            params: {
                search: this.state.search
            }
        })
        .then(res => {
            this.setState({ policies: res.data.policies })
        })
        .catch(err => apiError(err));
    }

    handlePolicyId = (policyId) => {
        if(this.state.policyId !== policyId){
            this.setState({ policyId: policyId })
        } else this.setState({ policyId: 0 })
    }

    render() {
        return (
        <div className="content-wrap">
            {/**********************
                Breadcrumbbar
             **********************/}
                <div className="breadcrumbbar">
                <div className="flexRow row">
                    <div className="col-md-4">
                    <div className="page-head d-flex align-items-center justify-content-between">
                        <h4 className="page-title">Policies</h4>
                    </div>
                    </div>
                </div>
                </div>
            {/*******************
                Breadcrumbbar End
            *******************/}



            {/**********************
             Filter
            **********************/}
             <div className="filter-wrapper">
                <div className="alignItemsCenter row">
                    <div className="col-md-3 p-0">
                        <div className="positionSearchIcon"> 
                            <InputGroup className="search-wrap">
                                 <FormControl type="text" placeholder="Search" value={this.state.search} onChange={e => this.setState({search: e.target.value}, ()=> this.load())} className="inputSearch mr-sm-2 form-control" /><SearchSharpIcon className="search-icon"></SearchSharpIcon>
                            </InputGroup>  
                        </div>    
                     </div>

                     <div className="col-md-9">
                     </div>
                </div>
             </div>
              {/**********************
                End
             **********************/}

                <div className="contentbar campListGrids">
                   
                        <div className="row TableHead mx-0">
                            <div className="col-md-1 expandGrid">#</div>
                            <div className="col-md-6">Policy Name</div>
                            <div className="col-md-3">Type</div>
                            <div className="col-md-1">Action</div>
                          </div>

                            <Accordion>
                                <Card>
                                    {this.state.policies.map((policy, index) => {
                                        return <div key={`policy-${index}`}>
                                            <Accordion.Toggle as={Card.Header} eventKey={index+1} className={this.state.policyId === policy.id? "active tableGrid": "tableGrid"} onClick={e => this.handlePolicyId(policy.id)}>
                                                    <div className="row align-items-center">
                                                        <div className="col-md-1 expandGrid">
                                                            <ChevronRightSharpIcon className="right-arrow"></ChevronRightSharpIcon>
                                                            </div>
                                                        <div className="col-md-6">{policy.name}</div>
                                                        <div className="col-md-3">{policy.type}</div>
                                                        <div className="col-md-1"><Button className="btn-primary-rgba btn-round"><VisibilitySharpIcon className="table-icon"></VisibilitySharpIcon></Button></div>
                                                    </div>
                                               
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey={index+1}>
                                                <Card.Body>
                                                    <div id="table-resp" className="tableData">
                                                        <Table responsive hover>
                                                            <thead>
                                                                <tr>
                                                                    <th>Id</th>
                                                                    <th>Action Name</th>
                                                                    <th>Type</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {policy.policyActions.map((policyAction, index1) => {
                                                                    return <tr key={`action-${index1}`}>
                                                                        <td>{index1 + 1}</td>
                                                                        <td>{policyAction.action.name}</td>
                                                                        <td>{policyAction.action.type}</td>
                                                                        <td>None</td>
                                                                    </tr>
                                                                })}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </div> 
                                    })}
                                </Card>
                            </Accordion>

                   </div>
            </div>
        );
    }
}