import React, { Component } from "react";
import ApexCharts from 'apexcharts'

export default class AverageBreach extends Component {
	state = { chart: null };

	componentDidMount = () => {
		this.load();
	}

	load = () => {
		var options = {
			series: [{
				name: "Average Breach Response",
				data: this.props.responce? this.props.responce: []
			}],
			chart: {
				height: 300,
				type: 'line',
				zoom: {
					enabled: false
				},
				toolbar: {
					show: false
				}
			},
			colors:['#5570E6'],
			dataLabels: {
				enabled: false
			},
			stroke: {
				curve: 'straight'
			},
			grid: {
				position: 'front'
			},
			xaxis: {
				categories: [],
			},
			markers: {
				size: 5,
			}
		}
		var chart = new ApexCharts(document.querySelector("#average"), options);
		if(chart) chart.render();
		this.setState({chart: chart});
	}

	componentDidUpdate = (prevProps) => {
		if(this.props.responce !== prevProps.responce){
			if(this.state.chart) this.state.chart.destroy();
			this.load();
		}
	}

	render() {
		return (
			<div id="average"></div>
		);
	}
}