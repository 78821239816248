import { Component } from "react";
import ApexCharts from 'apexcharts'

export default class CategoryLanding extends Component {
	constructor(props) {
		super(props);
		this.state = {
			chart: ''
		}
	}
	componentDidMount = () => {
		let options = {
			series: [{
				data: this.props.categoryTimeSpend['series']
			}],
			chart: {
				type: 'bar',
				height: 350,
				toolbar: {
					show: false
				}
			},
			plotOptions: {
				bar: {
					horizontal: true,
				}
			},
			dataLabels: {
				enabled: false
			},
			xaxis: {
				categories: this.props.categoryTimeSpend['category'],
			}
		}
		var chart = new ApexCharts(document.querySelector("#landing"), options);
		chart.render();
	}

	componentDidUpdate = (prevProps) => {
		if (this.props.categoryTimeSpend !== prevProps.categoryTimeSpend) {
			this.state.chart.destroy();
			this.componentDidMount();
		}
	}

	render() {
		return '';
	}
}