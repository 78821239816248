import React, { Component } from "react";
import Pagination from "react-js-pagination";
import AddSharpIcon from '@material-ui/icons/AddSharp';
import SearchSharpIcon from '@material-ui/icons/SearchSharp';
import { Card, FormControl, InputGroup, Breadcrumb, Button, Form } from 'react-bootstrap';

import axios from '../../axios';
import axiosRequest from 'axios';
import apiError from '../../error';
import Spinner from '../../component/spinner/spinner';

var cancelRequest = undefined, CancelToken = axiosRequest.CancelToken;

export default class EmailList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            count: 0,
            users: [],
            limit: 10,
            search: '',
            activePage: 1,
            loading: true,
        }
        this.load();
    }

    nextPath = path => this.props.history.push(path);

    load = async () => {
        await this.setState({ loading: true })
        if(cancelRequest) cancelRequest();
        axios.get('/api/user/emails', {
            params: {
                limit: this.state.limit, 
                search: this.state.search,
                page: this.state.activePage, 
            },
            cancelToken: new CancelToken(function executor(c) { cancelRequest = c })
        })
        .then((res)=>{
            this.setState({
                loading: false,
                users: res.data.users,
                count: res.data.count
            })
        })
        .catch(error => {
            if(!axiosRequest.isCancel(error)){
                this.setState({ users: [], count: 0, loading: false })
                apiError(error);
            }
        })
    }

    activate = async (userId, companyId) => {
        await this.setState({ loading: true })
        axios.post('/api/auth/activateclient', { userId, companyId })
        .then((res)=>{
            this.setState({ loading: false })
            this.load();
        })
        .catch(error => {
            apiError(error);
            this.setState({ loading: false })
        })
    }

    render() {
        return (
            <div className="content-wrap">
                <div className="breadcrumbbar">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-head d-flex align-items-center justify-content-between">
                                <h4 className="page-title">Client Emails</h4>
                                <Breadcrumb className="mb-0">
                                    <Breadcrumb.Item href="#" active>Client Emails </Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </div>
                    </div>
                </div>
                <Card>
                    <Card.Body>
                        <div className="row justify-content-between align-items-center mini-head">
                            <div className="col-md-4">
                                <InputGroup className="search-wrap"><FormControl type="text" placeholder="Search" value={this.state.search} className="mr-sm-2" onChange={(e) => this.setState({search: e.target.value, activePage: 1}, () => this.load())}/><SearchSharpIcon className="search-icon"></SearchSharpIcon></InputGroup>
                            </div>
                            <div className="col-md-8 text-right d-flex justify-content-end align-items-center">
                                <Form.Group controlId="exampleForm.ControlSelect1" onChange={(e)=> this.setState({limit: parseInt(e.target.value, 10), activePage: 1}, ()=> this.load())} className="d-inline-block">
                                    <Form.Control as="select" className="btn btn-outline-secondary">
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </Form.Control>
                                </Form.Group>
                                <Button variant="primary" className="ml-2" disabled={this.state.loading} onClick={this.load}><Spinner loading={this.state.loading}></Spinner>Refresh</Button>
                                <Button variant="primary" className="ml-2" onClick={(e) => this.nextPath('/9LKSJFBSJKLDFO/jasdfasdfjksdkGDFc')}><AddSharpIcon className="btn-icon"></AddSharpIcon>Create Client</Button>
                            </div>
                        </div>
                        <div>
                            <div className="row bg-light mx-0 row-head mb-3">
                                <div className="col-md-1">#</div>
                                <div className="col-md-4">Name</div>
                                <div className="col-md-3">Email</div>
                                <div className="col-md-2">Mobile</div>
                                <div className="col-md-2">Status</div>
                            </div>
                            {this.state.users.map((user, index) => {
                                let lastname = user.lastname? user.lastname:'';
                                return <div key={'email-'+index} className="row mx-0 align-items-center body-row bg-white mb-3 py-2">
                                    <div className="col-md-1">{index+1}</div>
                                    <div className="col-md-3">{user.firstname + lastname}</div>
                                    <div className="col-md-4">{user.email}</div>
                                    <div className="col-md-2">{user.phone? user.phone: '---'}</div>
                                    <div className="col-md-2">{user.status === 'active'? <p style={{color: 'green'}}>Active</p>: <Button onClick={e => this.activate(user.id, user.companyId)}>Activate</Button> }</div>
                                </div>
                            })}
                        </div>
                        { !this.state.loading ?
                            <Pagination
                                itemClass="page-item"
                                linkClass="page-link"
                                activePage={this.state.activePage}
                                itemsCountPerPage={this.state.limit}
                                totalItemsCount={this.state.count}
                                onChange={(pageNumber) => this.setState({activePage: pageNumber}, ()=> this.load())}
                            />: null
                        }
                    </Card.Body>
                </Card>
            </div>
        );
    }
}