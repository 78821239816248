import React, { Component } from "react";
import ApexCharts from 'apexcharts'

export default class groupReponseChart extends Component {
	constructor(props) {
		super(props);
		this.state = {
			chart: ''
		}
	}

	componentDidMount = () => {
		this.load();
	}

	load = () => {
		var options = {
			series: [{
				name: "Breach Time",
				data: this.props.groupResponce['breachTime']? this.props.groupResponce['breachTime']: []
			}, {
				name: "Incident Time",
				data: this.props.groupResponce['incidentTime']? this.props.groupResponce['incidentTime']: []
			}],
			chart: {
				height: 350,
				type: 'bar'
			},
			dataLabels: {
				enabled: false,
			},
			stroke: {
				curve: 'smooth'
			},
			xaxis: {
				type: 'text',
				labels: {
					show: false
				},
				categories: this.props.groupResponce['group']? this.props.groupResponce['group']: [],
			},
			tooltip: {
                enabled: true,
                style: {
                    backgroud: 'white',
                    fontSize: '12px',
                    fontFamily: undefined
                },
                y: {
                    formatter: (value) => { return value + " Seconds" },
                }
            },
		}
		
		var chart = new ApexCharts(document.querySelector("#groupChart"), options);
		if(chart) chart.render();
		this.setState({ chart: chart });
	}

	componentDidUpdate = (prevProps) => {
		if(this.props.groupResponce !== prevProps.groupResponce){
			if(this.state.chart) this.state.chart.destroy();
			this.load();
		}
	}

	render() {
		return (
			<div id="groupChart"></div>
		);
	}
}