export function setCookie(name, value, minute) {
    var expires = "", httpOnly = "";
    if (minute) {
        var date = new Date();
        expires = "; expires=" + new Date(date.setMinutes(date.getMinutes() + minute)).toUTCString();
    }
    if(name === 'token'){
        // httpOnly = 'httpOnly=true;'
    }
    document.cookie = name + "=" + (value || "")  + expires + "; SameSite=Lax; path=/; secure;" + httpOnly;
}

export function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

export function eraseCookie(name) {   
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export function getDate(date) {
    date = new Date(date);
    if(!isNaN(date.getTime())){
        const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        const day = date.getDate();
        const monthIndex = date.getMonth();
        const year = date.getFullYear();
        return day +'th '+ monthNames[monthIndex] +' '+ year +' | '+ date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    } else return '---';
}

export function getDateWithoutTime(date) {
    date = new Date(date);
    if(!isNaN(date.getTime())){
        const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        const day = date.getDate();
        const monthIndex = date.getMonth();
        const year = date.getFullYear();
        return day +' '+ monthNames[monthIndex] +' '+ year
    } else return '---';
}

export function makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789', charactersLength = 62;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function timeInAgoFormat(date) {
    var seconds = Math.floor((new Date() - date) / 1000);
    seconds = Math.abs(seconds);
    var interval = Math.floor(seconds / 31536000);
    if (interval > 1) {
        return interval + " years";
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
        return interval + " months";
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
        return interval + " days";
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
        return interval + " hours";
    }
    interval = Math.floor(seconds / 60);
    if (interval > 1) {
        return interval + " minutes";
    }
    return Math.floor(seconds) + " seconds";
}

export function removeExif(imageBuffer, options) {
    if (imageBuffer === undefined || imageBuffer.length < 2) {
        return undefined;
    }

    if (options === undefined) {
        options = {};
    }
    
    // Ensure it's a JPEG image before continuing
    var startPortion = imageBuffer[0].toString(16) + imageBuffer[1].toString(16);

    if (startPortion !== "ffd8") {
        return null;
        // throw new Error("Not a JPEG");
    }

    // Main portion which handles the logic for where to splice
    var offsetPairs = [];
    var lastRecordedByteIndex = 0;
    for (var i = 0; i + 1 < imageBuffer.length; i++) {
        // Gather the hex representation of the two bytes found at this point in string format
        const bytePortion = imageBuffer[i].toString(16) + imageBuffer[i + 1].toString(16);

        // There should be no EXIF metadata after the SOS marker
        if (bytePortion === "ffda") {
            break;
        }

        // Check for markers
        switch (bytePortion) {
            // APP1 Marker (which is typically designated for EXIF)
            case "ffe1":
                // Grab offset size of the EXIF data which is found in following two bytes
                const offsetSize = imageBuffer[i + 2] * 256 + imageBuffer[i + 3];

                if (options.verbose) {
                    console.log("APP1 start");
                    console.log("offset in hex: " + imageBuffer[i + 2].toString(16) + imageBuffer[i + 3].toString(16))
                    console.log("offset in decimal: " + offsetSize);
                }

                // By default, the first slice will start from last recorded index (typically 0)
                // and ends at the start of the APP1 section
                let offsetEnd = i;
                if (options.keepMarker) {
                    // Indicate that the size of the APP1 section is now just 2 bytes (these two)
                    imageBuffer[i + 2] = 0;
                    imageBuffer[i + 3] = 2;
                    // Offset end will now be at i + 4 to keep the marker and offset bytes
                    offsetEnd = i + 4
                }

                // Push the start and end offsets to the offset pairs,
                // to indicate that we want a slice of the data from these ends
                offsetPairs.push({
                    start: lastRecordedByteIndex,
                    end: offsetEnd
                });

                // Skip to the end of the APP1 segment
                lastRecordedByteIndex = i + offsetSize + 2;

                if (options.verbose)
                    console.log((imageBuffer[lastRecordedByteIndex] * 256 + imageBuffer[lastRecordedByteIndex + 1]).toString(16));

                i = lastRecordedByteIndex;
                if (options.verbose)
                    console.log("New i->" + i)
                break;
            default: continue
        }
    };

    // Write the last pair
    offsetPairs.push({
        start: lastRecordedByteIndex,
        end: imageBuffer.length
    });

    if (options.verbose)
        offsetPairs.forEach((pair) => {
            console.log(pair);
        });

    // This part here will slice the image buffer into pieces with the
    // size and offset of each piece defined by the offset pairs
    var imageSlices = offsetPairs.map((pair) => {
        return imageBuffer.slice(pair.start, pair.end);
    });

    return Buffer.concat(imageSlices);
};

/**
 * Remove EXIF data from multiple images
 */
export function removeMultipleExif(imageBuffers, options) {
    if (options === undefined) {
        options = {};
    }
    return imageBuffers.map((imageBuffer) => removeExif(imageBuffer, options.verbose));
}

export function getRandomColor(name) {
    const color = {
        'A': {light: '#FFD0D0', dark: '#FF4646', name: 'A'},
        'B': {light: '#FFF7D0', dark: '#F5B156', name: 'B'},
        'C': {light: '#D4EDDA', dark: '#1A8F34', name: 'C'},
        'D': {light: '#556EE7', dark: '#D4DBF9', name: 'D'},
        'E': {light: '#FBECD2', dark: '#F7A331', name: 'E'},
        'F': {light: '#CCF0E3', dark: '#34C38F', name: 'F'},
        'G': {light: '#E4E4E4', dark: '#343A40', name: 'G'},
        'H': {light: '#B3CCFF', dark: '#152C5B', name: 'H'},
        'I': {light: '#E7C3FF', dark: '#8E08E8', name: 'I'},
        'J': {light: '#FFDFA8', dark: '#FFA50D', name: 'J'},
        'K': {light: '#C3D3FF', dark: '#0043FF', name: 'K'},
        'L': {light: '#DFFFA1', dark: '#729C1F', name: 'L'},
        'M': {light: '#FFE5BE', dark: '#E99413', name: 'M'},
        'N': {light: '#B4FFF5', dark: '#009480', name: 'N'},
        'O': {light: '#FEC6A6', dark: '#CA4A00', name: 'O'},
        'P': {light: '#FFE1E1', dark: '#F33C3C', name: 'P'},
        'Q': {light: '#FED3FF', dark: '#B007B1', name: 'Q'},
        'R': {light: '#8ED2B9', dark: '#005536', name: 'R'},
        'S': {light: '#E4FF9A', dark: '#789D12', name: 'S'},
        'T': {light: '#DAF4EB', dark: '#34C38F', name: 'T'},
        'U': {light: '#FCF1DF', dark: '#F1B44C', name: 'U'},
        'V': {light: '#D4DBF9', dark: '#556EE6', name: 'V'},
        'W': {light: '#FDE4E4', dark: '#F46A6A', name: 'W'},
        'X': {light: '#FFE7CC', dark: '#F4B46A', name: 'X'},
        'Y': {light: '#CBEFFF', dark: '#42C2F9', name: 'Y'},
        'Z': {light: '#EEEEEE', dark: '#444444', name: 'Z'},
        '0': {light: '#FFD0D0', dark: '#FF4646', name: '0'},
        '1': {light: '#FFF7D0', dark: '#F5B156', name: '1'},
        '2': {light: '#E4E4E4', dark: '#343A40', name: '2'},
        '3': {light: '#8ED2B9', dark: '#005536', name: '3'},
        '4': {light: '#D4DBF9', dark: '#556EE6', name: '4'},
        '5': {light: '#B3CCFF', dark: '#152C5B', name: '5'},
        '6': {light: '#FEC6A6', dark: '#CA4A00', name: '6'},
        '7': {light: '#DAF4EB', dark: '#F4B46A', name: '7'},
        '8': {light: '#CBEFFF', dark: '#34C38F', name: '8'},
        '9': {light: '#B4FFF5', dark: '#009480', name: '9'},
    }
    return color[name.charAt(0).toUpperCase()];
}

export function CountryCode(){
    return [{
            "code": "+7840",
            "name": "Abkhazia"
        },
        {
            "code": "+93",
            "name": "Afghanistan"
        },
        {
            "code": "+355",
            "name": "Albania"
        },
        {
            "code": "+213",
            "name": "Algeria"
        },
        {
            "code": "+1684",
            "name": "American Samoa"
        },
        {
            "code": "+376",
            "name": "Andorra"
        },
        {
            "code": "+244",
            "name": "Angola"
        },
        {
            "code": "+1 264",
            "name": "Anguilla"
        },
        {
            "code": "+1268",
            "name": "Antigua and Barbuda"
        },
        {
            "code": "+54",
            "name": "Argentina"
        },
        {
            "code": "+374",
            "name": "Armenia"
        },
        {
            "code": "+297",
            "name": "Aruba"
        },
        {
            "code": "+247",
            "name": "Ascension"
        },
        {
            "code": "+61",
            "name": "Australia"
        },
        {
            "code": "+672",
            "name": "Australian External Territories"
        },
        {
            "code": "+43",
            "name": "Austria"
        },
        {
            "code": "+994",
            "name": "Azerbaijan"
        },
        {
            "code": "+1242",
            "name": "Bahamas"
        },
        {
            "code": "+973",
            "name": "Bahrain"
        },
        {
            "code": "+880",
            "name": "Bangladesh"
        },
        {
            "code": "+1246",
            "name": "Barbados"
        },
        {
            "code": "+1268",
            "name": "Barbuda"
        },
        {
            "code": "+375",
            "name": "Belarus"
        },
        {
            "code": "+32",
            "name": "Belgium"
        },
        {
            "code": "+501",
            "name": "Belize"
        },
        {
            "code": "+229",
            "name": "Benin"
        },
        {
            "code": "+1441",
            "name": "Bermuda"
        },
        {
            "code": "+975",
            "name": "Bhutan"
        },
        {
            "code": "+591",
            "name": "Bolivia"
        },
        {
            "code": "+387",
            "name": "Bosnia and Herzegovina"
        },
        {
            "code": "+267",
            "name": "Botswana"
        },
        {
            "code": "+55",
            "name": "Brazil"
        },
        {
            "code": "+246",
            "name": "British Indian Ocean Territory"
        },
        {
            "code": "+1284",
            "name": "British Virgin Islands"
        },
        {
            "code": "+673",
            "name": "Brunei"
        },
        {
            "code": "+359",
            "name": "Bulgaria"
        },
        {
            "code": "+226",
            "name": "Burkina Faso"
        },
        {
            "code": "+257",
            "name": "Burundi"
        },
        {
            "code": "+855",
            "name": "Cambodia"
        },
        {
            "code": "+237",
            "name": "Cameroon"
        },
        {
            "code": "+1",
            "name": "Canada"
        },
        {
            "code": "+238",
            "name": "Cape Verde"
        },
        {
            "code": "+345",
            "name": "Cayman Islands"
        },
        {
            "code": "+236",
            "name": "Central African Republic"
        },
        {
            "code": "+235",
            "name": "Chad"
        },
        {
            "code": "+56",
            "name": "Chile"
        },
        {
            "code": "+86",
            "name": "China"
        },
        {
            "code": "+61",
            "name": "Christmas Island"
        },
        {
            "code": "+61",
            "name": "Cocos-Keeling Islands"
        },
        {
            "code": "+57",
            "name": "Colombia"
        },
        {
            "code": "+269",
            "name": "Comoros"
        },
        {
            "code": "+242",
            "name": "Congo"
        },
        {
            "code": "+243",
            "name": "Congo, Dem. Rep. of (Zaire)"
        },
        {
            "code": "+682",
            "name": "Cook Islands"
        },
        {
            "code": "+506",
            "name": "Costa Rica"
        },
        {
            "code": "+385",
            "name": "Croatia"
        },
        {
            "code": "+53",
            "name": "Cuba"
        },
        {
            "code": "+599",
            "name": "Curacao"
        },
        {
            "code": "+537",
            "name": "Cyprus"
        },
        {
            "code": "+420",
            "name": "Czech Republic"
        },
        {
            "code": "+45",
            "name": "Denmark"
        },
        {
            "code": "+246",
            "name": "Diego Garcia"
        },
        {
            "code": "+253",
            "name": "Djibouti"
        },
        {
            "code": "+1767",
            "name": "Dominica"
        },
        {
            "code": "+1809",
            "name": "Dominican Republic"
        },
        {
            "code": "+670",
            "name": "East Timor"
        },
        {
            "code": "+56",
            "name": "Easter Island"
        },
        {
            "code": "+593",
            "name": "Ecuador"
        },
        {
            "code": "+20",
            "name": "Egypt"
        },
        {
            "code": "+503",
            "name": "El Salvador"
        },
        {
            "code": "+240",
            "name": "Equatorial Guinea"
        },
        {
            "code": "+291",
            "name": "Eritrea"
        },
        {
            "code": "+372",
            "name": "Estonia"
        },
        {
            "code": "+251",
            "name": "Ethiopia"
        },
        {
            "code": "+500",
            "name": "Falkland Islands"
        },
        {
            "code": "+298",
            "name": "Faroe Islands"
        },
        {
            "code": "+679",
            "name": "Fiji"
        },
        {
            "code": "+358",
            "name": "Finland"
        },
        {
            "code": "+33",
            "name": "France"
        },
        {
            "code": "+596",
            "name": "French Antilles"
        },
        {
            "code": "+594",
            "name": "French Guiana"
        },
        {
            "code": "+689",
            "name": "French Polynesia"
        },
        {
            "code": "+241",
            "name": "Gabon"
        },
        {
            "code": "+220",
            "name": "Gambia"
        },
        {
            "code": "+995",
            "name": "Georgia"
        },
        {
            "code": "+49",
            "name": "Germany"
        },
        {
            "code": "+233",
            "name": "Ghana"
        },
        {
            "code": "+350",
            "name": "Gibraltar"
        },
        {
            "code": "+30",
            "name": "Greece"
        },
        {
            "code": "+299",
            "name": "Greenland"
        },
        {
            "code": "+1473",
            "name": "Grenada"
        },
        {
            "code": "+590",
            "name": "Guadeloupe"
        },
        {
            "code": "+1671",
            "name": "Guam"
        },
        {
            "code": "+502",
            "name": "Guatemala"
        },
        {
            "code": "+224",
            "name": "Guinea"
        },
        {
            "code": "+245",
            "name": "Guinea-Bissau"
        },
        {
            "code": "+595",
            "name": "Guyana"
        },
        {
            "code": "+509",
            "name": "Haiti"
        },
        {
            "code": "+504",
            "name": "Honduras"
        },
        {
            "code": "+852",
            "name": "Hong Kong SAR China"
        },
        {
            "code": "+36",
            "name": "Hungary"
        },
        {
            "code": "+354",
            "name": "Iceland"
        },
        {
            "code": "+91",
            "name": "India"
        },
        {
            "code": "+62",
            "name": "Indonesia"
        },
        {
            "code": "+98",
            "name": "Iran"
        },
        {
            "code": "+964",
            "name": "Iraq"
        },
        {
            "code": "+353",
            "name": "Ireland"
        },
        {
            "code": "+972",
            "name": "Israel"
        },
        {
            "code": "+39",
            "name": "Italy"
        },
        {
            "code": "+225",
            "name": "Ivory Coast"
        },
        {
            "code": "+1876",
            "name": "Jamaica"
        },
        {
            "code": "+81",
            "name": "Japan"
        },
        {
            "code": "+962",
            "name": "Jordan"
        },
        {
            "code": "+77",
            "name": "Kazakhstan"
        },
        {
            "code": "+254",
            "name": "Kenya"
        },
        {
            "code": "+686",
            "name": "Kiribati"
        },
        {
            "code": "+965",
            "name": "Kuwait"
        },
        {
            "code": "+996",
            "name": "Kyrgyzstan"
        },
        {
            "code": "+856",
            "name": "Laos"
        },
        {
            "code": "+371",
            "name": "Latvia"
        },
        {
            "code": "+961",
            "name": "Lebanon"
        },
        {
            "code": "+266",
            "name": "Lesotho"
        },
        {
            "code": "+231",
            "name": "Liberia"
        },
        {
            "code": "+218",
            "name": "Libya"
        },
        {
            "code": "+423",
            "name": "Liechtenstein"
        },
        {
            "code": "+370",
            "name": "Lithuania"
        },
        {
            "code": "+352",
            "name": "Luxembourg"
        },
        {
            "code": "+853",
            "name": "Macau SAR China"
        },
        {
            "code": "+389",
            "name": "Macedonia"
        },
        {
            "code": "+261",
            "name": "Madagascar"
        },
        {
            "code": "+265",
            "name": "Malawi"
        },
        {
            "code": "+60",
            "name": "Malaysia"
        },
        {
            "code": "+960",
            "name": "Maldives"
        },
        {
            "code": "+223",
            "name": "Mali"
        },
        {
            "code": "+356",
            "name": "Malta"
        },
        {
            "code": "+692",
            "name": "Marshall Islands"
        },
        {
            "code": "+596",
            "name": "Martinique"
        },
        {
            "code": "+222",
            "name": "Mauritania"
        },
        {
            "code": "+230",
            "name": "Mauritius"
        },
        {
            "code": "+262",
            "name": "Mayotte"
        },
        {
            "code": "+52",
            "name": "Mexico"
        },
        {
            "code": "+691",
            "name": "Micronesia"
        },
        {
            "code": "+1 808",
            "name": "Midway Island"
        },
        {
            "code": "+373",
            "name": "Moldova"
        },
        {
            "code": "+377",
            "name": "Monaco"
        },
        {
            "code": "+976",
            "name": "Mongolia"
        },
        {
            "code": "+382",
            "name": "Montenegro"
        },
        {
            "code": "+1664",
            "name": "Montserrat"
        },
        {
            "code": "+212",
            "name": "Morocco"
        },
        {
            "code": "+95",
            "name": "Myanmar"
        },
        {
            "code": "+264",
            "name": "Namibia"
        },
        {
            "code": "+674",
            "name": "Nauru"
        },
        {
            "code": "+977",
            "name": "Nepal"
        },
        {
            "code": "+31",
            "name": "Netherlands"
        },
        {
            "code": "+599",
            "name": "Netherlands Antilles"
        },
        {
            "code": "+1 869",
            "name": "Nevis"
        },
        {
            "code": "+687",
            "name": "New Caledonia"
        },
        {
            "code": "+64",
            "name": "New Zealand"
        },
        {
            "code": "+505",
            "name": "Nicaragua"
        },
        {
            "code": "+227",
            "name": "Niger"
        },
        {
            "code": "+234",
            "name": "Nigeria"
        },
        {
            "code": "+683",
            "name": "Niue"
        },
        {
            "code": "+672",
            "name": "Norfolk Island"
        },
        {
            "code": "+850",
            "name": "North Korea"
        },
        {
            "code": "+1670",
            "name": "Northern Mariana Islands"
        },
        {
            "code": "+47",
            "name": "Norway"
        },
        {
            "code": "+968",
            "name": "Oman"
        },
        {
            "code": "+92",
            "name": "Pakistan"
        },
        {
            "code": "+680",
            "name": "Palau"
        },
        {
            "code": "+970",
            "name": "Palestinian Territory"
        },
        {
            "code": "+507",
            "name": "Panama"
        },
        {
            "code": "+675",
            "name": "Papua New Guinea"
        },
        {
            "code": "+595",
            "name": "Paraguay"
        },
        {
            "code": "+51",
            "name": "Peru"
        },
        {
            "code": "+63",
            "name": "Philippines"
        },
        {
            "code": "+48",
            "name": "Poland"
        },
        {
            "code": "+351",
            "name": "Portugal"
        },
        {
            "code": "+1787",
            "name": "Puerto Rico"
        },
        {
            "code": "+974",
            "name": "Qatar"
        },
        {
            "code": "+262",
            "name": "Reunion"
        },
        {
            "code": "+40",
            "name": "Romania"
        },
        {
            "code": "+7",
            "name": "Russia"
        },
        {
            "code": "+250",
            "name": "Rwanda"
        },
        {
            "code": "+685",
            "name": "Samoa"
        },
        {
            "code": "+378",
            "name": "San Marino"
        },
        {
            "code": "+966",
            "name": "Saudi Arabia"
        },
        {
            "code": "+221",
            "name": "Senegal"
        },
        {
            "code": "+381",
            "name": "Serbia"
        },
        {
            "code": "+248",
            "name": "Seychelles"
        },
        {
            "code": "+232",
            "name": "Sierra Leone"
        },
        {
            "code": "+65",
            "name": "Singapore"
        },
        {
            "code": "+421",
            "name": "Slovakia"
        },
        {
            "code": "+386",
            "name": "Slovenia"
        },
        {
            "code": "+677",
            "name": "Solomon Islands"
        },
        {
            "code": "+27",
            "name": "South Africa"
        },
        {
            "code": "+500",
            "name": "South Georgia and the South Sandwich Islands"
        },
        {
            "code": "+82",
            "name": "South Korea"
        },
        {
            "code": "+34",
            "name": "Spain"
        },
        {
            "code": "+94",
            "name": "Sri Lanka"
        },
        {
            "code": "+249",
            "name": "Sudan"
        },
        {
            "code": "+597",
            "name": "Suriname"
        },
        {
            "code": "+268",
            "name": "Swaziland"
        },
        {
            "code": "+46",
            "name": "Sweden"
        },
        {
            "code": "+41",
            "name": "Switzerland"
        },
        {
            "code": "+963",
            "name": "Syria"
        },
        {
            "code": "+886",
            "name": "Taiwan"
        },
        {
            "code": "+992",
            "name": "Tajikistan"
        },
        {
            "code": "+255",
            "name": "Tanzania"
        },
        {
            "code": "+66",
            "name": "Thailand"
        },
        {
            "code": "+670",
            "name": "Timor Leste"
        },
        {
            "code": "+228",
            "name": "Togo"
        },
        {
            "code": "+690",
            "name": "Tokelau"
        },
        {
            "code": "+676",
            "name": "Tonga"
        },
        {
            "code": "+1 868",
            "name": "Trinidad and Tobago"
        },
        {
            "code": "+216",
            "name": "Tunisia"
        },
        {
            "code": "+90",
            "name": "Turkey"
        },
        {
            "code": "+993",
            "name": "Turkmenistan"
        },
        {
            "code": "+1649",
            "name": "Turks and Caicos Islands"
        },
        {
            "code": "+688",
            "name": "Tuvalu"
        },
        {
            "code": "+1340",
            "name": "U.S. Virgin Islands"
        },
        {
            "code": "+256",
            "name": "Uganda"
        },
        {
            "code": "+380",
            "name": "Ukraine"
        },
        {
            "code": "+971",
            "name": "United Arab Emirates"
        },
        {
            "code": "+44",
            "name": "United Kingdom"
        },
        {
            "code": "+1",
            "name": "United States"
        },
        {
            "code": "+598",
            "name": "Uruguay"
        },
        {
            "code": "+998",
            "name": "Uzbekistan"
        },
        {
            "code": "+678",
            "name": "Vanuatu"
        },
        {
            "code": "+58",
            "name": "Venezuela"
        },
        {
            "code": "+84",
            "name": "Vietnam"
        },
        {
            "code": "+1 808",
            "name": "Wake Island"
        },
        {
            "code": "+681",
            "name": "Wallis and Futuna"
        },
        {
            "code": "+967",
            "name": "Yemen"
        },
        {
            "code": "+260",
            "name": "Zambia"
        },
        {
            "code": "+255",
            "name": "Zanzibar"
        },
        {
            "code": "+263",
            "name": "Zimbabwe"
        }
    ]
}

export function formateToLocalDate(date) {
    try {
    //   console.log({ date });
      const formattedDate = new Date(date);
      let days = "";
      formattedDate
        .toLocaleDateString()
        .split("/")
        .forEach((r, idx) => {
          if (idx === 0) days = r;
          else days = r + "-" + days;
        });
      let time = formattedDate.toLocaleTimeString();
  
      return days + " " + time;
    } catch (err) {
      console.log(err);
      return "";
    }
  }

export function appType(){
    return process.env.REACT_APP_APP_TYPE;
};