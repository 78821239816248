import React, { Component } from "react";
import ApexCharts from 'apexcharts'

export default class TopCategory extends Component {
	constructor(props){
		super(props);
		this.state = {
			chart: null
		}
	}

	componentDidMount = () => {
		this.load();
	}

	load = () => {
		let options = {
			series: [{
				data: this.props.series? this.props.series: [],
			}],
			chart: {
				type: 'bar',
				toolbar: {
					show: false
				}
			},
			plotOptions: {
				bar: {
					vertical: true,
				}
			},
			tooltip: {
                enabled: false,
            },
			dataLabels: {
				enabled: false
			},
			xaxis: {
				labels: {
				  	rotate: 0
				},
				categories: this.props.label? this.props.label: [],
			}
		};
		var chart = new ApexCharts(document.querySelector("#top-category"), options);
		chart.render();
		this.setState({ chart: chart });
	}

	componentDidUpdate = (prevProps) => {
		if(this.props.label !== prevProps.label){
			if(this.state.chart) this.state.chart.destroy();
			this.load();
		}
	}

	render() {
		return (
			<div id="top-category"></div>
		);
	}
}