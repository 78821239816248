import React, { Component } from "react";
import ApexCharts from 'apexcharts';

export default class AverageTimeSpend extends Component {
	
	state = { chart: null };

	componentDidMount = () => {
		this.load();
	}

	load = () => {
		var options = {
			series: [{
				name: "Average Time Spend",
				data: this.props.timeSpend? this.props.timeSpend: []
			}],
			chart: {
				height: 300,
				type: 'line',
				zoom: {
					enabled: false
				},
				toolbar: {
					show: false
				}
			},
			colors:['#5570E6'],
			dataLabels: {
				enabled: false
			},
			stroke: {
				curve: 'straight'
			},
			grid: {
				position: 'front'
			},
			xaxis: {
				categories: [],
			},
			markers: {
				size: 5,
			}
		}
		var chart = new ApexCharts(document.querySelector("#time"), options);
		if (chart) chart.render();
		this.setState({ chart: chart });
	}

	componentDidUpdate = (prevProps) => {
		if (this.props.timeSpend !== prevProps.timeSpend) {
			if (this.state.chart) this.state.chart.destroy();
			this.load();
		}
	}

	render() {
		return (
			<div id="time"></div>
		);
	}
}