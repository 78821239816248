import React, { Component } from "react";

import axios from "../../axios";
import { setCookie } from "../../function";
import Loader from "../../component/loader/loader";

export default class SigninSSO extends Component {
	constructor(props) {
		super(props);
		this.getUserDetails();
	}

	getUserDetails = async () => {
		const token = this.props.match.params.token;
		axios.get("/api/auth/details", {
			headers: {
				Authorization: token
			}
		})
		.then((res) => {
			localStorage["firstname"] = res.data.firstname;
			localStorage["lastname"] = res.data.lastname;
			localStorage["email"] = res.data.email;

			setCookie("token", res.data.token, 180);
			setCookie("logo", res.data.path, 30000);
			setCookie("userType", res.data.type, 30000);
			setCookie("userAgent", res.data.userAgent, 30000);
			setCookie("firstname", res.data.firstname, 30000);
			setCookie("username", res.data.companyName, 30000);
			setCookie("isDashboard", res.data.dashboard, 30000);
			setCookie("accessLevel", res.data.accessLevel, 30000);
			setCookie("billingType", res.data.billingType, 30000);

			window.location = "/";
		})
		.catch((error) => {
			window.location = "/";
		});
	};

	render() {
		return (
			<Loader></Loader>
		);
	}
}
