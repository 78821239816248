import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import CampaignCreate from './campaign-create/campaign-create';
import CampaignLaunch from './campaign-launch/campaign-launch';
// import CampaignPreview from './campaign-preview/campaign-preview';
import CampaignTemplate from './campaign-template/campaign-template';

export default class Campaign extends Component {
	render() {	
        const { match } = this.props;
		return (
            <Switch>
                <Route path={`${match.path}/`} exact component={ CampaignTemplate }/>
                <Route path={`${match.path}/launch/:simulationType/:attackType/:ch`} exact component={ CampaignLaunch }/>
                <Route path={`${match.path}/:id`} exact component={ CampaignCreate }/>
                {/* <Route path={`${match.path}/preview/:id`} exact component={ CampaignPreview }/> */}
                <Route render={() => (<Redirect to="/invalid" />)}/>
            </Switch>
		);
	}
}