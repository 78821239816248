import React, { Component } from 'react';
import EditSharpIcon from '@material-ui/icons/EditSharp';
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchSharpIcon from '@material-ui/icons/SearchSharp';
import { FormControl, Button, InputGroup, Card, Table, Breadcrumb, Form, Dropdown } from 'react-bootstrap';

import './actions.css'

export default class Actions extends Component {
    constructor(props){
        super(props);
        this.state = {
            loading: true
        }
    }
 
    render() {
        return (
            <div className="content-wrap">
                <div className="breadcrumbbar">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-head d-flex align-items-center justify-content-between">
                                <h4 className="page-title">Actions</h4>
                                <Breadcrumb className="mb-0">
                                    <Breadcrumb.Item href="#" active>Actions</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </div>
                    </div>
                </div>
                <Card>
                    <Card.Body>
                        <div className="page-content">
                            <div className="row justify-content-between align-items-center mini-head">
                                <div className="col-md-3">
                                    <InputGroup className="search-wrap"><FormControl type="text" placeholder="Search" value="Search" className="mr-sm-2" /><SearchSharpIcon className="search-icon"></SearchSharpIcon></InputGroup>
                                </div>
                                <div className="col-md-5 text-right d-flex justify-content-end">
                                    <Dropdown className="mr-2">
                                        <Dropdown.Toggle  variant="outline-secondary" id="dropdown-basic">
                                            Status
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#/action-1">All</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Pending</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Approved</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Declined</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Form.Group controlId="exampleForm.ControlSelect1" onChange={(e)=> this.setState({limit: parseInt(e.target.value, 10), activePage: 1}, ()=> this.load())} className="d-inline-block">
                                        <Form.Control as="select" className="mr-2 btn btn-outline-secondary">
                                            <option>10</option>
                                            <option>25</option>
                                            <option>50</option>
                                            <option>100</option>
                                        </Form.Control>
                                    </Form.Group>
                                    <Dropdown className="mr-2">
                                        <Dropdown.Toggle  variant="outline-secondary" id="dropdown-basic">
                                            Requested From
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#/action-1">All</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Sarvesh Patel</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Smite Tiwari</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Anjani Pandey</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Button variant="dark"><FilterListIcon className="btn-icon"></FilterListIcon> Filter</Button>
                                </div>
                            </div>
                            <Table responsive>
                                <thead className="bg-light">
                                    <tr>
                                    <th>Action</th>
                                    <th>Request Form</th>
                                    <th>Date</th>
                                    <th>Status</th>
                                    <th>Approved By</th>
                                    <th>Take Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><b>Create Campaign</b></td>
                                        <td>Sarvesh Patel</td>
                                        <td>31st Dec 2020 09:15 PM</td>
                                        <td>Approved</td>
                                        <td>Archit jain</td>
                                        <td><Button variant="primary" size="sm" className="edit_btn"><EditSharpIcon fontSize="small"></EditSharpIcon></Button></td>
                                    </tr>
                                    <tr>
                                        <td><b>Create Campaign</b></td>
                                        <td>Sarvesh Patel</td>
                                        <td>31st Dec 2020 09:15 PM</td>
                                        <td>Decline</td>
                                        <td>Archit jain</td>
                                        <td><Button variant="primary" size="sm" className="edit_btn"><EditSharpIcon fontSize="small"></EditSharpIcon></Button></td>
                                    </tr>
                                    <tr>
                                        <td><b>Create Campaign</b></td>
                                        <td>Sarvesh Patel</td>
                                        <td>31st Dec 2020 09:15 PM</td>
                                        <td>Pending</td>
                                        <td>Archit jain</td>
                                        <td><Button variant="primary" size="sm" className="edit_btn"><EditSharpIcon fontSize="small"></EditSharpIcon></Button></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        );
    }
}