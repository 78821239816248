import React, { Component } from 'react';
import { Card, Breadcrumb, ProgressBar } from 'react-bootstrap';
import BarChartSharpIcon from '@material-ui/icons/BarChartSharp';
import ExpandLessSharpIcon from '@material-ui/icons/ExpandLessSharp';

// import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
// import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';


import PhishRate from '../../../component/reports/industry/phish-rate-graph';
import AverageClick from '../../../component/reports/industry/average-click';
import AverageBreach from '../../../component/reports/industry/average-breach';
import PhishRateLine from '../../../component/reports/industry/phish-rate-line';
import AverageTimeSpend from '../../../component/reports/industry/average-time-spend';

// import CompanyOffenders from '../../../component/reports/industry/company-offenders';
// import IndustryOffenders from '../../../component/reports/industry/industry-offenders';

import axios from '../../../axios';
import apiError from '../../../error';
import './industry-comparison.css';

export default class IndustryComparison extends Component {
    constructor(props){
        super(props);
        this.state = {
            loading: false,
            attackTypes: [],
            attackTypeId: 1,

            phishRate: 0,
            responce: [],
            timeSpend: [],
            currentRate: 0,
            initialRate: 0,
            industryRate: 0,
            industryRisk: [],

        }
        this.loadAttackType();
    }
    
    loadAttackType = () => {
        axios.get('/api/attacktypes', {})
        .then(res => {
            if(res.data.attackTypes && res.data.attackTypes.length){
                this.setState({ 
                    attackTypes: res.data.attackTypes, 
                    attackTypeId: res.data.attackTypes[0].id,
                }, () => {
                    this.handleRefresh();
                });
            }
        })
        .catch(err => apiError(err))
    }  
    
    handleRefresh = () => {
        this.phishRate();
        this.averageTimeSpend();
        this.industryCamparision();
        this.averageBreachResponce();
        this.averageclickResponce();
    }

    phishRate = () => {
        axios.get('/api/overview/phishRate', {})
        .then(res => {
            this.setState({phishRate: res.data.phishRate? res.data.phishRate: 0})
        })
        .catch(err => apiError(err));
    }

    industryCamparision = () => {
        axios.get('/api/overview/industryCamparision', {})
        .then(res => {
            this.setState({
                industryRate: res.data.industry? res.data.industry: 0,
                initialRate: res.data.initial? res.data.initial: 0,
                currentRate: res.data.current? res.data.current: 0,
            })
        })
        .catch(err => apiError(err))
    }

    averageBreachResponce = () => {
        axios.get('/api/overview/averageBreachTime', {})
        .then(res => {
            let responce = [], results = res.data.results;
            if(res.data.results){
                for(let i=0; i<results.rows.length; i++){
                    responce.push(
                        Math.ceil(parseInt(results.rows[i]['breachTime'], 10)/(parseInt(results.count[i]['count'], 10)*60))
                    )
                }
                this.setState({ responce: responce })
            }
        })
        .catch(err => apiError(err))
    }
    averageTimeSpend = () => {
        axios.get('/api/overview/averageTimeSpend', {})
        .then(res => {
            let timeSpend = [], results = res.data.results;
            if(res.data.results){
                for(let i=0; i<results.rows.length; i++){
                    timeSpend.push(
                        Math.ceil(parseInt(results.rows[i]['timeSpend'], 10)/(parseInt(results.count[i]['count'], 10)*60))
                    )
                }
                this.setState({ timeSpend: timeSpend })
            }
        })
        .catch(err => apiError(err))
    }
    averageclickResponce = () => {
        axios.get('/api/overview/averageclickResponce', {})
        .then(res => {
            // this.setState({  })
        })
        .catch(err => apiError(err))
    }

    render() {
        return (
            <div className="content-wrap">
                <div className="breadcrumbbar">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-head d-flex align-items-center justify-content-between">
                                <h4 className="page-title">Industry Comparison Reports</h4>
                                <Breadcrumb className="mb-0">
                                    <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
                                    <Breadcrumb.Item href="#" active>Industry Comparison Reports</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </div>
                    </div>
                </div>
                <Card>
                    <Card.Body>
                        <div className="page-content" id="graph-wrap">
                            <h6 className="tab-head mb-0 bg-light p-3">Phish Risk</h6>
                            <div className="row graph-row">
                                <div className="col-md-6 right-border">
                                    <div className="card-wrap">
                                        <h6>Phish Risk Rate</h6>
                                        <div className="row m-0">
                                            <div className="col-md-3 p-0 rating-num">
                                                <h3>{this.state.phishRate}%</h3>
                                                <div className="d-flex align-items-center border-bottom">
                                                    <BarChartSharpIcon className="green-icon"></BarChartSharpIcon>
                                                    <p className="rate-text">Your Current <br></br>Phish Rate</p>
                                                </div>
                                                <div className="d-flex align-items-center py-2">
                                                    <div id="line" className="line-graph"></div>
                                                    <PhishRateLine></PhishRateLine>
                                                    <span className="d-flex align-items-center green-text"><ExpandLessSharpIcon className="green-arrow"></ExpandLessSharpIcon> 6%</span>
                                                </div>
                                                <p className="para">Industry Comparison Rating</p>
                                            </div>
                                            <div className="col-md-9 pr-0">
                                                <div id="phish-rate"></div>
                                                <PhishRate></PhishRate>
                                            </div>
                                        </div>
                                        <div className="row align-items-center justify-content-between bottom-wrap m-0">
                                            <div className="col-md-4">
                                                <p><span className="dot"></span> Your Current</p>
                                                <h5>{this.state.currentRate}%</h5>
                                                <ProgressBar className="bg-voilet" now={Math.trunc(this.state.currentRate)} />
                                            </div>
                                            <div className="col-md-4 border-l-r">
                                                <p><span className="dot greend"></span> Your Initial</p>
                                                <h5>{this.state.initialRate}%</h5>
                                                <ProgressBar className="bg-green" now={Math.trunc(this.state.initialRate)} />
                                            </div>
                                            <div className="col-md-4">
                                                <p><span className="dot blue"></span> Your Industry</p>
                                                <h5>{this.state.industryRate}%</h5>
                                                <ProgressBar className="bg-blue" now={Math.trunc(this.state.industryRat)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <h6 className="mb-3">Average Click Response</h6>
                                    <div id="click"></div>
                                    <AverageClick></AverageClick>
                                </div>
                            </div>
                            <div className="row graph-row">
                                <div className="col-md-6 right-border">
                                    <h6 className="mb-3">Average Breach Response</h6>
                                    <AverageBreach
                                        responce={this.state.responce}
                                    ></AverageBreach>
                                </div>
                                <div className="col-md-6">
                                    <h6 className="mb-3">Average Time Spend (Landing Page)</h6>
                                    <AverageTimeSpend
                                        timeSpend={this.state.timeSpend}
                                    ></AverageTimeSpend>
                                </div>
                            </div>
                            {/* <div className="row">
                                <div className="col-md-6 border-right">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="avg-block offenders">
                                                <h6>Company Hack Records</h6>
                                                <div className="d-flex align-items-center">
                                                    <h5>3456</h5>
                                                    <div className="text-success"><ArrowDropDownIcon></ArrowDropDownIcon> 22%</div>
                                                </div>
                                                <p className="m-0">Last Month : 234</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                        <div className="avg-block offenders">
                                                <h6>Overall Hack Records</h6>
                                                <div className="d-flex align-items-center">
                                                    <h5>3456</h5>
                                                    <div className="red"><ArrowDropUpIcon></ArrowDropUpIcon> 92%</div>
                                                </div>
                                                <p className="m-0">Last Month : 234</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="avg-block offenders">
                                                <h6>Company Offenders</h6>
                                                <div className="row">
                                                    <div className="col-md-5">
                                                        <h5>3456</h5>
                                                        <p className="m-0">+2000(-0.9)</p>
                                                    </div>
                                                    <div className="col-md-7">
                                                        <div id="company"></div>
                                                        <CompanyOffenders></CompanyOffenders>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="avg-block offenders">
                                                <h6>Industry Offenders</h6>
                                                <div className="row">
                                                    <div className="col-md-5">
                                                        <h5>3456</h5>
                                                        <p className="m-0">+2000(-0.9)</p>
                                                    </div>
                                                    <div className="col-md-7">
                                                        <div id="industry"></div>
                                                        <IndustryOffenders></IndustryOffenders>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </Card.Body>
                </Card>
            </div>
        );
    }
}