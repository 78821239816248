import React, { Component } from "react";

import './language.css';

export default class Launguages extends Component {

    render(){
        return (
            <div className="headerlanguage">
                <div id="google_translate_element2" style={{display: 'block'}}></div>
            </div>
        );
    }
}