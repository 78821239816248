import React, { Component } from "react";

import './loader.css';
export default class Loader extends Component {

    render() {
        return (
            <div className="my-load" id="loading">
                <div className="loader-demo-box">
                    <div className="bar-loader">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </div>
        );
    }
}