import React from 'react';
import { toast } from "react-toastify";
import CKEditor from 'ckeditor4-react';
import { Link } from 'react-router-dom';
import { IoInformation } from "react-icons/io5";
import { BsBoxArrowInRight } from "react-icons/bs";
import AddIcon from '@material-ui/icons/Add';
import Tooltip from 'react-bootstrap/Tooltip';
import { Button, Form } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';

import axios from '../../../axios';
import apiError from '../../../error';
import AddURL from '../import-site/add-url';
import ImportSite from '../import-site/import-site';
import Loader from '../../../component/loader/loader';
import Spinner from '../../../component/spinner/spinner';
import ImportLandingFromStore from './import-landing-from-store'
export default class Pages extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            url: '',
            html: '',
            addURL: '',
            show: false,
            importUrl: '',
            loading: false,
            loadHtml: false,
            landing_urls: [],
            showAddURL: false,
            ransonware_file: '',
            loadingImport: false,
            capture_passwords: 0,
            capture_credentials: 0,
            auto_instant_awarenes: 0,
            landing_url: this.props.landing_url,
            showImportModal: false,
            
            ransomwareFileId: 0,
            ransomwareFiles: [],
        }
    }

    componentWillMount = () => {
        if(this.props.pageId){
            axios.get('/api/pages/'+this.props.pageId, {})
            .then(res => {
                this.setState({
                    loadHtml: true,
                    html: res.data.page.html,
                    url: res.data.page.redirect_url,
                    auto_instant_awarenes: res.data.page.bluePage,
                    ransonware_file: res.data.page.ransonware_file,
                    ransomwareFileId: res.data.page.ransomwareFileId,
                    capture_passwords: res.data.page.capture_passwords,
                    capture_credentials: res.data.page.capture_credentials,
                    landing_url: res.data.page.landing_url? res.data.page.landing_url: '',
                }, () => {
                    this.loadLandingUrls();
                    this.loadRansomwareFile();
                })
            })
            .catch(err => {
                apiError(err)
            })
        } else {
            this.setState({ loadHtml: true })
            this.loadLandingUrls();
            this.loadRansomwareFile();
        }
    }

    loadRansomwareFile = () => {
        axios.get('/api/ransomware/getFile', {})
        .then(res => {
            this.setState({ loading: false, ransomwareFiles: res.data.ransomwareFiles })
        })
        .catch(error => {
            apiError(error);
        })
    }

    loadLandingUrls = () => {
        axios.get(`/api/pages/url?status=${true}`, {})
        .then(res => {
            this.setState({ loading: false, landing_urls: res.data.landing_urls })
        })
        .catch(error => {
            apiError(error);
        })
    }

    handleChange = (e) => {
        this.setState({ html: e.editor.getData() });
    }

    handleCreateTemplate = () => {
        const data = {
            html: this.state.html,
            redirect_url: this.state.url,
            landing_url: this.state.landing_url,
            ransonware_file: this.state.ransonware_file,
            ransomwareFileId: this.state.ransomwareFileId,
            campaignTemplateId: this.props.campaignTemplateId,
            bluePage: this.state.auto_instant_awarenes? true: false,
            capture_passwords: this.state.capture_passwords? true: false,
            capture_credentials: this.state.capture_credentials? true: false,
        }
        if(!this.state.auto_instant_awarenes){
            if(!this.state.url.length || (this.state.url).trim().length === 0 ){
                toast.error("Redirect URL required");
                return ;
            }
        }
        if( !this.state.landing_url || this.state.landing_url.length === 0 || (this.state.landing_url).trim().length === 0 ){
            toast.error("please enter landing url");
            return ;
        }
        if(this.props.attack_type_name === "Ransomware"){
            if( !this.state.ransonware_file || this.state.ransonware_file.length === 0 || (this.state.ransonware_file).trim().length === 0 ){
                toast.error("please enter ransonware file name");
                return ;
            }
            if( !this.state.ransomwareFileId || parseInt(this.state.ransomwareFileId, 10) === 0 ){
                toast.error("please select ransomware file");
                return ;
            }
        }
        this.setState({ loading: true })
        if(this.props.pageId){
            axios.patch('/api/pages/'+this.props.pageId, data )
            .then(res => {
                this.setState({ loading: false })
                this.props.handleTabChange('next');
            })
            .catch(error => {
                apiError(error);
                this.setState({ loading: false })
            })
        }else {
            axios.post('/api/pages/', data )
            .then(res => {
                this.setState({ loading: false })
                this.props.handleTabChange('next');
                this.props.handlePageId(res.data.page.id);
                toast.success('Landing page created successfully') 
            })
            .catch(error => {
                apiError(error)
                this.setState({ loading: false })
            })
        }
    }

    handleImportSite = async() => {
        if(this.state.importUrl.length === 0){
            toast.error("please enter url");
            return ;
        }
        await this.setState({ loadingImport: true });
        axios.get('/api/import/site', { params:{ url: this.state.importUrl }})
        .then(res => {
            this.setState({ html: res.data.html, loadingImport: false });
            this.handleImportSiteModal();
        })
        .catch(err => {
            apiError(err)
            this.setState({ loadingImport: false });
        })
    }

    handleImportSiteModal = () => {
        this.setState({show: !this.state.show});
    }

    handleContent = (e) => {
        this.setState({ importUrl: e.target.value })
    }

    validURL = (str) => {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
    }

    handleAddURL = () => {
        if(this.state.addURL.length === 0){
            toast.error("Please enter landing url");
            return ;
        } else if(!this.validURL(this.state.addURL)){
            toast.error("please enter valid landing url");
            return ;
        } else {
            axios.post('/api/pages/url', { url: this.state.addURL })
            .then(res => {
                this.loadLandingUrls();
                this.setState({ showAddURL: false })
                toast.success('Landing URL added successfully') 
            })
            .catch(err => {
                apiError(err)
            })
        }
    }

    //i write this function so that this can update the state for the data that is taking in
    handleLoadSelectedStoreTemplateHtml = (html, name, capture_credentials, capture_passwords) => {
        console.log("These aRE THE THINFS PASSED",{ name, capture_credentials, capture_passwords})
        this.setState({
            html,
            capture_credentials,
            capture_passwords
        }, () => {
            toast.success(`Template "${name}" loaded successfully`);
        });
    }

    render(){
        return (
            <>
            <div className="email-template-wrap">

                    <div className='rightSectionHeader'>
                        <div className="row justify-content-between align-items-center">
                            <div className="col-md-6 tab-right-header">
                                <h6>Create Landing Page</h6>
                                <p>Create email template</p>
                            </div>
                                <div className="col-md-6 text-right">
                                    <OverlayTrigger
                                            placement='top'
                                            overlay={<Tooltip id='top'>This mode help you to Create a landing page template via different method </Tooltip>}>
                                            <Button variant="secondary" type="button" onClick={() => this.setState({ showImportModal: true })} className="mr-2">Import From Store</Button>
                                    </OverlayTrigger>
                                    <Link to="/landing-url" className="landing-red">
                                        <Button variant="primary" className="mr-2"><AddIcon className="btn-icon"></AddIcon>Add Landing URL</Button>
                                    </Link>
                                    <Button variant="primary" type="button" onClick={this.handleImportSiteModal} className="mr-2"><BsBoxArrowInRight className='btn-icon'/>Import Site</Button>
                                </div>
                            </div>
                        </div>



                        <div className='setMarginCss'>
                            <Form>
                                <Form.Group className='checkBoxWrap'>
                                    <Form.Label>Landing Page Url
                                    <OverlayTrigger
                                        placement='top'
                                        overlay={<Tooltip id='top'>Add URL to redirect the users at your preferred landing page</Tooltip>}>
                                        <IoInformation className="tooltip-icon"></IoInformation>
                                    </OverlayTrigger>
                                    </Form.Label>

                                    <Form.Control as="select" value={this.state.landing_url} onChange={e => this.setState({landing_url: e.target.value })}>
                                            {this.state.landing_urls.map((url, index) => {
                                                return <option key={index} disabled={!url.isValid} value={url.name}>{url.name}{url.name === this.props.landing_url? "  (Default)": ""}</option>
                                            })}
                                    </Form.Control>

                                </Form.Group>
                            </Form>
                        </div>
                {this.props.attack_type_name === "Ransomware"?
                    <div className='setMarginCss'>
                        <Form.Group>
                            <Form.Label>Ransomware File Name
                            <OverlayTrigger
                                placement='top'
                                overlay={<Tooltip id='top'>This would be the name of the executable ransomware file</Tooltip>}>
                                <HelpOutlineIcon className="tooltip-icon"></HelpOutlineIcon>
                            </OverlayTrigger>
                            </Form.Label>
                            <Form.Control type="text" value={this.state.ransonware_file} onChange={e => this.setState({ransonware_file: e.target.value})} placeholder="Please enter ransomware file name" />
                        </Form.Group>
                    </div>: null
                }

                {this.props.attack_type_name === "Ransomware"?
                    <div className='setMarginCss'>
                    <Form.Group>
                        <Form.Label>Select Ransomware File
                        <OverlayTrigger
                            placement='top'
                            overlay={<Tooltip id='top'>Choose the ransomware file you will be using for the campaign</Tooltip>}>
                            <HelpOutlineIcon className="tooltip-icon"></HelpOutlineIcon>
                        </OverlayTrigger>
                        </Form.Label>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Control as="select" value={this.state.ransomwareFileId} onChange={(e)=> this.setState({ransomwareFileId: parseInt(e.target.value, 10)} )}>
                                <option value={0}>Select Ransomware File</option>
                                {this.state.ransomwareFiles.map((file, index) => {
                                    return <option key={`ransomware-${index}`} value={file.id}>{file.name} ({file.description})</option>
                                })}
                            </Form.Control>
                        </Form.Group>
                    </Form.Group>
                    </div>: null
                }

                {this.state.loadHtml?
                    <div className='setMarginCss'>
                        <CKEditor
                            config={{
                                allowedContent: true,
                                fullPage: true
                            }}
                            data={this.state.html}
                            onChange={this.handleChange}
                        ></CKEditor>
                    </div>: ''
                }




                        <div className='checkBoxWrap setMarginCss'>
                             <Form.Group controlId="formBasicCheckbox" className='mb-0'>
                             <Form.Check type="checkbox" 
                                label="Capture Username/Email" 
                                checked={this.state.capture_credentials} 
                                onChange={e => this.setState({capture_credentials: !this.state.capture_credentials})} />
                             </Form.Group>
                        </div>



                    <div className='checkBoxWrap setMarginCss'>
                        <Form.Group controlId="formBasicCheckbox2" className="mb-0 d-flex">
                            <Form.Check type="checkbox" checked={this.state.capture_passwords} onChange={e => this.setState({capture_passwords: !this.state.capture_passwords})} label="Capture Password" />
                            <OverlayTrigger
                                placement='top'
                                overlay={<Tooltip id='top'>Allows you to capture the credentials of the user who has been redirected to the landing page</Tooltip>}>
                                <IoInformation className="tooltip-icon"></IoInformation>
                            </OverlayTrigger>
                        </Form.Group>
                    </div>

                    <div className='checkBoxWrap setMarginCss'>
                        <Form.Group controlId="formBasicCheckbox3" className="mb-0 d-flex">
                            <Form.Check type="checkbox" checked={this.state.auto_instant_awarenes} onChange={e => this.setState({auto_instant_awarenes: !this.state.auto_instant_awarenes})} label="Auto-Instant Awareness" />
                            <OverlayTrigger
                                placement='top'
                                overlay={<Tooltip id='top'>Select this to redirect the user to an awareness page as soon as they submit their credentials and password on the landing page</Tooltip>}>
                                <IoInformation className="tooltip-icon " ></IoInformation>
                            </OverlayTrigger>
                        </Form.Group>
                    </div>

                {!this.state.auto_instant_awarenes?
                    <Form.Group className="col-md-12 pl-0">
                        <Form.Label>Redirect URL/Link to Instant Awareness Page</Form.Label>
                        <Form.Control type="text" value={this.state.url} onChange={e => this.setState({url: e.target.value})} placeholder="https://awareness.threatcop.ai/abc.html" />
                    </Form.Group>:''
                }
        </div>

                <div className="row mt-2">
                        <div className="footer-wrapper justify-content-end d-flex">
                            <Button className="btn closeBtn mr-2" onClick={e => this.props.handleTabChange('prev')}><ArrowBackOutlinedIcon className="btn-icon"></ArrowBackOutlinedIcon> Previous</Button>
                            <Button variant="success" disabled={this.state.loading} type="button" onClick={this.handleCreateTemplate}><Spinner loading={this.state.loading}></Spinner><ArrowForwardOutlinedIcon className={this.state.loading?"btn-icon d-none": "btn-icon"}></ArrowForwardOutlinedIcon> Go To Next</Button>
                        </div>
                </div>

                <ImportSite
                    show={this.state.show}
                    importUrl={this.state.importUrl}
                    handleContent={this.handleContent}
                    handleImportSite={this.handleImportSite}
                    handleImportSiteModal={this.handleImportSiteModal}
                ></ImportSite>
                <ImportLandingFromStore
                    showImportModal={this.state.showImportModal}
                    handleClose={e => this.setState({showImportModal: false })}
                    handleLoadSelectedStoreTemplateHtml={this.handleLoadSelectedStoreTemplateHtml}
                ></ImportLandingFromStore>
                <AddURL
                    addURL={this.state.addURL}
                    show={this.state.showAddURL}
                    handleSubmit={this.handleAddURL}
                    handleAddURL={e => this.setState({ showAddURL: false })}
                    handleContent={e => this.setState({ addURL: e.target.value})}
                ></AddURL>
                {this.state.loadingImport? <Loader></Loader>: null}
          </>
        )
    }
}