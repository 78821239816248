import moment from 'moment';
import swal from 'sweetalert';
import { toast } from "react-toastify";
import React, { Component } from "react";
import { Card, Breadcrumb } from 'react-bootstrap';
import { Calendar, momentLocalizer } from 'react-big-calendar';

import axios from '../../axios';
import apiError from '../../error';
import CalenderDetail from '../../component/calendar-detail/calendar.detail';

import './calender.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';

export default class Calendars extends Component {

    constructor(props){
        super(props);
        this.state = {
            events: [],
            show: false,
            campaign: {},
            tempalte: [],
            resultCount: 0,
            simulationId: 0,
            createdAt: new Date(),
            scheduledAt: new Date()
        }
    }

    componentDidMount = () => {
        this.loadEvents();
    }

    loadEvents = () => {
        axios.get('/api/simulation/calenders', {})
        .then((res) => {
            var events = [], campaignId = 0, count = 1;
            for(let i=0; i<res.data.simulations.length; i++){
                let event = res.data.simulations[i];
                if(event.campaignId === campaignId){
                    events.push({
                        simulationId: event.id,
                        end: new Date(event.scheduledAt),
                        start: new Date(event.scheduledAt),
                        title: event.campaign.name+'-'+count,
                    })
                    count = count + 1;
                } else {
                    count = 1;
                    events.push({
                        simulationId: event.id,
                        title: event.campaign.name,
                        end: new Date(event.scheduledAt),
                        start: new Date(event.scheduledAt),
                    })
                }
                campaignId = event.campaignId
            }
            this.setState({ events: events })
        })
        .catch(err => apiError(err))
    }

    loadDetail = (e) => {
        axios.get('/api/simulation/details', {
            params: {
                simulationId: e.simulationId
            }
        })
        .then((res) => {
            this.setState({ 
                show: true, 
                simulationId: e.simulationId,
                resultCount: res.data.resultCount,
                campaign: res.data.details.campaign,
                createdAt: res.data.details.createdAt,
                scheduledAt: res.data.details.scheduledAt,
                tempalte: res.data.details.campaign.assign_templates,
            })
        })
        .catch(err => apiError(err))
    }

    handleModal = () => {
        this.setState({ show: false })
    }

    deleteSimulation = () => {
        swal({
            title: "Do you want to delete this Campaign?",
            icon: "warning",
            buttons: [ 'No', 'Yes' ],
            dangerMode: true,
        })
        .then(willSuccess => {
            if (willSuccess) {
                axios.delete(`/api/simulation/delete/${this.state.simulationId}`, {})
                .then(res => {
                    this.loadEvents();
                    this.setState({ show: false })
                    toast.success("Simulation deleted successfully");
                })
                .catch(err => apiError(err))
            }
        })
    }
    
    render() {
        const localizer = momentLocalizer(moment);
        return (
            <div className="content-wrap">
                <div className="breadcrumbbar">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-head d-flex align-items-center justify-content-between">
                                <h4 className="page-title">Calendar</h4>
                                <Breadcrumb className="mb-0">
                                    <Breadcrumb.Item href="#" active>Calendar Events</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </div>
                    </div>
                </div>
                <Card>
                    <Card.Body>
                    <div className="page-content">
                        <Calendar
                            endAccessor="end"
                            dayMaxEvent={true}
                            startAccessor="start"
                            localizer={localizer}
                            style={{ height: 500 }}
                            events={this.state.events}
                            onSelectEvent={e => this.loadDetail(e)}
                        ></Calendar>
                    </div>
                    </Card.Body>
                </Card> 
                <CalenderDetail
                    show={this.state.show}
                    campaign={this.state.campaign}
                    templates={this.state.tempalte}
                    createdAt={this.state.createdAt}
                    resultCount={this.state.resultCount}
                    scheduledAt={this.state.scheduledAt}
                    simulationId={this.state.simulationId}
                    handleModal={this.handleModal}
                    deleteSimulation={this.deleteSimulation}
                ></CalenderDetail>       
            </div>
        );
    }
}