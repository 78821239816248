import React, {Component} from 'react';
import { toast } from 'react-toastify';
import { Modal, Form, Button, Table } from 'react-bootstrap';

import axios from '../../axios';
import apiError from '../../error';
// import Spinner from '../spinner/spinner';

import './rightside-modal.css'

export default class RightSideModalGroupAD extends Component {
    state = {
        selected: {},
    }

    handleSelect = (name) => {
        var selected = {...this.state.selected};
        if(selected[name]){
            delete selected[name];
        } else {
            selected[name] = true;
        }
        this.setState({ selected: selected });
    }

    handleSubmit = () => {
        axios.post('/api/groups/active-directory/user-groups', {
            groups: Object.keys(this.state.selected)
        })
        .then(res => {
            toast.success("Processing........")
        })
        .catch(err => {
            apiError();
        })
    }

    render() {
        const groups = this.props.adGroups? this.props.adGroups: [];
        groups.sort(function(a, b) { return b.users.length-a.users.length })
        return (
            <Modal className="info-filter right" show={this.props.show} onHide={this.props.handleAdGroup} autoFocus keyboard>
                <Modal.Header closeButton>
                    <Modal.Title> AD Group List </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Group Name</th>
                                <th style={{textAlign: "center" }}>No of Employees</th>
                            </tr>
                        </thead>
                        <tbody>
                            {groups.map((group, index) => {
                                return <tr key={index}>
                                    <td>
                                        <Form.Check type="checkbox" onClick={e => this.handleSelect(group.cn)} label="" />
                                    </td>
                                    <td>{group.cn}</td>
                                    <td style={{textAlign: "center" }}>{ group.users.length }</td>
                                </tr>
                            })}
                        </tbody>
                    </Table>
                    <div className="d-flex justify-content-end add-user-btn">
                        <div className="mr-2 text-right">
                            <Button variant="primary" onClick={this.props.handleAdGroup}>Close</Button>
                        </div>
                        <div className="text-right">
                            <Button variant="success" onClick={this.handleSubmit}>Save</Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}