import { toast } from "react-toastify";
import React, { Component } from "react";
import CloudSharpIcon from '@material-ui/icons/CloudSharp';
import { Card, Breadcrumb, Button, Form } from 'react-bootstrap';

import './landing-edit.css';
import axios from '../../../axios';
import apiError from '../../../error';
import Loader from '../../../component/loader/loader';

export default class Landing_URL_Edit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: '',
            ns1: '',
            ns2: '',
            txtarea: '',
            originalNS: [],
            domainName: '',
            loading: false,
            urlId: parseInt(this.props.match.params.urlId, 10),
        }
    }
    componentDidMount = () => {
        if (this.state.urlId) {
            this.load();
        }
    }

    load = () => {
        this.setState({ loading: true })
        axios.get(`/api/pages/url/${this.state.urlId}`, {})
            .then(res => {
                var txtarea = '';
                for(let i=0; res.data.originalNS && i<res.data.originalNS.length; i++){
                    txtarea += res.data.originalNS[i]+'\n'
                }
                this.setState({ loading: false, domainName: res.data.domainName, ns1: res.data.ns1, ns2: res.data.ns2, originalNS: res.data.originalNS? res.data.originalNS: '', txtarea: txtarea })
            })
            .catch(err => {
                apiError(err)
                this.setState({ loading: false })
            })
    }

    handleCopy = (id, content) => {
        const el = document.createElement('textarea');
        el.value = content;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        var element = document.getElementById(`copy-${id}`);
        if (element) {
            element.innerHTML = "Copied";
            setTimeout(() => {
                element.innerHTML = "";
            }, 1000);
        }
    }

    verifyDomain = () => {
        if (this.state.urlId) {
            this.setState({ loading: true })
            axios.get(`/api/pages/validate/${this.state.urlId}`, {})
            .then(res => {
                this.setState({ loading: false })
                if (res.data.status) {
                    this.props.history.push(`/landing-url/preview/${this.state.urlId}`);
                } else {
                    this.load();
                    toast.info('Details not verified. Please update NS record and try again.');
                }
            })
            .catch(err => {
                apiError(err)
                this.setState({ loading: false })
            })
        }
    }

    render() {
        return (
            <div className="content-wrap">
                <div className="breadcrumbbar">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-head d-flex align-items-center justify-content-between">
                                <div className="">
                                    <h4 className="page-title">Authenticate Your domain</h4>
                                    <p>To make this easier, please answer the following questions</p>
                                </div>

                                <Breadcrumb className="mb-0">
                                    <Breadcrumb.Item href="#" active>Landing URL</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </div>
                    </div>
                </div>
                <Card>
                    <Card.Body>
                        <div className="nameserver_setup">
                            <h4>Complete your nameserver setup</h4>
                            <p className="yet_active"><span style={{color: 'blue'}}>{this.state.domainName}</span> is not yet active on Cloudfare</p>

                            <div className="network_sloution">
                                <h4> 1. Log into your <span className="netwrk_solu">Network Solutions Account</span></h4>
                                <p>Remove these nameservers.</p>
                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Nameservers</Form.Label>
                                    <Form.Control disabled as="textarea" value={this.state.txtarea} rows={this.state.originalNS.length} />
                                </Form.Group>
                            </div>

                            <div className="cloudflare_name">
                                <h4> 2. Replace the cloudflare's nameservers</h4>
                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                    <Form.Label> <CloudSharpIcon className="icon_server"></CloudSharpIcon> Nameservers 1</Form.Label>
                                    <Form.Control type="text" disabled placeholder="" value={this.state.ns1}/>
                                    <span className="copy_text" onClick={e => this.handleCopy('nameserver1', this.state.ns1)}>Click to copy</span>
                                    <span className="copy_text_copied ml-2" id="copy-nameserver1"></span> 
                                </Form.Group>

                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                    <Form.Label> <CloudSharpIcon className="icon_server"></CloudSharpIcon> Nameservers 2</Form.Label>
                                    <Form.Control type="text" disabled placeholder="" value={this.state.ns2}/>
                                    <span className="copy_text" onClick={e => this.handleCopy('nameserver2', this.state.ns2)}>Click to copy</span>
                                    <span className="copy_text_copied ml-2" id="copy-nameserver2"></span> 
                                </Form.Group>
                            </div>
                            <Button variant="success" onClick={this.verifyDomain}>Verify Your NS Record</Button>
                        </div>
                    </Card.Body>
                </Card>
                {this.state.loading ? <Loader></Loader> : null}
            </div>
        );
    }
}