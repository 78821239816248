import React, { Component } from "react";
import ApexCharts from 'apexcharts'

export default class EVSCampaign extends Component {
    constructor(props) {
        super(props);
        this.state = { chart: null };
    }

    load = () => {
        var options = {
            series: [{
                name: 'EVS Scrore',
                data: this.props.evs_detail.data
            }],
            chart: {
                height: 200,
				width: 560,
                type: 'area',
                toolbar:{
                    show:false
                  },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                type: 'datetime',
                categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z"]
                // categories:this.props.evs_detail.date
            },
            tooltip: {
                x: {
                    format: 'dd/MM/yy HH:mm'
                },
            },
        };
        var chart = new ApexCharts(document.querySelector("#evsCampaign"), options);
        if (chart) {
            chart.render();
            this.setState({ chart: chart })
        }
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.evs_detail.data !== prevProps.evs_detail.data){
        	if(this.state.chart){
        		this.state.chart.destroy();
        	}
        	this.load();
        }
    }

    render() {
        return (
            <div id="evsCampaign"></div>
        );
    }
}