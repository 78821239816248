import React, { Component } from 'react';
import { toast } from "react-toastify";
import { Button, Form } from 'react-bootstrap';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';

import axios from '../../../axios';
import apiError from '../../../error';
import Spinner from '../../../component/spinner/spinner';
export default class AttackProfileCall extends Component{
    constructor(props){
        super(props);
        this.state = {
            apiKey: '',
            apiToken: '',
            senderId: '',
            username: '',
            callerId: '',
            gateways: [],
            callerIds: [],
            gatewayId: '',
            loading: false,
            gatewayNameIds: {},
            disableState: false,
        }
        this.load();
    }

    loadGatway = () => {
        axios.get('/api/voicegateway', {})
        .then(res => {
            this.setState({ gateways: res.data.voice_gateway })
            if(res.data.voice_gateway && res.data.voice_gateway.length){
                let gatewayNameIds = {}
                for(var i=0; i<res.data.voice_gateway.length; i++){
                    gatewayNameIds[res.data.voice_gateway[i].id] = res.data.voice_gateway[i].name;
                }
                this.setState({ gatewayNameIds: gatewayNameIds })
            }
        })
        .catch(error => apiError(error))
    }

    load = () => {
        if(this.props.senderId){
            axios.get('/api/senders/'+this.props.senderId, {})
            .then(res => {
                if(res.data.sender){
                    this.setState({ 
                           disableState: true,
                           apiToken: res.data.sender.host,
                           apiKey: res.data.sender.username,
                           username: res.data.sender.username,
                           callerId: res.data.sender.callerId,
                           senderId: res.data.sender.from_address,
                           gatewayId: res.data.sender.smsGatewayId,
                        }, () => {
                        this.loadGatway();
                        // this.reloadCaller();
                        })
                }
            })
            .catch(error => apiError(error))
        } else {
            this.loadGatway();
        }
    }

    validateSenderExotel = () => {
        if(!this.state.gatewayId || this.state.gatewayId.length === 0 || this.state.gatewayId === '-1'){
            toast.error("Please select voice gatway");
            return false;
        }
        if(!this.state.apiKey || this.state.apiKey.length === 0){
            toast.error("Please enter api key");
            return false;
        }
        if(!this.state.apiToken || this.state.apiToken.length === 0){
            toast.error("Please enter api token");
            return false;
        }
        if(!this.state.senderId || this.state.senderId.length === 0){
            toast.error("Please enter sender Id");
            return false;
        }
        if(!this.state.callerId || this.state.callerId.length === 0){
            toast.error("Please select callerId");
            return false;
        }
        return {
            apiKey: this.state.apiKey,
            callerId: this.state.callerId,
            apiToken: this.state.apiToken,
            senderId: this.state.senderId,
            gatewayId: this.state.gatewayId,
            campaignTemplateId: this.props.campaignTemplateId
        }
    }

    handleCreateSender = () => {
        if(this.state.gatewayNameIds[this.state.gatewayId] === "Exotel"){
            const data = this.validateSenderExotel();
            if(data){
                this.setState({ loading: true })
                if(this.props.senderId){
                    axios.patch('/api/senders/call/'+this.props.senderId, data )
                    .then(res => {
                        this.props.handleTabChange('next');
                        this.setState({ loading: false })
                    })
                    .catch(error => {
                        apiError(error)
                        this.setState({ loading: false })
                    })
                }else {
                    axios.post('/api/senders/call', data )
                    .then(res => {
                        this.setState({ loading: false })
                        this.props.handleTabChange('next');
                        this.props.handleSenderId(res.data.sender.id);
                        toast.success('Attack profile created successfully') 
                    })
                    .catch(error => {
                        apiError(error)
                        this.setState({ loading: false })
                    })
                }
            }
        } else {
            if(!this.state.gatewayId || this.state.gatewayId.length === 0 || this.state.gatewayId === '-1'){
                toast.error("Please select voice gateway");
                return false;
            }
        }
    }

    validateCallerId = () => {
        if(!this.state.apiKey || this.state.apiKey.length === 0){
            toast.error("Please enter api key");
            return false;
        }
        if(!this.state.apiToken || this.state.apiToken.length === 0){
            toast.error("Please enter api token");
            return false;
        }
        if(!this.state.senderId || this.state.senderId.length === 0){
            toast.error("Please enter sender Id");
            return false;
        }
        return {
            apiKey: this.state.apiKey,
            apiToken: this.state.apiToken,
            senderId: this.state.senderId
        }
    }

    reloadCaller = () => {
        const data = this.validateCallerId();
        if(data){
            axios.post('/api/voice', data )
            .then(res => {
                var callerIds = res.data.caller, data = [];
                if(callerIds && callerIds.TwilioResponse && callerIds.TwilioResponse.IncomingPhoneNumber){
                    data.push(callerIds.TwilioResponse.IncomingPhoneNumber);
                }
                if(callerIds && callerIds.TwilioResponse && callerIds.TwilioResponse.IncomingPhoneNumbers && callerIds.TwilioResponse.IncomingPhoneNumbers.IncomingPhoneNumber && callerIds.TwilioResponse.IncomingPhoneNumbers.IncomingPhoneNumber.length){
                    data = callerIds.TwilioResponse.IncomingPhoneNumbers.IncomingPhoneNumber;
                }
                this.setState({ callerIds: data })
                toast.success('Caller Id reloaded successfully') 
            })
            .catch(error => apiError(error))
        }
    }

    render(){
        return (
            <>
            <div className="email-template-wrap">

            <div className='rightSectionHeader'>
                        <div className="row justify-content-between align-items-center">
                            <div className="col-md-6 tab-right-header">
                                <h6>Create An Attack Profile</h6>
                                <p>Create email template</p>
                            </div>
                                
                            </div>
                        </div>



                <div className="row">
                    <div className="col-md-6">
                        <Form.Label>Calling Profile</Form.Label>
                        <Form.Control as="select" disabled={this.state.disableState} value={this.state.gatewayId} onChange={e => this.setState({gatewayId: e.target.value})} className="mt-2 mb-2">
                            <option value='-1'>Select Voice Gateway</option>
                            {this.state.gateways.map((gatway, index) => {
                                return <option key={index} value={gatway.id}>{gatway.name}</option>
                            })}
                        </Form.Control>
                        {this.state.gatewayNameIds[this.state.gatewayId] === "Exotel"?
                            <div>
                                <Form.Group>
                                    <Form.Label>API Key</Form.Label>
                                    <Form.Control type="text" placeholder="please enter api key" value={this.state.apiKey} onChange={e => this.setState({apiKey: e.target.value})} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>API Token</Form.Label>
                                    <Form.Control type="text" placeholder="please enter api token" value={this.state.apiToken} onChange={e => this.setState({apiToken: e.target.value})}/>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Sender ID</Form.Label>
                                    <Form.Control type="text" placeholder="please enter sender id" value={this.state.senderId} onChange={e => this.setState({senderId: e.target.value})}/>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Caller ID</Form.Label>
                                    <Form.Control as="select" value={this.state.callerId} onChange={e => this.setState({callerId: e.target.value})}>
                                        <option value='-1'>Select callerId</option>
                                        {this.state.callerIds.map((caller, index) => {
                                            return <option key={index} value={caller.PhoneNumber._text}>{caller.PhoneNumber._text}</option>
                                        })}
                                    </Form.Control>
                                </Form.Group>
                                <Button variant="success" type="button" onClick={this.reloadCaller}> Reload Caller Id </Button>
                            </div>:''
                        }
                    </div>
                </div>
              </div>


                    <div className="row mt-2">
                     <div className='footer-wrapper justify-content-end d-flex'> 
                    <Button variant="secondary" className="mr-2" onClick={e => this.props.handleTabChange('prev')}><ArrowBackOutlinedIcon className="btn-icon"></ArrowBackOutlinedIcon> Previous</Button>
                    <Button variant="success" disabled={this.state.loading} type="button" onClick={this.handleCreateSender}><Spinner loading={this.state.loading}></Spinner><ArrowForwardOutlinedIcon className={this.state.loading?"btn-icon d-none": "btn-icon"}></ArrowForwardOutlinedIcon> Go To Next</Button>
                    </div>
                </div>
           </>
        )
    }
}