import { toast } from "react-toastify";
import React, { Component } from "react";
import Tooltip from "react-bootstrap/Tooltip";
import { Button, Form } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { IoInformation } from "react-icons/io5";
import axios from "../../../axios";
import apiError from "../../../error";
import Loader from "../../../component/loader/loader";

export default class SSO extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ssologin: false,
            acsUrl: '',
            entityId: '',
            certificate: '',
            fileName: '',
            entryPointUrl: '',
            isEnable: false,
            uploadedFileName: null
        };
        this.inputFile = React.createRef();
        this.loadSSO();
    }

    loadSSO = () => {
        axios
            .get("/api/saml-sso", {})
            .then((res) => {
                let sso = res.data?.samlSSO?? null;
                if(sso){
                    this.setState({
                        ssologin: sso.ssologin,
                        acsUrl: sso.acsUrl,
                        entityId: sso.entityId,
                        fileName: sso.certificate,
                        entryPointUrl: sso.entryPointUrl
                    })
                }
            })
            .catch((err) => {
                apiError(err);
            });
    };

    disableSSO = (ssologin) => {
        axios.patch("/api/saml-sso", {
            ssologin
        })
        .catch((err) => {
            apiError(err);
        });
    };

    handleSSOLogin = () => {
        if (!this.state.entryPointUrl || this.state.entryPointUrl.length === 0) {
            toast.error("please enter entry point url");
            return;
        }
        
        var formData = new FormData();
        formData.append("certificate", this.state.certificate);
        formData.append("entryPointUrl", this.state.entryPointUrl);

        axios.post("/api/saml-sso", formData, {
            headers: {
                "Content-type": "multipart/form-data",
            },
        })
        .then((res) => {
            this.setState({isEnable: false})
            toast.success("sso updated successfully");
        })
        .catch((err) => {
            apiError(err);
        });
    };

    onButtonClick = () => {
        this.setState({ disabled: false });
        this.inputFile.current.click();
    };

    handleFile = (e) => {
        var url = e.target.files[0].name;
        var ext = url.substring(url.lastIndexOf('.') + 1).toLowerCase();
        if (ext === "pem" || ext === "cer"){
            this.setState({ certificate: e.target.files[0], isEnable: true, uploadedFileName: url }, () => {
                toast.success("Certificate File Has Been Uploaded.");
            })
        } else {
            toast.error("Unaccepted file format, try again.");
        }
    }

    render() {
        console.log(this.state);
        return (
            <div className="tab-wrap">
                <div className='tabHeading'>
                    <h6>SSO Login</h6>
                    <p>2FA enhances account security by preventing unauthorised access </p>
                </div> 


           
                  
                    <div className="col-md-4 pl-0">
                        <div className='profile-form'>
                            <Form>
                                
                                    <div>
                                        <Form.Check
                                            onChange={(e) => {
                                                this.setState({ ssologin: !this.state.ssologin }, () => {
                                                    this.disableSSO(this.state.ssologin)
                                                })
                                            }}
                                            checked={this.state.ssologin}
                                            type="switch"
                                            label="Enable SSO Login"
                                            id="custom-switch"
                                            className="mb-2"
                                        />
                                        {this.state.ssologin?
                                            <>
                                                <Form.Group>
                                                    <Form.Label>Audience (EntityID)</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        disabled
                                                        value={this.state.entityId}
                                                        onChange={(e) =>
                                                            this.setState({ entityId: e.target.value })
                                                        }
                                                    />
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label>ACS (Consumer) URL</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        disabled
                                                        value={this.state.acsUrl}
                                                        onChange={(e) =>
                                                            this.setState({ acsUrl: e.target.value })
                                                        }
                                                    />
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label>Entry Point URL</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter Entry Point URL"
                                                        value={this.state.entryPointUrl}
                                                        onChange={(e) =>
                                                            this.setState({ entryPointUrl: e.target.value, isEnable: true })
                                                        }
                                                    />
                                                </Form.Group>
                                              
                                                        <Form.Group>
                                                            <Form.Label>
                                                                Upload File
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip id="top">
                                                                            Upload metadata Certificate
                                                                        </Tooltip>
                                                                    }>
                                                                    <IoInformation className="tooltip-icon"></IoInformation>
                                                                </OverlayTrigger>
                                                            </Form.Label>

                                                            <Form className="formFile">
                                                                <Form.Group>
                                                                    <Form.File className='form-control' id="exampleFormControlFile1"
                                                                        accept="pem/*"
                                                                        ref={this.inputFile}
                                                                        onChange={this.handleFile}
                                                                      />
                                                                    </Form.Group>
                                                              </Form>

                                                            {this.state.uploadedFileName && this.state.uploadedFileName.length? 
                                                                <Form.Label> {this.state.uploadedFileName} </Form.Label>: 
                                                                <Form.Label> {this.state.fileName} </Form.Label>
                                                            }
                                                        </Form.Group>
                                                   
                                                <Button
                                                    disabled={!this.state.isEnable}
                                                    variant="success"
                                                    type="button"
                                                    onClick={this.handleSSOLogin}>
                                                    Save
                                                </Button>
                                            </>: null
                                        }
                                    </div>
                               
                            </Form>
                        </div>
                    </div>
              
                {this.state.loading ? <Loader></Loader> : null}
            </div>
        );
    }
}
