import React, { Component } from "react";
import { toast } from "react-toastify";
import { Card, Breadcrumb, Table, Form,Button } from 'react-bootstrap';
import ArrowBackSharpIcon from "@material-ui/icons/ArrowBackSharp";
import axios from '../../../axios';
import apiError from '../../../error';
import policy from '../../../assets/policy.png'
import './iamroles.css';

export default class IamUserEdit extends Component {
    constructor(props){
        super(props);
        this.state = {
            show: false,
            name: '',
            policies: [],
            description: '',
            policyRoles: [],
            roleId: parseInt(this.props.match.params.id, 10)? parseInt(this.props.match.params.id, 10): 0,
        }
        this.loadRoles();
    }

    loadRoles = () => {
        if(this.state.roleId && this.state.roleId > 0){
            axios.get('/api/roles/'+this.state.roleId, {})
            .then(res => {
                let policyRoles = [];
                if(res.data.role.policyRoles && res.data.role.policyRoles.length){
                    for(let i=0; i<res.data.role.policyRoles.length; i++){
                        policyRoles.push(res.data.role.policyRoles[i].policyId)
                    }
                }
                this.setState({
                    name: res.data.role.name,
                    policyRoles: policyRoles,
                    description: res.data.role.description
                }, () => this.loadPolicy())
            })
            .catch(err => apiError(err));
        }
    }

    handleRoleCreateOrUpdate = () => {
        if(!this.state.name.length){
            toast.error("Please enter Role name");
            return ;
        }
        if(!this.state.description.length){
            toast.error("Please enter Role description");
            return ;
        }
        if(this.state.roleId && this.state.roleId > 0){
            axios.patch('/api/roles/'+this.state.roleId, {
                name: this.state.name,
                description: this.state.description
            })
            .then(res => {
                toast.success("Role updated successfully")
            })
            .catch(err => apiError(err));
        } else {
            axios.post('/api/roles', {
                name: this.state.name,
                description: this.state.description
            })
            .then(res => {
                toast.success("Role created successfully");
                this.props.history.push('/iam/roles/'+res.data.role.id);
                this.setState({roleId: res.data.role.id}, () => {
                    this.loadRoles();
                })
            })
            .catch(err => apiError(err));
        }
    }

    loadPolicy = () => {
        axios.get('/api/policy/get', {})
        .then(res => {
            this.setState({ policies: res.data.policies })
        })
        .catch(err => apiError(err));
    }

    handlePolicyUpdate = ( policyId ) => {
        axios.post('/api/roles/policy', {
            policyId: policyId,
            roleId: this.state.roleId,
        })
        .catch(err => apiError(err));
    }

    render() {
        return (
            <div className="content-wrap">
                <div className="breadcrumbbar">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-head d-flex align-items-center justify-content-between">
                                <h4 className="page-title"><a href="" onClick={(e) => this.props.history.push("/iam/roles")} className="bar_Icons mr-2"><ArrowBackSharpIcon className="btn-icon"/></a>Roles</h4>
                            </div>
                        </div>
                    </div>
                </div>
               <div className="contentbar">
                  <div className="page-content mb-4" id="role-edit">
                            <h6>Create Role</h6>
                            <div className="form-wrap">
                                <Form className="col-md-4 p-0">
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label>Role Name</Form.Label>
                                        <Form.Control type="text" placeholder="Role Name" value={this.state.name} onChange={e => this.setState({name: e.target.value})} />
                                    </Form.Group>
                                    <Form.Group controlId="formBasicPassword">
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control as="textarea" rows={3} value={this.state.description} onChange={e => this.setState({description: e.target.value})}/>
                                    </Form.Group>
                                    <Button variant="primary" onClick={this.handleRoleCreateOrUpdate}> {this.state.roleId > 0? 'Update': 'Create' } </Button>
                                </Form>
                                <img src={policy} alt="email" className="policy-img"></img>
                            </div>
                 </div>
                    
                    {this.state.policies && this.state.policies.length > 0? 
                                <div className="page-content">
                                    <div className="tableContainerWrapInner">
                                    <Table responsive>
                                        <thead className="bg-light">
                                            <tr>
                                            <th>ID</th>
                                            <th>Policy Name</th>
                                            <th>Type</th>
                                            <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.policies.map((policy, index) => {
                                                return <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{policy.name}</td>
                                                    <td>{policy.type}</td>
                                                    {this.state.policyRoles.indexOf(policy.id) !== -1? 
                                                        <td><Form.Check type="switch" defaultChecked={true} onChange={e => this.handlePolicyUpdate(policy.id)} id={ 'custom-switch-' + index } /></td>: 
                                                        <td><Form.Check type="switch" onChange={e => this.handlePolicyUpdate(policy.id)} id={ 'custom-switch-' + index }/></td> 
                                                    } 
                                                </tr>
                                            })}
                                        </tbody>
                                    </Table>
                                    </div>
                                </div>: null
                        }
                </div>
            </div>
        );
    }
}