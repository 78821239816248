import React, { Component } from "react";
import ApexCharts from 'apexcharts'
export default class BreachTime extends Component {
	constructor(props) {
		super(props);
		this.state = {
			chart: null,
		};
	}

	componentDidMount = () => {
		this.load();
	}

	secondsToHms = (d) => {
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);
    
        var hDisplay = h > 0 ? (h < 10 ? "0" : "") + h + ":": "00:";
        var mDisplay = m > 0 ? (m < 10 ? "0" : "") + m + ":": "00:";
        var sDisplay = s > 0 ? (s < 10 ? "0" : "") + s + "": "00";
        return hDisplay + mDisplay + sDisplay; 
    }

	load = () => {
		let options = {
			series: [{
				name: 'Breach Time',
				data: this.props.breachTimeGraph['data']? this.props.breachTimeGraph['data']: []
			}],
			chart: {
				height: 50,
				type: 'line',
				zoom: {
					enabled: false
				},
				sparkline: { enabled: true },
				toolbar: {
					show: false
				}
			},
			dataLabels: {
				enabled: false
			},
			stroke: {
				curve: 'smooth',
				width: 2,
			},
			grid: {
				show: false
			},
			tooltip: {
				enabled: true,
				y: {
                    formatter: (value) => { return this.secondsToHms(value) + " Hrs"},
                }
			},
			xaxis: {
				categories: this.props.breachTimeGraph.date? this.props.breachTimeGraph.date: [],
			},
		}
		var chart = new ApexCharts(document.querySelector("#breach"), options);
		if(chart) chart.render();
		this.setState({chart: chart});
	}

	componentDidUpdate = (prevProps) => {
		if(this.props.breachTimeGraph !== prevProps.breachTimeGraph){
			if(this.state.chart) this.state.chart.destroy();
			this.load();
		}
	}

	render() {
		return (
			<div id="breach"></div>
		);
	}
}