import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import TimeSpend from './time-spend/time-spend'; 
// import HackRecords from './hack-records/hack-records';
import ClickReports from './click-reports/click-reports';
import BreachIncedent from './breach-incedent/breach-incedent';
import RepeatOffender from './repeat-offender/repeat-offender';
import CampaignAnalytics from './campaign-analytics/campaign-analytics';
import IndustryComparison from './industry-comparison/industry-comparison';
export default class Reports extends Component {
	render() {	
        const { match } = this.props;
		return (
            <Switch>
                <Route path={`${match.path}/analytics`} exact component={ CampaignAnalytics }/>
                <Route path={`${match.path}/breachincident`} exact component={ BreachIncedent }/>
                <Route path={`${match.path}/clickreports`} exact component={ ClickReports }/>
                <Route path={`${match.path}/timespend`} exact component={ TimeSpend }/>
                {/* <Route path={`${match.path}/hackrecords`} exact component={ HackRecords }/> */}
                <Route path={`${match.path}/repeatoffender`} exact component={ RepeatOffender }/>
                <Route path={`${match.path}/industrycomparison`} exact component={ IndustryComparison }/>
                <Route render={() => (<Redirect to="/invalid" />)}/>
            </Switch>
		);
	}
}