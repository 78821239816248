import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal, Table } from 'react-bootstrap';

import { getDate } from '../../function';
import './calender.details.css';
import VisibilitySharpIcon from '@material-ui/icons/VisibilitySharp';

export default class CalendarDetail extends Component{
    
    render(){
        const { id, campaign, templates, scheduledAt, createdAt, resultCount } = this.props;
        return (
            <Modal show={this.props.show} onHide={this.props.handleModal} autoFocus keyboard className="calnderModal">
                <Modal.Header closeButton>
                    <Modal.Title> Campaign Details </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table borderless>
                        <tbody>
                            <tr> <td>Campaign Name</td> <td>{campaign.name}</td> </tr>
                            <tr><td>Created Date</td><td>{getDate(createdAt)}</td></tr>
                            <tr><td>Scheduled Date</td><td>{getDate(scheduledAt)}</td></tr>
                            <tr><td>No of Users</td><td>{resultCount}</td></tr>
                            <tr><td>Frequency</td><td>{campaign.frequency}</td></tr>
                            <tr><td>Campaign Template Name</td><td></td></tr>
                        </tbody>
                    </Table>
                    {templates && templates.length?
                    <Table borderless>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Category</th>
                                <th>View</th>
                            </tr>
                        </thead>
                        <tbody>
                            {templates.map((template, index) => {
                                return <tr key={index}><td className="name-ellipsis">{template.campaign_template.name}</td><td>{template.campaign_template.category.name}</td><td><Link to={"/templates/preview/"+template.campaign_template.id} target="_blank"><VisibilitySharpIcon className="list-icon"></VisibilitySharpIcon></Link></td></tr>
                            })}
                        </tbody>
                    </Table>: null
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.handleModal}>Close</Button>
                    <Button variant="primary" onClick={e => this.props.deleteSimulation(id)}>Delete</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}