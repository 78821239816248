import React, { Component } from "react";
import ApexCharts from 'apexcharts'

export default class LinkClicked extends Component {
    
    state = { chart: null }

    componentDidMount = () => {
        this.load();
    }

    load = () => {
        let value = this.props.value? this.props.value: 0;
        let options = {
            series: [this.props.percent? this.props.percent: 0],
            chart: {
                height: 220,
                type: 'radialBar',
                offsetY: -10
            },
            colors: ['#FFDE6A'],
            tooltip: {
                enabled: true,
                style: {
                    backgroud: 'white',
                    fontSize: '12px',
                    fontFamily: undefined
                },
                y: {
                    formatter: (value) => { return value + "%" },
                }
            },
            plotOptions: {
                radialBar: {
                    startAngle: -135,
                    endAngle: 135,
                    dataLabels: {
                        name: {
                            fontSize: '18px',
                            color: undefined,
                            offsetY: 70
                        },
                        value: {
                            offsetY: -10,
                            fontSize: '22px',
                            color: undefined,
                            formatter: function (val) {
                                return value;
                            }
                        }
                    }
                }
            },
            stroke: {
                dashArray: 4
            },
            labels: ['Link Clicked'],
        }
        var chart = new ApexCharts(document.querySelector("#linkclicked_sms"), options);
        chart.render();
        this.setState({ chart: chart })
    }

    componentDidUpdate = (prevProps) => {
		if(this.props.percent !== prevProps.percent || this.props.value !== prevProps.value){
			if(this.state.chart) this.state.chart.destroy();
            this.load();
        }
	}

    render() {
        return (
            <div id="linkclicked_sms"></div>
        );
    }
}