import React, { Component } from 'react';
import { Dropdown, Badge } from 'react-bootstrap';
import MoreVertSharpIcon from "@material-ui/icons/MoreVertSharp";
import MoreHorizSharpIcon from '@material-ui/icons/MoreHorizSharp';

import { getDate } from '../../function';

export default class Department extends Component {
    render(){
        const group = this.props.group;
        return (

                     <tbody>
                         <tr>
                             <td>
                                 {group.name}
                             </td>
                             <td>
                                {getDate(group.createdAt)}
                             </td>
                             <td>
                                    {group.targets} {group.targets > 1? 'Members': 'Member'}
                             </td>
                             <td>
                                  {group.status === "completed"? <span className='badge alert-success'>{group.status}</span>:''}
                                   {group.status === "failed"?<span className='badge alert-danger '>{group.status}</span>:''}
                                   {group.status === "pending"? <span className='badge alert-warning'>{group.status}</span>:''}
                             </td>

                            <td>
                         
                                <Dropdown
                                      className="action-dropdown dropdownIcon" ref={this.selectRef}>
                                             <Dropdown.Toggle className="btn-only" id="dropdown-basic">
                                                    <MoreVertSharpIcon className="dot-icon"></MoreVertSharpIcon>
                                                    </Dropdown.Toggle>
                                             <Dropdown.Menu>
                                                  <Dropdown.Item onClick={e => this.props.nextPath('/department/groups/'+this.props.group.id)}>Edit</Dropdown.Item>
                                                    <Dropdown.Item onClick={e => this.props.handleDeleteGroup(this.props.group.id)}>Delete</Dropdown.Item>
                                                </Dropdown.Menu>
                                </Dropdown>

                             </td>

                        </tr>
                    </tbody> 

                            

            // <div className="row mx-0 align-items-center body-row text-left" key={'department'+this.props.index}>
            //     <div className="col-md-11 d-block clickable-row py-3" onClick={e => this.props.nextPath('/department/groups/'+this.props.group.id)}> 
            //         <div className="row align-items-center m-0">
            //             <div className="col-md-5">{group.name}</div>
            //             <div className="col-md-3">{getDate(group.createdAt)}</div>
            //             <div className="col-md-2">{group.targets} {group.targets > 1? 'Members': 'Member'} </div>
            //             <div className="col-md-2">
            //                 {group.status === "completed"?<Badge pill variant="success">{group.status}</Badge>:''}
            //                 {group.status === "failed"?<Badge pill variant="danger">{group.status}</Badge>:''}
            //                 {group.status === "pending"?<Badge pill variant="warning">{group.status}</Badge>:''}
            //             </div>
            //         </div>
            //     </div>
            //     <div className="col-md-1">
            //         <Dropdown className="action-dropdown">
            //             <Dropdown.Toggle className="btn-primary-rgba btn-round" id="dropdown-basic">
            //             <MoreHorizSharpIcon className="table-icon"></MoreHorizSharpIcon>
            //             </Dropdown.Toggle>
            //             <Dropdown.Menu>
            //                 <Dropdown.Item onClick={e => this.props.nextPath('/department/groups/'+this.props.group.id)}>Edit</Dropdown.Item>
            //                 <Dropdown.Item onClick={e => this.props.handleDeleteGroup(this.props.group.id)}>Delete</Dropdown.Item>
            //             </Dropdown.Menu>
            //         </Dropdown>
            //     </div>
            // </div>
        )
    }
}