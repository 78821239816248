import React, { Component } from 'react';
import { Table, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ArrowBackSharpIcon from '@material-ui/icons/ArrowBackSharp';
import './evs-edit.css';
import axios from '../../../axios';
import apiError from '../../../error';
import evs5 from '../../../assets/evs5.png';
import { getDate } from '../../../function';
import emailIcon from "../../../assets/Emailas.png";
import LinkIcon from "../../../assets/Link.png";
import SentIcon from "../../../assets/Sent.png";
import dataSub from "../../../assets/Submit.png";
import EVSChart from '../../../component/evs/chart/evs-chart';
import EVSCampaignChart from '../../../component/evs/chart/evs-campaign-chart';
import HackRecordList from '../../../component/hackrecord/hack-component';
import EVSCampaigns from '../../../component/evs/evs-campaigns/evs-campaigns';

export default class EVSDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            percent: 0,
            groups: [],
            lastName: '',
            firstName: '',
            phone: '',
            location: null,
            department: null,
            position: null,
            campaigns: [],
            hackrecord: [],
            attackTypeId: '',
            attackTypes: [],
            groupError: '',
            campaignError: '',
            evsId: this.props.match.params.id,
            evs_detail: { data: [], date: [] },
        }
    }

    componentDidMount = () => {
        this.loadAttackType();
    }

    loadAttackType = () => {
        axios.get('/api/attacktypes', {})
            .then(res => {
                this.setState({ attackTypes: res.data.attackTypes, attackTypeId: res.data.attackTypes[0].id }, () => {
                    this.load();
                });
            })
            .catch(err => apiError(err))
    }

    nextPath = path => this.props.history.push(path);

    load = () => {
        axios.get('/api/evs/' + this.state.evsId, {})
        .then(res => {
            let evs = res.data.evs;
            this.setState({
                percent: parseInt(evs.percent, 10),
                firstName: evs.target.firstname,
                lastName: evs.target.lastname,
                email: evs.target.email,
                phone: evs.target.phone ?? null,
                position: evs.target.position ?? null,
                location: evs.target?.location?.name ?? null,
                department: evs.target?.department?.name ?? null
            }, () => {
                this.loadGroups(evs.target.id);
                this.loadCampaigns(evs.target.id);
                this.loadHackRecord(evs.target.email);
            })
            var evs_details = res.data.evs.evs_details, evs_detail = { data: [], date: [] };
            for (let i = 0; evs_details && i < evs_details.length; i++) {
                evs_detail.date.push(evs_details[i].createdAt);
                var score = (parseInt(evs_details[i].score, 10) * 100) / parseInt(evs_details[i].maximum, 10)
                evs_detail.data.push(score ? score : 0);
            }
            this.setState({ evs_detail: evs_detail })
        })
        .catch(error => apiError(error))
    }

    loadCampaigns = (targetId) => {
        axios.get('/api/targets/' + targetId + '/campaigns', {
            params: {
                attackTypeId: this.state.attackTypeId
            }
        })
        .then(res => {
            this.setState({ campaigns: res.data.campaigns })
        })
        .catch(error => {
            this.setState({ campaignError: 'Campaign not found' })
        })
    }

    loadCampaignDetail = (targetId) => {
        axios.get('/api/targets/' + targetId + '/campaign-details', {
            params: {
                attackTypeId: this.state.attackTypeId
            }
        })
        .then(res => {
            this.setState({  })
        })
        .catch(error => {
            
        })
    }

    loadGroups = (targetId) => {
        axios.get('/api/targets/' + targetId + '/groups', {})
        .then(res => {
            this.setState({ groups: res.data.groups })
        })
        .catch(error => {
            this.setState({ groupError: 'Group not found' })
        })
    }

    loadHackRecord = (email) => {
        axios.post('/api/report/hackrecords/', {
            email: email
        })
        .then(res => {
            this.setState({ hackrecord: res.data.results })
            setTimeout(() => {
                for (let i = 0; i < this.state.hackrecord.length; i++) {
                    document.getElementById(`record${i}`).insertAdjacentHTML("beforeEnd", this.state.hackrecord[i].Description);
                }
            }, 0)
        })
        .catch(err => { })
    }

    render() {
        return (
            <div className="content-wrap" id="evs-detail">
                <div className="breadcrumbbar">
                    <div className="flexRow row">
                        <div className="col-md-4">
                            <div className="page-head d-flex align-items-center justify-content-between">
                                <h4 className="page-title">
                                    <a onClick={e => this.props.history.goBack()} className="bar_Icons mr-2"><ArrowBackSharpIcon className="btn-icon" /></a>Employee Vulnerability Score</h4>
                            </div>
                        </div>
                        <div className="col-md-8 flexFilterButtons"></div>
                    </div>
                </div>

                <div className='contentbar evsDetails'>
                    <div className='row m-0 gridfirstRow'>
                        <div className='col-md-3 setPadding'>
                            <div className='infoCard userDetails'>
                                <div className='infocardBody'>
                                    <div class="cardHeader">
                                        <h6>User Details</h6>
                                    </div>

                                    <div className='nameinfoBox'>
                                        <div className='circleName'>
                                            <p>Ks</p>
                                        </div>
                                        <h5>{this.state.firstName} {this.state.lastName ? this.state.lastName : ''}</h5>
                                        <p>{this.state.email}</p>
                                    </div>

                                    <div className='contentBox'>
                                        <Table responsive className='userTable'>
                                            <tbody>
                                                <tr>
                                                    <td>Phone Number</td>
                                                    <td>{this.state.phone || '---'}</td>
                                                </tr>
                                                <tr>
                                                    <td>Location</td>
                                                    <td>{this.state.location || '---'}</td>
                                                </tr>

                                                <tr>
                                                    <td>Department</td>
                                                    <td>{this.state.department || '---'}</td>
                                                </tr>

                                                <tr>
                                                    <td>Position</td>
                                                    <td>{this.state.position || '---'}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className='col-md-9 setPadding'>
                            <div className='infoCard'>
                                <div class="cardHeader">
                                    <h6>User Details</h6>

                                    <div className='phishingBox'>
                                        <Form.Group controlId="exampleForm.ControlSelect2">
                                            <Form.Control as="select" value={this.state.attackTypeId} onChange={e => this.setState({ attackTypeId: e.target.value }, () => this.loadCampaigns())}>
                                                <option value=''>Attack Vector</option>
                                                {this.state.attackTypes.map((attack, index) => {
                                                    return <option key={'attack-' + index} value={attack.id}>{attack.name}</option>
                                                })}
                                            </Form.Control>
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className='infocardBody'>
                                    <div className='mainmatrixSection'>
                                        <div className='matrixBox'>
                                            <h6>Email Sent</h6>
                                            <div className='contFlex'>
                                                <div className='circleIcon'>
                                                    <img src={SentIcon} className="img-fluid"></img>
                                                </div>
                                                <p>45</p>
                                            </div>
                                        </div>

                                        <div className='matrixBox'>
                                            <h6>Email Openned</h6>
                                            <div className='contFlex'>
                                                <div className='circleIcon'>
                                                    <img src={emailIcon} className="img-fluid"></img>
                                                </div>
                                                <p>45</p>
                                            </div>
                                        </div>

                                        <div className='matrixBox'>
                                            <h6>Link Clicked</h6>
                                            <div className='contFlex'>
                                                <div className='circleIcon'>
                                                    <img src={LinkIcon} className="img-fluid"></img>
                                                </div>
                                                <p>45</p>
                                            </div>
                                        </div>

                                        <div className='matrixBox'>
                                            <h6>Data Submitted</h6>
                                            <div className='contFlex'>
                                                <div className='circleIcon'>
                                                    <img src={dataSub} className="img-fluid"></img>
                                                </div>
                                                <p>45</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className='row m-0'>
                                <div className='col-md-4 setPadding'>
                                    <div className='infoCard vltsetHeight'>
                                        <div class="cardHeader">
                                            <h6>Vulnerability</h6>
                                        </div>
                                        <div className='infocardBody'>
                                            <EVSChart percent={this.state.percent}></EVSChart>
                                        </div>

                                    </div>
                                </div>
                                <div className='col-md-8 setPadding'>
                                    <div className='infoCard'>
                                        <div class="cardHeader">
                                            <h6>EVS Score</h6>
                                        </div>

                                        <div className='infocardBody'>
                                            <EVSCampaignChart evs_detail={this.state.evs_detail}></EVSCampaignChart>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row m-0 gridsecondRow'>
                        <div className='col-md-8 setPadding'>
                            <div className='infoCard'>
                                <div class="cardHeader">
                                    <h6>Campaigns Status</h6>
                                </div>
                                <div className='infocardBody'>
                                    <div>
                                        <Table responsive className="mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Campaign</th>
                                                    <th>Created At</th>
                                                    <th>Activity</th>
                                                </tr>
                                            </thead>
                                            {this.state.campaignError.length === 0 ?
                                                this.state.campaigns.map((campaign, index) => {
                                                    return <EVSCampaigns
                                                        key={index}
                                                        campaign={campaign}
                                                        nextPath={this.nextPath}
                                                    ></EVSCampaigns>
                                                }) :
                                                <p style={{ color: '#ef9a9a' }}>{this.state.campaignError}</p>
                                            }
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-4 setPadding'>
                            <div className='infoCard'>
                                <div class="cardHeader">
                                    <h6>Group Information</h6>
                                </div>
                                <div className='infocardBody'>
                                    {this.state.groupError.length === 0 ?
                                        this.state.groups.map((group, index) => {
                                            return (
                                                <div key={'groups' + index} className="groupInformation">
                                                    <div className="gpinfoImg">
                                                        <img src={evs5} alt="email" className="img-fluid"></img>
                                                    </div>
                                                    <div>
                                                        <h6>{group.group.name}</h6>
                                                        <p className="mb-0">{getDate(group.group.createdAt)}</p>
                                                    </div>
                                                </div>
                                            )
                                        }) :
                                        <p style={{ color: '#ef9a9a' }}>{this.state.groupError}</p>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row m-0 gridsecondRow'>
                        <div className='infoCard'>
                            <div class="cardHeader">
                                <h6>Hack Records</h6>
                            </div>
                            <div className='infocardBody'>
                                {this.state.hackrecord && this.state.hackrecord.length ?
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                            <th>Domain name
                                            <OverlayTrigger
                                                placement='top'
                                                overlay={<Tooltip id='top'>Domain over which your personal data has been compromised </Tooltip>}>
                                                <HelpOutlineIcon className="tooltip-icon"></HelpOutlineIcon>
                                            </OverlayTrigger>
                                        </th>
                                        <th>Breach Date
                                            <OverlayTrigger
                                                placement='top'
                                                overlay={<Tooltip id='top'>Data on which your personal data has been compromised</Tooltip>}>
                                                <HelpOutlineIcon className="tooltip-icon"></HelpOutlineIcon>
                                            </OverlayTrigger>

                                        </th>
                                        <th>Description
                                            <OverlayTrigger
                                                placement='top'
                                                overlay={<Tooltip id='top'>A detailed explanation of Breach</Tooltip>}>
                                                <HelpOutlineIcon className="tooltip-icon"></HelpOutlineIcon>
                                            </OverlayTrigger>
                                        </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.hackrecord.map((e, index) => {
                                                return <HackRecordList key={index} hackrecord={e} id={`record${index}`}></HackRecordList>
                                            })}
                                        </tbody>
                                    </Table> : <p>no hack records found for this email</p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}