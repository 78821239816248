import React, { Component } from "react";
import jquery from "jquery";
import swal from "sweetalert";
import Tab from "react-bootstrap/Tab";
import { toast } from "react-toastify";
import ArrowBackSharpIcon from "@material-ui/icons/ArrowBackSharp";

import axios from "../../../axios";
import apiError from "../../../error";
import Loader from "../../../component/loader/loader";
import Game from "../../../component/create-template/game/game";
import Pages from "../../../component/create-template/pages/pages";
import Preview from "../../../component/create-template/preview/preview";
import NavTabs from "../../../component/create-template/nav-tabs/nav-tabs";
import Training from "../../../component/create-template/training/training";
import Templates from "../../../component/create-template/templates/templates";
import TemplatesSMS from "../../../component/create-template/templates-sms/templates-sms";
import CampaignName from "../../../component/create-template/campaign-name/campaign-name";
import TemplatesCall from "../../../component/create-template/templates-call/templates-call";
import AttackProfile from "../../../component/create-template/attacker-profile/attacker-profile";
import AttachmentTemplate from "../../../component/create-template/templates/attachment-template";
import GenerateTemplate from "../../../component/create-template/generate-template/generate-template";
import TemplatesWhatsApp from "../../../component/create-template/templates-message/templates-message";
import AttachmentProfile from "../../../component/create-template/attachment-profile/attachment-profile";
import AttackProfileSMS from "../../../component/create-template/attacker-profile-sms/attacker-profile-sms";
import AttackProfileCall from "../../../component/create-template/attacker-profile-call/attacker-profile-call";
import AttackProfileWhatsApp from "../../../component/create-template/attacker-profile-whatsapp/attacker-profile-whatsapp";

import "./campaign.css";
import { SOCKET_EVENTS, socket } from "../../../socket";

export default class CampaignCreate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isSocketConnected: false,
			attack_type: [],
			templateName: "",
			simulation_type: [],
			campaignNameShow: true,
			generateTemplateShow: true,
			attackTypeId: -1,
			simulationTypeId: -1,
			attack_type_name: {},
			simulation_type_name: {},
			pageId: 0,
			gameId: 0,
			senderId: 0,
			templateId: 0,
			trainingId: 0,
			categoryId: 0,
			languageId: 0,
			loading: true,
			redisKey: "",
			landing_url: "",
			showNextTab: false,
			disableState: false,
			senderType: "automatic",
			campaignNameLoading: false,
			credential_harvesting: false,
			campaignTemplateId: parseInt(this.props.match.params.id, 10),
			companyUrl: "",
			context: "",
			companyLogo: "",
			filePath: "",
			message: "",
			show: false,
			templateResponse: [],
			count: 5,
			htmlContent: "",
			subContent: "",
			generationAttemptsCount: "",
			tabBtn: true,
			tabValue: "information",
			generationAttempts: "",
			showColorLogoFeature: false,
			lang: false,
		};
	}

	load = async () => {
		if (this.state.campaignTemplateId) {
			axios
				.get("/api/campaign/template/" + this.state.campaignTemplateId, {})
				.then((res) => {
					this.setState({
						loading: false,
						landing_url: res.data.landing_url,
						pageId: res.data.campaign_template.pageId,
						gameId: res.data.campaign_template.gameId,
						templateName: res.data.campaign_template.name,
						senderId: res.data.campaign_template.senderId,
						trainingId: res.data.campaign_template.courseId,
						templateId: res.data.campaign_template.templateId,
						senderType: res.data.campaign_template.senderType,
						categoryId: res.data.campaign_template.categoryId,
						languageId: res.data.campaign_template.languageId,
						attackTypeId: res.data.campaign_template.attackTypeId,
						simulationTypeId: res.data.campaign_template.simulationTypeId,
						disableState: res.data.campaign_template.simulationTypeId ? true : false,
						credential_harvesting: res.data.campaign_template.credential_harvesting,
					}, () => this.loadSimulaitonType());
				})
				.catch((error) => {
					this.setState({ loading: false });
					apiError(error);
				});
		} else {
			this.loadSimulaitonType();
		}
	};

	loadSimulaitonType = async () => {
		this.setState({ loading: true });
		axios
			.get("/api/simulation/type", {})
			.then((res) => {
				this.setState({ loading: false, simulation_type: res.data.simulation_type });
				for (let i = 0; res.data.simulation_type && i < res.data.simulation_type.length; i++) {
					if (res.data.simulation_type[i].id === this.state.simulationTypeId) {
						this.setState({ simulation_type_name: res.data.simulation_type[i].name });
					}
				}
			})
			.catch((error) => {
				this.setState({ loading: false });
				apiError(error);
			});
		if (this.state.simulationTypeId && this.state.simulationTypeId !== -1) {
			axios
				.get("/api/simulation/type/" + this.state.simulationTypeId, {})
				.then((res) => {
					this.setState({ attack_type: res.data.simulation_attack });
					for (let i = 0; res.data.simulation_attack && i < res.data.simulation_attack.length; i++) {
						if (res.data.simulation_attack[i].attack_type.id === this.state.attackTypeId) {
							this.setState({ attack_type_name: res.data.simulation_attack[i].attack_type.name, showNextTab: true });
						}
					}
				})
				.catch((error) => apiError(error));
		}
	};

	handleSimulationType = async (e) => {
		let simulation_type = JSON.parse(e.target.value);
		this.setState({
			attackTypeId: -1,
			attack_type_name: "",
			simulationTypeId: simulation_type.id,
			simulation_type_name: simulation_type.name,
		});
		if (parseInt(simulation_type.id, 10) !== -1) {
			this.setState({ loading: true });
			axios
				.get("/api/simulation/type/" + simulation_type.id, {})
				.then((res) => this.setState({ attack_type: res.data.simulation_attack, loading: false, showNextTab: true }))
				.catch((error) => {
					apiError(error);
					this.setState({ loading: false });
				});
		} else {
			this.setState({ attack_type: [] });
		}
	};

	handleHarvesting = () => {
		this.setState({ credential_harvesting: !this.state["credential_harvesting"] });
	};

	handleAttackType = async (e) => {
		let attack_type = JSON.parse(e.target.value);
		this.setState({
			attackTypeId: attack_type.id,
			attack_type_name: attack_type.name,
		});
		if (attack_type.name === "Ransomware") {
			this.setState({ credential_harvesting: true });
		}
	};

	handleSenderType = () => {
		axios
			.post("/api/campaign/template/senderType/" + this.state.campaignTemplateId, {})
			.then((res) => this.setState({ senderType: res.data.senderType }))
			.catch((error) => apiError(error));
	};

	handleCampaignName = (e) => {
		this.setState({ templateName: e.target.value });
	};

	handleCampaignNameShow = () => {
		this.setState({ campaignNameShow: !this.state.campaignNameShow });
	};

	handleSenderId = (senderId) => {
		this.setState({ senderId: senderId });
	};

	handleTemplateId = (templateId) => {
		this.setState({ templateId: templateId });
	};

	handlePageId = (pageId) => {
		this.setState({ pageId: pageId });
	};

	handleCategoryId = (e) => {
		this.setState({ categoryId: e.target.value });
	};

	handleLanguageId = (e) => {
		let langValue = e.target[e.target.value].text;
		if (langValue === "Arabic" || langValue === "Urdu" || langValue === "Persian" || langValue === "Turkish") {
			this.setState({ lang: true });
			this.setState({ languageId: e.target.value });
		} else this.setState({ lang: false });
		this.setState({ languageId: e.target.value });
	};

	handleTraining = (trainingId) => {
		this.setState({ trainingId: trainingId });
	};

	handleCompanyUrl = (e) => {
		this.setState({ companyUrl: e.target.value });
	};
	handleContext = (e) => {
		this.setState({ context: e.target.value });
	};
	handleClose = () => {
		this.setState({ show: false });
	};

	handleValidateAI = () => {
		//eslint-disable-next-line
		const urlRegex = /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
		if (this.state.companyUrl.length === 0 || this.state.companyUrl.trim().length === 0 || !urlRegex.test(this.state.companyUrl)) {
			toast.error("Please enter valid company url");
			return false;
		} else if (this.state.context.length === 0 || this.state.context.trim().length === 0) {
			toast.error("Please enter context");
			return false;
		} else if (this.state.filePath.length === 0 || this.state.filePath.trim().length === 0) {
			toast.error("Please select company logo");
			return false;
		} else if (this.state.languageId === 0 || this.state.languageId.trim().length === 0) {
			toast.error("Please select language");
		}
		return {
			companyUrl: this.state.companyUrl,
			context: this.state.context,
			filePath: this.state.filePath,
			languageId: this.state.languageId,
		};
	};

	validateInfoTemplate = (key) => {
		let data = this.handleValidate();
		if (data) {
			this.setState({ tabBtn: false, tabValue: key });
		}
	};

	previousTab = () => {
		this.setState({ tabBtn: false, tabValue: "information" });
	};

	/* This is SSE Implementation for AI template*/


	// async SSEConnection() {
	// 	const port = process.env.BACKEND_PORT;
	// 	const baseUrl = process.env.REACT_APP_API_URL || `http://localhost: + ${port}`;
	// 	const eventSourceUrl = `${baseUrl}/api/campaign/template/generate?key=${this.state.redisKey}`;
	// 	const EventSourcee = new EventSource(eventSourceUrl);
	// 	EventSourcee.onopen = () => {
	// 		console.log("SSE Connection is open!!!");
	// 	};
	// 	EventSourcee.onmessage = (event) => {
	// 		const res = JSON.parse(JSON.parse(JSON.parse(event.data)));
	// 		if (res) {
	// 			this.setState({ loading: false });
	// 			EventSourcee.close();
	// 			console.log("SSE Connection is Closed!!!");
	// 		}
	// 		let regexForSubject = /<h2\s*id=['"]emailSubject['"]>(.*?)<\/h2>/i;
	// 		let aiTemplateSubjectHtml = res.content;
	// 		let match = aiTemplateSubjectHtml.match(regexForSubject);
	// 		let subjectMatched;
	// 		if (match) subjectMatched = match[1];
	// 		else subjectMatched = "";

	// 		let removedContent = aiTemplateSubjectHtml.replace(regexForSubject, "").trim();

	// 		localStorage.setItem("defaultColor", res.content.color);
	// 		this.setState({ showColorLogoFeature: true });
	// 		this.setState({
	// 			htmlContent: removedContent,
	// 			count: this.state.count - 1,
	// 			generationAttempts: res.generationAttempts,
	// 			subContent: subjectMatched,
	// 		});
	// 		let response = localStorage.getItem("response");
	// 		if (response) {
	// 			response = JSON.parse(response);
	// 			response.push(removedContent);
	// 		} else {
	// 			response = [removedContent];
	// 		}
	// 		localStorage.setItem("response", JSON.stringify(response));
	// 		this.setState({ templateResponse: response });
	// 	};

	// 	EventSourcee.onerror = (error) => {
	// 		setTimeout(() => {
	// 			this.SSEConnection();
	// 		}, 5000);
	// 		console.timeEnd("SSE Timer");
	// 		console.error("EventSource failed:", error);
	// 		EventSourcee.close();
	// 	};
	// 	EventSourcee.onclose = () => {
	// 		console.log("SSE Connection Is Closed!!!");
	// 	};
	// }

	initializeSocket(){
		console.log("Initialising socket", socket);
		if(!socket.connected) socket.connect();
		else this.setState({ isSocketConnected: true })

		socket.on('connect', () => {
				console.log('Server connected !!');
				this.setState({ isSocketConnected: true })
		}, function (error) {
			console.log(error);
		});
		socket.on('disconnect', () => {
			this.setState({ isSocketConnected: false });
			console.log("Socket connection disconnected...")
		})

		socket.on(SOCKET_EVENTS.AI_TEMPLATE_DATA, this.saveAiTemplate.bind(this))
	}

	saveAiTemplate = (response)=> {
		this.setState({ loading: false });
		let regexForSubject = /<h2\s*id=['"]emailSubject['"]>(.*?)<\/h2>/i;
		let aiTemplateSubjectHtml = response.content;
		let match = aiTemplateSubjectHtml.match(regexForSubject);
		let subjectMatched;
		if (match) subjectMatched = match[1];
		else subjectMatched = "";

		let removedContent = aiTemplateSubjectHtml.replace(regexForSubject, "").trim();

		localStorage.setItem("defaultColor", response.content.color);
		this.setState({ showColorLogoFeature: true });
		this.setState({
			htmlContent: removedContent,
			count: this.state.count - 1,
			generationAttempts: response.generationAttempts,
			subContent: subjectMatched,
		});
		
		let responses = localStorage.getItem("response");
		if (responses) {
			response = JSON.parse(responses);
			response.push(removedContent);
		} else {
			response = [removedContent];
		}
		localStorage.setItem("response", JSON.stringify(response));
		this.setState({ templateResponse: response });
	}

  handleGenerateAi = async() => {
		try {
			if (this.state.templateResponse.length >= 5) {
				toast.error('You have reached your maximum limit for template generation. Please select anyone template to proceed.');
				socket.disconnect();
				return;
			}
			if(!this.state.isSocketConnected) return console.log("Socket still not connected")
			let data = this.handleValidateAI();
			console.log({data})
			if (data) {
					this.setState({ loading: true })
			    const res = await axios.post("/api/campaign/template/generate", data)
					if(res){
								this.findAttempts();
					}
		   }
		} catch(err){
			this.setState({ loading: false });
			apiError(err);
	 };
	}

	handleAPiForImage = (formData) => {
		axios
			.post("/api/campaign/template/generate/asset", formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then((res) => {
				this.setState({ filePath: res.data.filePath });
			})
			.catch((error) => { });
	};

	handleCompanyLogo = (e) => {
		if (e.target.files.length > 0) {
			this.setState({ companyLogo: e.target.files[0].name });
			let formData = new FormData();
			formData.append("companyLogo", e.target.files[0]);
			this.handleAPiForImage(formData);
		} else if (e.target.files.length === 0) {
			this.setState({ filePath: "" });
			this.setState({ companyLogo: "" });
		}
	};

	nextPath = (path) => {
		this.props.history.push(path);
	};

	handleTabChange = (type) => {
		try {
			setTimeout(function () {
				if (type === "next") {
					let tabId = jquery(".nav-link.active").parent().next().find("a").attr("id");
					document.getElementById(tabId).click();
				} else {
					let tabId = jquery(".nav-link.active").parent().prev().find("a").attr("id");
					document.getElementById(tabId).click();
				}
			}, 0);
		} catch (err) {
			console.log(err);
		}
	};

	componentDidMount = () => {
		this.initializeSocket();
		this.load();
		const pathname = window.location.pathname;
		if (pathname === "/templates/ai") {
			let templateResponse = localStorage.getItem("response");
			this.setState({ generateTemplateShow: false, templateResponse: templateResponse ? JSON.parse(templateResponse) : [] });
		}
	};

	findAttempts = () => {
		axios
			.get("/api/campaign/template/generate/generationAttempts")
			.then((res) => {
				let attempts = JSON.stringify(res.data.generationAttempts.generationAttempts);
				this.setState({ generationAttemptsCount: attempts });
				if (this.state.generationAttemptsCount === "0") {
					this.setState({ loading: false });
					swal({
						title: "You have reached Maximum Attempt limits",
						icon: "warning",
						confirmButtonText: "Redirect",
					}).then((result) => {
						if (result) {
							window.location.href = "/templates/ai";
						} else {
							swal("Cancelled", "Your action was canceled.", "info");
						}
					});
				}
			})
			.catch((err) => apiError(err));
	};

	handleValidate = () => {
		if (this.state.templateName.length === 0 || this.state.templateName.trim().length === 0) {
			toast.error("Please enter campaign name");
			return false;
		} else if (parseInt(this.state.categoryId, 10) === -1 || parseInt(this.state.categoryId, 10) === 0) {
			toast.error("Please select category");
			return false;
		} else if (parseInt(this.state.languageId, 10) === 0) {
			toast.error("Please select language");
			return false;
		} else if (parseInt(this.state.simulationTypeId, 10) === -1) {
			toast.error("Please select simulation type");
			return false;
		} else if (parseInt(this.state.attackTypeId, 10) === -1) {
			toast.error("Please select attack vector");
			return false;
		}
		return {
			categoryId: this.state.categoryId,
			languageId: this.state.languageId,
			attackTypeId: this.state.attackTypeId,
			name: this.state.templateName.trim(),
			simulationTypeId: this.state.simulationTypeId,
			credential_harvesting: this.state.credential_harvesting,
		};
	};
	handleCreateCampaignTemplate = async (e, index) => {
		if (!this.state.generateTemplateShow) {
			let localData = localStorage.getItem("response");
			let newLocalData = JSON.parse(localData);
			localStorage.setItem("response", JSON.stringify([newLocalData[index]]));
		}

		const data = this.handleValidate();
		if (data) {
			if (this.state.campaignTemplateId > 0) {
				this.setState({ campaignNameLoading: true, loading: true });
				axios
					.patch("/api/campaign/template/" + this.state.campaignTemplateId, data)
					.then((res) => {
						this.setState({ campaignNameShow: false, campaignNameLoading: false , loading: false});
					})
					.catch((error) => {
						this.setState({ campaignNameLoading: false, loading: false });
						apiError(error);
					});
			} else {
				await this.setState({ loading: true });
				axios
					.post("/api/campaign/template", data)
					.then((res) => {
						toast.success("Campaign template created successfully");
						this.setState({
							loading: false,
							disableState: true,
							campaignNameShow: false,
							landing_url: res.data.landing_url,
							senderType: res.data.campaign_template.senderType,
							campaignTemplateId: res.data.campaign_template.id,
						});
						this.props.history.push("/templates/" + res.data.campaign_template.id);
					})
					.catch((error) => {
						this.setState({ loading: false });
						apiError(error);
					});
			}
		}
	};

	render() {
		return (
			<div className="content-wrap">
				<div className="breadcrumbbar">
					<div className="flexRow row">
						<div className="col-md-4">
							<div className="page-head d-flex align-items-center justify-content-between">
								<h4 className="page-title">
									<a href="" onClick={(e) => this.props.history.push("/templates")} className="bar_Icons mr-2"><ArrowBackSharpIcon className="btn-icon" />
									</a>Create Template</h4>
							</div>
						</div>
						<div className="col-md-8 flexFilterButtons">
						</div>
					</div>
				</div>
				<div className="page-content">
					{this.state.campaignNameShow ? (
						this.state.generateTemplateShow ? (
							<CampaignName
								categoryId={this.state.categoryId}
								languageId={this.state.languageId}
								showNextTab={this.state.showNextTab}
								attack_type={this.state.attack_type}
								disableState={this.state.disableState}
								templateName={this.state.templateName}
								attackTypeId={this.state.attackTypeId}
								handleTabChange={this.handleTabChange}
								handleHarvesting={this.handleHarvesting}
								handleAttackType={this.handleAttackType}
								handleCategoryId={this.handleCategoryId}
								handleLanguageId={this.handleLanguageId}
								attackTypeName={this.state.attack_type_name}
								simulation_type={this.state.simulation_type}
								handleCampaignName={this.handleCampaignName}
								simulationTypeId={this.state.simulationTypeId}
								handleSimulationType={this.handleSimulationType}
								campaignTemplateId={this.state.campaignTemplateId}
								simulationTypeName={this.state.simulation_type_name}
								campaignNameLoading={this.state.campaignNameLoading}
								handleCampaignNameShow={this.handleCampaignNameShow}
								credential_harvesting={this.state.credential_harvesting}
								handleCreateCampaignTemplate={this.handleCreateCampaignTemplate}
								lang={this.state.lang}
							></CampaignName>
						) : (
							<GenerateTemplate
								categoryId={this.state.categoryId}
								languageId={this.state.languageId}
								showNextTab={this.state.showNextTab}
								attack_type={this.state.attack_type}
								disableState={this.state.disableState}
								templateName={this.state.templateName}
								attackTypeId={this.state.attackTypeId}
								handleTabChange={this.handleTabChange}
								handleHarvesting={this.handleHarvesting}
								handleAttackType={this.handleAttackType}
								handleCategoryId={this.handleCategoryId}
								handleLanguageId={this.handleLanguageId}
								lang={this.state.lang}
								attackTypeName={this.state.attack_type_name}
								simulation_type={this.state.simulation_type}
								handleCampaignName={this.handleCampaignName}
								simulationTypeId={this.state.simulationTypeId}
								handleSimulationType={this.handleSimulationType}
								campaignTemplateId={this.state.campaignTemplateId}
								simulationTypeName={this.state.simulation_type_name}
								campaignNameLoading={this.state.campaignNameLoading}
								handleCampaignNameShow={this.handleCampaignNameShow}
								credential_harvesting={this.state.credential_harvesting}
								handleCreateCampaignTemplate={this.handleCreateCampaignTemplate}
								companyUrl={this.state.companyUrl}
								context={this.state.context}
								companyLogo={this.state.companyLogo}
								show={this.state.show}
								// companyName={this.state.companyName}
								// handleCompanyName={this.handleCompanyName}
								handleCompanyUrl={this.handleCompanyUrl}
								handleContext={this.handleContext}
								handleCompanyLogo={this.handleCompanyLogo}
								handleGenerateAi={this.handleGenerateAi}
								handleClose={this.handleClose}
								count={this.state.count}
								htmlContent={this.state.htmlContent}
								templateResponse={this.state.templateResponse}
								validateInfoTemplate={this.validateInfoTemplate}
								previousTab={this.previousTab}
								tabBtn={this.state.tabBtn}
								tabValue={this.state.tabValue}
								generationAttempts={this.state.generationAttempts}
								showColorLogoFeature={this.state.showColorLogoFeature}
							></GenerateTemplate>
						)
					) : (
						<>
							<Tab.Container
								id="left-tabs-example"
								defaultActiveKey={
									this.state.attack_type_name === "Phishing" ||
									this.state.attack_type_name === "Ransomware" ||
									this.state.attack_type_name === "Attachment" ||
									this.state.attack_type_name === "QRcode" ? "third" :
									this.state.attack_type_name === "Smishing" ||
									this.state.attack_type_name === "WhatsApp" ? "third-sms" :
									this.state.attack_type_name === "Training + Quiz" ? "second" : "second-call"
								}
							>
								<div className="row create-tab">
									<div className="col-md-3 left tempalte-nav-tab">
										<NavTabs
											pageId={this.state.pageId}
											gameId={this.state.gameId}
											senderId={this.state.senderId}
											templateId={this.state.templateId}
											trainingId={this.state.trainingId}
											awarenessId={this.state.awarenessId}
											attack_type={this.state.attack_type_name}
											simulation_type={this.state.simulation_type_name}
											campaignTemplateId={this.state.campaignTemplateId}
											handleCampaignNameShow={this.handleCampaignNameShow}
											credential_harvesting={this.state.credential_harvesting}
										></NavTabs>
									</div>
									<div className="col-md-9 contentRgt">
										<Tab.Content>
											{this.state.attack_type_name === "Phishing" ||
												this.state.attack_type_name === "QRcode" ||
												this.state.attack_type_name === "Ransomware" ||
												this.state.attack_type_name === "Training + Quiz" ? (
												<Tab.Pane eventKey="second">
													<AttackProfile
														senderId={this.state.senderId}
														senderType={this.state.senderType}
														handleSenderId={this.handleSenderId}
														handleTabChange={this.handleTabChange}
														handleSenderType={this.handleSenderType}
														campaignTemplateId={this.state.campaignTemplateId}
													></AttackProfile>
												</Tab.Pane>
											) : null}
											{this.state.attack_type_name === "Attachment" ? (
												<Tab.Pane eventKey="second">
													<AttachmentProfile
														senderId={this.state.senderId}
														senderType={this.state.senderType}
														handleSenderId={this.handleSenderId}
														handleTabChange={this.handleTabChange}
														handleSenderType={this.handleSenderType}
														campaignTemplateId={this.state.campaignTemplateId}
													></AttachmentProfile>
												</Tab.Pane>
											) : null}
											{this.state.attack_type_name === "Smishing" ? (
												<Tab.Pane eventKey="second-sms">
													<AttackProfileSMS
														senderId={this.state.senderId}
														handleSenderId={this.handleSenderId}
														handleTabChange={this.handleTabChange}
														campaignTemplateId={this.state.campaignTemplateId}
														landing_url={this.state.landing_url}
													></AttackProfileSMS>
												</Tab.Pane>
											) : null}
											{this.state.attack_type_name === "WhatsApp" ? (
												<Tab.Pane eventKey="second-sms">
													<AttackProfileWhatsApp
														senderId={this.state.senderId}
														handleSenderId={this.handleSenderId}
														handleTabChange={this.handleTabChange}
														campaignTemplateId={this.state.campaignTemplateId}
													></AttackProfileWhatsApp>
												</Tab.Pane>
											) : null}
											{this.state.attack_type_name === "Vishing" ? (
												<Tab.Pane eventKey="second-call">
													<AttackProfileCall
														senderId={this.state.senderId}
														handleSenderId={this.handleSenderId}
														handleTabChange={this.handleTabChange}
														campaignTemplateId={this.state.campaignTemplateId}
													></AttackProfileCall>
												</Tab.Pane>
											) : null}
											{this.state.attack_type_name === "Phishing" || this.state.attack_type_name === "Ransomware" || this.state.attack_type_name === "QRcode" ? (
												<Tab.Pane eventKey="third">
													<Templates
														templateId={this.state.templateId}
														subContent={this.state.subContent}
														handleTabChange={this.handleTabChange}
														handleTemplateId={this.handleTemplateId}
														campaignTemplateId={this.state.campaignTemplateId}
													></Templates>
												</Tab.Pane>
											) : null}
											{this.state.attack_type_name === "Attachment" ? (
												<Tab.Pane eventKey="third">
													<AttachmentTemplate
														templateId={this.state.templateId}
														subContent={this.state.subContent}
														handleTabChange={this.handleTabChange}
														handleTemplateId={this.handleTemplateId}
														campaignTemplateId={this.state.campaignTemplateId}
													></AttachmentTemplate>
												</Tab.Pane>
											) : null}
											{this.state.attack_type_name === "Smishing" ? (
												<Tab.Pane eventKey="third-sms">
													<TemplatesSMS
														templateId={this.state.templateId}
														handleTabChange={this.handleTabChange}
														handleTemplateId={this.handleTemplateId}
														campaignTemplateId={this.state.campaignTemplateId}
													></TemplatesSMS>
												</Tab.Pane>
											) : null}
											{this.state.attack_type_name === "WhatsApp" ? (
												<Tab.Pane eventKey="third-sms">
													<TemplatesWhatsApp
														templateId={this.state.templateId}
														handleTabChange={this.handleTabChange}
														handleTemplateId={this.handleTemplateId}
														campaignTemplateId={this.state.campaignTemplateId}
													></TemplatesWhatsApp>
												</Tab.Pane>
											) : null}
											{this.state.attack_type_name === "Vishing" ? (
												<Tab.Pane eventKey="third-call">
													<TemplatesCall
														templateId={this.state.templateId}
														handleTabChange={this.handleTabChange}
														handleTemplateId={this.handleTemplateId}
														campaignTemplateId={this.state.campaignTemplateId}
													></TemplatesCall>
												</Tab.Pane>
											) : null}
											<Tab.Pane eventKey="fourth">
												<Pages
													pageId={this.state.pageId}
													handlePageId={this.handlePageId}
													landing_url={this.state.landing_url}
													handleTabChange={this.handleTabChange}
													attack_type_name={this.state.attack_type_name}
													campaignTemplateId={this.state.campaignTemplateId}
												></Pages>
											</Tab.Pane>
											<Tab.Pane eventKey="six">
												{this.state.simulation_type_name.indexOf("Training") > -1 && (
													<Training
														trainingId={this.state.trainingId}
														handleTraining={this.handleTraining}
														handleTabChange={this.handleTabChange}
														campaignTemplateId={this.state.campaignTemplateId}
													></Training>
												)}
											</Tab.Pane>
											<Tab.Pane eventKey="seven">
												<Game
													gameId={this.state.gameId}
													handleTabChange={this.handleTabChange}
													campaignTemplateId={this.state.campaignTemplateId}
												></Game>
											</Tab.Pane>
											<Tab.Pane eventKey="eight">
												<Preview nextPath={this.nextPath} campaignTemplateId={this.state.campaignTemplateId}></Preview>
											</Tab.Pane>
										</Tab.Content>
									</div>
								</div>
							</Tab.Container>
						</>
					)}
				</div>
				{this.state.loading ? <Loader></Loader> : null}
			</div>
		);
	}
}
