import React from 'react';
import { Button, Table, Form, Row, Col } from 'react-bootstrap';
export default class GroupsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedGroups: this.props.groups.reduce((acc, group) => {
        acc[group.googleGroupId] = { sync: group.sync };
        return acc;
      }, {}),
      scheduled: {
        minutes: '',
        hours: '',
        days: '',
      }
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.groups !== this.props.groups) {
      this.setState({
        checkedGroups: this.props.groups.reduce((acc, group) => {
          acc[group.googleGroupId] = { sync: group.sync || false };
          return acc;
        }, {})
      });
    }
  }

  handleCheckboxChange = (event, googleGroupId) => {
    const isChecked = event.target.checked;
    this.setState(prevState => ({
      checkedGroups: {
        ...prevState.checkedGroups,
        [googleGroupId]: { ...prevState.checkedGroups[googleGroupId], sync: isChecked }
      }
    }));
  };

  // New handlers for minutes, hours, and days
  handleScheduledChange = (type, event) => {
    const value = event.target.value;
    this.setState(prevState => ({
      scheduled: {
        ...prevState.scheduled,
        [type]: value
      }
    }));
  };

  handleSave = () => {
    const { checkedGroups, scheduled } = this.state;
    const { groups, onSave } = this.props;
    // Combine minutes, hours, and days into a cron-like string
    const scheduledString = `${scheduled.minutes} ${scheduled.hours} ${scheduled.days} * *`;

    const selectedGroups = groups.filter(group => 
      checkedGroups[group.googleGroupId] && checkedGroups[group.googleGroupId].sync
    ).map(group => ({
      name: group.name,
      googleGroupId: group.googleGroupId,
      sync: checkedGroups[group.googleGroupId].sync,
    }));

    onSave(selectedGroups, scheduledString);
  };

  render() {
    const { groups } = this.props;
    const { checkedGroups, scheduled } = this.state;
    return (
      <>
        <Table striped bordered hover className='gsuiteTable'>
          <thead>
            <tr>
              <th>Auto-Sync</th>
              <th>Name</th>
              <th>Google Group ID</th>
            </tr>
          </thead>
          <tbody>
            {groups.map((group) => (
              <tr key={group.googleGroupId}>
                <td>
                  <Form.Check 
                    type="checkbox"
                    checked={checkedGroups[group.googleGroupId]?.sync}
                    onChange={(event) => this.handleCheckboxChange(event, group.googleGroupId)}
                  />
                </td>
                <td>{group.name}</td>
                <td>{group.googleGroupId}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Form>
          <Row>
            <Col xs={4}>
              <Form.Group>
                <Form.Label>Minutes</Form.Label>
                <Form.Control
                  type="number"
                  min="0"
                  max="59"
                  step="1"
                  value={scheduled.minutes}
                  onChange={(e) => this.handleScheduledChange('minutes', e)}
                />
              </Form.Group>
            </Col>
            <Col xs={4}>
              <Form.Group>
                <Form.Label>Hours</Form.Label>
                <Form.Control
                  type="number"
                  min="0"
                  max="23"
                  step="1"
                  value={scheduled.hours}
                  onChange={(e) => this.handleScheduledChange('hours', e)}
                />
              </Form.Group>
            </Col>
            <Col xs={4}>
              <Form.Group>
                <Form.Label>Days</Form.Label>
                <Form.Control
                  type="number"
                  min="1"
                  max="31"
                  step="1"
                  value={scheduled.days}
                  onChange={(e) => this.handleScheduledChange('days', e)}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
        <Button variant="success" onClick={this.handleSave}>Save</Button>
      </>
    );
  }
}
