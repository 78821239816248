import React, { Component } from 'react';
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import { Card, Breadcrumb, Form, Button } from 'react-bootstrap';
import FilterListSharpIcon from '@material-ui/icons/FilterListSharp';

import Loader from '../../../component/loader/loader';
import Dept from '../../../component/reports/repeat-offenders/dept';
import Category from '../../../component/reports/repeat-offenders/category';
// import Offenders from '../../../component/reports/repeat-offenders/offenders';
import ReportModal from '../../../component/reports/report-filter/report-filter';
// import TotalUsers from '../../../component/reports/repeat-offenders/total-users';
import TopOffender from '../../../component/reports/repeat-offenders/top-offender';
import ThreatProfile from '../../../component/reports/repeat-offenders/threat-profile';
// import RepeatOffenders from '../../../component/reports/repeat-offenders/repeat-offenders';

import axios from '../../../axios';
import apiError from '../../../error';

import './repeat-offender.css';

export default class RepeatOffender extends Component {
    constructor(props){
        super(props);
        this.printReport = this.printReport.bind(this);
        this.reportRef = React.createRef();
        this.state = {
            offenders: 0,
            totalUser: 0,
            loading: true,
            byCategory: {},
            topOffender: {},
            attackTypes: [],
            attackTypeId: 1,
            userProfile: {},
            byDepartment: {},
            repeatOffenders: 0,

            endDate: new Date(),
            startDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),

            campaigns: [],
            campaignIds: [],
            selectedCampaignIds: [],

            locations: [],
            location: '',

        }
        this.loadAttackType();
    }
    
    loadAttackType = () => {
        axios.get('/api/attacktypes', {})
        .then(res => {
            if(res.data.attackTypes && res.data.attackTypes.length){
                let attacktypeName = {};
                for(let i=0; i<res.data.attackTypes.length; i++){
                    attacktypeName[res.data.attackTypes[i].id] = res.data.attackTypes[i].name
                }   
                this.setState({ 
                    attacktypeName: attacktypeName,
                    attackTypes: res.data.attackTypes, 
                    attackTypeId: res.data.attackTypes[0].id,
                }, () => {
                    this.loadCampaigns();
                });
            }
        })
        .catch(err => apiError(err))
    }  

    handleRefresh = () => {
        this.totalUsers();
        this.offenders();
        this.topOffenders();
        this.repeatOffenders();
        this.userThreatProfile();
        this.repeatOffendersByCategory();
        this.repeatOffendersByDepartment();

        this.loadLocation();
    }

    totalUsers = () => {
        axios.post('/api/pdfreport/totalUsers', {
            location: this.state.location,
            attackTypeId: this.state.attackTypeId, 
            selectedCampaignIds: this.state.selectedCampaignIds,
            credentialHarvesting: this.state.credentialHarvesting 
        })
        .then(res => {
            this.setState({ totalUser: res.data.totalUser });
        })
        .catch(err => apiError(err))
    }

    offenders = () => {
        axios.post('/api/pdfreport/offenders', {
            location: this.state.location,
            attackTypeId: this.state.attackTypeId, 
            selectedCampaignIds: this.state.selectedCampaignIds,
            credentialHarvesting: this.state.credentialHarvesting 
        })
        .then(res => {
            this.setState({ offenders: res.data.offenders });
        })
        .catch(err => apiError(err))
    }

    repeatOffenders = () => {
        axios.post('/api/pdfreport/repeatOffenders', {
            location: this.state.location,
            attackTypeId: this.state.attackTypeId, 
            selectedCampaignIds: this.state.selectedCampaignIds,
            credentialHarvesting: this.state.credentialHarvesting 
        })
        .then(res => {
            this.setState({ repeatOffenders: res.data.repeatOffenders });
        })
        .catch(err => apiError(err))
    }

    repeatOffendersByDepartment = () => {
        axios.post('/api/pdfreport/repeatOffendersByDepartment', {
            location: this.state.location,
            attackTypeId: this.state.attackTypeId, 
            selectedCampaignIds: this.state.selectedCampaignIds,
            credentialHarvesting: this.state.credentialHarvesting 
        })
        .then(res => {
            var name = [], repeatOffenders = [];
            for(let i=0; i< res.data.results.length; i++){
                let result = res.data.results[i];
                name.push(result.group.name);
                repeatOffenders.push(result.count);
            }
            this.setState({ byDepartment: { name: name, repeatOffenders: repeatOffenders }});
        })
        .catch(err => apiError(err))
    }

    repeatOffendersByCategory = () => {
        axios.post('/api/pdfreport/repeatOffendersByCategory', {
            location: this.state.location,
            attackTypeId: this.state.attackTypeId, 
            selectedCampaignIds: this.state.selectedCampaignIds,
            credentialHarvesting: this.state.credentialHarvesting 
        })
        .then(res => {
            var name = [], repeatOffenders = [];
            for(let i=0; i< res.data.results.length; i++){
                let result = res.data.results[i];
                repeatOffenders.push(result.count);
                name.push(result.campaign_template.category.name);
            }
            this.setState({ byCategory: { name: name, repeatOffenders: repeatOffenders }});
        })
        .catch(err => apiError(err))
    }

    topOffenders = () => {
        axios.post('/api/pdfreport/topOffenders', {
            location: this.state.location,
            attackTypeId: this.state.attackTypeId, 
            selectedCampaignIds: this.state.selectedCampaignIds,
            credentialHarvesting: this.state.credentialHarvesting 
        })
        .then(res => {
            let topOffender = {}, name = [], count = [];
            for(let i=0; i<res.data.topOffenders.length; i++){
                if(parseInt(res.data.topOffenders[i].count, 10) > 1){
                    count.push(parseInt(res.data.topOffenders[i].count, 10));
                    name.push(res.data.topOffenders[i].target.firstname)
                }
            }
            topOffender['name'] = name
            topOffender['count'] = count;
            this.setState({ topOffender: topOffender });
        })
        .catch(err => apiError(err))
    }

    topOffendersCSV = () => {
        axios.post('/api/pdfreport/topOffendersCSV', {
            location: this.state.location,
            attackTypeId: this.state.attackTypeId, 
            selectedCampaignIds: this.state.selectedCampaignIds,
            credentialHarvesting: this.state.credentialHarvesting 
        })
        .then(res => {
            var link = document.createElement("a");
            link.download = 'TopOffenders';
            link.href = res.data.url;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
        .catch(err => apiError(err))
    }

    userThreatProfile = () => {
        axios.get('/api/evs/most/', {})
        .then(res => {
            let userProfile = {}, name = [], score = [];
            for(let i=0; i<res.data.evs.length; i++){
                let s = parseInt(res.data.evs[i].percent, 10);
                score.push(s? s: 0);
                name.push(res.data.evs[i].target.firstname)
            }
            userProfile['name'] = name
            userProfile['score'] = score;

            this.setState({userProfile: userProfile})
        })
        .catch(err => apiError(err));
    }

    loadCampaigns = async() => {
        await this.setState({ loading: true })
        axios.get('/api/campaigns/list', {
            params: { 
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                attackTypeId: this.state.attackTypeId 
            }
        })
        .then(res => {
            var campaigns = [], selectedCampaignIds = [];
            for(var i=0; i<res.data.campaigns.length; i++){
                campaigns.push({
                    value: res.data.campaigns[i].id,
                    label: res.data.campaigns[i].name
                })
                selectedCampaignIds.push(res.data.campaigns[i].id)
            }
            this.setState({ 
                    loading: false, 
                    campaigns: campaigns, 
                    campaignIds: campaigns,
                    selectedCampaignIds: selectedCampaignIds, 
                }, () => {
                    this.handleRefresh();
            });
        })
        .catch(err => {
            apiError(err);
            this.setState({ campaigns: [], loading: false, selectedCampaignIds: [] })
        })
    }

    loadLocation = async() => {
        axios.post('/api/pdfreport/location', {
            endDate: this.state.endDate,
            startDate: this.state.startDate,
            attackTypeId: this.state.attackTypeId,
            selectedCampaignIds: this.state.selectedCampaignIds,
            credentialHarvesting: this.state.credentialHarvesting 
        })
        .then(res => {
            this.setState({ locations: res.data.results });
        })
        .catch(err => {
            apiError(err);
        })
    }
    
    handleSelect = (startDate, endDate) => {
        this.setState({ 
            campaignIds: [],
            endDate: endDate,
            startDate: startDate, 
            selectedCampaignIds: [], 
        }, () => this.loadCampaigns())
    }

    handleCampaigns = (e) => {
        let selectedCampaignIds = [];
        for(let i=0; i<e.length; i++){
            selectedCampaignIds.push(e[i].value)
        }
        this.setState({ selectedCampaignIds: selectedCampaignIds, campaignIds: e })
    }

    downloadReport = async() => {
        const input = document.getElementById("report");
        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            this.downloadBase64File(imgData, 'report.png');
        })
    }

    downloadBase64File = (base64Data, fileName) => {
        const downloadLink = document.createElement("a");
        const linkSource = base64Data;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    printReport = () => {
        html2canvas(this.reportRef.current).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            let imageHeight = this.reportRef.current?.clientHeight;
            let imageWidth = this.reportRef.current?.clientWidth;
            let ratio = imageHeight / imageWidth;

            let orientation = 'portrait';

            if(ratio < 1){
                orientation = 'landscape';
            }

            const pdf = new jsPDF({
                orientation: orientation,
                unit: 'pt',
                format: [canvas.width + 40, canvas.height + 40 ],
            });
            
            pdf.addImage(imgData, "JPEG", 20, 20, canvas.width, canvas.height);
            pdf.save(`Repeat-Offenders.pdf`);
        }).catch(err =>{
            apiError(err);
        });
    }

    render() {
        // const ref = React.createRef();
        return (
            <div className="content-wrap">
                <div className="breadcrumbbar">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-head d-flex align-items-center justify-content-between">
                                <h4 className="page-title">Repeat Offender</h4>
                                <Breadcrumb className="mb-0">
                                    <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
                                    <Breadcrumb.Item href="#" active>Repeat Offender</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </div>
                    </div>
                </div>
                <Card>
                    <Card.Body>
                        <div className="page-content" id="graph-wrap">
                            <div className="row bg-light py-2 align-items-center">
                                <div className="col-md-3">
                                    <h6 className="tab-head mb-0">Repeat Offender Reports</h6>
                                </div>
                                <div className="col-md-9 text-left">
                                    <div className="d-flex justify-content-end">
                                        <Form.Group controlId="exampleForm.ControlSelect1" className="mr-2 mb-0">
                                            <Form.Control as="select" value={this.state.attackTypeId} onChange={e => this.setState({attackTypeId: parseInt(e.target.value, 10)}, () => this.loadCampaigns())}>
                                                {this.state.attackTypes.map((attacktype, index) => {
                                                    return <option key={index} value={attacktype.id}>{attacktype.name}</option>
                                                })}
                                            </Form.Control>
                                        </Form.Group>
                                        <Button variant="primary" className="mr-2" onClick={this.topOffendersCSV}>Download CSV</Button>
                                        <Button variant="primary" className="mr-2" onClick={this.printReport}>Download Report</Button>
                                        <Button variant="dark" onClick={() => this.setState({reportModal: true})}><FilterListSharpIcon className="btn-icon" />Filter</Button>
                                    </div>
                                </div>
                            </div>
                            <div id="report" ref={this.reportRef}>
                                <div className="row graph-row ">
                                    <div className="col-md-6 d-flex  right-border">
                                        <div className="col-md-4">
                                            <div className="count-block">
                                                <h6 className="mb-3">Total <br></br> Users</h6>
                                                <h4>{this.state.totalUser}</h4>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="count-block">
                                                <h6 className="mb-3">Offenders</h6>
                                                <h4>{this.state.offenders}</h4>
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="count-block">
                                                <h6 className="mb-3">Repeat <br></br>Offenders</h6>
                                                <h4>{this.state.repeatOffenders}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <h6 className="mb-3">Top Offenders</h6>
                                        <TopOffender
                                            topOffender={this.state.topOffender}
                                        ></TopOffender>
                                    </div>
                                </div>
                                <div className="row graph-row">
                                    <div className="col-md-4 right-border">
                                        <h6 className="mb-3">Employee Threat Profile</h6>
                                        <ThreatProfile
                                            userProfile={this.state.userProfile}
                                        ></ThreatProfile>
                                    </div>
                                    <div className="col-md-4 right-border">
                                        <h6 className="mb-3">Offenders & Repeat Offenders (Category)</h6>
                                        <Category
                                            byCategory={this.state.byCategory}
                                        ></Category>
                                    </div>
                                    <div className="col-md-4">
                                        <h6 className="mb-3">Offenders & Repeat Offenders (Dept)</h6>
                                        <Dept 
                                            byDepartment={this.state.byDepartment}
                                        ></Dept>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
                <ReportModal 
                    /* for date range picker */
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    handleSelect={this.handleSelect}
                    /* for attack type */
                    attackTypes={this.state.attackTypes}
                    attackTypeId={this.state.attackTypeId}
                    attacktypeName={this.state.attacktypeName}
                    handleAttackType={e => this.setState({attackTypeId: parseInt(e.target.value, 10)}, () => this.loadCampaigns())}
                    /* for campaigns */
                    campaigns={this.state.campaigns}
                    selectCampaigns={this.state.campaignIds}
                    handleCampaigns={this.handleCampaigns}
                    /* for modal */
                    reportModal={this.state.reportModal} 
                    reportHandle={e=>this.setState({reportModal: false})}
                    /* for credencial harvesting */
                    credentialHarvesting={this.state.credentialHarvesting}
                    handleHarvesting={e => this.setState({credentialHarvesting: e.target.value})}
                    /* for location */
                    location={this.state.location}
                    locations={this.state.locations}
                    handleLocation={e => this.setState({ location: e.target.value })}
                    /* clear filtes */
                    clearFilters={e=>this.setState({
                        campaignIds: [],
                        reportModal: false,
                        endDate: new Date(),
                        selectedCampaignIds: [],
                        credentialHarvesting: 'NA',
                        startDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
                    }, () => this.loadCampaigns()) }
                    applyFilter={e => this.setState({reportModal: false}, () => this.handleRefresh())}
                ></ReportModal>
                {this.state.loading? <Loader></Loader>: null}
            </div>
        );
    }
}