import React, { Component } from "react";
import ApexCharts from 'apexcharts'

export default class FirstBreachReponse extends Component {
	constructor(props) {
		super(props);
		this.state = {
			chart: null,
		};
	}

	componentDidMount = () => {
		var options = {
			series: [{
				name: "Breach Time",
				data: this.props.firstResponce['breachTime']
			}, {
				name: "Incident Time",
				data: this.props.firstResponce['incidentTime']
			}],
			chart: {
				height: 350,
				type: 'line',
				zoom: {
					enabled: false
				},
				toolbar: {
					show: true
				}
			},
			dataLabels: {
				enabled: false
			},
			stroke: {
				curve: 'straight'
			},
			grid: {
				position: 'front'
			},
			xaxis: {
				categories: this.props.firstResponce['name'],
			},
			markers: {
				size: 5,
			},
			tooltip: {
                enabled: true,
                style: {
                    backgroud: 'white',
                    fontSize: '12px',
                    fontFamily: undefined
                },
                y: {
                    formatter: (value) => { return value + " Seconds" },
                }
            },
		}
		var chart = new ApexCharts(document.querySelector("#first-chart"), options);
		if(chart) chart.render();
		this.setState({chart: chart});
	}

	componentDidUpdate = (prevProps) => {
		if(this.props.firstResponce !== prevProps.firstResponce){
			if(this.state.chart) this.state.chart.destroy();
			this.componentDidMount();
		}
	}

	render() {
		return (
			<div id="first-chart"></div>
		);
	}
}