export default {
    "Simulation Attack": {
        'Phishing': [
            'campaign_name', 'template', 'page', 'attacker_profile', 'preview'
        ],
        'Smishing': [
            'campaign_name', 'template_sms', 'page', 'attacker_profile_sms', 'preview'
        ],
        'Ransomware': [
            'campaign_name', 'template', 'page', 'attacker_profile', 'preview'
        ],
        'Vishing': [
            'campaign_name', 'attacker_profile_call', 'preview'
        ],
        'Cyber Scam': [
            'campaign_name', 'template', 'page', 'attacker_profile', 'preview'
        ],
        'WhatsApp': [
            'campaign_name', 'template_sms', 'page', 'attacker_profile_sms', 'preview'
        ],
        'Attachment': [
            'campaign_name', 'template', 'attacker_profile', 'preview'
        ],
        'QRcode': [
            'campaign_name', 'template', 'page', 'attacker_profile', 'preview'
        ],
    },
    "Simulation Attack + Training": {
        'Phishing': [
            'campaign_name', 'template', 'page', 'training', 'attacker_profile', 'preview'
        ],
        'Smishing': [
            'campaign_name', 'template_sms', 'page', 'training', 'attacker_profile_sms', 'preview'
        ],
        'Cyber Scam': [
            'campaign_name', 'template', 'page', 'training', 'attacker_profile', 'preview'
        ],
        'Attachment': [
            'campaign_name', 'template', 'training' ,'attacker_profile', 'preview'
        ],
        'QRcode': [
            'campaign_name', 'template', 'page', 'training', 'attacker_profile', 'preview'
        ],
    },
    "Awareness Training": {
        'Training': [
            'campaign_name', 'attacker_profile', 'training', 'preview'
        ],
        'Training + Quiz': [
            'campaign_name', 'attacker_profile', 'training', 'preview'
        ],
        'Attachment': [
            'campaign_name', 'template', 'attacker_profile', 'preview'
        ]
    },
    "Gamified": {
        'Game': [
            'campaign_name', 'template', 'attacker_profile', 'game', 'preview'
        ]
    }
}