import React, { Component } from 'react';



import './blue-page-1.css';
import email from '../../assets/email.png';

export default class Actions extends Component {
    
    render() {
        return (
            <div className="blue-page1" id="bluePage-wrap">
                <h1 className="main-head">You have been Phished!</h1>
                <h4 className="sub-head">Remember these six rules to stay safe online</h4>
                <div className="row align-items-center">
                    <div className="col-md-3 left-side large-screen">
                                <div className="pointer-wrap d-flex">   
                                    <div className="para text-right">
                                        <h6>Protect Your <br></br>Personal Info</h6>
                                        <span className="line"></span>
                                        <p> Be very cautious with your personal info including usernames and passwords</p>
                                    </div>
                                    <div className="outer-circle"><span className="num">1</span></div>
                                </div>
                                <div className="pointer-wrap d-flex">   
                                    <div className="para text-right">
                                        <h6>Beware of <br></br>Suspicious Emails</h6>
                                        <span className="line"></span>
                                        <p>Be very suspicious of any emails you receive from trusted entities such as your bank or credit card</p>
                                    </div>
                                    <div className="outer-circle"><span className="num">2</span></div>
                                </div>
                                <div className="pointer-wrap d-flex">   
                                    <div className="para text-right">
                                        <h6>Do Not Click <br></br>Suspicious Links</h6>
                                        <span className="line"></span>
                                        <p>Deceptive links that mimic legitimate URL addresses are a common tool used in phishing scams</p>
                                    </div>
                                    <div className="outer-circle"><span className="num">3</span></div>
                                </div>
                            </div>


                            <div className="col-md-3 left-side mini-screen">
                                <div className="pointer-wrap d-flex">   
                                    <div className="outer-circle"><span className="num">1</span></div>
                                    <div className="para">
                                        <h6>Protect Your <br></br>Personal Info</h6>
                                        <span className="line"></span>
                                        <p> Be very cautious with your personal info including usernames and passwords</p>
                                    </div>
                                </div>
                                <div className="pointer-wrap d-flex">   
                                    <div className="outer-circle"><span className="num">2</span></div>
                                    <div className="para">
                                        <h6>Beware of <br></br>Suspicious Emails</h6>
                                        <span className="line"></span>
                                        <p>Be very suspicious of any emails you receive from trusted entities such as your bank or credit card</p>
                                    </div>
                                </div>
                                <div className="pointer-wrap d-flex">   
                                    <div className="outer-circle"><span className="num">3</span></div>
                                    <div className="para">
                                        <h6>Do Not Click <br></br>Suspicious Links</h6>
                                        <span className="line"></span>
                                        <p>Deceptive links that mimic legitimate URL addresses are a common tool used in phishing scams</p>
                                    </div>
                                </div>
                            </div>
                           

                  <div className="col-md-6 text-center">
                      <div>
                         <img src={email} alt="email" className="img-fluid email-img"></img>
                      </div>
                  </div>

                  <div className="col-md-3">
                    <div className="pointer-wrap d-flex">   
                            <div className="outer-circle"><span className="num">4</span></div>
                            <div className="para">
                                <h6>Know Common<br></br>Phishing Language</h6>
                                <span className="line"></span>
                                <p>Legitimate business will not send you email to ask for your login information or sensitive personal information </p>
                            </div>
                        </div>
                        <div className="pointer-wrap d-flex">   
                            <div className="outer-circle"><span className="num">5</span></div>
                            <div className="para">
                                <h6>False Sense <br></br>Urgency</h6>
                                <span className="line"></span>
                                <p>Look out for emails that try to convey a sense or urgency, and be wary of any email that does not address you directly</p>
                            </div>
                        </div>
                        <div className="pointer-wrap d-flex">   
                            <div className="outer-circle"><span className="num">6</span></div>
                            <div className="para">
                                <h6>Compromised <br></br>Accounts</h6>
                                <span className="line"></span>
                                <p> Warning that your account has been compromised, for example, are a common way to lure victims</p>
                            </div>
                        </div>
                    </div>

              </div>
            </div>
        );
    }
}