import React, { Component } from "react";
import swal from 'sweetalert';
import { toast } from "react-toastify";
import Tooltip from 'react-bootstrap/Tooltip';
import { IoInformation } from "react-icons/io5";
import { Button, Badge, Form, Table,Dropdown } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import MoreVertSharpIcon from '@material-ui/icons/MoreVertSharp';
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import TXTModal from './txt-modal';
import axios from '../../../axios';
import apiError from '../../../error';
import { getDate } from '../../../function';
import Spinner from '../../../component/spinner/spinner';

export default class Domain extends Component {
    constructor(props){
        super(props);
        this.state = {
            domain: {},
            domains: [],
            domainId: 0,
            domainName: '',
            loading: false,
            txtModal: false,
        }
    }

    componentDidMount = () => {
        this.loadDomain();
    }

    loadDomain = () => {
        axios.get('/api/domain', {})
        .then(res => {
            this.setState({domains: res.data.domains});
        })
        .catch(err => {
            apiError(err);
        })
    }

    handleCreateDomain = async() => {
        if(this.state.domainName.length === 0){
            toast.error("Please enter domain name");
            return ;
        } else {
            await this.setState({ loading: true })
            axios.post('/api/domain', {
                domainName: this.state.domainName
            })
            .then(res => {
                this.setState({ loading: false })
                toast.success("Domain added successfully")
                this.setState({domainName: ''});
                this.loadDomain();
            })
            .catch(err => {
                this.setState({ loading: false })
                apiError(err);
            })
        }
    }

    handleDeleteDomain = (domainId) => {
        swal({
            title: "Do you want to delete this Domain?",
            icon: "warning",
            buttons: [ 'No', 'Yes' ],
            dangerMode: true,
        })
        .then(willSuccess => {
            if (willSuccess) {
                axios.delete('/api/domain/'+domainId, {})
                .then(res => {
                    toast.success("Domain deleted successfully");
                    this.loadDomain();
                })
                .catch(err => {
                    apiError(err);
                })
            }
        });
        
    }

    render() {
        return (
            <div className="tab-wrap">
                <div className='tabHeading'>
                    <h6>Domain</h6>
                    <p>2FA enhances account security by preventing unauthorised access </p>
                </div> 

                        <div className="col-md-4 pl-0">
                            <div className='profile-form'>
                            <Form>
                                <Form.Group>
                                    <Form.Label>Domain Name
                                        <OverlayTrigger
                                            placement='top'
                                            overlay={<Tooltip id='top'>Approved Domain by the user for simulation</Tooltip>}>
                                            <IoInformation className="tooltip-icon"></IoInformation>
                                        </OverlayTrigger>
                                    </Form.Label>
                                    <div className="d-flex">
                                        <Form.Control type="text" placeholder="Domain Name" value={this.state.domainName} onChange={e => this.setState({domainName: e.target.value})} />
                                        <Button variant="success" className="ml-2 w-25" type="button" disabled={this.state.loading} onClick={this.handleCreateDomain}><Spinner loading={this.state.loading}></Spinner>Add</Button>
                                    </div>
                                </Form.Group>
                            </Form>
                           
                            </div>
                        </div>

                    <div className="tableContainerWrapInner">
                        <Table responsive>
                            <thead className="bg-light">
                                    <tr>
                                        <th>Domain Name</th>
                                        <th>Date</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {this.state.domains.map((domain, index) => {
                                    return<tr key={'domain-'+index}>
                                        <td>{domain.name}</td>
                                        <td>{(getDate(domain.createdAt))}</td>
                                        <td><Badge pill className={domain.isVerify? "alert-success": "badge-soft-warning"}>{domain.isVerify? "Verified": "Pending"}</Badge></td>
                                        <td>

                                        <Dropdown
                                        className="action-dropdown dropdownIcon"
                                        ref={this.selectRef}
                                        >
                                        <Dropdown.Toggle
                                            className="btn-only"
                                            id="dropdown-basic"
                                        >
                                            <MoreVertSharpIcon className="dot-icon"></MoreVertSharpIcon>
                                                 </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item>
                                                        {!domain.isVerify && (
                                                            <span onClick={() => this.setState({ txtModal: true, domain: domain })}>
                                                            Verify
                                                            </span>
                                                        )}
                                                        </Dropdown.Item>
                                                            <Dropdown.Item
                                                                onClick={() => this.handleDeleteDomain(domain.id)}>
                                                                    Delete
                                                            </Dropdown.Item>
                                                    </Dropdown.Menu>
                                             </Dropdown>

                                        </td>
                                    </tr>
                                        })}

                                </tbody>

                            </Table>

                    </div>



{/* 
                  <div className="col-md-12 pl-0">
                                        
                                                {this.state.domains.map((domain, index) => {
                                                    return <div className="row mx-0 align-items-center setting-body" key={'domain-'+index}>
                                                        <div className="col-md-3">{domain.name}</div>
                                                        <div className="col-md-2"><Badge pill className={domain.isVerify? "badge-soft-success": "badge-soft-warning"}>{domain.isVerify? "Verified": "Pending"}</Badge></div>
                                                        <div className="col-md-4">{(getDate(domain.createdAt))}</div>
                                                        <div className="col-md-3 d-flex justify-content-center align-items-center">
                                                            {!domain.isVerify? <Button className="mr-2 view-btn"  onClick={() => this.setState({txtModal: true, domain: domain})}>Verify</Button>: null}
                                                            <Button className="btn-danger-rgba btn-round"  onClick={() => this.handleDeleteDomain(domain.id)} >
                                                                <DeleteSharpIcon className="table-icon text-danger"></DeleteSharpIcon>
                                                            </Button>
                                                        </div>
                                                    </div>
                                            })}
                       </div>
         */}
                                


                <TXTModal 
                    txtModal={this.state.txtModal} 
                    txtHandle={e=>this.setState({txtModal: false})} 
                    domain={this.state.domain}
                    loadDomain={this.loadDomain}
                ></TXTModal>
            </div>
        );
    }
}