import { io } from 'socket.io-client';

// "undefined" means the URL will be computed from the `window.location` object
const URL = process.env.NODE_ENV === 'production' ? undefined : process.env.REACT_APP_API_URL;

export const socket = io(URL, {
  autoConnect: true,
  withCredentials: true,
  
  transports : ['websocket']
});

export const SOCKET_EVENTS = {
  AI_TEMPLATE_DATA: "api:template:ai:response",
}