import swal from "sweetalert";
import { toast } from "react-toastify";
import React, { Component } from "react";
import Tooltip from 'react-bootstrap/Tooltip';
import { IoInformation } from "react-icons/io5";
import DateTimePicker from 'react-datetime-picker';
import MultiSelect from "react-multi-select-component";
import SendSharpIcon from '@material-ui/icons/SendSharp';
import CheckSharpIcon from '@material-ui/icons/CheckSharp';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import DateRangeSharpIcon from '@material-ui/icons/DateRangeSharp';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import RemoveRedEyeSharpIcon from '@material-ui/icons/RemoveRedEyeSharp';
import { Card, Breadcrumb, Form, Button, Table, ListGroup, InputGroup, Modal } from 'react-bootstrap';

import './campaign-launch.css';
import axios from '../../../axios';
import apiError from '../../../error';
import Loader from "../../../component/loader/loader";
import { setCookie, getDate } from "../../../function";
import Preview from '../../../component/preview/preview';
import Newcamp from "../../../assets/newcamp.png";
import Follow from "../../../assets/follow.png";
import ArrowBackSharpIcon from '@material-ui/icons/ArrowBackSharp';
import ScheduledModal from '../../../component/schedule-modal/schedule-modal';
import TestEmails from '../../../component/test-campaign-email/test-campaign-email';


export default class CampaignLaunch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            name: '',
            groups: [],
            groupIds: [],
            grouplist: [],
            nameError: false,
            userSelectError: false,
            date: new Date(),
            campaignCategory: [],
            campaignTemplate: [],

            single: true,
            random: false,
            frequency: 'One Time',

            period: '',
            selectedTeplate: {},
            showModal: false,
            showTestModal: false,
            previewSrc: '',
            emails: '',
            previewType: '',
            categoryCount: {},
            templateName: null,
            selectedOption: '',
            lmsDelay: new Date(new Date().setMinutes(new Date().getMinutes() + 300)),

            disabledSendNowButton: false,

            overview: [],
            campaigns: [],
            simulationId: 0,
            simulationState: null,
            sendCampaignOnOldSimulation: false,

            attackType: this.props.match.params.attackType,
            credentialHarvesting: this.props.match.params.ch,
            simulationType: this.props.match.params.simulationType,

            templateId: this.useQuery().get('ctID') ? this.useQuery().get('ctID') : '0',
            category: this.useQuery().get('category') ? this.useQuery().get('category') : '',
            type: this.useQuery().get('type') ? this.useQuery().get('type') : 'System Templates',

            copySuccess: '',
            identifier: '',
            campaginTypeModal: true,

            firstname: '',
            lastname: '',
            lmsMsgStatus: '',
            isRepliedCampaign: false
        }
        this.loagGroups();
        this.loadCampaigns();
        this.loadTemplatesCategory();
    }

    loadCampaigns = () => {
        axios.get('/api/campaigns/simulations', {
            params: {
                attackType: this.state.attackType,
                simulationType: this.state.simulationType
            }
        })
            .then(async (res) => {
                this.setState({ campaigns: res.data.campaigns })
            })
            .catch(error => apiError(error))
    }

    loadSimulationDetails = () => {
        if (this.state.simulationId && this.state.simulationId !== '-1') {
            axios.get('/api/campaigns/detail', {
                params: {
                    attackType: this.state.attackType,
                    simulationId: this.state.simulationId
                }
            })
                .then(async (res) => {
                    this.setState({ overview: res.data.status, campaignName: res.data.campaignName })
                })
                .catch(error => apiError(error))
        } else {
            this.setState({ overview: [] })
        }
    }

    useQuery = () => {
        return new URLSearchParams(window.location.search);
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.match.params.ch !== this.props.match.params.ch ||
            prevProps.match.params.attackType !== this.props.match.params.attackType ||
            prevProps.match.params.simulationType !== this.props.match.params.simulationType
        ) {
            this.setState({
                period: '',
                single: true,
                random: false,
                selectedTeplate: {},
                frequency: 'One Time',
                campaginTypeModal: true,
                attackType: this.props.match.params.attackType,
                credentialHarvesting: this.props.match.params.ch,
                simulationType: this.props.match.params.simulationType,
                templateId: this.useQuery().get('ctID') ? this.useQuery().get('ctID') : '0',
                category: this.useQuery().get('category') ? this.useQuery().get('category') : '',
                type: this.useQuery().get('type') ? this.useQuery().get('type') : 'System Templates',
            }, () => {
                this.loadCampaigns();
                this.loadTemplatesCategory();
            })
        }
    }

    componentDidMount() {
        if (this.state.selectedOption === "") {
            this.setState({selectedOption:"1"})
        }
    }

    handleChangeTimeFrame = (event) => {
        const selectedOption = event.target.value;
        this.setState({ selectedOption }, () => {
        });
    }

    showHandle = (type) => {
        let previewSrc = '';
        if (type === 'template') {
            previewSrc = this.state['templatePath'];
        } else if (type === 'page') {
            previewSrc = this.state['pagePath'];
        } else if (type === 'awareness') {
            previewSrc = this.state['awarenessPath'];
        }
        this.setState({ showModal: !this.state.showModal, previewSrc: previewSrc, previewType: type });
    }

    showHandleTestEmail = () => {
        this.setState({ showTestModal: !this.state.showTestModal });
    }

    handleFrequency = (frequency) => {
        this.setState({ period: '', frequency: frequency })
    }

    handleMode = (status) => {
        if (status === 'single') {
            this.setState({
                single: true,
                random: false,
                frequency: 'One Time'
            })
        } else if (status === 'random') {
            this.setState({
                single: false,
                random: true,
                templateId: '0',
                frequency: 'One Time'
            })
        }
    }

    handleCategory = (e) => {
        this.setState({
            single: true,
            random: false,
            templateId: '0',
            campaignTemplate: [],
            frequency: 'One Time',
            category: e.target.value,
        }, () => this.loadTemplates())
    }

    handleCampaignType () {
        this.setState({
            // single: true,
            // random: false,
            templateId: '0',
            campaignTemplate: [],
            // frequency: 'One Time',
        }, ()=> this.getTemplateDetail())
    }

    handleGroup = (e) => {
        let groupIds = [];
        for (let i = 0; i < e.length; i++) {
            groupIds.push(e[i].value)
        }
        this.setState({ groupIds: groupIds, grouplist: e, userSelectError: false});
    }

    loagGroups = () => {
        axios.get('/api/groups/parentGroup', {})
            .then((res) => {
                var groups = [];
                for (var i = 0; i < res.data.groups.length; i++) {
                    if (res.data.groups[i].targets > 0) {
                        groups.push({
                            value: res.data.groups[i].id,
                            label: res.data.groups[i].name + " ( " + res.data.groups[i].targets + " Members )"
                        })
                    }
                }
                this.makeid();
                this.setState({ groups: groups })
            })
            .catch(err => apiError(err))
    }

    loadTemplatesCategory = () => {
        axios.get('/api/campaign/template/categories', {
            params: {
                type: this.state.type,
                attackType: this.props.match.params.attackType,
                credentialHarvesting: this.props.match.params.ch,
                simulationType: this.props.match.params.simulationType
            }
        })
            .then((res) => {
                let categoryCount = {};
                for (let i = 0; i < res.data.template_category.length; i++) {
                    categoryCount[res.data.template_category[i].category.id] = res.data.template_category[i].count;
                }
                this.setState({
                    loading: false,
                    categoryCount: categoryCount,
                    campaignCategory: res.data.template_category,
                })
                if (this.useQuery().get('category')) {
                    this.loadTemplates();
                }
            })
            .catch(error => {
                this.setState({
                    loading: false,
                    categoryCount: {},
                    campaignCategory: [],
                })
                apiError(error);
            })
    }

    loadTemplates = async () => {
        if (this.state.category && this.state.category.length !== 0) {
            await this.setState({ loading: true });
            axios.get('/api/campaign/template/template', {
                params: {
                    type: this.state.type,
                    categoryId: this.state.category,
                    attackType: this.props.match.params.attackType,
                    credentialHarvesting: this.props.match.params.ch,
                    simulationType: this.props.match.params.simulationType
                }
            })
                .then((res) => {
                    this.setState({
                        loading: false,
                        campaignTemplate: res.data.campaign_template,
                    })
                    if (this.useQuery().get('ctID')) {
                        this.getTemplateDetail();
                    }
                })
                .catch(error => {
                    this.setState({
                        loading: false,
                        campaignTemplate: [],
                    })
                    apiError(error);
                })
        }
    }

    getTemplateDetail = async () => {
        if (this.state.templateId && this.state.templateId !== 0 && this.state.templateId !== '0') {
            // console.log("its okkk!!");
            await this.setState({ loading: true })
            axios.get('/api/campaign/template/detail', {
                params: {
                    type: this.state.type,
                    templateId: this.state.templateId
                }
            })
                .then((res) => {
                    const campaignTemplate = res.data.campaign_template;
                    this.setState({
                        loading: false,
                        templateName: campaignTemplate.template ? campaignTemplate.template.templateName : null,
                        templateId: this.state.templateId,
                        pagePath: res.data.pagePath,
                        templatePath: res.data.templatePath,
                        awarenessPath: res.data.awarenessPath,
                        selectedTeplate: res.data.campaign_template
                    });
                })
                .catch(error => {
                    this.setState({
                        loading: false,
                        pagePath: '',
                        templatePath: '',
                        awarenessPath: '',
                        templateName: '',
                        campaignTemplate: [],
                        selectedTeplate: {}
                    });
                    apiError(error);
                })
        }
    }

    validateCampaign = () => {
        if (this.state.sendCampaignOnOldSimulation) {
            if (!this.state.simulationId || !parseInt(this.state.simulationId, 10)) {
                toast.error("Please select campaign from campaign list");
                return false;
            }
            if (!this.state.simulationState || this.state.simulationState.length === 0) {
                toast.error("Please select result mode where you want run campaign");
                return false;
            }
        } else {
            if (this.state.name.length === 0 || (this.state.name).trim().length === 0) {
                this.setState({ nameError: true })
                toast.error("Please enter campaign name");
                return false;
            } else if (this.state.groupIds.length === 0) {
                this.setState({ userSelectError: true })
                toast.error("Please select groups");
                return false;
            }
        }
        if (this.state.attackType !== 'Removal Media') {
            if (this.state.category.length === 0) {
                toast.error("Please select category");
                return false;
            }
            if (this.state.single) {
                if (!this.state.templateId || this.state.templateId.length === 0 || this.state.templateId === '0') {
                    toast.error("Please select template");
                    return false;
                }
            }
            if (this.state.random && this.state.frequency !== 'One Time') {
                if (!this.state.period || this.state.period.length === 0) {
                    toast.error("Please select campaign period");
                    return false;
                }
            }
        }
        if (this.state.simulationType === 'Simulation Attack + Training') {
            if (!this.state.lmsMsgStatus || this.state.lmsMsgStatus.length === 0) {
                toast.error("Please select Awareness Targeted Group");
                return false;
            }
        }
        return {
            type: this.state.type,
            single: this.state.single,
            random: this.state.random,
            scheduledAt: this.state.date,
            groupIds: this.state.groupIds,
            category: this.state.category,
            frequency: this.state.frequency,
            identifier: this.state.identifier,
            campaignName: (this.state.name).trim(),
            simulationType: this.state.simulationType,
            campaignTemplateId: this.state.templateId,
            attackType: this.props.match.params.attackType,
            credentialHarvesting: this.state.credentialHarvesting,
            period: (this.state.period && this.state.period.length !== 0) ? this.state.period : '1',

            simulationId: this.state.simulationId,
            simulationState: this.state.simulationState,
            sendCampaignOnOldSimulation: this.state.sendCampaignOnOldSimulation,

            lmsDelay: this.state.lmsDelay,
            lmsMsgStatus: this.state.lmsMsgStatus,
            isRepliedCampaign: this.state.isRepliedCampaign
        };
    }

    runCampaign = () => {
        var data = this.validateCampaign();
        if (data) {
            this.setState({ loading: true, showScheduledModal: false, disabledSendNowButton: true })
            if (this.state.sendCampaignOnOldSimulation) {
                this.sendCampaign(data);
            } else {
                axios.post('/api/groups/alreadyExistsTargetInGroups', {
                    groupIds: data.groupIds
                })
                    .then(async res => {
                        if (res.data.success) {
                            await this.setState({ loading: false });
                            var content = document.createElement("div");
                            content.innerHTML = res.data.group_targets[0].email;
                            content.innerHTML += res.data.group_targets.length > 1 ? `....   and ${res.data.group_targets.length - 1} other Emails<br>` : "<br>";
                            content.innerHTML += `Still if you want to run campaign click on Yes otherwise No.`
                            swal({
                                title: "User exists in multiple groups",
                                html: true,
                                icon: "warning",
                                content: content,
                                buttons: ['No', 'Yes'],
                            })
                                .then(async willSuccess => {
                                    if (willSuccess) {
                                        this.setState({ loading: true })
                                        this.sendCampaign(data);
                                    } else {
                                        this.setState({ loading: false, disabledSendNowButton: false })
                                    }
                                })
                        } else {
                            this.sendCampaign(data);
                        }
                    })
                    .catch(error => {
                        this.setState({ loading: false, disabledSendNowButton: false })
                        apiError(error);
                    })
            }
        }
    }

    sendCampaign = (data) => {
        data["delay"] = this.state.selectedOption;
        axios.post('/api/simulation/', data)
            .then(res => {
                this.setState({ loading: false, disabledSendNowButton: false })
                setCookie('isDashboard', true, 30000);
                toast.success("Campaign run successfully");
                setTimeout(() => {
                    this.props.history.push('/campaign/' + res.data.campaignId);
                }, 500);
            })
            .catch(error => {
                this.setState({ loading: false, disabledSendNowButton: false })
                apiError(error);
            })
    }

    testCampaign = (targetDetails) => {
        if (this.state.templateId && this.state.templateId !== 0 && this.state.templateId !== '0') {
            if (targetDetails.length && targetDetails[0].emails && targetDetails[0].emails.length) {
                this.setState({ loading: true })
                axios.post('/api/simulation/test', {
                    targetDetails,
                    campaignTemplateId: this.state.templateId,
                })
                    .then(res => {
                        this.showHandleTestEmail();
                        this.setState({ loading: false })
                        toast.success("Test campaign run successfully");
                        setTimeout(() => { window.open('/campaign/test-results/?attackTypeId=' + this.state.attackType) }, 500);
                    })
                    .catch(error => {
                        this.setState({ loading: false })
                        apiError(error);
                    })
            } else {
                toast.error("Please enter email Id");
            }
        }
    }

    copyToClipboardIdentifier = (e) => {
        this.textArea1.select();
        document.execCommand('copy');
        e.target.focus();
        this.setState({ copySuccess: 'Copied!' });
    };

    makeid = () => {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < 30; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        this.setState({ identifier: result })
    }
    
    handleReplyTo = () => {
        swal({
            title: "Make sure IMAP is setup correctly in your acount",
            icon: "info",
            buttons: [ 'Not Now!', 'Yes' ],
        })
        .then(willSuccess => {
            if (willSuccess) {
                this.setState({isRepliedCampaign: true})
            }
        })
    }

    render() {
        const frequencyArr = ['One Time', 'Weekly', 'Monthly', 'Quartly', 'Annualy'];
        const disableRandom = this.state.categoryCount[this.state.category] && this.state.categoryCount[this.state.category] >= 5 ? false : true;
        return (
            <div className="content-wrap">
            
                <div className="breadcrumbbar">
                    <div className="flexRow row">
                        <div className="col-md-4">
                            <div className="page-head d-flex align-items-center justify-content-between">
                            <h4 className="page-title">
                                <a onClick={e => this.props.history.goBack()} className="bar_Icons mr-2"><ArrowBackSharpIcon className="btn-icon"/></a>Create New Campaign</h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contentbar">
                 <div className="row">
                    <div className="col-lg-12">
                    <div className="newCampCreate">
        
                <Form>
                    
                    <div className="crtAttackType">
                    <div className="typewrapper">
                        <h6>Simulation Type : <span>{this.state.simulationType}</span></h6>
                    </div>
                    <div className="typewrapper">
                    <h6>Attack Vector : <span>{this.state.attackType}</span></h6>
                    </div>
                    </div>

                    <div className="blockSpace">
                        <div class="compHeading">
                            <span class="point">1</span>Basic Information
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                {this.state.sendCampaignOnOldSimulation ?
                                        <Form.Group>
                                            <Form.Label>Campaign List</Form.Label>
                                            <Form.Control as="select" value={this.state.simulationId} onChange={e => this.setState({ simulationId: e.target.value }, () => this.loadSimulationDetails())}>
                                                <option value="-1">Select campaign</option>
                                                {this.state.campaigns.map((campaign) => {
                                                    return campaign.simulations.map((simulation) => {
                                                        return <option key={`campaign-${simulation.id}`} value={simulation.id}>{`${campaign.name} (${getDate(simulation.launchedAt)})`}</option>
                                                    })
                                                })}
                                            </Form.Control>
                                        </Form.Group> :
                                        <Form.Group>
                                            <Form.Label>Campaign Name<span className="red-star">*</span></Form.Label>
                                            <Form.Control type="text" isInvalid={this.state.nameError} placeholder="Campaign name" value={this.state.name} onChange={e => this.setState({ name: e.target.value, nameError: false })} />
                                        </Form.Group>
                                    }

                            </div>


                            <div className="col-md-8">
                            {this.state.sendCampaignOnOldSimulation ?
                                <div className="form-group">
                                    <label class="form-label">Select Email Event</label>
                                    {parseInt(this.state.simulationId, 10) > 0?
                                        <div className={parseInt(this.state.simulationId, 10) > 0 ? "overview-campaign" : ""}>
                                            {this.state.attackType === "Phishing" ?
                                                this.state.overview.map((status, index) => {
                                                    status.count = status.count ? status.count : 0;
                                                    if (status.message === "Email Opened")
                                                        return <Form.Check key={index} type="radio" onChange={e => this.setState({ simulationState: 'Email Opened' })} label={`Email Opened (${status.count})`} checked={this.state.simulationState === 'Email Opened'} />
                                                    if (status.message === "Link Clicked")
                                                        return <Form.Check key={index} type="radio" onChange={e => this.setState({ simulationState: 'Link Clicked' })} label={`Link Clicked (${status.count})`} checked={this.state.simulationState === 'Link Clicked'} />
                                                    if (status.message === "Data Submitted")
                                                        return <Form.Check key={index} type="radio" onChange={e => this.setState({ simulationState: 'Data Submitted' })} label={`Data Submitted (${status.count})`} checked={this.state.simulationState === 'Data Submitted'} />
                                                    return null;
                                                }): null
                                            } {this.state.attackType === "Smishing" ?
                                                this.state.overview.map((status, index) => {
                                                    status.count = status.count ? status.count : 0;
                                                    if (status.message === "Link Clicked")
                                                        return <Form.Check className="mr-4" key={index} type="radio" onChange={e => this.setState({ simulationState: 'Link Clicked' })} label={`Link Clicked (${status.count})`} checked={this.state.simulationState === 'Link Clicked'} />
                                                    if (status.message === "Data Submitted")
                                                        return <Form.Check className="mr-4" key={index} type="radio" onChange={e => this.setState({ simulationState: 'Data Submitted' })} label={`Data Submitted (${status.count})`} checked={this.state.simulationState === 'Data Submitted'} />
                                                    return null;
                                                }) : null
                                            } {this.state.attackType === "Ransomware" ?
                                                this.state.overview.map((status, index) => {
                                                    status.count = status.count ? status.count : 0;
                                                    if (status.message === "Email Opened")
                                                        return <Form.Check className="mr-4" key={index} type="radio" onChange={e => this.setState({ simulationState: 'Email Opened' })} label={`Email Opened (${status.count})`} checked={this.state.simulationState === 'Email Opened'} />
                                                    if (status.message === "Link Clicked")
                                                        return <Form.Check className="mr-4" key={index} type="radio" onChange={e => this.setState({ simulationState: 'Link Clicked' })} label={`Link Clicked (${status.count})`} checked={this.state.simulationState === 'Link Clicked'} />
                                                    if (status.message === "System Hack")
                                                        return <Form.Check className="mr-4" key={index} type="radio" onChange={e => this.setState({ simulationState: 'System Hack' })} label={`System Hack (${status.count})`} checked={this.state.simulationState === 'System Hack'} />
                                                    return null;
                                                }) : null
                                            } {this.state.attackType === "Vishing" ?
                                                this.state.overview.map((status, index) => {
                                                    status.count = status.count ? status.count : 0;
                                                    if (status.message === "Data Submitted")
                                                        return <Form.Check className="mr-4" key={index} type="radio" onChange={e => this.setState({ simulationState: 'Data Submitted' })} label={`Data Submitted (${status.count})`} checked={this.state.simulationState === 'Data Submitted'} />
                                                    return null;
                                                }) : null
                                            }
                                        </div>:
                                    <div class="noteSection"><p>The email event will become visible once you select an existing campaign.</p></div>
                                    }
                                </div> :

                        <div className="col-md-6 pl-0">
                                <Form.Group>
                                    <Form.Label>User Group<span className="red-star">*</span></Form.Label>
                                    <MultiSelect
                                        className = {this.state.userSelectError ? "multi-select empty " : "multi-select borderRemove"}
                                        clearSearch="Clear Search"
                                        options={this.state.groups}
                                        value={this.state.grouplist}
                                        onChange={this.handleGroup}                                            
                                        overrideStrings={{ selectSomeItems: "Select user groups" }}
                                    />
                                </Form.Group>
                                </div>
                            }
                            </div>

                        
                            
                        </div>
                    </div>

                    <div className="blockSpace">
                    <div class="compHeading">
                            <span class="point">2</span>Template Selection
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                    {this.state.attackType !== "Removal Media" ?
                                        <div>
                                            <div className="row">
                                                <Form.Group className="col-md-12">
                                                    <Form.Label>Template Type<span className="red-star">*</span>
                                                        <OverlayTrigger
                                                            placement='top'
                                                            overlay={<Tooltip id='top'> Allows you to choose from Custom Templates, System Templates and Community Templates</Tooltip>}>
                                                            <IoInformation className="tooltip-icon"></IoInformation>
                                                        </OverlayTrigger>
                                                    </Form.Label>
                                                    <Form.Control as="select" value={this.state.type} onChange={e => this.setState({ type: e.target.value, category: ''}, () => this.loadTemplatesCategory())}>
                                                        <option value="System Templates">System Templates</option>
                                                        <option value="My Templates">Custom Templates</option>
                                                        <option value="Community Templates">Community Templates</option>
                                                    </Form.Control>
                                                </Form.Group>
                                            </div>
                                            {(this.state.simulationType).indexOf('Training') !== -1 ? (this.state.attackType !== 'Attachment') ? 

                                                <div>
                                                    <div className="custom-switch121">
                                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                                            <Form.Label>Awareness Targeted Group</Form.Label>
                                                            <Form.Control as="select" value={this.state.lmsMsgStatus} onChange={e => {
                                                                this.setState({ lmsMsgStatus: e.target.value })}}>
                                                                <option value={''}>Select Awareness Targeted Group</option>
                                                                <option value={'linkclicked'}>Link Clicked</option>
                                                                {(this.state.credentialHarvesting === true || this.state.credentialHarvesting === "true") && <option value={'datasubmitted'}>Data Submitted</option>}
                                                                {/* <option value={'scheduled'}>Scheduled For Some Time</option> */}
                                                            </Form.Control>
                                                        </Form.Group>
                                                        {this.state.lmsMsgStatus === 'scheduled' &&
                                                            <DateTimePicker
                                                                minDate={new Date()}
                                                                value={this.state.lmsDelay}
                                                                onChange={e => this.setState({ lmsDelay: e })}
                                                            />
                                                        }
                                                    </div>

                                                    <div className="timeFrameForSimulationAndTraining">
                                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                                            <Form.Label>Select Time Frame</Form.Label>
                                                            <Form.Control as="select" value={this.state.selectedOption} onChange={this.handleChangeTimeFrame} >
                                                                <option value="1">1 sec</option>
                                                                <option value="5">5 min</option>
                                                                <option value="10">10 min</option>
                                                                <option value="15">15 min</option>
                                                            </Form.Control>
                                                        </Form.Group>
                                                    </div>

                                                </div> : 
                                                <div>
                                                    <div className="custom-switch121">
                                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                                            <Form.Label>Awareness Targeted Group</Form.Label>
                                                            <Form.Control as="select" value={this.state.lmsMsgStatus} onChange={e => { 
                                                                this.setState({ lmsMsgStatus: e.target.value })}}>
                                                                <option value={''}>Select Awareness Targeted Group</option>
                                                                <option value={'Attachment Opened'}>Attachment Opened</option>
                                                                {/* {(this.state.credentialHarvesting === true || this.state.credentialHarvesting === "true") && <option value={'datasubmitted'}></option>} */}
                                                                {/* <option value={'scheduled'}>Scheduled For Some Time</option> */}
                                                            </Form.Control>
                                                        </Form.Group>
                                                        {this.state.lmsMsgStatus === 'scheduled' &&
                                                            <DateTimePicker
                                                                minDate={new Date()}
                                                                value={this.state.lmsDelay}
                                                                onChange={e => this.setState({ lmsDelay: e })}
                                                            />
                                                        }
                                                    </div>

                                                    <div className="timeFrameForSimulationAndTraining">
                                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                                            <Form.Label>Select Time Frame</Form.Label>
                                                            <Form.Control as="select" value={this.state.selectedOption} onChange={this.handleChangeTimeFrame} >
                                                                <option value="1">1 sec</option>
                                                                <option value="5">5 min</option>
                                                                <option value="10">10 min</option>
                                                                <option value="15">15 min</option>
                                                            </Form.Control>
                                                        </Form.Group>
                                                    </div>

                                                </div> : null
                                            }
                                        </div> :
                                        <Form.Group>
                                            <Form.Label>Identifier</Form.Label>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <Form.Control type="text" ref={(textarea) => this.textArea1 = textarea} placeholder="" value={this.state.identifier} />
                                                </div>
                                                <div className="col-md-6">
                                                    <InputGroup.Append>
                                                        <Button variant="secondary" onClick={this.copyToClipboardIdentifier}><FileCopyOutlinedIcon className="only-icon"></FileCopyOutlinedIcon></Button>
                                                    </InputGroup.Append>
                                                    <p className="copy-text">{this.state.copySuccess}</p>
                                                </div>
                                            </div>
                                        </Form.Group>
                                    }
                            </div>
                            <div className="col-md-4">
                                {this.state.attackType !== "Removal Media" ?
                                    <div>
                                        <Form.Group>
                                            <Form.Label>Template Category<span className="red-star">*</span>
                                                <OverlayTrigger
                                                    placement='top'
                                                    overlay={<Tooltip id='top'>These are the categories of attack templates</Tooltip>}>
                                                    <IoInformation className="tooltip-icon"></IoInformation>
                                                </OverlayTrigger>
                                            </Form.Label>
                                            <Form.Control as="select" value={this.state.category} onChange={this.handleCategory}>
                                                <option value=''>Select Template Category</option>
                                                {this.state.campaignCategory.map((category, index) => {
                                                    return <option key={index} value={category.category.id}>{category.category.name + " ( " + category.count + " Templates ) "}</option>
                                                })}
                                            </Form.Control>
                                        </Form.Group>
                                    </div> : null
                                }
                            </div>  
                            <div className="col-md-4">
                                <Form.Group>
                                    <Form.Label>Template<span className="red-star">*</span></Form.Label>
                                    <Form.Control as="select" 
                                        value={this.state.templateId} 
                                        onChange={e =>{
                                            this.setState({ templateId: e.target.value }, () => this.getTemplateDetail())}
                                        }>
                                        <option value='0'>Select Campaign template</option>
                                        {this.state.campaignTemplate && this.state.campaignTemplate.map((template, index) => {
                                            return <option key={index} value={template.id}>{template.name}</option>
                                        })}
                                    </Form.Control>
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                    
                    <div className="blockSpace">
                    <div class="compHeading">
                          <span class="point">3</span>Mode Selection
                      </div>
                    <div className="col-md-4 pl-0">
                            <Form.Group>
                                <Form.Label>Campaign Mode
                                    <OverlayTrigger
                                        placement='top'
                                        overlay={<Tooltip id='top'>Helps you select how the templates are sent to each user. Random option can be chosen only for template categories with 5 or more templates</Tooltip>}>
                                        <IoInformation className="tooltip-icon"></IoInformation>
                                    </OverlayTrigger>
                                </Form.Label>
                                <div className="campRadiobtn d-flex justify-content-start">
                                    <Form.Check className="" type="radio" onChange={e => this.handleMode('single')} label="Single" checked={this.state.single} />
                                    <Form.Check type="radio" onChange={e => this.handleMode('random')} label="Random" checked={this.state.random} disabled={disableRandom} />
                                </div>
                            </Form.Group>
                            {this.state.random? 
                                <div className="freq-list mb-3">
                                    <Form.Label>Frequency
                                        <OverlayTrigger
                                            placement='top'
                                            overlay={<Tooltip id='top'>Select the frequency of your campaign</Tooltip>}>
                                            <HelpOutlineIcon className="tooltip-icon"></HelpOutlineIcon>
                                        </OverlayTrigger>
                                    </Form.Label>
                                    <ListGroup defaultActiveKey="#link1" horizontal>
                                        <ListGroup.Item href="#link1" onClick={e => this.handleFrequency('One Time')}>
                                            One Time
                                        </ListGroup.Item>
                                        <ListGroup.Item href="#link2" onClick={e => this.handleFrequency('Weekly')}>
                                            Weekly
                                        </ListGroup.Item>
                                        <ListGroup.Item href="#link3" onClick={e => this.handleFrequency('Monthly')}>
                                            Monthly
                                        </ListGroup.Item>
                                        <ListGroup.Item href="#link4" onClick={e => this.handleFrequency('Quartly')}>
                                            Quartly
                                        </ListGroup.Item>
                                        <ListGroup.Item href="#link5" onClick={e => this.handleFrequency('Annualy')}>
                                            Annually
                                        </ListGroup.Item>
                                    </ListGroup>
                                </div>: null
                            }
                            {this.state.frequency !== "One Time" ?
                                <div className="mt-3">
                                    <Form.Label>Campaign Period</Form.Label>
                                    <Form.Control as="select" value={this.state.period} onChange={e => this.setState({ period: e.target.value })}>
                                        <option value=''>Select campaign Period</option>
                                        {frequencyArr.indexOf(this.state.frequency) <= frequencyArr.indexOf('Weekly') ? <option value='1'>One Month</option> : null}
                                        {frequencyArr.indexOf(this.state.frequency) <= frequencyArr.indexOf('Monthly') ? <option value='3'>Three Month</option> : null}
                                        {frequencyArr.indexOf(this.state.frequency) <= frequencyArr.indexOf('Quartly') ? <option value='6'>Six Month</option> : null}
                                        {frequencyArr.indexOf(this.state.frequency) <= frequencyArr.indexOf('Annualy') ? <option value='12'>One Year</option> : null}
                                    </Form.Control>
                                </div> : null
                            }
                        </div>  
                    </div>

                    <div className="blockSpace">
                     <div class="compHeading">
                            <span class="point">4</span>User Reply Configuration
                        </div>
                        <div className="col-md-4 pl-0">
                            {this.state.attackType === 'Phishing' || this.state.attackType === "Ransomware" || this.state.attackType === 'Attachment' || this.state.attackType === "QRcode"?
                                <>
                                    <Form.Label>Allow user reply
                                        <OverlayTrigger
                                            placement='top'
                                            overlay={<Tooltip id='top'>Allows the user to reply to the phishing email recieved.</Tooltip>}>
                                            <IoInformation className="tooltip-icon"></IoInformation>
                                        </OverlayTrigger>
                                    </Form.Label>
                                    <div className="campRadiobtn d-flex justify-content-start">         
                                        <Form.Check type="radio" onChange={e => this.setState({isRepliedCampaign: false})} label="Off" checked={!this.state.isRepliedCampaign} />
                                        <Form.Check type="radio" onChange={this.handleReplyTo} label="On" checked={this.state.isRepliedCampaign} />
                                    </div>
                                </>: null
                            }  
                        </div> 
                    </div>

                        
                    {this.state.templateId && this.state.selectedTeplate && this.state.templateId !== '0' ?
                                <div className="col-md-12">
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th>Campaign Template Name</th>
                                                {this.state.selectedTeplate && this.state.selectedTeplate.templateId ? <th>{this.state.attackType === "Smishing" ? "SMS Template" : this.state.attackType === "WhatsApp" ? "Template Name" : "Email Template"}</th> : null}
                                                {this.state.selectedTeplate && this.state.selectedTeplate.pageId ? <th>Landing Page</th> : null}
                                                {this.state.selectedTeplate && this.state.selectedTeplate.pageId ? <th>Awareness</th> : null}
                                                <th>{this.state.attackType === "Smishing" || this.state.attackType === "WhatsApp" ? "Send Test Message" : "Send Test Email"}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{this.state.selectedTeplate ? this.state.selectedTeplate.name : ''}</td>
                                                {this.state.attackType === "WhatsApp" ? <><td>{this.state.templateName}</td></> : this.state.selectedTeplate && this.state.selectedTeplate.templateId ? <td>Preview <RemoveRedEyeSharpIcon className="btn-icon" style={{ cursor: "pointer", color: "blue" }} onClick={e => this.showHandle('template')}></RemoveRedEyeSharpIcon></td> : null}
                                                {this.state.selectedTeplate && this.state.selectedTeplate.pageId ? <td>Preview <RemoveRedEyeSharpIcon className="btn-icon" style={{ cursor: "pointer", color: "blue" }} onClick={e => this.showHandle('page')}></RemoveRedEyeSharpIcon></td> : null}
                                                {this.state.selectedTeplate && this.state.selectedTeplate.pageId ? <td>Preview <RemoveRedEyeSharpIcon className="btn-icon" style={{ cursor: "pointer", color: "blue" }} onClick={e => this.showHandle('awareness')}></RemoveRedEyeSharpIcon></td> : null}
                                                <td><Button variant="primary" className="m-0" type="button" onClick={this.showHandleTestEmail}><SendSharpIcon className="btn-icon m-0"></SendSharpIcon></Button></td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div> : null
                            }
  
                </Form>

                    </div>
                </div>
            </div>
         </div>


                <div className="capFooter text-right">
                                <div className="text-right">
                                <Button variant="primary" type="button" disabled={this.state.disabledSendNowButton} className="text-white mr-2" onClick={e => this.setState({ showScheduledModal: true })}><DateRangeSharpIcon className="btn-icon"></DateRangeSharpIcon> Schedule</Button>
                                    <Button variant="primary" type="button" disabled={this.state.disabledSendNowButton} className="text-white " onClick={this.runCampaign}><SendSharpIcon className="btn-icon"></SendSharpIcon> Send Now</Button>
                                   
                                </div>
                    </div>


            


                     
        
                <ScheduledModal
                    date={this.state.date}
                    runCampaign={this.runCampaign}
                    handleDateTime={e => this.setState({ date: e })}
                    showModal={this.state.showScheduledModal}
                    showHandle={e => { this.setState({ showScheduledModal: false }) }}
                ></ScheduledModal>
                <Preview
                    showModal={this.state.showModal}
                    showHandle={this.showHandle}
                    src={this.state.previewSrc}
                    attackType={this.state.attackType}
                    previewType={this.state.previewType}
                    html={this.state.selectedTeplate && this.state.selectedTeplate.template ? this.state.selectedTeplate.template.html : ''}
                ></Preview>
                <TestEmails
                    attackType={this.state.attackType}
                    emails={this.state.emails}
                    firstname={this.state.firstname}
                    lastname={this.state.lastname}
                    testCampaign={this.testCampaign}
                    showModal={this.state.showTestModal}
                    showHandle={this.showHandleTestEmail}
                    handleEmails={(e) => this.setState({ emails: e.target.value })}
                    handleFirstname={(e) => this.setState({ firstname: e.target.value })}
                    handleLastname={(e) => this.setState({ lastname: e.target.value })}
                ></TestEmails>

                {this.state.loading ? <Loader></Loader> : ''}
                <Modal className="campLaunchmodal" show={this.state.campaginTypeModal}>
                    <Modal.Header closeButton>
                        <div>
                            <h5>Campaign Run Type</h5>
                            <p>Select campaign run type</p>
                        </div>
                    </Modal.Header>

                    <Modal.Body>
                                
                    <div className="simselectCard">
                        <div className="simmainFlex">
                            <div onClick={e => this.setState({ sendCampaignOnOldSimulation: false })} className={!this.state.sendCampaignOnOldSimulation ? "active_cl simCard" : "simCard"}>
                            <div className="simIcon">
                                <img src={Newcamp} className="img-fluid"/>
                            </div>
                            <div className="simContent">
                                <h6>New Campaign</h6>
                                <p>Create campaign from the scratch</p>
                            </div>
                            </div>

                                <div onClick={e => this.setState({ sendCampaignOnOldSimulation: true })} className={this.state.sendCampaignOnOldSimulation ? "active_cl simCard" : "simCard"}>
                                    <div className="simIcon">
                                        <img src={Follow} className="img-fluid"/>
                                    </div>
                                    <div className="simContent">
                                        <h6>Follow-Up Campaign</h6>
                                        <p>Create follow up for the existing campaign</p>
                                    </div>
                                </div>
                            </div>
                            </div>
                        {/* <div className="simselectCard verify_check">
                            <ul className="verfiy_list">
                                <li onClick={e => this.setState({ sendCampaignOnOldSimulation: false })} className={!this.state.sendCampaignOnOldSimulation ? "active_cl" : ""}>
                                    {!this.state.sendCampaignOnOldSimulation ? <CheckSharpIcon className="icon_position" /> : null}
                                    <h5>New Campaign</h5>
                                </li>
                                <li onClick={e => this.setState({ sendCampaignOnOldSimulation: true })} className={this.state.sendCampaignOnOldSimulation ? "active_cl" : ""}>
                                    {this.state.sendCampaignOnOldSimulation ? <CheckSharpIcon className="icon_position" /> : null}
                                    <h5>FollowUp Campaign</h5>
                                </li>
                            </ul>
                        </div> */}

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" type="button" onClick={e => this.setState({ campaginTypeModal: false })}>Next</Button>
                    </Modal.Footer>
                </Modal>
        </div>
        );
    }
}