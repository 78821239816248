import React, { Component } from "react";
import { toast } from "react-toastify";
import Pagination from "react-js-pagination";
import DehazeIcon from "@material-ui/icons/Dehaze";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import SearchSharpIcon from "@material-ui/icons/SearchSharp";
import SaveAltSharpIcon from "@material-ui/icons/SaveAltSharp";
import { FormControl, InputGroup, Button, Form, Table, } from "react-bootstrap";

import axiosRequest from "axios";
import axios from "../../../axios";
import apiError from "../../../error";
import Loader from "../../../component/loader/loader";
import EVSGrid from "../../../component/evs/evs-grid/evs-grid";
import EVSTable from "../../../component/evs/evs-table/evs-table";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";

import "./evs-list.css";
var cancelRequest = undefined, CancelToken = axiosRequest.CancelToken;

export default class EVS extends Component {
	constructor(props) {
		super(props);
		this.state = {
			evs: [],
			count: 0,
			limit: 10,
			range: 100,
			search: "",
			type: false,
			activePage: 1,
			loading: true,
		};
	}

	componentDidMount = () => {
		this.load();
	};

	nextPath = (path) => this.props.history.push(path);

	load = async () => {
		await this.setState({ loading: true });
		if (cancelRequest) cancelRequest();
		axios
			.get("/api/evs", {
				params: {
					range: this.state.range,
					limit: this.state.limit,
					search: this.state.search,
					page: this.state.activePage,
				},
				cancelToken: new CancelToken(function executor(c) {
					cancelRequest = c;
				}),
			})
			.then((res) => {
				this.setState({
					loading: false,
					evs: res.data.evs,
					count: res.data.count,
				});
			})
			.catch((error) => {
				if (!axiosRequest.isCancel(error)) {
					this.setState({ evs: [], count: 0, loading: false });
					apiError(error);
				}
			});
	};

	syncEvs = async () => {
		await this.setState({ loading: true });
		axios
			.get("/api/evs/sync", {})
			.then((res) => {
				this.setState({ loading: false });
				toast.warning(res.data.evs, { autoClose: 5000 });
			})
			.catch((error) => {
				this.setState({ loading: false });
				apiError(error);
			});
	};

	downloadFile = () => {
		this.setState({ loading: true });
		axios
			.get("/api/evs/csv", {})
			.then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", "evs.csv");
				document.body.appendChild(link);
				link.click();
				this.setState({ loading: false });
			})
			.catch((err) => {
				this.setState({ loading: false });
				apiError(err);
			});
	};

	render() {
		return (
			<div className="content-wrap" id="evs-wrap">
				<div className="breadcrumbbar">
					<div className="flexRow row">
						<div className="col-md-4">
							<div className="page-head d-flex align-items-center justify-content-between">
								<h4 className="page-title">Employee Vulnerability Score</h4>
							</div>
						</div>

						<div className="col-md-8 flexFilterButtons">
							<div className="btn-container float-right d-flex align-items-center">
								<Button variant="primary" onClick={this.downloadFile}>
									{" "}
									<SaveAltSharpIcon className="btn-icon"></SaveAltSharpIcon>{" "}
									Export All
								</Button>
							</div>
						</div>
					</div>
				</div>
				<div className="filter-wrapper">
					<div className="alignItemsCenter row">
						<div className="col-md-3 p-0">
							<div className="positionSearchIcon">
								<InputGroup className="search-wrap">
									<FormControl
										type="text"
										placeholder="Search"
										className="inputSearch mr-sm-2 form-control"
										value={this.state.search}
										onChange={(e) =>
											this.setState(
												{ search: e.target.value, activePage: 1 },
												() => this.load()
											)
										}
									/>
									<SearchSharpIcon className="search-icon"></SearchSharpIcon>
								</InputGroup>
							</div>
						</div>

						<div className="col-sm-9 p-0">
							<div className="rightFilters">

								<div className="sort-filter-btn">
									{!this.state["type"] ? (
										<ViewModuleIcon
											variant="outline-secondary"

											onClick={() =>
												this.setState({ type: !this.state["type"] })
											}
										>
										</ViewModuleIcon>
									) : (
										<DehazeIcon
											variant="outline-secondary"

											onClick={() =>
												this.setState({ type: !this.state["type"] })
											}
										>
										</DehazeIcon>
									)}
								</div>

								<Form.Group controlId="exampleForm.ControlSelect1"  onChange={(e) => this.setState({ limit: parseInt(e.target.value, 10), activePage: 1 }, () => this.load())} className="d-inline-block">
									<Form.Control as="select" className="mr-2 btn">
										<option value="10">10</option>
										<option value="25">25</option>
										<option value="50">50</option>
										<option value="100">100</option>
									</Form.Control>
								</Form.Group>
							</div>
						</div>
					</div>
				</div>
				<div className="contentbar">
					{this.state["type"] ? (
						<div className="tableContainerWrapInner">
							<Table responsive>
								<thead className="bg-light">
									<tr>
										<th>Name</th>
										<th>Email</th>
										<th>Mobile</th>
										<th>Score</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									{this.state.evs && this.state.evs.length ? (
										this.state.evs.map((e, index) => {
											return (
												<EVSTable
													evs={e}
													key={index}
													nextPath={this.nextPath}
												></EVSTable>
											);
										})
									) : (
										<h6>No EVS Record</h6>
									)}
								</tbody>
							</Table>
						</div>
					) : (
						<div className="fixcalcHeight">
							<div className="evsWrapper">
								{this.state.evs && this.state.evs.length ? (
									this.state.evs.map((e, index) => {
										return (
											<div className="evsGrid">
												<EVSGrid
													evs={e}
													key={index}
													nextPath={this.nextPath}
												></EVSGrid>
											</div>

										);
									})
								) : (
									<h6>No EVS Record</h6>
								)}
							</div>
						</div>
					)}
					<div className="pagination-Wrap">
						<div className="row">
							<div className="col-md-6">
								{!this.state.loading ? (
									<Pagination
										itemClass="page-item"
										linkClass="page-link"
										activePage={this.state.activePage}
										itemsCountPerPage={this.state.limit}
										totalItemsCount={this.state.count}
										onChange={(pageNumber) =>
											this.setState({ activePage: pageNumber }, () => this.load())
										}
									/>
								) : (
									<Loader></Loader>
								)}
							</div>
							<div className="col-md-6 text-right d-flex align-items-center footer-pg">
								<div className="w-100 pgCount"><p> {this.state.count} Templates Available</p> </div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
