import React, { Component } from 'react';
import { Card } from '@mui/material';
import { toast } from 'react-toastify';
import { Modal, Row } from 'react-bootstrap';
import CheckSharpIcon from '@material-ui/icons/CheckSharp';
import InfiniteScroll from 'react-infinite-scroller';
import axios from '../../../axios';
import apiError from '../../../error';
import './import-email-from-store.css';

export default class ImportEmailFromStore extends Component {
    constructor(props) {
        super(props);
        this.state = {
            templates: [],
            hasMore: true,
            page: 1,
            selectedTemplateId: null,
        };
    }

    loadEmailTemplates = () => {
        const { page } = this.state;
        axios.post(`/api/store/list-emails`, { page })
            .then(response => response.data)
            .then(responseData => {
                if (responseData && responseData.response) {
                    this.setState(prevState => ({
                        templates: [...prevState.templates, ...responseData.response],
                        hasMore: responseData.response.length >= 10,
                        page: prevState.page + 1
                    }));
                } else {
                    this.setState({ hasMore: false });
                }
            })
            .catch(apiError);
    }

    componentDidMount() {
        this.loadEmailTemplates();
    }

    loadHtml = (templateId) => {
        axios.get(`/api/store/fetch-email/${templateId}`)
            .then(response => response.data)
            .then(responseData => {
                if (responseData && responseData.response) {
                    const { html, subject, emailTracking, attachments } = responseData.response;
                    const attachmentNames = attachments.map(attachment => attachment.filename);
                    this.props.handleLoadSelectedStoreTemplateHtml(html, subject, emailTracking, attachmentNames);
                    this.props.handleClose();
                } else {
                    toast.error('Template HTML not found');
                }
            })
            .catch(apiError);
    }

    handleCardClick = (templateId) => {
        this.setState({ selectedTemplateId: templateId });
        this.loadHtml(templateId);
    }

    render() {
        const { templates, hasMore, selectedTemplateId } = this.state;
        const { showImportModal, handleClose } = this.props;

        return (
            <Modal className="info-filter emailRight right" style={{ width: '500px', maxHeight: '80vh', overflowY: 'auto' }} show={showImportModal} onHide={handleClose} autoFocus keyboard>
                <Modal.Header closeButton>
                    <Modal.Title>Email Template</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={this.loadEmailTemplates}
                        hasMore={hasMore}
                        loader={<div className="loader" key={0}>Infinite scroller loading ...</div>}
                        useWindow={false}
                    >
                        <Row className='emailCard'>
                            {templates.map((template, index) => (
                                <div key={index} className='col-md-4'>
                                    <Card 
                                        style={{ 
                                            position: 'relative', 
                                            cursor: 'pointer',
                                            boxShadow: selectedTemplateId === template.id ? '0 5px 10px rgba(0, 0, 0, 0.3)' : '0 1px 3px rgba(0, 0, 0, 0.2)',
                                            transform: selectedTemplateId === template.id ? 'scale(0.98)' : 'scale(1)',
                                            transition: 'transform 0.1s, box-shadow 0.1s'
                                        }} 
                                        onClick={() => this.handleCardClick(template.id)}
                                    >
                                        <img crossOrigin="anonymous" alt="no image" src={template.thumbnail} />
                                        <div style={{ marginTop: '8px' }}>
                                            <h5>{template.name}</h5>
                                            <p>Email Tracking: {template.emailTracking ? 'Yes' : 'No'}</p>
                                        </div>
                                    </Card>
                                </div>
                            ))}
                        </Row>
                    </InfiniteScroll>
                </Modal.Body>
            </Modal>
        );
    }
}
