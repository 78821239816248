import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import LandingURLList from './landing-url-list/landing-url-list';
import LandingURLEdit from './landing-url-edit/landing-url-edit';
import LandingURLPreview from './landing-url-preview/landing-url-preview';

export default class LandingURL extends Component {
	render() {	
        const { match } = this.props;
		return (
            <Switch>
                <Route path={`${match.path}/`} exact component={ LandingURLList }/>
                <Route path={`${match.path}/:urlId`} exact component={ LandingURLEdit }/>
                <Route path={`${match.path}/preview/:urlId`} exact component={ LandingURLPreview }/>
                <Route render={() => (<Redirect to="/invalid" />)}/>
            </Switch>
		);
	}
}