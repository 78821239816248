import { Modal } from 'react-bootstrap';
import React, { Component } from 'react';
import TreeView from 'react-simple-jstree';

export default class GroupsHierarchy extends Component {

    render() {
        return (
            <Modal className="info-filter right" style={{width: '500px'}} show={this.props.showHierarchy} onHide={this.props.handleClose} autoFocus keyboard>
                <Modal.Header closeButton>
                    <Modal.Title> Groups Hierarchy </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <TreeView 
                        treeData={{ core: { data: this.props.finalArray }, 'themes' : {
                            'responsive': false
                        },}} 
                        onChange={(e, data) => this.props.nextPath(`/department/groups/${data.selected}`)}
                    ></TreeView>
                </Modal.Body>
            </Modal>
        );
    }
}
