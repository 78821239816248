import swal from 'sweetalert';
import React, { Component } from "react";
import { Card, Breadcrumb, Button, Spinner } from 'react-bootstrap';

import './landing-url-preview.css';
import axios from '../../../axios';
import apiError from '../../../error';
import Loader from "../../../component/loader/loader";

export default class Landing_URL_Preview extends Component {
	constructor(props) {
        super(props);
        this.state = { 
			loading: false,
			'index-1': '',
			'index-2': '',
			'index-3': '',
			urlId: parseInt(this.props.match.params.urlId, 10),
		}
	}
	
	addToCloudFlayer = async(index) => {
        if(this.state.urlId){
			var keys = ['mail_cname', 'dkim1', 'dkim2'];
			await this.setState({ [`index-${index}`]: 'pending'})
            if(keys && keys[index-1]){
				await axios.post(`/api/pages/addToCloudFlayer`, {
                    key: keys[index-1],
                    landingUrlId: this.state.urlId
                })
                .then(res => {
					if(index === keys.length){
						this.makeActiveDomain();
						this.setState({ [`index-${index}`]: 'done' })
					} else {
						this.setState({ [`index-${index}`]: 'done' })
						this.addToCloudFlayer(index + 1);
					}
                })
                .catch(err => {
                    apiError(err)
                })
            }
        }
	}
	
	makeActiveDomain = () => {
		swal({
            title: "Your Domain has been ready to use, click on yes and use it.",
            icon: "warning",
            buttons: [ 'No', 'Yes' ],
            dangerMode: true,
        })
        .then(async willSuccess => {
            if (willSuccess) {
				await this.setState({ loading: true })
				axios.post(`/api/pages/make-active`, {
					landingUrlId: this.state.urlId
				})
				.then(res => {
					this.props.history.push('/landing-url');
					this.setState({ loading: false });
				})
				.catch(err => {
					apiError(err);
					this.setState({ loading: false })
				})
			}
        })
	}

	render() {
		return (
			<div className="content-wrap">
				<div className="breadcrumbbar">
					<div className="row">
						<div className="col-12">
							<div className="page-head d-flex align-items-center justify-content-between">
								<h4 className="page-title">Landing URL Preview</h4>
								<Breadcrumb className="mb-0">
									<Breadcrumb.Item href="#" active>Landing URL Preview</Breadcrumb.Item>
								</Breadcrumb>
							</div>
						</div>
					</div>
				</div>
				<Card>
					<Card.Body>
						<div className="steps_progress">
							<div className='wrapper'>
								<div className='steps' id='steps'>
									<div className='step'>
										{this.state["index-1"] === "done"?
											<div className='number completed'>
												<svg viewBox="0 0 512 512" width="100" title="check">
													<path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" />
												</svg> 
											</div>:
											this.state["index-1"] === "pending"? <div className='number'><Spinner animation="border" variant="warning" /></div>: 
											<div className='number'>
												 
											</div>
										}
										<div className='info'>
											<p className='title'> Adding SPF Record</p>
											<p className='text'>If your domain is added to Cloudflare via one of our hosting partners, manage your DNS records via the hosting partner. In this case, the Cloudflare DNS app informs customers to manage DNS outside of Cloudflare..</p>
										</div>
									</div>

									<div className='step'>
										{this.state["index-2"] === "done"?
											<div className='number completed'>
												<svg viewBox="0 0 512 512" width="100" title="check">
													<path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" />
												</svg> 
											</div>:
											this.state["index-2"] === "pending"? <div className='number'><Spinner animation="border" variant="warning" /></div>:
											<div className='number'>
												 
											</div>
										}
										<div className='info'>
											<p className='title'>Adding DKIM1 Record</p>
											<p className='text'>If your domain is added to Cloudflare via one of our hosting partners, manage your DNS records via the hosting partner. In this case, the Cloudflare DNS app informs customers to manage DNS outside of Cloudflare.</p>
										</div>
									</div>


									<div className='step'>
										{this.state["index-3"] === "done"?
											<div className='number completed'>
												<svg viewBox="0 0 512 512" width="100" title="check">
													<path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" />
												</svg> 
											</div>:
											this.state["index-3"] === "pending"? <div className='number'><Spinner animation="border" variant="warning" /></div>	:
											<div className='number'>
												 
											</div>
										}
										<div className='info'>
											<p className='title'>Adding DKIM2 Record</p>
											<p className='text'>If your domain is added to Cloudflare via one of our hosting partners, manage your DNS records via the hosting partner. In this case, the Cloudflare DNS app informs customers to manage DNS outside of Cloudflare.</p>
										</div>
									</div>
								</div>
							</div>
							<div className="continue_btn">
								<Button variant="success" onClick={e => this.addToCloudFlayer(1)}>Upload Details</Button>
							</div>
						</div>
						{this.state.loading? <Loader></Loader>: null}
					</Card.Body>
				</Card>
			</div>
		);
	}
}