import React, { Component } from 'react';
import { toast } from "react-toastify";
import Tooltip from 'react-bootstrap/Tooltip';
import { Button, Form } from 'react-bootstrap';
import AddSharpIcon from "@material-ui/icons/AddSharp";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import DoneAllSharpIcon from '@material-ui/icons/DoneAllSharp';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';

import axios from '../../../axios';
import apiError from '../../../error';
import TestEmailModal from '../attacker-profile/testemail';
import { getCookie } from '../../../function';
import Spinner from '../../../component/spinner/spinner';

export default class AttachmentProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            host: '',
            data: {},
            email: '',
            show: false,
            username: '',
            loading: false,
            isLoaded: false,
            password: '*****',
            allDisabled: false,
            billingType: getCookie('billingType'),

            domains: [],
            senderName: '',
            domainName: '',
            senderEmailName: '',
        }
    }

    componentDidMount = () => {
        this.load();
        this.loadDomain();
    }

    load = () => {
        if (this.props.senderId && this.props.senderId > 0) {
            axios.get('/api/senders/' + this.props.senderId, {})
                .then(res => {
                    let domainName = '', senderEmailName = '', senderName = '';
                    try {
                        if (res.data.sender.from_address && res.data.sender.from_address.split("@")[1]) {
                            senderEmailName = res.data.sender.from_address.split("@")[0];
                            domainName = res.data.sender.from_address.split("@")[1];
                            if (domainName.indexOf('>') > -1) {
                                domainName = domainName.split('>')[0];
                            }
                        }
                        if (senderEmailName && senderEmailName.length && (senderEmailName).indexOf('<') > -1) {
                            senderName = senderEmailName.split("<")[0];
                            senderEmailName = senderEmailName.split("<")[1]
                        }
                    } catch (err) { }
                    this.setState({
                        senderName: senderName,
                        domainName: domainName,
                        host: res.data.sender.host,
                        senderEmailName: senderEmailName,
                        username: res.data.sender.username ? res.data.sender.username : ''
                    })
                    this.loadDomain()
                })
                .catch(error => apiError(error))
        } else {
            this.loadDomain();
        }
    }

    validateSenderCustom = async () => {
        if (this.state.senderEmailName.length === 0) {
            toast.error("please enter sender Name");
            return null;
        }
        if (this.state.domainName.length === 0) {
            toast.error("please select domain");
            return null;
        }
        if (!this.state.host || this.state.host.length === 0 || (this.state.host).trim().length === 0) {
            toast.error("please enter smtp host");
            return null;
        }
        var from_address = `${this.state.senderEmailName}@${this.state.domainName}`

        if (this.state.senderName.length > 0) {
            from_address = `${this.state.senderName}<${from_address}>`
        }
        const data = {
            host: this.state.host,
            from_address: from_address,
            campaignTemplateId: this.props.campaignTemplateId
        }
        if (this.state.password !== "*****") {
            data['password'] = this.state.password;
            data['username'] = this.state.username;
            if (!this.state.username || this.state.username.length === 0) {
                toast.error("please enter username");
                return null;
            }
        }
        return data;
    }

    validateSenderAutomatic = () => {
        if (this.state.senderEmailName.length === 0) {
            toast.error("please enter sender Name");
            return null;
        }
        if (this.state.domainName.length === 0) {
            toast.error("please select domain");
            return null;
        }
        var from_address = `${this.state.senderEmailName}@${this.state.domainName}`

        if (this.state.senderName.length > 0) {
            from_address = `${this.state.senderName}<${from_address}>`
        }
        const data = {
            from_address: from_address,
            campaignTemplateId: this.props.campaignTemplateId
        }
        return data;
    }

    handleCreateSender = async () => {
        if (this.props.senderType === 'custom') {
            let data = await this.validateSenderCustom();
            if (data) {
                await this.setState({ loading: true })
                if (this.props.senderId) {
                    data['username'] = this.state.username;
                    axios.patch('/api/senders/custom/' + this.props.senderId, data)
                        .then(res => {
                            this.setState({ loading: false })
                            this.props.handleTabChange('next');
                        })
                        .catch(error => {
                            this.setState({ loading: false })
                            apiError(error)
                        })
                } else {
                    axios.post('/api/senders/custom/', data)
                        .then(res => {
                            this.setState({ loading: false })
                            this.props.handleTabChange('next');
                            this.props.handleSenderId(res.data.sender.id);
                        })
                        .catch(error => {
                            this.setState({ loading: false })
                            apiError(error)
                        })
                }
            }
        } else {
            let data = this.validateSenderAutomatic()
            if (data) {
                this.setState({ loading: true })
                if (this.props.senderId) {
                    axios.patch('/api/senders/automatic/' + this.props.senderId, data)
                        .then(res => {
                            this.setState({ loading: false })
                            this.props.handleTabChange('next');
                        })
                        .catch(error => {
                            this.setState({ loading: false })
                            apiError(error)
                        })
                } else {
                    axios.post('/api/senders/automatic/', data)
                        .then(res => {
                            this.setState({ loading: false })
                            this.props.handleTabChange('next');
                            this.props.handleSenderId(res.data.sender.id);
                        })
                        .catch(error => {
                            this.setState({ loading: false })
                            apiError(error)
                        })
                }
            }
        }
    }

    sendTestMail = async () => {
        if (this.state.email.length === 0) {
            toast.error("please enter email Id");
            return;
        }
        if (this.props.senderType === 'custom') {
            let data = await this.validateSenderCustom();
            if (data) {
                data['email'] = this.state.email;
                data['senderId'] = this.state.senderId;
                data['username'] = this.state.username;
                this.setState({ loading: true })
                axios.post('/api/senders/custom/test', data)
                    .then(res => {
                        this.setState({ show: false, loading: false })
                        toast.success('test email send successfully')
                    })
                    .catch(error => {
                        this.setState({ loading: false })
                        apiError(error)
                    })
            }
        } else {
            let data = this.validateSenderAutomatic();
            if (data) {
                data['email'] = this.state.email;
                data['senderId'] = this.state.senderId;
                this.setState({ loading: true })
                axios.post('/api/senders/automatic/test', data)
                    .then(res => {
                        this.setState({ show: false, loading: false })
                        toast.success('test email send successfully')
                    })
                    .catch(error => {
                        this.setState({ loading: false })
                        apiError(error)
                    })
            }
        }
    }

    loadDomain = () => {
        axios.get('/api/pages/url', {})
            .then(res => {
                let domains = [], landing_urls = res.data.landing_urls;
                for (let i = 0; i < landing_urls.length; i++) {
                    if (!(landing_urls[i].name).startsWith('http')) {
                        domains.push(landing_urls[i].name)
                    }
                }
                if (domains.indexOf(this.state.domainName) === -1) {
                    domains.push(this.state.domainName);
                }
                this.setState({ domains: domains })
            })
            .catch(error => {
                apiError(error)
            })
    }

    render() {
        return (
            <>
            <div className="tabContent-block email-template-wrap">
                <div className='rightSectionHeader'>
                    <div className="row justify-content-between d-flex align-items-center mb-3">
                        <div className="col-md-6 tab-right-header">
                            <h6>Create An Attack Profile</h6>
                            <p>Create email template</p>
                        </div>
                        <div className="col-md-6 text-right">
                            <div className="d-flex float-right">
                            {this.state.billingType === "Threatcop" ? <div className="mr-2 mt-1"> <Form.Check type="switch" onChange={this.props.handleSenderType} checked={this.props.senderType === "custom"} id="custom-switch" label="Custom" /> </div> : null}
                            <Button variant="primary" type="button" onClick={e => this.setState({ show: true })}> <AddSharpIcon className="btn-icon mr-2 ml-2"></AddSharpIcon> Send Test Email </Button>

                            </div>
                        </div>
                    </div>
                </div>  


                <div className='attackCard'>
                    <div className="warningCard">
                            <h5><b>Note Whitelisting IP:</b> You will have to whitelist TSAT's IP addresses, hostnames and email domains
                             to make sure that the simulated phishing emails are successfully delivered to the users' inboxes.  <Button className="readBtn" variant="link" href="https://helpdesk.kratikal.com/portal/en/kb/threatcop/whitelisting" target="_blank" rel="noopener noreferrer">Read more</Button></h5>
                            <ul>
                                <li>
                                     IP 1 : 168.245.74.19
                                </li>
                                <li>
                                IP 2 : 35.154.207.235
                                </li>
                            </ul>
                        </div>
                </div>





                <div className="attackCard">
                     <div className='midCard'>
                            <div className='headerPara'>
                                <h5>Sender Name</h5>
                                <p className='lead'><span className='red-star'>*</span> This name will be displayed in the user's inbox. If left blank, the sender email address will be shown instead.</p> 
                            </div>
                            <Form.Control type="text" className='widthSet' placeholder="Enter sender name" value={this.state.senderName} onChange={e => this.setState({ senderName: e.target.value })} />
                    </div>
                 </div>


                 <div className="attackCard">
                        <div className='midCard'>
                            <div className='headerPara'>
                                <h5>Sender Domain</h5>
                                    <p className='lead'><span className='red-star'>*</span> This name will be displayed in the user's inbox. If left blank, the sender email address will be shown instead.</p>
                                </div>

                                  <div className='radioCheck'>
                                    <Form.Group >
                                        <Form.Check className='dFlex' type="radio" label="Defult" name="formHorizontalRadios"
                                        id="formHorizontalRadios1"
                                        />
                                        <Form.Check type="radio" className='dFlex' label="Custom" name="formHorizontalRadios"
                                        id="formHorizontalRadios2"
                                        />
                                    
                                    </Form.Group>
                                    </div>

                                    <div className="domainWrap mb-3">
                                        <Form.Group className='mb-0'>
                                            <Form.Label className='form-label'>Enter Sender Name </Form.Label>
                                            
                                                  <Form.Control type="text" placeholder="Enter sender name" value={this.state.senderEmailName} onChange={e => this.setState({ senderEmailName: e.target.value })} />
                                            
                                        </Form.Group>
                                        <p style={{ margin: '34px 10px 10px 10px' }}>@</p>
                                        <Form.Group className='mb-0'>
                                            <Form.Label className='form-label'>Select Domain</Form.Label>
                                            <Form.Control as="select" value={this.state.domainName} onChange={e => this.setState({ domainName: e.target.value })}>
                                            <option value=''>Select Domain for Sender</option>
                                            {this.state.domains.map((domain, index) => <option value={domain} key={index}>{domain}</option>)}
                                        </Form.Control>
                                        </Form.Group>
                                </div>

                                {this.props.senderType === 'custom' ?
                            <>
                                <Form.Group controlId="host">
                                    <Form.Label>Host
                                        <OverlayTrigger
                                            placement='top'
                                            overlay={<Tooltip id='top'>Enter the server name for your SMTP server.</Tooltip>}>
                                            <HelpOutlineIcon className="tooltip-icon"></HelpOutlineIcon>
                                        </OverlayTrigger>
                                    </Form.Label>
                                    <Form.Control className='widthSet' type="text" disabled={this.state.allDisabled} placeholder="smtp.example.com" value={this.state.host} onChange={e => this.setState({ host: e.target.value })} />
                                </Form.Group>
                                <Form.Group controlId="formPlaintextEmail">
                                    <Form.Label>Username
                                        <OverlayTrigger
                                            placement='top'
                                            overlay={<Tooltip id='top'>This mode help you to classify a Sender ID</Tooltip>}>
                                            <HelpOutlineIcon className="tooltip-icon"></HelpOutlineIcon>
                                        </OverlayTrigger>
                                    </Form.Label>
                                    <Form.Control className='widthSet' type="text" disabled={this.state.allDisabled} autoComplete="new-user" placeholder="Username" value={this.state.username} onChange={e => this.setState({ username: e.target.value })} />
                                </Form.Group>
                                <Form.Group controlId="formPlaintextPassword">
                                    <Form.Label>Password
                                        <OverlayTrigger
                                            placement='top'
                                            overlay={<Tooltip id='top'>This mode help you to classify a Sender ID</Tooltip>}>
                                            <HelpOutlineIcon className="tooltip-icon"></HelpOutlineIcon>
                                        </OverlayTrigger>
                                    </Form.Label>
                                    <Form.Control className='widthSet' type="password" disabled={this.state.allDisabled} autoComplete="new-password" placeholder="*****" value={this.state.password} onChange={e => this.setState({ password: e.target.value })} />
                                </Form.Group>
                            </> : ''
                        }

                        </div>
                    </div>
                 

                {/* <div className="row">
                    <div className="col-md-6">
                        <Form.Group controlId="from">
                            <div className="row">
                                <Form.Control type="text" placeholder="Enter sender name" value={this.state.senderName} onChange={e => this.setState({ senderName: e.target.value })} />
                                <Form.Label style={{ fontSize: '11px' }}>This name will be displayed in the user's inbox. If left blank, the sender email address will be shown instead.</Form.Label>
                                <div className="col-sm-5 p-0 mt-2">
                                    <Form.Group>
                                        <Form.Control type="text" placeholder="Enter sender name" value={this.state.senderEmailName} onChange={e => this.setState({ senderEmailName: e.target.value })} />
                                    </Form.Group>
                                </div>
                                <div className="col-md-1 set_width p-0 mt-2"><p>@</p></div>
                                <div className="col-sm-6 p-0 mt-2">
                                    <Form.Group>
                                        <Form.Control as="select" value={this.state.domainName} onChange={e => this.setState({ domainName: e.target.value })}>
                                            <option value=''>Select Domain for Sender</option>
                                            {this.state.domains.map((domain, index) => <option value={domain} key={index}>{domain}</option>)}
                                        </Form.Control>
                                    </Form.Group>
                                </div>

                            </div>
                        </Form.Group>
                        {this.props.senderType === 'custom' ?
                            <>
                                <Form.Group controlId="host">
                                    <Form.Label>Host
                                        <OverlayTrigger
                                            placement='top'
                                            overlay={<Tooltip id='top'>Enter the server name for your SMTP server.</Tooltip>}>
                                            <HelpOutlineIcon className="tooltip-icon"></HelpOutlineIcon>
                                        </OverlayTrigger>
                                    </Form.Label>
                                    <Form.Control type="text" disabled={this.state.allDisabled} placeholder="smtp.example.com" value={this.state.host} onChange={e => this.setState({ host: e.target.value })} />
                                </Form.Group>
                                <Form.Group controlId="formPlaintextEmail">
                                    <Form.Label>Username
                                        <OverlayTrigger
                                            placement='top'
                                            overlay={<Tooltip id='top'>This mode help you to classify a Sender ID</Tooltip>}>
                                            <HelpOutlineIcon className="tooltip-icon"></HelpOutlineIcon>
                                        </OverlayTrigger>
                                    </Form.Label>
                                    <Form.Control type="text" disabled={this.state.allDisabled} autoComplete="new-user" placeholder="Username" value={this.state.username} onChange={e => this.setState({ username: e.target.value })} />
                                </Form.Group>
                                <Form.Group controlId="formPlaintextPassword">
                                    <Form.Label>Password
                                        <OverlayTrigger
                                            placement='top'
                                            overlay={<Tooltip id='top'>This mode help you to classify a Sender ID</Tooltip>}>
                                            <HelpOutlineIcon className="tooltip-icon"></HelpOutlineIcon>
                                        </OverlayTrigger>
                                    </Form.Label>
                                    <Form.Control type="password" disabled={this.state.allDisabled} autoComplete="new-password" placeholder="*****" value={this.state.password} onChange={e => this.setState({ password: e.target.value })} />
                                </Form.Group>
                            </> : ''
                        }
                    </div>
                   
                </div> */}
                </div>


                   <div className="row mt-2">
                        <div className='footer-wrapper justify-content-end d-flex'>
                        <Button variant="secondary" className="mr-2" onClick={e => this.props.handleTabChange('prev')}><ArrowBackOutlinedIcon className="btn-icon"></ArrowBackOutlinedIcon> Previous</Button>
                        <Button variant="success" disabled={this.state.loading} type="button" onClick={this.handleCreateSender}><Spinner loading={this.state.loading}></Spinner><ArrowForwardOutlinedIcon className={this.state.loading ? "btn-icon d-none" : "btn-icon"}></ArrowForwardOutlinedIcon> Go To Next</Button>
                        </div>
                  </div>

                <TestEmailModal
                    show={this.state.show}
                    email={this.state.email}
                    loading={this.state.loading}
                    sendTestMail={this.sendTestMail}
                    handleEmail={e => this.setState({ email: e.target.value })}
                    handleEmailModal={e => this.setState({ show: !this.state.show })}
                ></TestEmailModal>
        </>
        )
    }
}