import React, { Component } from "react";
import swal from "sweetalert";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";

import SearchSharpIcon from "@material-ui/icons/SearchSharp";
import CachedSharpIcon from "@material-ui/icons/CachedSharp";
import ArrowBackSharpIcon from "@material-ui/icons/ArrowBackSharp";
import { PiArrowBendUpRight, PiXThin, PiDownloadSimple } from "react-icons/pi";
import { Tab, Tabs, Button, Card, Form, InputGroup, FormControl, Accordion, ProgressBar, Table, Dropdown } from "react-bootstrap";

import { getDate } from "../../../function"
import Breach from "../../../assets/breach.png";
import Loader from "../../../component/loader/loader";
import Error from "../../../component/campaigns/charts/error";
import Total from "../../../component/campaigns/charts/total";
import SMSSend from "../../../component/campaigns/charts/sms-send";
import Results from "../../../component/campaigns/results/results";
import EmailSend from "../../../component/campaigns/charts/email-send";
import SystemHack from "../../../component/campaigns/charts/system-hack";
import CallPicked from "../../../component/campaigns/charts/call-picked";
import MessageSent from "../../../component/campaigns/charts/message-send";
import EmailOpened from "../../../component/campaigns/charts/email-opened";
import LinkClicked from "../../../component/campaigns/charts/link-clicked";
import DataSubmitted from "../../../component/campaigns/charts/data-submitted";
import EmailReported from "../../../component/campaigns/charts/email-reported";
import CallInitiated from "../../../component/campaigns/charts/call-initiated";
import DetailModal from "../../../component/campaigns/detail-modal/detail-modal";
import AttachmentOpened from "../../../component/campaigns/charts/attachment-opened";
import DetailModalLMS from "../../../component/campaigns/detail-modal/detail-modal-lms";

import axiosRequest from "axios";
import axios from "../../../axios";
import apiError from "../../../error";

import "./campaign.css";
import Replied from "../../../component/campaigns/charts/replied";

var cancelRequest = undefined,
	CancelToken = axiosRequest.CancelToken;

export default class CampaignEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			count: 0,
			limit: 10,
			value: [],
			status: {},
			search: "",
			message: {},
			results: [],
			details: {},
			groupName: [],
			category: [],
			activity: "",
			loading: true,
			activePage: 1,
			attackType: "",
			incedent: 0,
			activeId: null,
			breachTime: 0,
			detailModal: false,
			expiredProgress: 0,
			statusFilter: "all",
			simulationError: false,
			disabledClosedBtn: false,
			isRepliedCampaign: false,
			campaignId: parseInt(this.props.match.params.campaignId, 10),
			simulationId: parseInt(this.props.match.params.simulationId, 10),

			lmsCount: 0,
			lmsLimit: 10,
			isLMS: false,
			lmsSearch: "",
			lmsStatus: {},
			previewURL: "",
			lmsResults: [],
			lmsCampaign: {},
			lmsActivePage: 1,
			awarenessId: null,
			isScheduledLMS: true,
			detailModalLMS: false,
			lmsStatusFilter: "all",
			statusClass: {
				"Email Sent": "badge badge-pill badge-soft-primary",
				Pending: "badge badge-pill badge-soft-primary",
				"Email Opened": "badge badge-pill badge-soft-success",
				"Link Clicked": "badge badge-pill badge-soft-warning",
				Error: "badge badge-pill badge-soft-danger",
			},
		};
	}

	componentDidMount = () => {
		if (this.state.simulationId) this.handleRefresh();
		else this.getSimulationId();
	};

	secondsToHms = (d) => {
		d = Number(d);
		var h = Math.floor(d / 3600);
		var m = Math.floor((d % 3600) / 60);
		var s = Math.floor((d % 3600) % 60);

		var hDisplay = h > 0 ? (h < 10 ? "0" : "") + h + " : " : "00 : ";
		var mDisplay = m > 0 ? (m < 10 ? "0" : "") + m + " : " : "00 : ";
		var sDisplay = s > 0 ? (s < 10 ? "0" : "") + s + "" : "00";
		return hDisplay + mDisplay + sDisplay;
	};

	days_between = (date1, date2, Expired) => {
		const ONE_DAY = 1000 * 60 * 60 * 24;
		const differenceMs = Math.abs(date1 - date2);
		var day = Math.round(differenceMs / ONE_DAY);
		day = day ? day : 1;
		day = day > 7 ? 100 : (day * 100) / Expired;
		day = day > 100 ? 100 : day;
		return day;
	};

	getSimulationId = () => {
		if (!this.state.simulationId) {
			axios
				.get("/api/simulation/simulationId", {
					params: { campaignId: this.state.campaignId },
				})
				.then((res) => {
					this.setState({ simulationId: res.data.simulationId, loading: false }, () => {
						this.handleRefresh();
					});
				})
				.catch((error) => {
					setTimeout(() => {
						this.getSimulationId();
					}, 3000);
				});
		} else {
			this.handleRefresh();
		}
	};

	getDetails = () => {
		axios
			.get("/api/campaigns/details", {
				params: { campaignId: this.state.campaignId, simulationId: this.state.simulationId },
			})
			.then((res) => {
				var expiredProgress = this.days_between(new Date(), new Date(res.data.details.scheduledAt), res.data.Expired);
				this.setState({ details: res.data.details, expiredProgress: expiredProgress, groupName: res.data.details.campaign.campaign_groups });
				if (res.data.details && res.data.details.campaign && res.data.details.campaign.isLMS) {
					this.setState({
							isLMS: true,
							awarenessId: res.data.details.awarenessId,
							isScheduledLMS: res.data.details.isScheduledLMS,
						},
						() => this.handleRefreshLMS()
					);
				}
			})
			.catch((error) => apiError(error));
	};

	handleRefresh = () => {
		this.setState({ simulationError: false });
		this.load();
		this.loadTotal();
		this.getDetails();
		this.loadBreachAndIncident();
	};

	handleRefreshLMS = () => {
		this.loadTraining();
		this.loadTrainingResult();
	};

	loadTotal = () => {
		axios
			.get("/api/simulation/", {
				params: {
					campaignId: this.state.campaignId,
					simulationId: this.state.simulationId,
				},
			})
			.then((res) => {
				if (res.data.simulation) {
					var status = {};
					if (res.data.simulation.status) {
						for (var i = 0; i < res.data.simulation.status.length; i++) {
							var data = res.data.simulation.status[i];
							status[data["message"]] = parseInt(data["count"], 10) ? parseInt(data["count"], 10) : 0;
						}
					}
					this.setState({
						status: status,
						loading: false,
						isRepliedCampaign: res.data.simulation.isRepliedCampaign,
						disabledClosedBtn: res.data.simulation.completedAt ? true : false,
						attackType: res.data.simulation.campaign && res.data.simulation.campaign.attack_type ? res.data.simulation.campaign.attack_type.name : "",
					});
				}
			})
			.catch((error) => {
				apiError(error);
			});
	};

	load = async () => {
		this.setState({ loading: true });
		if (cancelRequest) cancelRequest();
		axios
			.get("/api/results", {
				params: {
					limit: this.state.limit,
					search: this.state.search,
					page: this.state.activePage,
					filter: this.state.statusFilter,
					campaignId: this.state.campaignId,
					simulationId: this.state.simulationId,
				},
				cancelToken: new CancelToken(function executor(c) {
					cancelRequest = c;
				}),
			})
			.then(async (res) => {
				await this.setState({
					loading: false,
					count: res.data.count,
					results: res.data.results,
				});
			})
			.catch((error) => {
				if (!axiosRequest.isCancel(error)) {
					this.setState({ campaigns: [], count: 0, loading: false });
					apiError(error);
				}
			});
	};

	handleFilterEventCsv = () => {
		axios
			.get("/api/results/eventcsv", {
				params: {
					campaignId: this.state.campaignId,
					simulationId: this.state.simulationId,
					filter: this.state.statusFilter && this.state.statusFilter !== "all" ? this.state.statusFilter : null,
					tzOffset: new Date().getTimezoneOffset() !== 0 ? new Date().getTimezoneOffset() : 0,
				},
			})
			.then((res) => {
				const link = document.createElement("a");
				link.target = "_blank";
				link.download = res.data.campaignName;
				link.href = res.data.filename;
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			})
			.catch((err) => {
				apiError(err);
			});
	};

	handleFilterCsv = () => {
		axios
			.get("/api/results/csv", {
				params: {
					campaignId: this.state.campaignId,
					simulationId: this.state.simulationId,
					filter: this.state.statusFilter && this.state.statusFilter !== "all" ? this.state.statusFilter : null,
				},
			})
			.then((res) => {
				const link = document.createElement("a");
				link.target = "_blank";
				link.download = res.data.campaignName;
				link.href = res.data.filename;
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			})
			.catch((err) => {
				apiError(err);
			});
	};

	loadBreachAndIncident = () => {
		axios
			.get("/api/results/breach/incedent", {
				params: {
					campaignId: this.state.campaignId,
					simulationId: this.state.simulationId,
				},
			})
			.then((res) => {
				if (res.data.result_be) {
					this.setState({ breachTime: this.secondsToHms(res.data.result_be.breachTime) });
				}
				if (res.data.result_in) {
					this.setState({ incedent: this.secondsToHms(res.data.result_in.incidentTime) });
				}
			})
			.catch((err) => {
				apiError(err);
			});
	};

	handleResend = () => {
		swal({
			title: "Do you want to Resend Mail For Pending User?",
			icon: "info",
			buttons: ["No", "Yes"],
			dangerMode: true,
		}).then((willSuccess) => {
			if (willSuccess) {
				this.setState({ loading: true });
				axios
					.post("/api/simulation/resend", {
						campaignId: this.state.campaignId,
						simulationId: this.state.simulationId,
					})
					.then((r) => {
						this.setState({ loading: false });
						toast.success("Resend Mail in progress and results will be updated soon.");
					})
					.catch((error) => {
						apiError(error);
						this.setState({ loading: false });
					});
			}
		});
	};

	closeCampaign = () => {
		swal({
			text: "Ending your campaign will disable the tracking of all the actions and users will no longer be able to access the landing or drop off page",
			title: "Do you want to end campaign?",
			icon: "info",
			buttons: ["No", "Yes"],
			dangerMode: true,
		}).then((willSuccess) => {
			if (willSuccess) {
				this.setState({ loading: true });
				axios
					.post("/api/simulation/complete", {
						campaignId: this.state.campaignId,
						simulationId: this.state.simulationId,
					})
					.then((r) => {
						toast.success("Campaign close successfully");
						this.setState({ loading: false, disabledClosedBtn: true }, () => {
							this.getDetails();
						});
					})
					.catch((error) => {
						apiError(error);
						this.setState({ loading: false });
					});
			}
		});
	};

	handleActiveCampaign = (resultId) => {
		if (this.state.activeId === resultId) {
			this.setState({ activeId: "" });
		} else {
			this.setState({ activeId: resultId });
		}
	};

	loadTraining = async () => {
		this.setState({ loading: true });
		axios
			.get("/api/lms/campaign", {
				params: {
					awarenessId: this.state.awarenessId,
				},
			})
			.then((res) => {
				this.setState({ loading: false, lmsStatus: res.data.campaign });
			})
			.catch((error) => {
				this.setState({ loading: false });
			});
	};

	loadTrainingResult = async () => {
		axios
			.get("/api/lms/campaign-result", {
				params: {
					limit: this.state.lmsLimit,
					search: this.state.lmsSearch,
					page: this.state.lmsActivePage,
					filter: this.state.lmsStatusFilter,
					awarenessId: this.state.awarenessId,
				},
			})
			.then((res) => {
				this.setState({
					loading: false,
					previewURL: res.data.previewURL,
					lmsCount: res.data.campaign.count,
					lmsCampaign: res.data.campaign.data,
					lmsResults: res.data.campaign.memberList,
				});
			})
			.catch((error) => {
				this.setState({ loading: false });
			});
	};

	render() {
		let names = "";
		const groupName = this.state.groupName;
		groupName.map((element) => {
			let name = element.group.name
			names += name
			return name;
		})

		const filterableStatuses = {
			"Phishing": ["Email Sent", "Email Opened", "Link Clicked", "Data Submitted", "Replied", "Email Reported", "Pending"],
			"Ransomware": ["Email Sent", "Email Opened", "Link Clicked", "Email Reported", "System Hacked", "Replied", "Email Reported", "Pending"],
			"Smishing": ["SMS Sent", "Link Clicked", "Data Submitted", "Pending"],
			"Vishing": ["Call Initiated", "Call Picked", "Data Submitted", "Pending"],
			"WhatsApp": ["Message Sent", "Link Clicked", "Data Submitted", "Pending"],
			"Attachment": ["Email Sent", "Email Opened", "Attachment Opened", "Replied", "Email Reported", "Pending"],
			"QRcode": ["Email Sent", "Email Opened", "Link Clicked", "Data Submitted", "Replied", "Email Reported", "Pending"],
		}
		const TotalCount = this.state.status['Total'] ? this.state.status['Total'] : 1;

		return (
			<div className="content-wrap">
				<div className="breadcrumbbar">
					<div className="flexRow row">
						<div className="col-md-4">
							<div className="page-head d-flex align-items-center justify-content-between">
								<h4 className="page-title">
									<a onClick={e => this.props.history.push('/campaign')} className="bar_Icons mr-2"><ArrowBackSharpIcon className="btn-icon" />
									</a>Campaign & Analytics</h4>
							</div>
						</div>
						<div className="col-md-8 flexFilterButtons">
							<div className="btn-container float-right d-flex align-items-center">

								<Button variant="danger" disabled={this.state.disabledClosedBtn} style={this.state.disabledClosedBtn ? { pointerEvents: 'all', cursor: "not-allowed" } : {}} onClick={this.closeCampaign} className="mr-2"><PiXThin className="btn-icon" />End Campaign</Button>
								<Button variant="primary" onClick={e => this.props.history.push(`/campaign/reports/${this.state.campaignId}/${this.state.simulationId}`)}><PiDownloadSimple className="btn-icon" />Generate Report</Button>
							</div>
						</div>
					</div>
				</div>


				<div className="page-content tab-setting campInformation" id="tab-wrapper">
					<Tabs defaultActiveKey="campinfo" id="uncontrolled-tab-example">
						<Tab eventKey="campinfo" title="Campaign Information">
							<div className="camptabBtn">
								<div className="row">
									<div className="col-md-6">
										<div class="tabHeading">
											<h6>Campaign Metrics</h6>
											<p>Showing all campaign metrics</p>
										</div>
									</div>
									<div className="col-md-6 dFlex">
										<Button className="commonrefreshBtn mr-2" onClick={this.handleRefresh}><CachedSharpIcon className="btn-icon" /></Button>
										<Button variant="secondary" onClick={this.handleResend}><PiArrowBendUpRight className="btn-icon" />Resend Pending Emails</Button>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-12">
									<div className="mainCard campMetrics">
										<div className="row text-center justify-content-between py-3 m-0">
											{'Total' in this.state.status && this.state.attackType === "Removal Media" ?
												<div className="col-sm-2">
													<Total
														value={this.state.status['Total']}
														percent={100}
													></Total>
												</div> : ''
											}{'Email Sent' in this.state.status ?
												<div className="col-sm-2">
													<EmailSend
														value1={this.state.status['Email Sent']}
														value2={this.state.status['Error']}
														percent={((this.state.status['Email Sent'] * 100) / TotalCount).toFixed(2)}>
														</EmailSend>
												</div> : ''
											}{'SMS Sent' in this.state.status ?
												<div className="col-sm-2">
													<SMSSend
														value={this.state.status['SMS Sent']}
														percent={((this.state.status['SMS Sent'] * 100) / TotalCount).toFixed(2)}
													></SMSSend>
												</div> : ''
											}{'Message Sent' in this.state.status ?
												<div className="col-sm-2">
													<MessageSent
														value={this.state.status['Message Sent']}
														percent={((this.state.status['Message Sent'] * 100) / TotalCount).toFixed(2)}
													></MessageSent>
												</div> : ''
											}{'Call Initiated' in this.state.status ?
												<div className="col-sm-2">
													<CallInitiated
														value={this.state.status['Call Initiated']}
														percent={((this.state.status['Call Initiated'] * 100) / TotalCount).toFixed(2)}
													></CallInitiated>
												</div> : ''
											}{'Call Picked' in this.state.status ?
												<div className="col-sm-2">
													<CallPicked
														value={this.state.status['Call Picked']}
														percent={((this.state.status['Call Picked'] * 100) / TotalCount).toFixed(2)}
													></CallPicked>
												</div> : ''
											}{'Email Opened' in this.state.status ?
												<div className="col-sm-2">
													<EmailOpened
														value={this.state.status['Email Opened']}
														percent={((this.state.status['Email Opened'] * 100) / TotalCount).toFixed(2)}
													></EmailOpened>
												</div> : ''
											}{'Link Clicked' in this.state.status ?
												<div className="col-sm-2">
													<LinkClicked
														value={this.state.status['Link Clicked']}
														percent={((this.state.status['Link Clicked'] * 100) / TotalCount).toFixed(2)}
													></LinkClicked>
												</div> : ''
											}{'Attachment Opened' in this.state.status && (this.state.attackType === "Phishing" || this.state.attackType === "Ransomware" || this.state.attackType === "Attachment") ?
												<div className="col-sm-2">
													<AttachmentOpened
														value={this.state.status['Attachment Opened']}
														percent={((this.state.status['Attachment Opened'] * 100) / TotalCount).toFixed(2)}
													></AttachmentOpened>
												</div> : ''
											}
											{'Data Submitted' in this.state.status ?
												<div className="col-sm-2">
													<DataSubmitted
														value={this.state.status['Data Submitted']}
														percent={((this.state.status['Data Submitted'] * 100) / TotalCount).toFixed(2)}
													></DataSubmitted>
												</div> : ''
											}{'System Hacks' in this.state.status ?
												<div className="col-sm-2">
													<SystemHack
														value={this.state.status['System Hacks']}
														percent={((this.state.status['System Hacks'] * 100) / TotalCount).toFixed(2)}
													></SystemHack>
												</div> : ''
											}{'Replied' in this.state.status && this.state.isRepliedCampaign ?
												<div className="col-sm-2">
													<Replied
														value={this.state.status['Replied']}
														percent={((this.state.status['Replied'] * 100) / TotalCount).toFixed(2)}
													></Replied>
												</div> : ''
											}{'Email Reported' in this.state.status && (this.state.attackType === "Phishing" || this.state.attackType === "Ransomware" || this.state.attackType === "QRcode" || this.state.attackType === "Attachment") ?
												<div className="col-sm-2">
													<EmailReported
														value={this.state.status['Email Reported']}
														percent={((this.state.status['Email Reported'] * 100) / TotalCount).toFixed(2)}
													></EmailReported>
												</div> : ''
											}
											{/* {'Error' in this.state.status ?
												<div className="col-sm-2">
													<Error
														value={this.state.status['Error']}
														percent={((this.state.status['Error'] * 100) / TotalCount).toFixed(2)}
													></Error>
												</div> : ''
											} */}
										</div>
									</div>
								</div>

								<div className="col-md-4">
									<div class="tabHeading mbottom">
										<h6>Campaign Information</h6>
										<p>Showing all campaign metrics</p>
									</div>
									<div className="mainCard">
										<Table responsive className="campinfoTable">
											{this.state.details && this.state.details.campaign ?
												<tbody>
													<tr>
														<td>Campaign Name</td>
														<td>{this.state.details.campaign.name}</td>
													</tr>
													<tr>
														<td>Create Date</td>
														<td>{getDate(this.state.details.createdAt)}</td>
													</tr>
													<tr>
														<td>Launch Date</td>
														<td>{getDate(this.state.details.scheduledAt)}</td>
													</tr>
													<tr>
														<td>Frequency</td>
														<td>{this.state.details.campaign.frequency}</td>
													</tr>
													{this.state.details.campaign.assign_templates ?
														<>
															<tr>
																<td className="v-top">Campaign Templates</td>
																<td>
																	<ul className="table-ul">
																		{this.state.details.campaign.assign_templates.map((template, index) => {
																			return template.campaign_template ? <li key={'template-' + index}><span>{template.campaign_template.name}</span> <Link to={"/templates/preview/" + template.campaign_template.id} target="_blank">View</Link></li> : null
																		})}
																	</ul>

																</td>
															</tr>
															<tr>
																<td>Time Frame</td>
																<td>{this.state.details.delay > 1 ? this.state.details.delay + " min" : "1 sec"}</td>
															</tr>
															<tr>
																<td>Target Group</td>
																<td>{names}</td>
															</tr>
														</> : null
													}
												</tbody> : null
											}
										</Table>
									</div>
								</div>

								<div className="col-md-4 setPadding px-0">
									<div class="tabHeading mbottom">
										<h6>Campaign Progress</h6>
										<p>Showing all campaign metrics</p>
									</div>

									<div className="mainCard setHeight">
										{this.state.details && this.state.details.completedAt ?
											<ProgressBar animated now={100} label='Completed 100%'></ProgressBar> :
											<ProgressBar animated now={this.state.expiredProgress} label={'In Progress ' + (this.state.expiredProgress).toFixed(2) + "%"} ></ProgressBar>
										}
									</div>

									<div class="tabHeading mbottom">
										<h6>Campaign Duration</h6>
										<p>Showing all campaign metrics</p>
									</div>

									<div className="mainCard setHeight">
										<div className="campdurationBox mt-3">
											<div className="dayDate">
												<h5>7</h5>
												<h6>Days</h6>
											</div>

											<div className="dayDate">
												<p>Start date: <span>08-02-024</span></p>
												<p>End date: <span>08-02-024</span></p>
											</div>

										</div>
									</div>

								</div>

								<div className="col-md-4 setPadding">
									<div class="tabHeading mbottom">
										<h6>BreachTime</h6>
										<p>Showing all campaign metrics</p>
									</div>

									<div className="mainCard setHeight">
										<div className={'Email Reported' in this.state.status ? "graph-head graph-head-1 breachNumber" : "graph-head breachNumber"}>
											<h4>{this.state.breachTime ? this.state.breachTime : '--- '} Hrs</h4>
										</div>
										<div className="breachCard">
											<div className="icon">
												<img src={Breach} className="img-fluid" />
											</div>
											<div className="rightcontent">
												<p>The purpose of this report is to provide a detailed analysis of
													the employees’ susceptibility to a Attachment attack.</p>
											</div>
										</div>
									</div>


									<div class="tabHeading mbottom">
										<h6>Incident Report</h6>
										<p>Showing all campaign metrics</p>
									</div>

									<div className="mainCard setHeight">
										{'Email Reported' in this.state.status && (this.state.attackType === "Phishing" || this.state.attackType === "Ransomware" || this.state.attackType === "QRcode") ?
											<div className="breachNumber">
												<h4>{this.state.incedent ? this.state.incedent : '--- '} Hrs</h4>
											</div> : ''
										}

										<div className="breachCard">
											<div className="icon">
												<img src={Breach} className="img-fluid" />
											</div>
											<div className="rightcontent">
												<p>The purpose of this report is to provide a detailed analysis of
													the employees’ susceptibility to a Attachment attack.</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</Tab>

						<Tab eventKey="userdetails" title="User Detail List">
							<div className="camptabBtn">
								<div className="row">
									<div className="col-md-3">
										<div class="tabHeading">
											<h6>User Detail List</h6>
											<p>Showing all campaign metrics</p>
										</div>
									</div>
									<div className="col-md-9 p-0">
										<div className="dFlex justify-content-end rightdropdownBtn">
											<Button className="commonrefreshBtn mr-2" onClick={this.load}><CachedSharpIcon className="btn-icon" /></Button>
											<InputGroup className="search-wrap"><FormControl type="text" placeholder="Search" value={this.state.search} onChange={(e) => this.setState({ search: e.target.value, activePage: 1 }, () => this.load())} className="inputSearch mr-sm-2" /><SearchSharpIcon className="search-icon"></SearchSharpIcon></InputGroup>
											<Form.Group controlId="exampleForm.ControlSelect1" onChange={(e) => this.setState({ limit: parseInt(e.target.value, 10), activePage: 1 }, () => this.load())} className="d-inline-block mr-2">
												<Form.Control as="select">
													<option value="all">Show</option>
													<option value="10">10</option>
													<option value="25">25</option>
													<option value="50">50</option>
													<option value="100">100</option>
												</Form.Control>
											</Form.Group>
											<Form.Group controlId="exampleForm.ControlSelect1" value={this.state.statusFilter} onChange={(e) => this.setState({ statusFilter: e.target.value, activePage: 1, results: [] }, () => this.load())} className="d-inline-block mr-2">
												<Form.Control as="select">
													<option value="all">All</option>
													{filterableStatuses[this.state.attackType] ?
														filterableStatuses[this.state.attackType].map((filter, index) => {
															if (filter === 'Replied') {
																return this.state.isRepliedCampaign ? <option key={`filter-${index}`} value={filter}>{filter}</option> : null
															} else return <option key={`filter-${index}`} value={filter}>{filter}</option>
														}) : null
													}
												</Form.Control>
											</Form.Group>

											<Dropdown className="dropdownIcon">
												<Dropdown.Toggle id="dropdown-basic" className="btn btn-trans dropdown-toggle">
													Export
												</Dropdown.Toggle>
												<Dropdown.Menu>
													<Dropdown.Item onClick={this.handleFilterCsv}>Export CSV</Dropdown.Item>
													<Dropdown.Item onClick={this.handleFilterEventCsv}>Export Event CSV</Dropdown.Item>
												</Dropdown.Menu>
											</Dropdown>
										</div>
									</div>
								</div>
							</div>


							<div className="custom-table tableContainerWrapInner userdetailsTable">
								<div className="row mx-0 TableHead">
									<div className="col-md-1 pl-3">#</div>
									<div className="col-md-3">Name/Email</div>
									<div className="col-md-2">Phone</div>
									<div className="col-md-2">Position</div>
									<div className="col-md-2">Status</div>
									<div className="col-md-2">Location</div>
								</div>
								<div>
									{this.state.results.length ?
										<Accordion>
											<Card>
												{this.state.results.map((result, index) => {
													return <Results
														index={index + 1}
														result={result}
														key={'campaign-' + index}
														attackType={this.state.attackType}
														statusFilter={this.state.statusFilter}
														activeRow={this.state.activeId === result.id}
														handleActiveCampaign={this.handleActiveCampaign}
													></Results>
												})}
											</Card>
										</Accordion> :
										<h6 className="mt-3">Result Not Found</h6>
									}
									<div className="pagination-Wrap">
										{!this.state['loading'] ?
											<Pagination
												itemClass="page-item"
												linkClass="page-link"
												activePage={this.state.activePage}
												itemsCountPerPage={this.state.limit}
												totalItemsCount={this.state.count}
												onChange={(pageNumber) => this.setState({ activePage: pageNumber }, () => this.load())}
											/> : <Loader></Loader>
										}

									</div>
								</div>
							</div>
						</Tab>
					</Tabs>
				</div>
				<DetailModal
					groupName={this.state.groupName}
					details={this.state.details}
					detailModal={this.state.detailModal}
					detailHandle={e => this.setState({ detailModal: false })}
				></DetailModal>
				<DetailModalLMS
					details={this.state.lmsCampaign}
					previewURL={this.state.previewURL}
					detailModal={this.state.detailModalLMS}
					detailHandle={e => this.setState({ detailModalLMS: false })}
				></DetailModalLMS>
			</div>
		);
	}
}
