import React, {Component} from 'react';
import { Modal } from 'react-bootstrap';
import PlayArrowSharpIcon from '@material-ui/icons/PlayArrowSharp';

import welcomeImg from '../../assets/product-guide/Welcome-Image.jpg';
import userGroup from '../../assets/product-guide/Add-User-Group.jpg';
import CreateCampaign from '../../assets/product-guide/Create-Template.jpg';
import runCampaign from '../../assets/product-guide/Run-Campaign.jpg';
import download from '../../assets/product-guide/Download-Report.jpg';
import analysis from '../../assets/product-guide/Analyze-and-Aware.jpg'

import './product-guide-modal.css'

export default class ProductGuideModal extends Component {
    state = { 
        show1: true,
        show2: false,
        show3: false,
        show4: false,
        show5: false,
        show6: false,
    }

    handleTab = (showId, hideId) => {
        this.setState({ 
            [`show${showId}`]: true,
            [`show${hideId}`]: false,
        })
    }

    render() {
        return (
            <Modal className="product-modal" show={this.props.show} autoFocus keyboard>
              
                <Modal.Body>
                    <button className="skip-btn" onClick={e => this.props.handleModal()}>Skip</button>
                    {/* ==================1st wrap======================== */}
                    {this.state.show1?
                        <div className="first-wrap">
                            <h4 className="main-heading">Welcome to TSAT Quick-Start Guide!</h4>
                            <span className="u-line"></span>
                            <p className="first-para">This guide will give you a quick walkthrough of TSAT's feature and help you swiftly get started with your first campaign. 
                                In order to give you a hassle-free experience, we update this guide every time a new feature is added to the tool.</p>

                            <div className="row align-items-center">
                                <div className="col-md-6">
                                    <ul className="welcome-points">
                                        <li><PlayArrowSharpIcon className="list-icon" /> Add User & Groups</li>
                                        <li><PlayArrowSharpIcon className="list-icon" /> Create Campaign Template </li>
                                        <li><PlayArrowSharpIcon className="list-icon" /> Run Campaign</li>
                                        <li><PlayArrowSharpIcon className="list-icon" /> Download Reports</li>
                                        <li><PlayArrowSharpIcon className="list-icon" /> Analyse & Aware</li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <img alt="" src={welcomeImg} className="img-fluid"></img>
                                </div>
                                <div className="col-md-12 text-center">
                                    <button className="round-btn dark-blue" onClick={e => this.handleTab(2, 1)}>Start Here</button>
                                </div>
                            </div>
                        </div>: null
                    }
                    {/* ==================2st wrap======================== */}
                    {this.state.show2?
                        <div className="second-wrap">
                            <h4 className="main-heading">Add User & Groups</h4>
                            <span className="u-line"></span>
                            <p className="first-para">Whether you wish to train one user, a particular department, or your entire organization. 
                            All you need to do is upload a CSV or manually enter the details of the employees and you are ready to get started.</p>

                            <div className="row align-items-center">
                            
                                <div className="col-md-12 text-center">
                                    <img src={userGroup} className="img-fluid guide-img" alt=""></img>
                                </div>
                                <div className="col-md-12 d-flex justify-content-between">
                                    <button className="round-btn light-blue" onClick={e => this.handleTab(1, 2)}>Previous</button>
                                    <button className="round-btn dark-blue" onClick={e => this.handleTab(3, 2)}>Next</button>
                                </div>
                            </div>
                        </div>: null
                    }
                    {/* ==================3st wrap======================== */}
                    {this.state.show3?
                        <div className="third-wrap">
                            <h4 className="main-heading">Create Campaign Template</h4>
                            <span className="u-line"></span>
                            <p className="first-para">Choose from a library of 100+ templates belonging to a wide range of categories which includes Ecommerce, 
                                Insurance, O365, Work From Home, and more. You can also create templates in multiple languages such as Hindi, Arabic, etc.</p>

                            <div className="row align-items-center">
                            
                                <div className="col-md-12 text-center">
                                    <img src={CreateCampaign} className="img-fluid guide-img" alt=""></img>
                                </div>
                                <div className="col-md-12 d-flex justify-content-between">
                                    <button className="round-btn light-blue" onClick={e => this.handleTab(2, 3)}>Previous</button>
                                    <button className="round-btn dark-blue" onClick={e => this.handleTab(4, 3)}>Next</button>
                                </div>
                            </div>
                        </div>: null
                    }

                    {/* ==================4th wrap======================== */}
                    {this.state.show4?
                        <div className="fourth-wrap">
                            <h4 className="main-heading">Run Campaign</h4>
                            <span className="u-line"></span>
                            <p className="first-para">With your users and template ready, it is now time to test your employees’ 
                                cybersecurity awareness by initiating a phishing campaign. Run immediately or schedule a campaign to a time that suits you.</p>

                            <div className="row align-items-center">
                            
                                <div className="col-md-12 text-center">
                                    <img src={runCampaign} className="img-fluid guide-img" alt=""></img>
                                </div>
                                <div className="col-md-12 d-flex justify-content-between">
                                    <button className="round-btn light-blue" onClick={e => this.handleTab(3, 4)}>Previous</button>
                                    <button className="round-btn dark-blue" onClick={e => this.handleTab(5, 4)}>Next</button>
                                </div>
                            </div>
                        </div>: null
                    }

                    {/* ==================5th wrap======================== */}
                    {this.state.show5?
                        <div className="fifth-wrap">
                            <h4 className="main-heading">Download Reports</h4>
                            <span className="u-line"></span>
                            <p className="first-para">Get detailed insights of your simulation campaigns categorized into multiple reporting analytics, 
                            check your breach time, phish risk rate, click rate, department wise data and much more. You can also download your desired reports in multiple formats.</p>

                            <div className="row align-items-center">
                            
                                <div className="col-md-12 text-center">
                                    <img src={download} className="img-fluid guide-img" alt=""></img>
                                </div>
                                <div className="col-md-12 d-flex justify-content-between">
                                    <button className="round-btn light-blue" onClick={e => this.handleTab(4, 5)}>Previous</button>
                                    <button className="round-btn dark-blue" onClick={e => this.handleTab(6, 5)}>Next</button>
                                </div>
                            </div>
                        </div>: null
                    }

                    {/* ==================6th wrap======================== */}
                    {this.state.show6?
                        <div className="sixth-wrap">
                            <h4 className="main-heading">Analyze & Aware </h4>
                            <span className="u-line"></span>
                            <p className="first-para">Aware your users with the integrated awareness platform designed for creating interactive courses and assessments to help your workforce become more vigilant against the rising cyber threat landscape. 
                            Review course analytics and assessment results along with live campaign tracking.</p>

                            <div className="row align-items-center">
                            
                                <div className="col-md-12 text-center">
                                    <img src={analysis} className="img-fluid guide-img" alt=""></img>
                                </div>
                                <div className="col-md-12 d-flex justify-content-between">
                                    <button className="round-btn light-blue" onClick={e => this.handleTab(5, 6)}>Previous</button>
                                    <button className="round-btn dark-blue" onClick={e => this.props.handleModal()}>Get Started with TSAT</button>
                                </div>
                            </div>
                        </div>: null
                    }
                </Modal.Body>
            </Modal>
        );
    }
}