import React, { Component } from "react";
import { DateRangePicker } from 'react-date-range';
import { Modal, Button ,Form} from 'react-bootstrap';
import MultiSelect from "react-multi-select-component";
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';

import './report-filter.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
export default class reportModal extends Component {

    render(){
        return (
            <Modal show={this.props.reportModal} className="report-modal" onHide={() => this.props.reportHandle()} dialogClassName="modal-90w" aria-labelledby="example-custom-modal-styling-title">
                <Modal.Body className="p-0">  
                    <ClearOutlinedIcon  className="modal-cancel" onClick={this.props.reportHandle}></ClearOutlinedIcon>
                    <div className="row m-0">
                        <div className="col-md-8 pt-3">
                            <DateRangePicker
                                ranges={[{
                                    startDate: this.props.startDate,
                                    endDate: this.props.endDate,
                                    key: 'selection'
                                }]}
                                onChange={date => this.props.handleSelect(date.selection.startDate, date.selection.endDate)}
                                months={2}
                                direction="horizontal"
                                showSelectionPreview={true}
                                moveRangeOnFirstSelection={false}
                            ></DateRangePicker>
                        </div>
                        <div className="col-md-4 filter-wrap pt-3">
                            <div>
                                <Form.Group controlId="exampleForm.ControlSelect1">
                                    <Form.Label>Attack Vector</Form.Label>
                                    <Form.Control as="select" value={this.props.attackTypeId} onChange={this.props.handleAttackType}>
                                        {this.props.attackTypes.map((attacktype, index) => {
                                            return <option key={index} value={attacktype.id}>{attacktype.name}</option>
                                        })}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group controlId="exampleForm.ControlSelect3">
                                    <Form.Label>Credential Harvesting</Form.Label>
                                    <Form.Control as="select" value={this.props.credentialHarvesting} onChange={this.props.handleHarvesting}>
                                        <option value={'NA'}>All Campaign Template</option>
                                        <option value={true}>With Credential Harvesting</option>
                                        <option value={false}>Without Credential Harvesting</option>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group controlId="exampleForm.ControlSelect2">
                                    <Form.Label>Campaigns</Form.Label>
                                    <MultiSelect
                                        clearSearch="Clear Search"
                                        hasSelectAll={true}
                                        options={this.props.campaigns? this.props.campaigns: []}
                                        value={this.props.selectCampaigns}
                                        onChange={this.props.handleCampaigns}
                                        overrideStrings={{selectSomeItems: "Select campaigns"}}
                                    ></MultiSelect>
                                </Form.Group>
                                <Form.Group controlId="exampleForm.ControlSelect4" className="mb-2">
                                    <Form.Label>Locations</Form.Label>
                                    <Form.Control as="select" value={this.props.location} onChange={this.props.handleLocation}>
                                        <option value={null}>Select Location</option>
                                        {this.props.locations && this.props.locations.length?
                                            this.props.locations.map((result, index) => {
                                                return <option key={'country-'+index} value={result.countryCode}>{result.countryCode}</option>
                                            }): null
                                        }
                                    </Form.Control>
                                </Form.Group>
                                <div className="d-flex justify-content-end btn-row">
                                    <Button variant="outline-dark" className="mr-2" onClick={this.props.clearFilters}>Reset</Button>
                                    <Button variant="primary" onClick={this.props.applyFilter}>Apply</Button>
                                </div>
                            </div>
                        </div>
                    </div> 
                </Modal.Body>
            </Modal>
        );
    }
}