import React, { Component } from "react";
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { Button, Form, Alert } from 'react-bootstrap';

import threatcop from '../../assets/threatcop-b.png'

import axios from '../../axios';
import apiError from '../../error' ;
import Spinner from '../../component/spinner/spinner';

import './register.css';
export default class Register extends Component {
    constructor(props){
        super(props);
        this.state = {
            phone: '',
            email: '',
            lastname: '',
            firstname: '',
            loading: false,
            success: false,
            industryId: '0',
            industries: [],
        }
        this.loadIndustry();
    }

    validateEmail=(email) =>{
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      };

    validate = () => {
        if(this.state['firstname'].length === 0 || (this.state['firstname']).trim().length === 0){
            toast.error('First name can\'t be blank')
            return false;
        }
        
        if(!this.validateEmail(this.state['email']) || (this.state['email'].length === 0 || (this.state['email']).trim().length === 0)){
            // toast.error('email can\'t be blank')
            toast.error('Please enter valid email Id')
            return false;
        }
        if(this.state.industryId === 0 || this.state.industryId === '0'){
            toast.error('Please Select Industry Type')
            return false;
        }
        else return true;
    }

    handleSubmit = (event) =>{
        event.preventDefault(); 
        const isValid = this.validate();
        if(isValid){
            this.setState({loading: true});
            axios.post('/api/auth/register/', {
                phone: this.state.phone,
                email: this.state.email,
                lastname: this.state.lastname,
                firstname: this.state.firstname,
                industryId: this.state.industryId,
            })
            .then(res => {
                this.setState({success: true});
            })
            .catch(err => {
                apiError(err);
                setTimeout(()=> {this.setState({loading: false})}, 3000);
            })
        }
    }

    loadIndustry = () =>{
        axios.get('/api/auth/industry', {})
        .then(res => {
            this.setState({industries: res.data.industries});
        })
        .catch(err => {
            apiError(err);
        })
    }

    render() {
        return (
            <div className="login-wrap" id="auth-section">
                <div className="row m-0">
                    {/* <div className="col-sm-8 d-flex align-items-center left-wrap">
                        <div className="col-sm-9 mx-auto mid-blank">
                            <img src={loginImg} alt="email" className="login-img"></img>
                            <div className="text-wrap">
                                <h5>Digital Collection</h5>
                                <p>All important data and information of all  your tenants <br></br> in one place, completely digital.</p>
                            </div>
                        </div>
                    </div> */}

                    <div className="col-sm-4 mx-auto right-wrap">
                        <div className="col-sm-10 mx-auto p-0">
                            {!this.state.success?
                                <div className="content">
                                    <div className="text-center">
                                     <img src={threatcop} alt="email" className="threatcop-logo"></img>
                                    </div>
                                    <div className="login-box">
                                        <h4>Create account</h4>
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Control type="text" value={this.state.firstname} autoComplete="new-password" onChange={(e) => this.setState({firstname: e.target.value})}  placeholder="First Name*" />
                                        </Form.Group>
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Control type="text" value={this.state.lastname} autoComplete="new-password" onChange={(e) => this.setState({lastname: e.target.value})}  placeholder="Last Name" />
                                        </Form.Group>
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Control type="email"value={this.state.email} autoComplete="new-password" onChange={(e) => this.setState({email: e.target.value})}  placeholder="Email*" />
                                        </Form.Group>
                                        <PhoneInput country={'us'} value={this.state.phone} onChange={phone => this.setState({ phone: phone }) } ></PhoneInput>
                                        <Form.Group controlId="exampleForm.ControlSelect1" className="mt-3">
                                            <Form.Control as="select" className="form-industry" value={this.state.industryId} onChange={e => this.setState({industryId: e.target.value})}>
                                                <option value="0">Select Industry Type*</option>
                                                {this.state.industries.map((industry, index) => {
                                                    return <option key={index} value={industry.id}>{industry.name}</option>
                                                })}
                                            </Form.Control>
                                        </Form.Group>
                                        <Button disabled={this.state.loading} onClick={this.handleSubmit} className="btn-login w-100"><Spinner loading={this.state.loading}></Spinner>Register</Button>
                                        <p>Already Registered? <Button className="btn-link ml-1" onClick={() => this.props.history.push('/login')}>Login Here</Button></p>
                                    </div>
                                </div>: 
                                <Alert variant="success">
                                    <Alert.Heading>Hey, you have successfully registered</Alert.Heading>
                                    <p>Verification email has been send to your email.</p>
                                    <p>Please verify your email Id before login</p><hr/>
                                    <p>Already Verify <Button onClick={() => this.props.history.push('/login')}>Login Here</Button></p>
                                </Alert>
                            }
                        </div>
                        <ToastContainer position="top-right" autoClose={5000}/>
                    </div>
                </div>
            </div>
        );
    }
}