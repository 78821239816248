import QRCode from 'qrcode';
import { toast } from 'react-toastify';
import React, { Component } from "react";
import { Card, Breadcrumb, Button } from 'react-bootstrap';

import axios from '../../../axios';
import apiError from '../../../error';
import Loader from '../../../component/loader/loader';

import './mobile-auth.css';

export default class MobileAuth extends Component {

    state = {
        otp: '',
        loading: false
    }

    componentDidMount = () => {
        this.loadDetail();
    }

    loadDetail = () => {
        axios.get('/api/user/getQr', {})
        .then(res => {
            QRCode.toCanvas(document.getElementById('canvas'), res.data.uri, function (error) {
                if (error) console.error(error)
                console.log('success!');
            })
        })
        .catch(err => {
            apiError(err);
        })
    }

    handleDetail = () => {
        if(!this.state.otp || this.state.otp.length === 0){
            toast.error("please enter otp first");
        } else {
            this.setState({ loading: true })
            axios.post('/api/user/postQr', {
                otp: this.state.otp
            })
            .then(res => {
                toast.success("you have been setup successfully 2FA");
                this.setState({ loading: false })
                this.props.history.push('/settings');
            })
            .catch(err => {
                apiError(err);
                this.setState({ loading: false })
            })
        }
    }

    render() {

        return (
            <div className="content-wrap">
                <div className="breadcrumbbar">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-head d-flex align-items-center justify-content-between">
                                <h4 className="page-title">Auth Settings</h4>
                                <Breadcrumb className="mb-0">
                                    <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
                                    <Breadcrumb.Item href="#" active>Auth Settings</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </div>
                    </div>
                </div>
                <Card>
                    <Card.Body>
                        <div className="page-content" id="tab-wrapper">
                            <div className="authencation_mobile_verification">
                                <Button className="btn btn-secondary back_auth" onClick={e => this.props.history.push('/settings')}>Back</Button>
                                <h5 className="auth_ver_mobile">Authentication steps:</h5>
                                <ul className="auth_steps">
                                    <li>
                                        <p>1. Install an authenticator app on your mobile device</p>
                                    </li>

                                    <li>
                                        <p>2. Scan the following QR code in your authenticator app</p>
                                        <div className="">
                                            <canvas id="canvas"></canvas>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="form-group">
                                            <label>3. Enter the code from your authenticator app below</label>
                                            <input type="text" className="form-control w-25" value={this.state.otp} onChange={e => this.setState({ otp: e.target.value })} placeholder="Enter your code" />
                                        </div>
                                    </li>
                                </ul>
                                <Button type="button" className="btn btn-success" onClick={this.handleDetail}>Enable</Button>
                            </div>
                        </div>
                        {this.state.loading? <Loader></Loader>: null}
                    </Card.Body>
                </Card>
            </div>
        );
    }
}