import React, {Component} from 'react';
import { Modal } from 'react-bootstrap';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';

import './preview.css';

export default class Preview extends Component {
    render() {
        return (
            <Modal className="preview-modal" show={this.props.showModal} onHide={this.props.showHandle} autoFocus keyboard>
                <ClearOutlinedIcon  className="modal-cancel" onClick={this.props.showHandle}/>
                <Modal.Body className="mx-auto">
                    {(this.props.attackType === "Phishing" || this.props.attackType === "Smishing" || this.props.attackType === "Ransomware" || this.props.attackType === "WhatsApp" || this.props.attackType === "Attachment" || this.props.attackType === "QRcode" || this.props.previewType === "page")? <img alt="" className="img-fluid" src={this.props.src}></img>: this.props.html? this.props.html: 'Image not found'}
                </Modal.Body>
            </Modal>
        );
    }
}