import React, { Component } from "react";
import ApexCharts from 'apexcharts'
export default class ThreatProfile extends Component {
	constructor(props) {
		super(props);
		this.state = { chart: null }
	}

	componentDidMount = () => {
		this.load();
	}

	load = () => {
		let options = {
			series: [{
				name: 'Score',
				data: this.props.userProfile['score']? this.props.userProfile['score']: []
			}],
			chart: {
				type: 'bar',
				height: 350,
				toolbar: {
					show: false
				}
			},
			plotOptions: {
				bar: {
					horizontal: false,
					dataLabels: {
						position: 'top',
					},
				}
			},
			dataLabels: {
				enabled: true,
				offsetX: -6,
				style: {
					fontSize: '12px',
					colors: ['#fff']
				}
			},
			stroke: {
				show: true,
				width: 1,
				colors: ['#fff']
			},
			xaxis: {
				labels: {
					show: true,
					rotate: -45,
					rotateAlways: false,
					hideOverlappingLabels: true,
					showDuplicates: false,
					trim: false,
					minHeight: undefined,
					maxHeight: 120,
				},
				categories: this.props.userProfile['name']? this.props.userProfile['name']: []
			}
		}
		var chart = new ApexCharts(document.querySelector("#profile"), options);
		chart.render();
		this.setState({chart: chart})
	}

	componentDidUpdate = (prevProps) => {
		if(this.props.userProfile !== prevProps.userProfile){
			if(this.state.chart) this.state.chart.destroy();
			this.load();
		}
	}

	render() {
		return (
			<div id="profile"></div>
		);
	}
}