import React, { Component } from 'react';

import Point1 from '../../assets/b-img-1.png';
import Point2 from '../../assets/b-img-2.png';
import Point3 from '../../assets/b-img-3.png';
import Point4 from '../../assets/b-img-4.png';
import Point5 from '../../assets/b-img-5.png';
import Point6 from '../../assets/b-img-6.png';
import Arrow1 from '../../assets/arrow-1.png';
import Arrow2 from '../../assets/arrow-2.png';
import Arrow3 from '../../assets/arrow-3.png';
import Arrow4 from '../../assets/arrow-4.png';
import './blue-page-2.css';

export default class Actions extends Component {

    render() {
        return (
            <div className="" id="bluePage-2-wrap">
                <div className="col-md-10 mx-auto">
                    <h1 className="main-head">You have been Phished!</h1>
                    <h4 className="sub-head">Remember these six rules to stay safe online</h4>
                    <div className="row justify-content-center align-items-center mid-row">
                        <div className="col-md-5 col-lg-4 text-center">
                            <img src={Point1} className="img-fluid pointer" alt=""></img>
                        </div>
                        <div className="col-md-7 col-lg-6">
                            <div className="box-numeric">
                                <div className="left-unit">
                                    <div className="num-box">
                                        <span>01</span>
                                    </div>
                                </div>
                                <div className="right-unit">
                                    <h4>Protect Your Personal Info</h4>
                                    <p> Be very cautious with your personal info including usernames and passwords</p>
                                </div>
                            </div>
                            <img src={Arrow1} className="arrow-img arrow-1" alt=""></img>
                        </div>
                    </div>
                    <div className="row justify-content-end align-items-center mid-row">
                        <div className="col-md-5 col-lg-4 text-center order-md-2">
                            <img src={Point2} className="img-fluid pointer" alt=""></img>
                        </div>
                        <div className="col-md-7 col-lg-6 order-md-1">
                            <div className="box-numeric">
                                <div className="left-unit">
                                    <div className="num-box">
                                        <span>02</span>
                                    </div>
                                </div>
                                <div className="right-unit">
                                    <h4>Beware of Suspicious Emails</h4>
                                    <p> Be very suspicious of any emails you receive from trusted entities such as your bank or credit card</p>
                                </div>
                            </div>
                            <img src={Arrow2} className="arrow-img arrow-2" alt=""></img>
                        </div>
                    </div>
                    <div className="row justify-content-center align-items-center mid-row">
                        <div className="col-md-5 col-lg-4 text-center">
                            <img src={Point3} className="img-fluid pointer" alt=""></img>
                        </div>
                        <div className="col-md-7 col-lg-6">
                            <div className="box-numeric">
                                <div className="left-unit">
                                    <div className="num-box">
                                        <span>03</span>
                                    </div>
                                </div>
                                <div className="right-unit">
                                    <h4>Do Not Click Suspicious Links</h4>
                                    <p>Deceptive links that mimic legitimate URL addresses are a common tool used in phishing scams</p>
                                </div>
                            </div>
                            <img src={Arrow3} className="arrow-img arrow-3" alt=""></img>
                        </div>
                    </div>
                    <div className="row justify-content-end align-items-center mid-row">
                        <div className="col-md-5 col-lg-4 text-center order-md-2">
                            <img src={Point4} className="img-fluid pointer" alt=""></img>
                        </div>
                        <div className="col-md-7 col-lg-6 order-md-1">
                            <div className="box-numeric">
                                <div className="left-unit">
                                    <div className="num-box">
                                        <span>04</span>
                                    </div>
                                </div>
                                <div className="right-unit">
                                    <h4>Know Common Phishing Language</h4>
                                    <p> Legitimate business will not send you email to ask for your login information or sensitive personal information</p>
                                </div>
                            </div>
                            <img src={Arrow4} className="arrow-img arrow-4" alt=""></img>
                        </div>
                    </div>
                    <div className="row justify-content-center align-items-center mid-row">
                        <div className="col-md-5 col-lg-4 text-center">
                            <img src={Point5} className="img-fluid pointer" alt=""></img>
                        </div>
                        <div className="col-md-7 col-lg-6">
                            <div className="box-numeric">
                                <div className="left-unit">
                                    <div className="num-box">
                                        <span>05</span>
                                    </div>
                                </div>
                                <div className="right-unit">
                                    <h4>False Sense Urgency</h4>
                                    <p> Look out for emails that try to convey a sense or urgency, and be wary of any email that does not address you directly</p>
                                </div>
                            </div>
                            <img src={Arrow3} className="arrow-img arrow-3" alt=""></img>
                        </div>
                    </div>
                    <div className="row justify-content-end align-items-center mid-row pb-5">
                        <div className="col-md-5 col-lg-4 text-center order-md-2">
                            <img src={Point6} className="img-fluid pointer" alt=""></img>
                        </div>
                        <div className="col-md-7 col-lg-6 order-md-1">
                            <div className="box-numeric">
                                <div className="left-unit">
                                    <div className="num-box">
                                        <span>06</span>
                                    </div>
                                </div>
                                <div className="right-unit">
                                    <h4>Compromised Accounts</h4>
                                    <p> Warning that your account has been compromised, for example, are a common way to lure victims</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}