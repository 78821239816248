import React, { Component } from "react";
import { Modal, Button } from 'react-bootstrap';
import CheckSharpIcon from '@material-ui/icons/CheckSharp';

import Mobile from '../../../assets/mobile.png';
import Message from '../../../assets/message.png';

import './towway.css';

export default class TWO_FA extends Component {
    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.handleClose} >
                <Modal.Header closeButton>
                    <Modal.Title>Two Factor Authentication Type</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="verify_check">
                        <ul className="verfiy_list">
                            <li onClick={e => this.props.handleOtpType('email')} className={this.props.otpType === "email"? "active_cl": ""}>
                                {this.props.otpType === "email"? <CheckSharpIcon  className="icon_position" />: null}
                                <img src={Message} alt=""></img>
                                <h5>Mail Authentication</h5>
                            </li>
                            <li onClick={e => this.props.handleOtpType('app')} className={this.props.otpType === "app"? "active_cl": ""}>
                            {this.props.otpType === "app"? <CheckSharpIcon className="icon_position" />: null}
                            <img src={Mobile} alt=""></img>
                                <h5>Mobile with Authentication</h5>
                            </li>
                        </ul>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={e => this.props.handleUpdate(false)}>Disable</Button>
                    <Button variant="primary" onClick={e => this.props.handleUpdate(true)}>Enable</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}