import swal from 'sweetalert';
import { toast } from "react-toastify";
import React, { Component } from "react";
import { Button, Form, Table } from 'react-bootstrap';
import PublishIcon from '@material-ui/icons/Publish';
import EditSharpIcon from '@material-ui/icons/EditSharp';
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';

import axios from '../../../axios';
import apiError from '../../../error';
import Loader from '../../loader/loader';
import { getDate } from '../../../function';

export default class Ransomware extends Component {
    constructor(props){
        super(props);
        this.state = {
            name: '',
            closeIn: '',
            loading: false,
            isEmail: false,
            description: '',
            ransomwareFiles: [],

            file: '',
            thumbnail: '',
            ransomwareFileId: 0,

        }
        this.file = React.createRef();
        this.thumbnail = React.createRef();
    }

    componentDidMount = () => {
        this.load();
    }

    load = () => {
        axios.get('/api/ransomware/getFile', {})
        .then(res => {
            this.setState({ loading: false, ransomwareFiles: res.data.ransomwareFiles })
        })
        .catch(error => {
            apiError(error);
        })
    }

    validate = () => {
        if(!this.state.name || this.state.name.length === 0){
            toast.error("Please enter name");
            return false;
        } else if(this.state.closeIn === 0){
            toast.error("Please enter close time");
            return false;
        }
        let formData = new FormData();

        formData.append('name', this.state.name)
        formData.append('isEmail', this.state.isEmail)
        formData.append('closeIn', this.state.closeIn)
        formData.append('description', this.state.description)
        formData.append('file', this.state.file)
        formData.append('thumbnail', this.state.thumbnail)
        formData.append('ransomwareFileId', this.state.ransomwareFileId)

        return formData;
        
    }

    handleSubmit = async() => {
        var formData = this.validate();
        if(formData){
            await this.setState({ loading: true });
            axios.post('/api/ransomware/uploadFile', formData, {
                headers: {
                    "Content-type": "multipart/form-data",
                } 
            })
            .then(res => {
                this.setState({ 
                    name: '',
                    file: '',
                    closeIn: '',
                    isEmail: false,
                    description: '',
                    thumbnail: '',
                    loading: false,
                    ransomwareFileId: 0
                }, () => {
                    this.load();
                })
                toast.success("Ransomware file uploaded successfully");
            })
            .catch(err => {
                this.setState({ loading: false });
                apiError(err);
            })
        }
    }

    handleEdit = (ransomwareFileId) => {
        var ransomwareFiles = this.state.ransomwareFiles;
        for(let i=0; i<ransomwareFiles.length; i++){
            if(ransomwareFiles[i].id === ransomwareFileId){
                this.setState({
                    name: ransomwareFiles[i].name,
                    closeIn: ransomwareFiles[i].closeIn,
                    isEmail: ransomwareFiles[i].isEmail,
                    ransomwareFileId: ransomwareFiles[i].id,
                    description: ransomwareFiles[i].description,
                })
            }
        }
    }

    handleDelete = (ransomwareFileId) => {
        swal({
            title: "Do you want to delete this File?",
            icon: "warning",
            buttons: [ 'No', 'Yes' ],
            dangerMode: true,
        })
        .then(willSuccess => {
            if (willSuccess) {
                axios.delete('/api/ransomware/deleteFile', {
                    params: {
                        ransomwareFileId: ransomwareFileId
                    }
                })
                .then(res => {
                    this.load();
                })
                .catch(error => {
                    apiError(error);
                })
            }
        });
        
    }

    render() {
        return (
            <div className="tab-wrap">
            <div className='tabHeading'>
                <h6>Ransomware Files</h6>
                <p>Displays basic information associ </p>
                </div>

                 <div className="col-md-4 pl-0">
                        <div className='profile-form'>
                        <Form>
                            <Form.Group>
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" autoComplete="new-password" placeholder="Please enter name" value={this.state.name} onChange={e => this.setState({name: e.target.value})}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Description</Form.Label>
                                <Form.Control type="text" autoComplete="new-password" placeholder="ransomware file description" value={this.state.description} onChange={e => this.setState({description: e.target.value})}/>
                            </Form.Group>
                            {/* <Form.Group>
                                <Form.Label>Close Time</Form.Label>
                                <Form.Control type="text" autoComplete="new-password" placeholder="ransomware file close time" value={this.state.closeIn} onChange={e => this.setState({closeIn: e.target.value})}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                    <Form.Check type="checkbox" label="Email Required" value={this.state.isEmail} onChange={e => this.setState({isEmail: !this.state.isEmail})} />
                                </Form.Group>
                            </Form.Group> */}

                            <div className="row">
                                <div className="col-md-6">
                                    <Form.Group controlId="formFileSm" className="mb-3">
                                        <Form.File id="exampleFormControlFile1" label="" accept="image/*" ref={this.thumbnail} onChange={e => this.setState({thumbnail: e.target.files[0]})} style={{display: 'none'}}/>
                                    </Form.Group>
                                    <Button variant="primary" onClick={e => this.thumbnail.current.click()}>Upload Ransomware Thumbnail<PublishIcon className="btn-icon"></PublishIcon></Button>
                                    {this.state.thumbnail && this.state.thumbnail.name ? <p className="mt-2">{this.state.thumbnail.name}</p>: <p  className="mt-2"></p>}
                                </div>
                                <div className="col-md-6">
                                    <Form.Group controlId="formFileSm" className="mb-3">
                                        <Form.File id="exampleFormControlFile2" label="" ref={this.file} onChange={e => this.setState({file: e.target.files[0]})} style={{display: 'none'}}/>
                                    </Form.Group>
                                    <Button variant="primary" onClick={e => this.file.current.click()}>Upload Ransomware File<PublishIcon className="btn-icon"></PublishIcon></Button>
                                    {this.state.file && this.state.file.name ? <p className="mt-2">{this.state.file.name}</p>: <p  className="mt-2"></p>}
                                </div>
                            </div>

                        </Form>
                        <Button variant="success" className="mb-2" onClick={this.handleSubmit}>Save Detail</Button>
                            
                        </div>
                 </div>

                 <div className="tableContainerWrapInner">
                    <Table responsive>
                             <thead className="bg-light">
                                    <tr>
                                        <th>Name</th>
                                        <th>Description</th>
                                        <th>Date</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>

                                <tbody>
                                {this.state.ransomwareFiles.map((ransomware, index) => {
                               
                                      return <tr key={'domain-'+index}>
                                            <td>{ransomware.name}</td>
                                            <td>{ransomware.description}</td>
                                            <td>{(getDate(ransomware.createdAt))}</td>
                                            <td>
                                            <div className="col-md-3 d-flex justify-content-center align-items-center">
                                                    <Button className="btn-danger-rgba btn-round mr-2"  onClick={() => this.handleEdit(ransomware.id)} >
                                                        <EditSharpIcon className="table-icon"></EditSharpIcon>
                                                    </Button>
                                                    <Button className="btn-danger-rgba btn-round"  onClick={() => this.handleDelete(ransomware.id)} >
                                                        <DeleteSharpIcon className="table-icon text-danger"></DeleteSharpIcon>
                                                    </Button>
                                                </div>
                                            </td>
                                        </tr>
                                })}

                         </tbody>

                    </Table>
                </div>


                {/* <div className="row">
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-8">
                                <Form>
                                    <Form.Group>
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control type="text" autoComplete="new-password" placeholder="Please enter name" value={this.state.name} onChange={e => this.setState({name: e.target.value})}/>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control type="text" autoComplete="new-password" placeholder="ransomware file description" value={this.state.description} onChange={e => this.setState({description: e.target.value})}/>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Close Time</Form.Label>
                                        <Form.Control type="text" autoComplete="new-password" placeholder="ransomware file close time" value={this.state.closeIn} onChange={e => this.setState({closeIn: e.target.value})}/>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                            <Form.Check type="checkbox" label="Email Required" value={this.state.isEmail} onChange={e => this.setState({isEmail: !this.state.isEmail})} />
                                        </Form.Group>
                                    </Form.Group>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <Form.Group controlId="formFileSm" className="mb-3">
                                                <Form.File id="exampleFormControlFile1" label="" accept="image/*" ref={this.thumbnail} onChange={e => this.setState({thumbnail: e.target.files[0]})} style={{display: 'none'}}/>
                                            </Form.Group>
                                            <Button variant="primary" onClick={e => this.thumbnail.current.click()}>Upload Ransomware Thumbnail<PublishIcon className="btn-icon"></PublishIcon></Button>
                                            {this.state.thumbnail && this.state.thumbnail.name ? <p className="mt-2">{this.state.thumbnail.name}</p>: <p  className="mt-2"></p>}
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group controlId="formFileSm" className="mb-3">
                                                <Form.File id="exampleFormControlFile2" label="" ref={this.file} onChange={e => this.setState({file: e.target.files[0]})} style={{display: 'none'}}/>
                                            </Form.Group>
                                            <Button variant="primary" onClick={e => this.file.current.click()}>Upload Ransomware File<PublishIcon className="btn-icon"></PublishIcon></Button>
                                            {this.state.file && this.state.file.name ? <p className="mt-2">{this.state.file.name}</p>: <p  className="mt-2"></p>}
                                        </div>
                                    </div>

                                </Form>
                                <Button variant="success" className="mb-2" onClick={this.handleSubmit}>Save Detail</Button>
                            </div> 
                        </div>
                        <div className="row bg-light mx-0 row-head">
                            <div className="col-md-3">Name</div>
                            <div className="col-md-2">Description</div>
                            <div className="col-md-4">Date</div>
                            <div className="col-md-3">Action</div>
                        </div>
                        {this.state.ransomwareFiles.map((ransomware, index) => {
                            return <div className="row mx-0 align-items-center setting-body" key={'domain-'+index}>
                                <div className="col-md-3">{ransomware.name}</div>
                                <div className="col-md-2">{ransomware.description}</div>
                                <div className="col-md-4">{(getDate(ransomware.createdAt))}</div>
                                <div className="col-md-3 d-flex justify-content-center align-items-center">
                                    <Button className="btn-danger-rgba btn-round mr-2"  onClick={() => this.handleEdit(ransomware.id)} >
                                        <EditSharpIcon className="table-icon"></EditSharpIcon>
                                    </Button>
                                    <Button className="btn-danger-rgba btn-round"  onClick={() => this.handleDelete(ransomware.id)} >
                                        <DeleteSharpIcon className="table-icon text-danger"></DeleteSharpIcon>
                                    </Button>
                                </div>
                            </div>
                        })}
                    </div>
                </div> */}
                {this.state.loading? <Loader></Loader>: null}
            </div>
        );
    }
}