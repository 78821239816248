import React, { Component } from "react";
import { Card, Breadcrumb, Tabs, Tab } from 'react-bootstrap';

import AD from "../../../component/settings/ad/ad";
import SMTP from "../../../component/settings/smtp/smtp";
import Domain from "../../../component/settings/domain/domain";
import Company from "../../../component/settings/company/company";
import Licence from "../../../component/settings/licence/licence";
import Profile from "../../../component/settings/profile/profile";
import Password from "../../../component/settings/password/password";
import Ransomware from '../../../component/settings/ransomware/ransomware'
import Azure from '../../../component/settings/azure/azure'
import GoogleAdmin from "../../../component/settings/googleadmin/googleadmin"
import IMAP from "../../../component/settings/imap/imap";
import { getCookie } from '../../../function';

import './settings.css'
import SSO from "../../../component/settings/sso/sso";

export default class SettingTab extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tab: null,
            userAgent: getCookie('userAgent')
        }
    }
    useQuery = () => {
        return new URLSearchParams(window.location.search);
    }
    nextPath = (path) => {
        this.props.history.push(path);
    }

    render() {
        var tab = this.state.tab ? this.state.tab : this.useQuery().get('tab') ? this.useQuery().get('tab') : 'profile';
        return (
            <div className="content-wrap">


                {/**********************
           			  Breadcrumbbar
           		  **********************/}
                <div className="breadcrumbbar">
                    <div className="flexRow row">
                        <div className="col-md-4">
                            <div className="page-head d-flex align-items-center justify-content-between">
                                <h4 className="page-title">Settings</h4>
                            </div>
                        </div>
                        <div className="col-md-8"> </div>
                    </div>
                </div>
                {/*******************
                    Breadcrumbbar End
                 *******************/}

                <div className="page-content tab-setting" id="tab-wrapper">
                    <Tabs activeKey={tab} onSelect={(k) => { this.setState({ tab: k }, () => this.props.history.push(`/settings/?tab=${k}`)) }} id="uncontrolled-tab-example">
                        <Tab eventKey="profile" title="Profile">
                            <Profile nextPath={this.nextPath}></Profile>
                        </Tab>
                        <Tab eventKey="company" title="Company">
                            <Company></Company>
                        </Tab>
                        <Tab eventKey="authentication" title="Security">
                            <Password></Password>
                        </Tab>
                        <Tab eventKey="sso" title="SSO Login">
                            <SSO></SSO>
                        </Tab>
                        <Tab eventKey="domain" title="Domain">
                            <Domain></Domain>
                        </Tab>

                        <Tab eventKey="advanced" title="SMTP">
                            <SMTP></SMTP>
                        </Tab>

                        <Tab eventKey="activedirectory" title="AD Credentials">
                            <AD></AD>
                        </Tab>

                        <Tab eventKey="azure" title="Azure">
                            <Azure></Azure>
                        </Tab>

                        <Tab eventKey="googleadmin" title="G-Suite">
                            <GoogleAdmin></GoogleAdmin>
                        </Tab>

                        {/* {this.state.userAgent && */}
                            <Tab eventKey="ransomware" title="Ransomware File">
                                <Ransomware></Ransomware>
                            </Tab>
                        {/* } */}
                        <Tab eventKey="imap" title="IMAP">
                            <IMAP></IMAP>
                        </Tab>
                    </Tabs>
                </div>

            </div>
        );
    }
}