import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
import { Modal, Button, Form, InputGroup } from 'react-bootstrap';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

import axios from '../../../axios';
import apiError from '../../../error';
import Spinner from '../../spinner/spinner';

export default class TXTModal extends Component {
    constructor(props){
        super(props)
        this.state = {
            isShow: 'dns',
            loading: false,
            copySuccessDns: '',
            copySuccessHttp: ''
        }
    }

    downloadTxtFile = () => {
        const element = document.createElement("a");
        const file = new Blob([this.props.domain.key], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = `${this.props.domain.key}.txt`;
        document.body.appendChild(element);
        element.click();
    }

    copyToClipboardDns = (e) => {
        this.textArea1.select();
        document.execCommand('copy');
        e.target.focus();
        this.setState({ copySuccessDns: 'Copied!', copySuccessHttp: '' });
    };

    copyToClipboardHttp = (e) => {
        this.textArea2.select();
        document.execCommand('copy');
        e.target.focus();
        this.setState({ copySuccessHttp: 'Copied!', copySuccessDns: '' });
    };

    handleVerifyDomain = async() => {
        await this.setState({ loading: true });
        axios.post('/api/domain/verify', { domainId: this.props.domain.id})
        .then(res => {
            if(!res.data.isVerify){
                toast.warning("Domain is not verified till now.")
            }
            this.props.txtHandle();
            this.props.loadDomain();
            this.setState({ loading: false });
        })
        .catch(err => {
            apiError(err);
            this.setState({ loading: false });
        })
    }

    handleVerifyDomainByFile = async() => {
        await this.setState({ loading: true });
        axios.post('/api/domain/verify/file', { domainId: this.props.domain.id})
        .then(res => {
            if(!res.data.isVerify){
                toast.warning("Domain is not verified till now.")
            }
            this.props.txtHandle();
            this.props.loadDomain();
            this.setState({ loading: false });
        })
        .catch(err => {
            this.setState({ loading: false });
            apiError(err);
        })
    }

    handleVerify = () => {
        if(this.state.isShow === 'dns'){
            this.handleVerifyDomain();
        } else if(this.state.isShow === 'http'){
            this.handleVerifyDomainByFile();
        }
    }

    render(){
        const domain = this.props.domain;
        return (
            <Modal show={this.props.txtModal} className="txtModal" onHide={() => this.props.txtHandle()} centered aria-labelledby="example-custom-modal-styling-title">
               

                <Modal.Header closeButton>
                     <Modal.Title id="example-custom-modal-styling-title">
                        <h5 className="mb-0">Verify domain ownership via {(this.state.isShow).toUpperCase()} record</h5>
                        <p style={{'color':"black"}}>{domain.name}</p>
                    </Modal.Title>
				</Modal.Header>


                
                <Modal.Body>
                        <Form.Group className="row m-0 align-items-center mb-2">
                            <Form.Label className="col-md-3 pl-0 mb-0">Instructions for</Form.Label>
                            <Form.Control value={this.state.isShow} as="select" className="col-md-4" onChange={e => this.setState({isShow: e.target.value})}>
                                <option value="dns">Verify over DNS</option>
                                <option value="http">Verify over HTTP</option>
                            </Form.Control>
                        </Form.Group>

                    <div className={this.state.isShow === 'dns'? "dns-wrap": "dns-wrap d-none"}>
                        <ul>
                            <li>Sign in to your domain name provider (e.g. godaddy.com or namecheap.com)</li>
                            <li>Copy the TXT record below into the DNS configuration for <b>{domain.name}</b></li>
                            <InputGroup className="my-3">
                                <Form.Control ref={(textarea) => this.textArea1 = textarea} type="text" value={domain.txt} />
                                <InputGroup.Append>
                                    <Button variant="secondary" onClick={this.copyToClipboardDns}><FileCopyOutlinedIcon className="only-icon"></FileCopyOutlinedIcon></Button>
                                </InputGroup.Append>
                            <p className="copy-text">{this.state.copySuccessDns}</p>
                            </InputGroup>
                            <li>Press verify below</li>
                        </ul>
                        <p><b>Note</b> : DNS changes may take some time to apply. If TSAT doesn’t find the record immediately, wait a day and then try to verify again</p>
                        <Button variant="link" target="_blank" href="https://helpdesk.kratikal.com/portal/en/kb/articles/how-to-add-and-verify-a-new-domain-through-dns" rel="noopener noreferrer" className="pl-0">Full Details</Button>
                    </div>
                    <div className={this.state.isShow === 'http'? "http-wrap mt-3": "http-wrap d-none mt-3"}>
                        <div className="d-flex">
                            <div className="count">1</div>
                            <div className="w-100">
                                <p>Place this verification token in a file</p>
                                <InputGroup className="my-3">
                                <Form.Control ref={(textarea) => this.textArea2 = textarea} type="text" value={domain.key} />
                                <InputGroup.Append>
                                    <Button variant="secondary" onClick={this.copyToClipboardHttp}><FileCopyOutlinedIcon className="only-icon"></FileCopyOutlinedIcon></Button>
                                </InputGroup.Append>
                            <p className="copy-text2">{this.state.copySuccessHttp}</p>
                            </InputGroup>
                                <p>Or <Link to="#" onClick={this.downloadTxtFile}>download</Link> the file you need.</p>
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className="count">2</div>
                            <div className="w-100">
                                <p>Upload the file to your server so it is accessible to the following URL:</p>
                                <ul>
                                    <li>{`http://${domain.name}/${domain.key}`}</li>
                                </ul>
                            </div>
                        </div>
                        <Button variant="link" target="_blank" href="https://helpdesk.kratikal.com/portal/en/kb/articles/how-to-add-and-verify-a-new-domain-through-dns-22-2-2021" className="pl-0" rel="noopener noreferrer">Full Details</Button>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={this.props.txtHandle}>Verify Later</Button>
                    <Button variant="primary" onClick={this.handleVerify} disabled={this.state.loading}><Spinner loading={this.state.loading}></Spinner> Verify </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}