import React, { Component } from "react";
import { Modal, Button, InputGroup, FormControl } from 'react-bootstrap';

import Spinner from '../spinner/spinner';

export default class ADPassword extends Component {
    
    render(){
        return (
            <Modal show={this.props.adModal} className="chooseModal" onHide={() => this.props.handleAdModal()} dialogClassName="modal-90w" aria-labelledby="example-custom-modal-styling-title">
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        <h6 className="mb-0">AD Password</h6>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="choose-wrap">
                        <InputGroup className="search-wrap">
                            <FormControl type="password" autoComplete="new-password" placeholder="Please enter your password" value={this.props.password} className="mr-sm-2" onChange={this.props.handlePassword}/>
                        </InputGroup>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" disabled={this.props.adLoading} onClick={this.props.handleADSAVE}><Spinner loading={this.props.adLoading}></Spinner> Next </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}