import { toast } from "react-toastify";
import React, { Component } from "react";
import { Form, Button } from 'react-bootstrap';

import axios from '../../../axios';
import apiError from '../../../error';
export default class Licence extends Component {

    constructor(props){
        super(props);
        this.state = {
            url: '',
            licence: '',
            isDisableLicence: true
        }
    }
    
    componentDidMount = () => {
        this.loadLicence();
        this.loadWebHookUrl();    
    }

    loadLicence = () => {
        axios.get('/api/meta/licence', {})
        .then(res => {
            if(res.data.data){
                // console.log("licence response===", res.data.data.value);
                this.setState({ licence: res.data.data.value })
            }
        })
        .catch(err => {
            apiError(err)
        })
    }

    loadWebHookUrl = () => {
        axios.get('/api/meta/webhook', {})
        .then(res => {
            if(res.data.data){
                this.setState({ url: res.data.data.value })
            }
        })
        .catch(err => {
            apiError(err)
        })
    }

    handleWebhookurl = () => {
        if(this.state.url && this.state.url.length){
            axios.post('/api/meta/webhook', {
                webhook: this.state.url,
            })
            .then(res => {
                toast.success("Web Hook Url updated successfully");
            })
            .catch(err => {
                apiError(err);
            })
        }
    }

    render() {
        return (
            <div className="tab-wrap">
                <div className="row">
                    <div className="col-md-3">
                        <h5>API Key : </h5>
                    </div>
                    <div className="col-md-9">
                        <Form>
                            <div className="row">
                                <div className="col-md-7">
                                    <Form.Group>
                                        <div className="d-flex">
                                            <Form.Control type="text" disabled={this.state.isDisableLicence} value={this.state.licence} />
                                        </div>
                                    </Form.Group>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <h5>Webhook Url : </h5>
                    </div>
                    <div className="col-md-9">
                        <Form>
                            <div className="row">
                                <div className="col-md-7">
                                    <Form.Group>
                                        <div className="d-flex">
                                            <Form.Control type="text" placeholder="enter your webhook url" value={this.state.url} onChange={e => this.setState({ url: e.target.value })} />
                                            <Button type="button" className="btn btn-primary ml-1" onClick={this.handleWebhookurl}>Save</Button>
                                        </div>
                                    </Form.Group>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                    </div>
                    <div className="col-md-6 note-box1 mb-4">
                        <h5>Note</h5>
                        <p>Webhook type post and we will send notification of data submit event</p>
                    </div>
                </div>
            </div>
        );
    }
}