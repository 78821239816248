import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Form, Button } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';

import heart from '../../assets/heart.png';
import appsumo from '../../assets/appsumo-logo.svg';
import threatcop from '../../assets/threatcop-b.png'

import axios from '../../axios';
import apiError from '../../error';
import { setCookie } from '../../function';
import Spinner from '../../component/spinner/spinner';

import './app-sumo.css';

export default class AppSumo extends Component {

    constructor(props){
        super(props);
        this.state = {
            firstname: '',
            firstnameError: false,

            lastname: '',

            email: '',
            emailError: false,

            password: '',
            passwordError: false,

            loading: false,
            promocode: this.props.match.params.promocode,

            industryId: '0',
            industries: [],
        }
        this.loadIndustry();
    }

    validateUser = () => {
        if(!this.state.firstname || this.state.firstname.length === 0 || (this.state.firstname).trim().length === 0){
            toast.error("please enter first name");
            this.setState({ firstnameError: true });
            return false;
        }
        if(!this.state.email || this.state.email.length === 0 || (this.state.email).trim().length === 0){
            toast.error("please enter email Id");
            this.setState({ emailError: true });
            return false;
        } 
        if(!this.state.password || this.state.password.length === 0){
            toast.error("please enter password");
            this.setState({ passwordError: true });
            return false;
        } 
        if(this.state.industryId === 0 || this.state.industryId === '0' || parseInt(this.state.industryId, 10) === 0){
            toast.error('Please select industry type')
            return false;
        }
        return true;
    }

    registerUser = async () => {
        await this.setState({ loading: true });
        if(this.validateUser()){
            axios.post('/api/auth/register/appsumo', {
                firstname: this.state.firstname,
                lastname: this.state.lastname,
                email: this.state.email,
                password: this.state.password,
                promocode: this.state.promocode,
                industryId: this.state.industryId,
            })
            .then(res => {
                this.setState({ loading: false });

                localStorage['firstname'] = res.data.firstname;
                localStorage['lastname'] = res.data.lastname;
                localStorage['email'] = res.data.email;

                setCookie('token', res.data.token, 180);
                setCookie('logo', res.data.path, 30000);
                setCookie('userType', res.data.type, 30000);
                setCookie('firstname', res.data.firstname, 30000);
                setCookie('username', res.data.companyName, 30000);
                setCookie('isDashboard', res.data.dashboard, 30000);
                setCookie('billingType', res.data.billingType, 30000);

                window.location = '/guide';
            })
            .catch(err => {
                this.setState({ loading: false });
                apiError(err)
            })
        } else {
            this.setState({ loading: false });
        }
    }

    loadIndustry = () =>{
        axios.get('/api/auth/industry', {})
        .then(res => {
            this.setState({industries: res.data.industries});
        })
        .catch(err => {
            apiError(err);
        })
    }

    render() {
        return (
            <div className="content-wrap" id="appsumo">
                <div className="appsumo_warp_form" id="appSumo">
                    <div className="logo_wrap_sumo">
                        <img src={threatcop} alt="email" className="img_wrap_logo"></img>
                        <img src={heart} alt="email" className="img_wrap_logohe"></img>
                        <img src={appsumo} alt="email" className="img_wrap_logo_set"></img>
                    </div>
                    <div className="formapp_sumo">
                        <h5 className="heading_sumo_form">Welcome Sumo-lings !</h5>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Control type="text" className="form-control input_wrap" placeholder="First Name" isInvalid={this.state.firstnameError} onChange={e => this.setState({firstname: e.target.value, firstnameError: false})} />
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Control type="text" className="form-control input_wrap" placeholder="Last Name" onChange={e => this.setState({lastname: e.target.value})} />
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Control type="email" autoComplete="new-user" className="form-control input_wrap" placeholder="Email Address" isInvalid={this.state.emailError} onChange={e => this.setState({email: e.target.value, emailError: false})} />
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Control type="password" autoComplete="new-password" className="form-control input_wrap" placeholder="Your Password" isInvalid={this.state.passwordError} onChange={e => this.setState({password: e.target.value, passwordError: false})} />
                        </Form.Group>
                        <p style={{'font-size': '12px'}}>Note: Password has to be between 8 to 15 characters which contain at least one numeric digit, one uppercase, one lowercase letter and one special character($@!%*#?&)</p>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Control as="select" className="form-industry" value={this.state.industryId} onChange={e => this.setState({industryId: e.target.value})}>
                                <option value="0">Select Industry Type*</option>
                                {this.state.industries.map((industry, index) => {
                                    return <option key={index} value={industry.id}>{industry.name}</option>
                                })}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Control type="text" disabled="disabled" className="form-control input_wrap" placeholder="AppSumo code" value={this.state.promocode} />
                        </Form.Group>
                        <div className="btn_signin">
                            <Button className="set_sign_btn w-100" disabled={this.state.loading} onClick={this.registerUser}><Spinner loading={this.state.loading}></Spinner>Get Started</Button>
                        </div>
                    </div>
                </div>
                <ToastContainer position="top-right" autoClose={5000}/>
            </div>
        );
    }
}