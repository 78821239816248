import React, { Component } from 'react';
import Nav from 'react-bootstrap/Nav';
import CheckCircleOutlineSharpIcon from '@material-ui/icons/CheckCircleOutlineSharp';

import campaignTabs from '../../../campaignTabs';
import campaignActiveTabs from '../../../campaignActiveTabs';

export default class NavTabs extends Component {

    render() {
        const campaignTemplateTab = campaignTabs[this.props.simulation_type][this.props.attack_type];
        const campaignTemplateActiveTab = campaignActiveTabs[this.props.simulation_type][this.props.attack_type + '-tabs'], activeTabs = {};
        let nextDisabled = true;
        Object.keys(campaignTemplateActiveTab).map(tabName => {
            console.log({disabled: this.props[tabName]})
            if (!this.props.credential_harvesting && tabName === 'pageId') {
                return null;
            }
            if (this.props[tabName]) {
                nextDisabled = true;
                return activeTabs[tabName] = false;
            } else {
                if (nextDisabled) {
                    nextDisabled = false;
                    return activeTabs[tabName] = false;
                } else {
                    nextDisabled = false;
                    return activeTabs[tabName] = true;
                }
            }
        })
        const navTabs = () => campaignTemplateTab && campaignTemplateTab.map((tabName, index) => {
            if (!this.props.credential_harvesting && tabName === 'page') {
                return '';
            }
            switch (tabName) {
                case "campaign_name":
                    return (
                        <Nav.Item key={index} onClick={this.props.handleCampaignNameShow}>
                            <Nav.Link eventKey="first" disabled={activeTabs['campaignTemplateId']}><div className='nav-status'>
                                  <CheckCircleOutlineSharpIcon className="check-icon"></CheckCircleOutlineSharpIcon> 
                            </div>
                                <div className='navFlex'>
                                    <div className='navContentWrap '>
                                    <h6> Campaign Name</h6>
                                        <p>Fill campaign title</p>
                                        </div> 
                                    </div>
                            </Nav.Link>
                        </Nav.Item>
                    )
                case "attacker_profile":
                    return (
                        <Nav.Item key={index}>
                            <Nav.Link disabled={activeTabs['senderId']} eventKey="second">
                                   <div className='navFlex'>
                                    <div className='nav-status'>
                                         {this.props.senderId ? <CheckCircleOutlineSharpIcon className="check-icon"></CheckCircleOutlineSharpIcon> : ''}
                                    </div>
                                     <div className='navContentWrap '>
                                      <h6>Attacker Profile</h6>
                                      <p>Fill the general setting</p>
                                    </div>
                                </div>
                            </Nav.Link>
                        </Nav.Item>
                    )
                case "attacker_profile_sms":
                    return (
                        <Nav.Item key={index}>
                            <Nav.Link disabled={activeTabs['senderId']} eventKey="second-sms">
                                 <div className='navFlex'>
                                 <div className='nav-status'>
                                    {this.props.senderId ? <CheckCircleOutlineSharpIcon className="check-icon"></CheckCircleOutlineSharpIcon> : ''}
                                </div>
                                     <div className='navContentWrap '>
                                    <h6>Attacker Profile</h6>
                                    <p>Fill the general setting</p>
                                      </div>
                                </div>
                                </Nav.Link>

                        </Nav.Item>
                    )
                case "attacker_profile_call":
                    return (
                        <Nav.Item key={index}>
                            <Nav.Link disabled={activeTabs['senderId']} eventKey="second-call">
                             <div className='navFlex'>
                                 <div className='nav-status'>
                                    {this.props.senderId ? <CheckCircleOutlineSharpIcon className="check-icon"></CheckCircleOutlineSharpIcon> : ''}
                                 </div>
                                 <div className='navContentWrap'>
                                      <h6>Attacker Profile</h6>
                                         <p>Fill the general setting</p>
                                 </div>
                                </div>
                             </Nav.Link>
                        </Nav.Item>
                    )
                case "template":
                    return (
                        <Nav.Item key={index}>
                            <Nav.Link disabled={activeTabs['templateId']} eventKey="third">
                               <div className='navFlex'>
                                  <div className='nav-status'>
                                     {this.props.templateId ? <CheckCircleOutlineSharpIcon className="check-icon"></CheckCircleOutlineSharpIcon> : ''}
                                     </div>
                                    <div className='navContentWrap '>
                                        <h6>Email Template </h6>
                                            <p>Fill the general setting</p>
                                        </div>
                                    </div> 
                                 </Nav.Link>
                        </Nav.Item>
                    )
                case "template_sms":
                    return (
                        <Nav.Item key={index}>
                            <Nav.Link disabled={activeTabs['templateId']} eventKey="third-sms">
                                 <div className='navFlex'>
                                  <div className='nav-status'>
                                      {this.props.templateId ? <CheckCircleOutlineSharpIcon className="check-icon"></CheckCircleOutlineSharpIcon> : ''}
                                     </div> 
                                        <div className='navContentWrap '>
                                          <h6>SMS Template</h6> 
                                          <p>Fill the general setting</p>
                                        </div>
                                </div>
                                </Nav.Link>
                        </Nav.Item>
                    )
                case "page":
                    return (
                        <Nav.Item key={index}>
                            <Nav.Link disabled={activeTabs['pageId']} eventKey="fourth">
                                <div className='navFlex'>
                                  <div className='nav-status'>
                                      {this.props.pageId ? <CheckCircleOutlineSharpIcon className="check-icon"></CheckCircleOutlineSharpIcon> : ''}
                                   </div>
                                        <div className='navContentWrap'>
                                            <h6>Landing Page </h6>
                                            <p>Fill the general setting</p>
                                        </div>
                                    </div>
                                </Nav.Link>
                        </Nav.Item>
                    )
                case "training":
                    return (
                        <Nav.Item key={index}>
                            <Nav.Link disabled={activeTabs['trainingId']} eventKey="six">
                                <div className='navFlex'>
                                     <div className='nav-status'>
                                          {this.props.trainingId ? <CheckCircleOutlineSharpIcon className="check-icon"></CheckCircleOutlineSharpIcon> : ''}
                                      </div>
                                        <div className='navContentWrap'>
                                       <h6>Training </h6>
                                       <p>Fill the general setting</p>
                                        </div>
                                    </div>
                                </Nav.Link>
                        </Nav.Item>
                    )
                case "game":
                    return (
                        <Nav.Item key={index}>
                            <Nav.Link disabled={activeTabs['gameId']} eventKey="seven">
                                  <div className='navFlex'>
                                     <div className='nav-status'>
                                          {this.props.gameId ? <CheckCircleOutlineSharpIcon className="check-icon"></CheckCircleOutlineSharpIcon> : ''}
                                      </div>
                                     <div className='navContentWrap'>
                                        <h6>Game </h6>
                                        <p>Fill the general setting</p>
                                    </div>
                                </div>
                                </Nav.Link>
                        </Nav.Item>
                    )
                case "preview":
                    return (
                        <Nav.Item key={index}>
                            <Nav.Link disabled={activeTabs['preview']} eventKey="eight">
                                  <div className='navFlex'>
                                    <div className='nav-status'>
                                         {this.props.preview ? <CheckCircleOutlineSharpIcon className="check-icon"></CheckCircleOutlineSharpIcon> : ''}
                                     </div>
                                         <div className='navContentWrap'>
                                        <h6>Preview</h6>
                                            <p>Fill the general setting</p>
                                      </div>
                                  </div>
                                </Nav.Link>
                        </Nav.Item>
                    )
                default: return '';
            }
        })
        return (
            <Nav variant="pills" className="flex-column m-0">
                {navTabs()}
            </Nav>
        )
    }
}