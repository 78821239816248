import React, { Component } from 'react';
import { toast } from "react-toastify";
import { Button, Form,OverlayTrigger, Tooltip } from 'react-bootstrap';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';

import axios from '../../../axios';
import TestSMSModal from './testsms';
import apiError from '../../../error';
import Loader from '../../../component/loader/loader';
import Spinner from '../../../component/spinner/spinner';

export default class AttackProfileSMS extends Component{
    constructor(props){
        super(props);
        this.state = {
            apiKey: '',
            apiToken: '',
            senderId: '',

            username: '',
            password: '*****',
            landing_url: '',

            /* EnableX */
            from_address: '',
            appId: '',
            appKey: '',
            campaignId: '',
            templateId: '',

            //mCarbon
            mCarbonUsername:'',
            mCarbonPassword:'',
            mCarbonUnicode:'',
            mCarbonFromId:'',
            dltContentId:'',
            dltPrincipalEntityId:'',

            gateways: [],
            gatewayId: 0,
            loading: false,
            gatewayNameIds: {},
            disableState: false,

            callerId: '',
            authToken: '',
            accountSid: '',
            callerIds: [],

            phone: '',
            show: false,
        }
        this.load();
    }

    loadGatway = () => {
        axios.get('/api/smsgateway', {})
        .then(res => {
            this.setState({ gateways: res.data.sms_gateway })
            if(res.data.sms_gateway && res.data.sms_gateway.length){
                let gatewayNameIds = {}
                for(var i=0; i<res.data.sms_gateway.length; i++){
                    gatewayNameIds[res.data.sms_gateway[i].id] = res.data.sms_gateway[i].name;
                }
                this.setState({ gatewayNameIds: gatewayNameIds }, () => {
                    if(this.state.gatewayId && gatewayNameIds[this.state.gatewayId] === 'Twilio'){
                        this.loadCallerIds();
                    }
                })
            }
        })
        .catch(error => apiError(error))
    }

    load = () => {
        if(this.props.senderId){
            axios.get('/api/senders/'+this.props.senderId, {})
            .then(async res => {
                // const appKey = await dcryptPassword(res.data.sender.password);
                if(res.data.sender){
                    this.setState({ 
                        disableState: true,
                        apiToken: res.data.sender.host,
                        apiKey: res.data.sender.username,
                        username: res.data.sender.username,
                        callerId: res.data.sender.callerId,
                        from_address: res.data.sender.from_address ? `${res.data.sender.from_address}`: '',
                        appId: res.data.sender.username ? `${res.data.sender.username}`: '',
                        appKey: res.data.sender.name ? `${res.data.sender.name}`: '',
                        campaignId: res.data.sender.campaignId ? `${res.data.sender.campaignId}`: '',
                        templateId: res.data.sender.callerId ? `${res.data.sender.callerId}`: '',
                        accountSid: res.data.sender.username,
                        senderId: res.data.sender.from_address,
                        authToken: res.data.sender.from_address,
                        gatewayId: res.data.sender.smsGatewayId,
                        mCarbonUsername: res.data.sender.name? res.data.sender.name: '',
                        mCarbonPassword: res.data.sender.mCarbonPassword? res.data.sender.mCarbonPassword:'',
                        mCarbonFromId: res.data.sender.from_address? res.data.sender.from_address: '',
                        dltContentId: res.data.sender.dltContentId? res.data.sender.dltContentId:'',
                        dltPrincipalEntityId: res.data.sender.dltPrincipalEntityId? res.data.sender.dltPrincipalEntityId: '',
                        // smsworksSender:res.data.sender.smsworksSender? res.data.sender.smsworksSender: '',
                    }, () => {
                        this.loadGatway();
                    })
                }
            })
            .catch(error => apiError(error))
        } else {
            this.loadGatway();
        }
    }

    validateSenderExotel = () => {
        if(!this.state.gatewayId || this.state.gatewayId.length === 0){
            toast.error("Please select sms gatway");
            return false;
        }
        if(!this.state.apiKey || this.state.apiKey.length === 0){
            toast.error("Please enter api Key");
            return false;
        }
        if(!this.state.apiToken || this.state.apiToken.length === 0){
            toast.error("Please enter api token");
            return false;
        }
        if(!this.state.senderId || this.state.senderId.length === 0){
            toast.error("Please enter sender Id");
            return false;
        }
        return {
            apiKey: this.state.apiKey,
            apiToken: this.state.apiToken,
            senderId: this.state.senderId,
            gatewayId: this.state.gatewayId,
            campaignTemplateId: this.props.campaignTemplateId
            
        }
    }

    validateSenderTextGuru = () => {
        if(!this.state.gatewayId || this.state.gatewayId.length === 0){
            toast.error("Please select sms gatway");
            return false;
        }
        if(!this.state.username || this.state.username.length === 0){
            toast.error("Please enter username");
            return false;
        }
        if(!this.state.password || this.state.password.length === 0){
            toast.error("Please enter password");
            return false;
        }
        if(!this.state.senderId || this.state.senderId.length === 0){
            toast.error("Please enter sender Id");
            return false;
        }
        return {
            username: this.state.username,
            password: this.state.password,
            senderId: this.state.senderId,
            gatewayId: this.state.gatewayId,
            campaignTemplateId: this.props.campaignTemplateId
        }
    }

    validateSenderTwilio = () => {
        if(!this.state.gatewayId || this.state.gatewayId.length === 0){
            toast.error("Please select sms gatway");
            return false;
        }
        if(!this.state.accountSid || this.state.accountSid.length === 0){
            toast.error("Please enter account sid");
            return false;
        }
        if(!this.state.authToken || this.state.authToken.length === 0){
            toast.error("Please enter auth token");
            return false;
        }
        if(!this.state.callerId || this.state.callerId.length === 0 || this.state.callerId === "0"){
            toast.error("Please select caller Id");
            return false;
        }
        return {
            callerId: this.state.callerId,
            gatewayId: this.state.gatewayId,
            authToken: this.state.authToken,
            accountSid: this.state.accountSid,
            campaignTemplateId: this.props.campaignTemplateId
        }
    }

    validateSenderEnableX= () => {
        if(!this.state.gatewayId || this.state.gatewayId.length === 0){
            toast.error("Please select sms gatway");
            return false;
        }
        if(!this.state.appId || this.state.appId.length === 0){
            toast.error("Please enter APP_ID");
            return false;
        }
        if(!this.state.appKey || this.state.appKey.length === 0){
            toast.error("Please enter auth token");
            return false;
        }
        if(!this.state.campaignId || this.state.campaignId.length === 0 || this.state.campaignId === "0"){
            toast.error("Please enter campaign id");
            return false;
        }
        if(!this.state.templateId || this.state.templateId.length === 0 || this.state.templateId === "0"){
            toast.error("Please enter template id");
            return false;
        }

        /**
         * campaignId is specific to EnableX API which allows organisations to categorise their messaging
         * configuration based on different project or use-cases
         * */
        return {
            campaignId: this.state.campaignId,
            gatewayId: this.state.gatewayId,
            from_address: this.state.from_address,
            appId: this.state.appId,
            appKey: this.state.appKey,
            campaignTemplateId: this.props.campaignTemplateId,
            templateId: this.state.templateId,
        }
    }

    validateSenderMCarbon= () => {
        if(!this.state.gatewayId || this.state.gatewayId.length === 0){
            toast.error("Please select sms gatway");
            return false;
        }
        if(!this.state.mCarbonUsername || this.state.mCarbonUsername.length === 0){
            toast.error("Please enter Username");
            return false;
        }
        if(!this.state.mCarbonPassword || this.state.mCarbonPassword.length === 0){
            toast.error("Please enter Password");
            return false;
        }
        if(!this.state.mCarbonFromId || this.state.mCarbonFromId === 0 || this.state.mCarbonFromId === "0"){
            toast.error("Please enter Sender Id");
            return false;
        }
        if(!this.state.dltContentId || this.state.dltContentId.length === 0 || this.state.dltContentId === "0"){
            toast.error("Please enter DLT content id");
            return false;
        }
        if(!this.state.dltPrincipalEntityId || this.state.dltPrincipalEntityId.length === 0 || this.state.dltPrincipalEntityId === "0"){
            toast.error("Please enter DLT principle entity id");
            return false;
        }
    
        return {
            gatewayId: this.state.gatewayId,
            mCarbonFromId: this.state.mCarbonFromId,
            mCarbonUsername: this.state.mCarbonUsername,
            mCarbonPassword: this.state.mCarbonPassword,
            dltContentId: this.state.dltContentId,
            dltPrincipalEntityId: this.state.dltPrincipalEntityId,
            campaignTemplateId: this.props.campaignTemplateId,
        }
    }

    validateSenderMCarbonInternational= () => {
        if(!this.state.gatewayId || this.state.gatewayId.length === 0){
            toast.error("Please select sms gatway");
            return false;
        }
        if(!this.state.mCarbonUsername || this.state.mCarbonUsername.length === 0){
            toast.error("Please enter Username");
            return false;
        }
        if(!this.state.mCarbonPassword || this.state.mCarbonPassword.length === 0){
            toast.error("Please enter Password");
            return false;
        }
        if(!this.state.mCarbonFromId || this.state.mCarbonFromId === 0 || this.state.mCarbonFromId === "0"){
            toast.error("Please enter Sender Id");
            return false;
        }
        // if(!this.state.dltContentId || this.state.dltContentId.length === 0 || this.state.dltContentId === "0"){
        //     toast.error("Please enter DLT content id");
        //     return false;
        // }
        // if(!this.state.dltPrincipalEntityId || this.state.dltPrincipalEntityId.length === 0 || this.state.dltPrincipalEntityId === "0"){
        //     toast.error("Please enter DLT principle entity id");
        //     return false;
        // }

        return {
            gatewayId: this.state.gatewayId,
            mCarbonFromId: this.state.mCarbonFromId,
            mCarbonUsername: this.state.mCarbonUsername,
            mCarbonPassword: this.state.mCarbonPassword,
            // dltContentId: this.state.dltContentId,
            // dltPrincipalEntityId: this.state.dltPrincipalEntityId,
            campaignTemplateId: this.props.campaignTemplateId,
        }
    }

    validateSenderSmsworks= () => {
        if(!this.state.gatewayId || this.state.gatewayId.length === 0){
            toast.error("Please select sms gatway");
            return false;
        }
        if(!this.state.from_address || this.state.from_address.length === 0){
            toast.error("Please enter senderId");
            return false;
        }

        return {
            gatewayId: this.state.gatewayId,
            smsworksSender: this.state.from_address,
            campaignTemplateId: this.props.campaignTemplateId,
        }
    }

    

    handleCreateSender = () => {
        if(this.state.gatewayNameIds[this.state.gatewayId] === "Exotel"){
            const data = this.validateSenderExotel();
            if(data){
                this.setState({ loading: true })
                if(this.props.senderId){
                    axios.patch('/api/senders/sms/Exotel/'+this.props.senderId, data )
                    .then(res => {
                        this.setState({ loading: false })
                        this.props.handleTabChange('next');
                    })
                    .catch(error => {
                        this.setState({ loading: false })
                        apiError(error)
                    })
                }else {
                    axios.post('/api/senders/sms/Exotel', data )
                    .then(res => {
                        this.setState({ loading: false })
                        this.props.handleTabChange('next');
                        this.props.handleSenderId(res.data.sender.id);
                        toast.success('Attack profile created successfully') 
                    })
                    .catch(error => {
                        this.setState({ loading: false })
                        apiError(error)
                    })
                }
            }
        } else if(this.state.gatewayNameIds[this.state.gatewayId] === "TextGuru"){
            const data = this.validateSenderTextGuru();
            if(data){
                this.setState({ loading: true })
                if(this.props.senderId){
                    axios.patch('/api/senders/sms/TextGuru/'+this.props.senderId, data )
                    .then(res => {
                        this.setState({ loading: false })
                        this.props.handleTabChange('next');
                    })
                    .catch(error => {
                        this.setState({ loading: false })
                        apiError(error)
                    })
                }else {
                    axios.post('/api/senders/sms/TextGuru', data )
                    .then(res => {
                        this.setState({ loading: false })
                        this.props.handleTabChange('next');
                        this.props.handleSenderId(res.data.sender.id);
                        toast.success('Attack profile created successfully') 
                    })
                    .catch(error => {
                        this.setState({ loading: false })
                        apiError(error)
                    })
                }
            }
        } else if(this.state.gatewayNameIds[this.state.gatewayId] === "Twilio"){
            const data = this.validateSenderTwilio();
            if(data){
                this.setState({ loading: true })
                if(this.props.senderId){
                    axios.patch('/api/senders/sms/Twilio/'+this.props.senderId, data )
                    .then(res => {
                        this.setState({ loading: false })
                        this.props.handleTabChange('next');
                    })
                    .catch(error => {
                        this.setState({ loading: false })
                        apiError(error)
                    })
                }else {
                    axios.post('/api/senders/sms/Twilio', data )
                    .then(res => {
                        this.setState({ loading: false })
                        this.props.handleTabChange('next');
                        this.props.handleSenderId(res.data.sender.id);
                        toast.success('Attack profile created successfully') 
                    })
                    .catch(error => {
                        this.setState({ loading: false })
                        apiError(error)
                    })
                }
            }
        } else if(this.state.gatewayNameIds[this.state.gatewayId] === "EnableX") {
            const data = this.validateSenderEnableX();
            if(data) {
                this.setState({ loading: true })
                if(this.props.senderId){
                    axios.patch('/api/senders/sms/enablex/'+this.props.senderId, data )
                    .then(res => {
                        this.setState({ loading: false })
                        this.props.handleTabChange('next');
                    })
                    .catch(error => {
                        this.setState({ loading: false })
                        apiError(error)
                    })
                } else {
                    axios.post('/api/senders/sms/enablex', data)
                    .then(res => {
                        this.setState({ loading: false })
                        this.props.handleTabChange('next');
                        this.props.handleSenderId(res.data.sender.id);
                        toast.success('Attack profile created successfully') 
                    })
                    .catch(error => {
                        this.setState({ loading: false })
                        apiError(error)
                    })
                }
            }
        }else if(this.state.gatewayNameIds[this.state.gatewayId] === "mCarbon") {
            const data = this.validateSenderMCarbon();
            if(data) {
                this.setState({ loading: true });
                if(this.props.senderId){
                    axios.patch('/api/senders/sms/mCarbon/'+this.props.senderId, data )
                    .then(res => {
                        this.setState({ loading: false })
                        this.props.handleTabChange('next');
                    })
                    .catch(error => {
                        this.setState({ loading: false })
                        apiError(error)
                    })
                } else {
                    axios.post('/api/senders/sms/mCarbon', data)
                    .then(res => {
                        this.setState({ loading: false })
                        this.props.handleTabChange('next');
                        this.props.handleSenderId(res.data.sender.id);
                        toast.success('Attack profile created successfully') 
                    })
                    .catch(error => {
                        this.setState({ loading: false })
                        apiError(error)
                    })
                }
            }
        } else if(this.state.gatewayNameIds[this.state.gatewayId] === "mCarbonInternational") {
            const data = this.validateSenderMCarbonInternational();
            if(data) {
                this.setState({ loading: true });
                if(this.props.senderId){
                    axios.patch('/api/senders/sms/mCarbonIntl/'+this.props.senderId, data )
                    .then(res => {
                        this.setState({ loading: false })
                        this.props.handleTabChange('next');
                    })
                    .catch(error => {
                        this.setState({ loading: false })
                        apiError(error)
                    })
                } else {
                    axios.post('/api/senders/sms/mCarbonIntl', data)
                    .then(res => {
                        this.setState({ loading: false })
                        this.props.handleTabChange('next');
                        this.props.handleSenderId(res.data.sender.id);
                        toast.success('Attack profile created successfully') 
                    })
                    .catch(error => {
                        this.setState({ loading: false })
                        apiError(error)
                    })
                }
            }
        } else if(this.state.gatewayNameIds[this.state.gatewayId] === "smsworks") {
            const data = this.validateSenderSmsworks();
            if(data) {
                this.setState({ loading: true });
                if(this.props.senderId){
                    axios.patch('/api/senders/sms/smsworks/'+this.props.senderId, data )
                    .then(res => {
                        this.setState({ loading: false })
                        this.props.handleTabChange('next');
                    })
                    .catch(error => {
                        this.setState({ loading: false })
                        apiError(error)
                    })
                } else {
                    axios.post('/api/senders/sms/smsworks', data)
                    .then(res => {
                        this.setState({ loading: false })
                        this.props.handleTabChange('next');
                        this.props.handleSenderId(res.data.sender.id);
                        toast.success('Attack profile created successfully') 
                    })
                    .catch(error => {
                        this.setState({ loading: false })
                        apiError(error)
                    })
                }
            }
        } else toast.success('something went wrong with data selected') ;
    }

    loadCallerIds = (status = false) => {
        if(!this.state.accountSid || this.state.accountSid.length === 0){
            toast.error("Please enter account sid");
            return false;
        }
        if(!this.state.authToken || this.state.authToken.length === 0){
            toast.error("Please enter auth token");
            return false;
        }
        this.setState({ loading: true });
        axios.post('/api/senders/sms/callerId', {
            authToken: this.state.authToken,
            accountSid: this.state.accountSid,
        })
        .then(res => {
            if(status) toast.success("Caller Id loaded successfully, please select caller Id");
            this.setState({ loading: false, callerIds: res.data.callerIds })
        })
        .catch(error => {
            this.setState({ loading: false, callerIds: [] })
            apiError(error)
        })
    }

    sendTestSMS = () => {
        let data = {};
        let apiEndpoint = '';
        if(this.state.gatewayNameIds[this.state.gatewayId] === "EnableX") {
            data = this.validateSenderEnableX();
            apiEndpoint = '/api/senders/enablex';
        } else if(this.state.gatewayNameIds[this.state.gatewayId] === "Twilio"){
            data = this.validateSenderTwilio();
            apiEndpoint = '/api/senders/twilio';
        }else if(this.state.gatewayNameIds[this.state.gatewayId] === "mCarbon"){
            data = this.validateSenderMCarbon();
            apiEndpoint = '/api/senders/mCarbon';
        }else if(this.state.gatewayNameIds[this.state.gatewayId] === "mCarbonInternational"){
            data = this.validateSenderMCarbonInternational();
            apiEndpoint = '/api/senders/mCarbonIntl';
        }
        //smsworks to be added
        if(data){
            if(!this.state.phone || this.state.phone.length === 0){
                toast.error("Please enter Phone number");
                return false;
            } else {
                data['phone'] = this.state.phone;
                this.setState({ loading: true });
                axios.post(apiEndpoint, data)
                .then(res => {
                    if(res){
                    this.setState({ loading: false, show: false });
                    toast.success("SMS send successfully.");
                    }
                })
                .catch(error => {
                    this.setState({ loading: false })
                    apiError(error)
                })
            }
        }
    }

    render(){
        return (
            <>
            <div className="email-template-wrap">
                    <div className='rightSectionHeader'>
                        <div className="row justify-content-between align-items-center">
                            <div className="col-md-6 tab-right-header">
                                <h6>Create An Attack Profile</h6>
                                <p>Create email template</p>
                            </div>
                                
                            </div>
                        </div>

            
                <div className="row">
                    <div className="col-md-6">
                        <div className="attackheader">
                            <h5>SMS Profile</h5>
                        </div>
                        <Form.Control as="select" disabled={this.state.disableState} value={this.state.gatewayId} onChange={e => this.setState({gatewayId: e.target.value})} className="mt-2 mb-2">
                            <option value='0'>Select SMS Gateway</option>
                            {this.state.gateways.map((gatway, index) => {
                                return <option key={index} value={gatway.id}>{gatway.name}</option>
                            })}
                        </Form.Control>
                        {this.state.gatewayNameIds[this.state.gatewayId] === "Exotel"?
                            <div>
                                <Form.Group>
                                    <Form.Label>API KEY</Form.Label>
                                    <Form.Control type="text" placeholder="please enter api key" value={this.state.apiKey} onChange={e => this.setState({apiKey: e.target.value})} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>API TOKEN</Form.Label>
                                    <Form.Control type="text" placeholder="please enter api token" value={this.state.apiToken} onChange={e => this.setState({apiToken: e.target.value})}/>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>SENDER ID</Form.Label>
                                    <Form.Control type="text" placeholder="please enter sender id" value={this.state.senderId} onChange={e => this.setState({senderId: e.target.value})}/>
                                </Form.Group>
                            </div>:''
                        }
                        {this.state.gatewayNameIds[this.state.gatewayId] === "TextGuru"?
                            <div>
                               <Form.Group>
                                    <Form.Label>Username</Form.Label>
                                    <Form.Control type="text" autoComplete="new-user" placeholder="Username" value={this.state.username} onChange={e => this.setState({username: e.target.value})}/>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" autoComplete="new-password" placeholder="*****" value={this.state.password} onChange={e => this.setState({password: e.target.value})}/>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>SENDER ID</Form.Label>
                                    <Form.Control type="text" placeholder="please enter sender id" value={this.state.senderId} onChange={e => this.setState({senderId: e.target.value})}/>
                                </Form.Group>
                            </div>:''
                        }
                        {this.state.gatewayNameIds[this.state.gatewayId] === "Twilio"?
                            <div>
                                <Form.Group>
                                    <Form.Label>ACCOUNT SID</Form.Label>
                                    <Form.Control type="text" placeholder="please enter account sid" value={this.state.accountSid} onChange={e => this.setState({accountSid: e.target.value})} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>AUTH TOKEN</Form.Label>
                                    <Form.Control type="text" placeholder="please enter auth token" value={this.state.authToken} onChange={e => this.setState({authToken: e.target.value})}/>
                                </Form.Group>
                                <Form.Label>CALLER ID</Form.Label>
                                <div className="row">
                                    <div className="col-md-8">
                                        <Form.Control value={this.state.callerId} onChange={e => this.setState({callerId: e.target.value})} as="select">
                                            <option value='0'>Select Caller ID</option>
                                            {this.state.callerIds.map((callerId, index) => {
                                                return <option key={index} value={callerId.phoneNumber}>{callerId.phoneNumber}</option>
                                            })}
                                        </Form.Control>
                                    </div>
                                    <div className="col-md-4">
                                        <Button variant="success" onClick={e => this.loadCallerIds(true)}>Load Caller Id</Button>
                                    </div>
                                </div>
                            </div>:''
                        }
                        {this.state.gatewayNameIds[this.state.gatewayId] === "TextLocal"?
                            <div> work in progress </div>:''
                        }

                        {this.state.gatewayNameIds[this.state.gatewayId] === "EnableX"?
                            <div>
                                <Form.Group>
                                    <Form.Label>FROM</Form.Label>
                                    <Form.Control type="text" placeholder="Enter the from address" value={this.state.from_address} autoComplete='false' onChange={e => this.setState({from_address: `${e.target.value}`})}/>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>APP_ID</Form.Label>
                                    <Form.Control type="text" placeholder="Enter the APP_ID" value={this.state.appId} autoComplete='false' onChange={e => this.setState({appId: `${e.target.value}`})}/>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>APP_KEY</Form.Label>
                                    <Form.Control type="text" placeholder="*****" value={this.state.appKey} autoComplete='false' onChange={e => this.setState({appKey: `${e.target.value}`})}/>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Campaign_ID</Form.Label>
                                    <Form.Control type="text" placeholder="please enter campaign id" value={this.state.campaignId} onChange={e => this.setState({campaignId: `${e.target.value}` })}/>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Template ID</Form.Label>
                                    <Form.Control type="text" placeholder="please enter template id" value={this.state.templateId} onChange={e => this.setState({templateId: `${e.target.value}` })}/>
                                </Form.Group>
                            </div>:''
                        }

                        {this.state.gatewayNameIds[this.state.gatewayId] === "mCarbon"?
                            <div>
                                <Form.Group>
                                    <Form.Label>FROM</Form.Label>
                                    <Form.Control type="text" placeholder="Enter the sender ID" value={this.state.mCarbonFromId} autoComplete='false' onChange={e => this.setState({mCarbonFromId: `${e.target.value}`})}/>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>USERNAME</Form.Label>
                                    <Form.Control type="text" placeholder="Enter the mCarbon username" value={this.state.mCarbonUsername} autoComplete='false' onChange={e => this.setState({mCarbonUsername: `${e.target.value}`})}/>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>PASSWORD</Form.Label>
                                    <Form.Control type="text" placeholder="********" value={this.state.mCarbonPassword} autoComplete='false' onChange={e => this.setState({mCarbonPassword: `${e.target.value}`})}/>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>DLT CONTENT ID</Form.Label>
                                    <Form.Control type="text" placeholder="please enter DLT content id" value={this.state.dltContentId} onChange={e => this.setState({dltContentId: `${e.target.value}` })}/>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>DLT PRINCIPLE ENTITY ID</Form.Label>
                                    <Form.Control type="text" placeholder="please enter DLT priciple id" value={this.state.dltPrincipalEntityId} onChange={e => this.setState({dltPrincipalEntityId: `${e.target.value}` })}/>
                                </Form.Group>
                            </div>:''
                        }
                        {this.state.gatewayNameIds[this.state.gatewayId] === "mCarbonInternational"?
                            <div>
                                <Form.Group>
                                    <Form.Label>FROM</Form.Label>
                                    <Form.Control type="text" placeholder="Enter the sender ID" value={this.state.mCarbonFromId} autoComplete='false' onChange={e => this.setState({mCarbonFromId: `${e.target.value}`})}/>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>USERNAME</Form.Label>
                                    <Form.Control type="text" placeholder="Enter the mCarbon username" value={this.state.mCarbonUsername} autoComplete='false' onChange={e => this.setState({mCarbonUsername: `${e.target.value}`})}/>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>PASSWORD</Form.Label>
                                    <Form.Control type="text" placeholder="********" value={this.state.mCarbonPassword} autoComplete='false' onChange={e => this.setState({mCarbonPassword: `${e.target.value}`})}/>
                                </Form.Group>
                                {/* <Form.Group>
                                    <Form.Label>DLT CONTENT ID</Form.Label>
                                    <Form.Control type="text" placeholder="please enter DLT content id" value={this.state.dltContentId} onChange={e => this.setState({dltContentId: `${e.target.value}` })}/>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>DLT PRINCIPLE ENTITY ID</Form.Label>
                                    <Form.Control type="text" placeholder="please enter DLT priciple id" value={this.state.dltPrincipalEntityId} onChange={e => this.setState({dltPrincipalEntityId: `${e.target.value}` })}/>
                                </Form.Group> */}
                            </div>:''
                        }
                        {this.state.gatewayNameIds[this.state.gatewayId] === "smsworks" ?
                            <div>
                                <Form.Group>
                                    <Form.Label>FROM</Form.Label>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Form.Control 
                                            type="text" 
                                            placeholder="Enter the sender ID" 
                                            value={this.state.from_address} 
                                            autoComplete='false' 
                                            onChange={e => this.setState({from_address: `${e.target.value}`})}
                                        />
                                        <OverlayTrigger
                                            placement='right'
                                            overlay={
                                                <Tooltip id='right'>
                                                    Sender Id must be an alphanumeric string of 3-11 characters and can not contain the following names: 2FA, Accept, Access, Active, Alert, App, Appointment,
                                                    Auth, Aware, Bank, Banking, Call, Card, Caution, Code, Confirm, Contact, Control, Delivery, Energy,
                                                    Fraud, Help, Info, Loan, Login, Logistics, Message, Mobile, MSG, Network, Notify, Order, OTP, Parcel,
                                                    Pay, Payment, Pin, Rebate, Receipt, Reminder, Reply, Schedule, Secure, Shipping, SMS, Support, Text,
                                                    Trace, TXT, Update, Verify, Winner
                                                </Tooltip>
                                            }>
                                            <HelpOutlineIcon className="tooltip-icon" style={{ marginTop: '-5px' }} />
                                        </OverlayTrigger>
                                    </div>
                                </Form.Group>
                            </div>
                            : ''
                        }
                    </div>
                </div>
             </div>


             <div className="row mt-2">
                     <div className='footer-wrapper justify-content-end d-flex'> 
                        <Button className="btn closeBtn mr-2" onClick={e => this.props.handleTabChange('prev')}><ArrowBackOutlinedIcon className="btn-icon"></ArrowBackOutlinedIcon> Previous</Button>
                        <Button variant="success" disabled={this.state.loading} type="button" onClick={this.handleCreateSender}><Spinner loading={this.state.loading}></Spinner><ArrowForwardOutlinedIcon className={this.state.loading?"btn-icon d-none": "btn-icon"}></ArrowForwardOutlinedIcon> Go To Next</Button>
                    </div>
                </div>


                
                <TestSMSModal
                    show={this.state.show}
                    phone={this.state.phone}
                    loading={this.state.loading}
                    handlePhone={e => this.setState({ phone: e.target.value })}
                    sendTestSMS={this.sendTestSMS}
                    // handlePhone={e => this.setState({ phone: e.target.value })}
                    handleSMSModal={e => this.setState({ show: !this.state.show })}
                ></TestSMSModal>
                {this.state.loading? <Loader></Loader>: null}
           </>
        )
    }
}