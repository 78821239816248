import React, { Component } from 'react';
import { toast } from "react-toastify";
import { GoChecklist } from "react-icons/go";
import AddSharpIcon from "@material-ui/icons/AddSharp";
import { PiArrowsOutThin } from "react-icons/pi";
import VisibilitySharpIcon from '@material-ui/icons/VisibilitySharp';
import { Button, Form, Badge, Table, Image, Modal, Tabs, Tab } from 'react-bootstrap';
import { PiCropThin } from "react-icons/pi";
import axios from '../../../axios';
import apiError from '../../../error';
import { getDate } from '../../../function';
import temp from '../../../assets/temp.png';
import Loader from '../../loader/loader';
import PreviewThumbnail from '../../preview/preview';
import TestEmails from '../../test-campaign-email/test-campaign-email';
import imageDefault from "../../../assets/campaign-thumbnail-phishing.png"

import './campaign-preview.css'
import { Diversity1Sharp } from '@mui/icons-material';

export default class CampaignPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: '',
            page: null,
            game: null,
            user: null,
            emails: '',
            loading: true,
            template: null,
            category: null,
            language: null,
            subject: null,
            training: null,
            bluePage: false,
            attackType: null,
            categoryId: null,
            showModal: false,
            landing_url: null,
            campaignName: null,
            from_address: null,
            awarenessPath: null,
            redirect_url: false,
            simulationType: null,
            showTestModal: false,
            emailTracking: false,
            capture_passwords: false,
            capture_credentials: false,
            credential_harvesting: false,

            templateName: null,
            brodcastName: null,

            imageURL: '',
            courseName: '',
            previewURL: '',
            lmsCourses: [],
            courseIds: [],
            myWindow: null,

            currentSrc: '',
            showThumbnail: false,

            firstname: '',
            lastname: '',
            tab: 'simulation',
        }
        this.load();
    }

    load = () => {
        console.log(this.props);
        // if(!parseInt(this.props.campaignTemplateId, 10)) return;
        this.setState({ loading: true })
        axios.get('/api/campaign/template/preview/' + parseInt(this.props.campaignTemplateId, 10), {})
            .then(res => {
                var campaignTemplate = res.data.campaign_template;
                this.setState({
                    loading: false,
                    user: res.data.user,
                    pageImg: res.data.pagePath,
                    game: campaignTemplate.game,
                    type: campaignTemplate.type,
                    templateImg: res.data.templatePath,
                    campaignName: campaignTemplate.name,
                    training: campaignTemplate.courseId,
                    awarenessPath: res.data.awarenessPath,
                    credential_harvesting: campaignTemplate.credential_harvesting,
                    from_address: campaignTemplate.sender ? campaignTemplate.sender.from_address : null,

                    subject: campaignTemplate.template ? campaignTemplate.template.subject : null,
                    emailTracking: campaignTemplate.template ? campaignTemplate.template.emailTracking : null,

                    templateName: campaignTemplate.template ? campaignTemplate.template.templateName : null,
                    brodcastName: campaignTemplate.template ? campaignTemplate.template.brodcastName : null,

                    category: campaignTemplate.category ? campaignTemplate.category.name : null,
                    categoryId: campaignTemplate.category ? campaignTemplate.category.id : null,
                    language: campaignTemplate.language ? campaignTemplate.language.name : null,
                    attackType: campaignTemplate.attack_type ? campaignTemplate.attack_type.name : null,
                    simulationType: campaignTemplate.simulation_type ? campaignTemplate.simulation_type.name : {},

                    bluePage: campaignTemplate.page ? campaignTemplate.page.bluePage : null,
                    landing_url: campaignTemplate.page ? campaignTemplate.page.landing_url : null,
                    capture_passwords: campaignTemplate.page ? campaignTemplate.page.capture_passwords : null,
                    capture_credentials: campaignTemplate.page ? campaignTemplate.page.capture_credentials : null,
                    redirect_url: campaignTemplate && campaignTemplate.credential_harvesting && campaignTemplate.page ? campaignTemplate.page.redirect_url : res.data.awarenessUrl,
                })
                if (campaignTemplate.courseId) {
                    var courseIds = [];
                    for (let i = 0; i < campaignTemplate.courses.length; i++) {
                        courseIds.push(campaignTemplate.courses[i].courseId)
                    }
                    this.setState({ courseIds: courseIds }, () => {
                        this.loadCourseLMS();
                    })
                }
            })
            .catch(error => {
                this.setState({ loading: false })
                apiError(error)
            })
    }

    testCampaign = (targetDetails) => {
        if (parseInt(this.props.campaignTemplateId, 10)) {
            if (targetDetails.length && targetDetails[0].emails && targetDetails[0].emails.length) {
                this.setState({ loading: true })
                axios.post('/api/simulation/test', {
                    targetDetails,
                    campaignTemplateId: parseInt(this.props.campaignTemplateId, 10),
                })
                    .then(res => {
                        toast.success("Test campaign run successfully");
                        this.setState({ loading: false, showTestModal: false })
                        setTimeout(() => { window.open('/campaign/test-results/?attackTypeId=' + this.state.attackType) }, 500);
                        this.setState({ loading: false })
                    })
                    .catch(error => {
                        this.setState({ loading: false })
                        apiError(error);
                    })
            } else {
                if (this.state.attackType === "Phishing" || this.state.attackType === "Ransomware" || this.state.attackType === "Attachment") {
                    toast.error("Please enter emailId");
                } else {
                    toast.error("Please enter Phone No.");
                }
            }
        } else toast.error("invalid campaign template");
    }

    launchCampaignTemplate = () => {
        let url = '/templates/launch/' + this.state.simulationType + '/' + this.state.attackType + '/' + this.state.credential_harvesting;
        let search = '?type=' + this.state.type + '&category=' + this.state.categoryId + '&ctID=' + parseInt(this.props.campaignTemplateId, 10);
        this.props.history.push({
            pathname: url,
            search: search
        })
    }

    loadCourseLMS = async () => {
        await this.setState({ loading: true });
        axios.get('/api/lms/course-detail', {
            params: {
                courseIds: this.state.courseIds
            }
        })
            .then(res => {
                var courseName = {};
                for (let i = 0; i < res.data.courses.length; i++) {
                    courseName[res.data.courses[i].id] = res.data.courses[i].name
                }
                this.setState({
                    loading: false,
                    courseName: courseName,
                    imageURL: res.data.imageURL,
                    lmsCourses: res.data.courses,
                    previewURL: res.data.previewURL,
                });
            })
            .catch(err => {
                this.setState({ loading: false });
                apiError(err)
            })
    }

    handleLMS = async (courseId) => {
        axios.get('/api/lms', {
            params: {
                url: `${this.state.previewURL}${courseId}?r=Sw4s56g`
            }
        })
            .then(res => {
                window.open(res.data.url, '_blank');
            })
            .catch(err => {
                apiError(err);
            });
    }

    render() {
        return (
            <Modal className="info-filter right right-preview-modal" show={this.props.show} onHide={() => this.props.handleModal(false)} autoFocus keyboard>
                <Modal.Header closeButton>
                    <div>
                        <h5>Template Details</h5>
                        <p>View Your  template details</p>
                    </div>
                </Modal.Header>



                <Modal.Body>

                    <div id="preview">

                        <div className="filter-wrapper filter-padding">
                            <div className="alignItemsCenter row">
                                <div className="col-md-3 p-0">
                                    <div className="positionSearchIcon">
                                        <p class="textMuted">Template / Preview / Template details</p>
                                    </div>
                                </div>

                                <div className="col-sm-9 flexFilterButtons">
                                    <div className='btn-container float-right d-flex align-items-center'>
                                        <Button variant="primary" className="mr-2" onClick={this.launchCampaignTemplate}> <AddSharpIcon className="btn-icon"></AddSharpIcon>Create Campaign</Button>
                                        <Button variant="primary" onClick={e => this.setState({ showTestModal: true })}><GoChecklist className="btn-icon"></GoChecklist>Test Campaign Template</Button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='templateDetailsWrapper'>
                            <div className='row'>
                                <div className="col-md-6">
                                    <div className='templateList'>
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td className='keyHeading'>Template Name:</td>
                                                    <td className='valueHeading'>{this.state.campaignName}</td>
                                                </tr>

                                                <tr>
                                                    <td className='keyHeading'>Template Category: </td>
                                                    <td className='valueHeading'><span className="p-badge">{this.state.category}</span></td>
                                                </tr>

                                                <tr>
                                                    <td className='keyHeading'>Language:</td>
                                                    <td className='valueHeading'>{this.state.language}</td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className='templateList'>
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td className='keyHeading'>Type of Simulation :</td>
                                                    <td className='valueHeading'>{this.state.simulationType}</td>
                                                </tr>

                                                <tr>
                                                    <td className='keyHeading'>Attack Vector : </td>
                                                    <td className='valueHeading'>{this.state.attackType}</td>
                                                </tr>

                                                <tr>
                                                    <td className='keyHeading'>Credential Harvesting :</td>
                                                    <td className='valueHeading'> {this.state.credential_harvesting && this.state.attackType !== "Vishing" ? <p className="badgeYn yes-badges">Yes</p> : <p className="badgeYn no-badges">No</p>}</td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='tabTamplate' id="tab-wrapper">
                            <Tabs activeKey={this.state.tab} onSelect={(tab) => this.setState({ tab: tab })}>
                                <Tab eventKey="simulation" title="simulation">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className='contHeading'>
                                                <h6>Email Template </h6>
                                            </div>

                                            <div className="img-hover-wrap templateView" onClick={e => {
                                                    this.setState({
                                                     showThumbnail: true,
                                                      currentSrc: this.state.templateImg
                                                        })
                                                     }}>
                                                 <div className='cardImg'>
                                                    <img src={!this.state.templateImg ? imageDefault : this.state.templateImg} className="img-fluid" alt=""></img>
                                                <div className='hover-btn'>
                                                     <PiArrowsOutThin className="btn-icon" />
                                                </div>
                                            </div>
                                         </div>
                                      </div>

                                        <div className="col-md-4">
                                            <div className='contHeading'>
                                                <h6>Landing Page </h6>
                                            </div>
                                            <div className="img-hover-wrap templateView" onClick={e => {
                                                    this.setState({
                                                    showThumbnail: true,
                                                    currentSrc: this.state.templateImg
                                                    })
                                                }}>
                                                    <div className='cardImg'>
                                                        <img src={!this.state.templateImg ? imageDefault : this.state.templateImg} className="img-fluid" alt=""></img>
                                                            <div className='hover-btn'>
                                                                <PiArrowsOutThin className="btn-icon" />
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className='contHeading'>
                                                <h6>Awareness Page </h6>
                                            </div>

                                            <div className="img-hover-wrap templateView" onClick={e => {
                                                                    this.setState({
                                                                    showThumbnail: true,
                                                                    currentSrc: this.state.templateImg
                                                                })
                                                            }}>
                                                <div className='cardImg'>
                                                    <img src={!this.state.templateImg ? imageDefault : this.state.templateImg} className="img-fluid" alt=""></img>
                                                    <div className='hover-btn'>
                                                          <PiArrowsOutThin className="btn-icon" />
                                                </div>
                                                </div>
                                             </div>
                                        </div>

                                    </div>

                                    <div className='url-wrapper'>
                                        <div className="row">
                                            <div className='col-md-4'>
                                                <div className='contHeading'>
                                                    <h6>Subject </h6>
                                                    <p>{!this.state.subject ? "No Subject Found" : this.state.subject}</p>
                                                </div>

                                                <div className='contHeading'>
                                                    <h6>Attacker Profile </h6>
                                                    <p> {!this.state.from_address ? "No Attacker Profile Found" : this.state.from_address}</p>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className='contHeading'>
                                                    <h6>Url </h6>
                                                    <p>{!this.state.landing_url ? "No URL Found" : this.state.landing_url}</p>
                                                </div>

                                                <Table>
                                                    <tbody>
                                                        <tr>
                                                            <td className='keyHeading'>Capture Credential </td>
                                                            <td className='valueHeading'> {this.state.capture_credentials ? <p className="badgeYn yes-badges">Yes</p> : <p className="badgeYn no-badges">No</p>}</td>
                                                        </tr>

                                                        <tr>
                                                            <td className='keyHeading'>Capture Password </td>
                                                            <td className='valueHeading'>{this.state.capture_passwords ? <p className="badgeYn yes-badges">Yes</p> : <p className="badgeYn no-badges">No</p>}</td>
                                                        </tr>

                                                        <tr>
                                                            <td className='keyHeading'>Auto-Instant Awareness </td>
                                                            <td className='valueHeading'> {this.state.bluePage ? <p className="badgeYn yes-badges">Yes</p> : <p className="badgeYn no-badges">No</p>}</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>

                                            <div className='col-md-4'>
                                                <div className='contHeading'>
                                                    <h6>Url </h6>
                                                    <p>{!this.state.redirect_url ? "No URL Found" : this.state.redirect_url}</p>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </Tab>

                                <Tab eventKey="training" title="training">

                                    <div className='contHeading'>
                                        <h6>Training </h6>
                                    </div>
                                    <div className="tableContainerWrapInner">
                                        <Table className="training-table" borderless>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Course</th>
                                                    <th>Lectures</th>
                                                    <th>Language</th>
                                                    <th>Action</th>
                                                    {/* <th> <Form.Check className="checkBox" type="checkbox" id="default-1" /> </th>
                                                    <th>Name</th>
                                                    <th>Created On</th>
                                                    <th>Lectures</th>
                                                    <th>Action</th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.lmsCourses.length ? this.state.lmsCourses.map((course, index) => {
                                                    return <tr key={index}>
                                                        <td>
                                                            <Form.Check
                                                                type="checkbox"
                                                                id="default-1"
                                                                className="checkBox"
                                                            />
                                                        </td>
                                                        <td>
                                                            <div className="row">
                                                                <div className="col-md-4">
                                                                    <Image src={this.state.imageURL + course.logo} alt="no image" className="training-img"></Image>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <h6>{course.name}</h6>
                                                                    <p>{getDate(course.createdAt)}</p>
                                                                    <p>Status: {course.status}</p>
                                                                    <p>Course Type: {course.courseType}</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>31-Mar-2024 | 10:00 AM</td>
                                                        <td>{course.totalLecture}</td>
                                                        {/* <td>{course.Language.name}</td> */}
                                                        <td><VisibilitySharpIcon className="btn-icon training-preview-icon" onClick={e => this.handleLMS(course.id)} ></VisibilitySharpIcon></td>
                                                    </tr>
                                                }) : <p style={{ margin: 0, padding: "10px", fontSize: "12px" }}>No Data Found</p>}
                                            </tbody>
                                        </Table>

                                    </div>


                                </Tab>
                            </Tabs>
                        </div>








                        {/* <div className="page-content">
                                    {this.state.attackType !== "Vishing"?
                                        <>
                                            <hr></hr>
                                            <div className="row">
                                                <div className="col-md-3">
                                                    { this.state.attackType === "Smishing" ? <h6>SMS Template : </h6>: <h6>Email Template : </h6>}
                                                </div>
                                                <div className="col-md-5">
                                                    {this.state.attackType === 'WhatsApp'?
                                                        <>
                                                            <div className="bg-light p-3 mb-3">
                                                                    <h6>Template Name</h6>
                                                                    <p className="mb-0">{this.state.templateName}</p>
                                                                </div>
                                                                <div className="bg-light p-3 mb-3">
                                                                    <h6>Brodcast Name</h6>
                                                                    <p className="mb-0">{this.state.brodcastName}</p>
                                                                </div>
                                                        </>:
                                                        <div className="img-hover-wrap">
                                                            <img src={this.state.templateImg} className="img-fluid" alt=""></img>
                                                            <div className="hover-btn">
                                                                <Button variant="dark" onClick={e => {
                                                                    this.setState({
                                                                        showThumbnail: true,
                                                                        currentSrc: this.state.templateImg
                                                                    })
                                                                }}><CropFreeSharpIcon className="btn-icon" />Expand</Button>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="side-wrap">
                                                    {this.state.attackType !== "WhatsApp"?
                                                        <>
                                                            <div className="d-flex">
                                                                <h6>Allow Mail Tracking : </h6>
                                                                {this.state.emailTracking? <p className="yes-badges ml-2"><DoneSharpIcon className="btn-icon" />Yes</p>: <p className="no-badges ml-2"><ClearSharpIcon className="btn-icon" />No</p>}
                                                            </div>
                                                            <div className="bg-light p-3 mb-3">
                                                                <h6>Subject : </h6>
                                                                <p className="p-img-head">{this.state.subject}</p>
                                                            </div>
                                                            <div className="bg-light p-3">
                                                                <h6>Attacker Profile</h6>
                                                                <p className="mb-0">Sender ID : {this.state.from_address}</p>
                                                            </div>
                                                        </>:null
                                                    }
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.credential_harvesting?
                                                <div className="row">
                                                    <hr></hr>
                                                    <div className="col-md-3">
                                                        <h6>Landing Page : </h6>
                                                    </div>
                                                    <div className="col-md-5">

                                                        <div className="img-hover-wrap">
                                                            <img src={this.state.pageImg} className="img-fluid" alt=""></img>
                                                            <div className="hover-btn">
                                                                <Button variant="dark" onClick={e => {
                                                                    this.setState({
                                                                        showThumbnail: true,
                                                                        currentSrc: this.state.pageImg
                                                                    })
                                                                }}><CropFreeSharpIcon className="btn-icon" />Expand</Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="side-wrap">
                                                            <div className="bg-light p-3 mb-3">
                                                                <h6>Url</h6>
                                                                <p className="mb-0">{this.state.landing_url}</p>
                                                            </div>
                                                            <div className="row">
                                                                <h6 className="col-md-6">Capture Credential : </h6>
                                                                <div className="col-md-6">
                                                                    {this.state.capture_credentials? <p className="yes-badges"><DoneSharpIcon className="btn-icon" />Yes</p>: <p className="no-badges"><ClearSharpIcon className="btn-icon" />No</p>}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <h6 className="col-md-6">Capture Password : </h6>
                                                                <div className="col-md-6">
                                                                    {this.state.capture_passwords? <p className="yes-badges"><DoneSharpIcon className="btn-icon" />Yes</p>: <p className="no-badges"><ClearSharpIcon className="btn-icon" />No</p>}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <h6 className="col-md-6">Auto-Instant Awareness : </h6>
                                                                <div className="col-md-6">
                                                                    {this.state.bluePage? <p className="yes-badges"><DoneSharpIcon className="btn-icon" />Yes</p>: <p className="no-badges"><ClearSharpIcon className="btn-icon" />No</p>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>: null
                                            }
                                            <hr></hr>
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <h6>Awareness Page : </h6>
                                                </div>
                                                <div className="col-md-5">
                                                    <div className="img-hover-wrap">
                                                        <img src={this.state.awarenessPath} className="img-fluid" alt=""></img>
                                                        <div className="hover-btn">
                                                            <Button variant="dark" onClick={e => {
                                                                this.setState({
                                                                    showThumbnail: true,
                                                                    currentSrc: this.state.awarenessPath
                                                                })
                                                            }}><CropFreeSharpIcon className="btn-icon" />Expand</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="bg-light p-3">
                                                        <h6>Url</h6>
                                                        <p className="mb-0">{this.state.redirect_url}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </>: null
                                    }



                                    {this.state.training?
                                        <div>
                                            <hr></hr>
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <h6>Training : </h6>
                                                </div>
                                                <div className="col-md-9 training-table-preview">
                                                    <Table borderless responsive>
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Course</th>
                                                                <th>Lectures</th>
                                                                <th>Language</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.lmsCourses.map((course, index) => {
                                                                return <tr key={index}>
                                                                    <td>{index+1}</td>
                                                                    <td>
                                                                        <div className="row">
                                                                            <div className="col-md-4">
                                                                                <Image src={this.state.imageURL+course.logo} alt="no image" className="training-img"></Image>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <h6>{course.name}</h6>
                                                                                <p>{getDate(course.createdAt)}</p>
                                                                                <p>Status: {course.status}</p>
                                                                                <p>Course Type: {course.courseType}</p>
                                                                                <p>Skills: {course.CourseSkills.map((skill, index) => {
                                                                                    return skill && skill.Skill? `${skill.Skill.name}, `: '';
                                                                                })}</p>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>{course.totalLecture}</td>
                                                                    <td>{course.Language.name}</td>
                                                                    <td><VisibilitySharpIcon className="btn-icon training-preview-icon" onClick={e => this.handleLMS(course.id)} ></VisibilitySharpIcon></td>
                                                                </tr>
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                        </div>: null
                                    }
                                    {this.state.game?
                                        <div>
                                            <hr></hr>  
                                            <div className="row">
                                                <Form.Label className="col-md-3">Game : </Form.Label>
                                                <div className="col-md-9 template-wrap d-flex">
                                                    <div className="col-md-6">
                                                        <div className="d-flex training-wrap">
                                                            <img src={temp} alt="template" className="img-fluid media-img"></img>
                                                            <div className="wrap">
                                                                <h6 className="mb-0">HR Payroll Course Video</h6>
                                                                <p className="mb-0">1 Hours, 10 min</p>
                                                            </div>
                                                            <Badge variant="primary"><VisibilitySharpIcon className="btn-icon"></VisibilitySharpIcon>Preview </Badge>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>: null
                                    }
                                </div> */}

                        <PreviewThumbnail
                            src={this.state.currentSrc}
                            attackType={this.state.attackType}
                            showModal={this.state.showThumbnail}
                            showHandle={e => this.setState({showThumbnail: false})} 
                        ></PreviewThumbnail>
                        <TestEmails 
                            attackType={this.state.attackType}
                            emails={this.state.emails} 
                            firstname={this.state.firstname}
                            lastname={this.state.lastname}
                            nextPath={path => this.props.history.push(path)}
                            testCampaign={this.testCampaign}
                            showModal={this.state.showTestModal} 
                            showHandle={e => this.setState({showTestModal: false})}
                        ></TestEmails>
                        {this.state['loading']? <Loader/>: null}
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}