import * as React from 'react';
import { toast } from "react-toastify";
import CKEditor from 'ckeditor4-react';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Tooltip from 'react-bootstrap/Tooltip';
import { IoInformation } from "react-icons/io5";
import { BsBoxArrowInRight } from "react-icons/bs";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';

import axios from '../../../axios';
import apiError from '../../../error';
import Loader from '../../../component/loader/loader';
import ImportEmail from '../import-email/import-email';
import Spinner from '../../../component/spinner/spinner';
import ImportEmailFromStore from './import-email-from-store';

export default class Templates extends React.Component {
    constructor(props) {
        super(props);
        this.fileRef= React.createRef(null);
        this.state = {
            show: false,
            subject: '',
            loading: false,
            defaultSubject: true,
            tracking: true,
            loadHtml: false,
            attachments: [],
            emailContent: '',
            changeUrls: false,
            attachmentKey: [],
            loadingImport: false,
            attachmentWithNoTemplateId: [],
            filename: "",
            colorCode: "",
            updateHtml: "",
            showImportModal: false,
            htmlContent: localStorage.getItem("response") ? JSON.parse(localStorage.getItem("response"))[0] : "",
            html: '<html> <head> <title></title> </head> <body data-gr-ext-disabled="forever" data-gr-ext-installed="" data-new-gr-c-s-check-loaded="14.991.0"> <p>{{.tracker}}</p> </body> </html>',
        }
    }
    componentDidMount = () => {

        let data = localStorage.getItem("response")
        if (data) {
            data && this.setState({ htmlContent: JSON.parse(data)[0] })
            this.setState({ colorCode: localStorage.getItem("backgroundHeader") })
            if (localStorage.getItem("backgroundHeader") || localStorage.getItem("logoPosition")) {
                if (localStorage.getItem("backgroundHeader") === null) localStorage.setItem("backgroundHeader", localStorage.getItem("defaultColor"))
                if (localStorage.getItem("logoPosition") === null) localStorage.setItem("logoPosition", "left")
                let patternHeader = new RegExp(/\.Aitemplate-header\s*{\s*background-color:\s*#[0-9a-fA-F]+;[^}]*text-align:\s*(center|left|right)/g);
                let patternFooter = new RegExp(/\.Aitemplate-footer\s*{\s*background-color:\s*#[0-9a-fA-F]+;/g);
                let str = this.state.htmlContent.replace(patternHeader, `.Aitemplate-header { \n background-color: ${localStorage.getItem("backgroundHeader")};\n padding: 20px;\n text-align: ${localStorage.getItem("logoPosition")}`);
                str = str.replace(patternFooter, `.Aitemplate-footer {\n  background-color: ${localStorage.getItem("backgroundHeader")};\n padding: 20px;\n`);
                this.setState({ updateHtml: str })
            }
            else {
                this.setState({ updateHtml: JSON.parse(data)[0] })
            }
        }
    }
    componentWillMount = () => {
        if(this.state.defaultSubject) {
            this.setState({subject: this.props.subContent})
        }
        if (this.props.templateId) {
            axios.get('/api/emails/' + this.props.templateId, {})
                .then(res => {
                    this.setState({
                        loadHtml: true,
                        html: res.data.template.html,
                        subject: res.data.template.subject,
                        attachments: res.data.template.attachments,
                        filename: res.data.template.attachments[0] ? res.data.template.attachments[0].filename : "",
                        tracking: res.data.template.html.search('{{.tracker}}') > -1,
                    })
                })
                .catch(err => {
                    apiError(err)
                })
        } else this.setState({ loadHtml: true })
    }

    handleChangeSubject = (e) => {
        this.setState({ defaultSubject: false })
        const userSubject = e.target.value; 
        this.setState({subject : userSubject});
     }
    
     handleChange = (e) => {
        let html = e.editor.getData();
        let tracking = html.search('{{.tracker}}') > -1;
        this.setState({ html: html, tracking: tracking });
    }

    handleCreateTemplate = () => {
        if (this.state.html.length === 0) {
            toast.error("please enter html content");
            return;
        } else if (this.state.subject.length === 0 || (this.state.subject).trim().length === 0) {
            toast.error("Please enter email subject");
            return;
        }
        const data = {
            html: this.state.html,
            subject: this.state.subject,
            campaignTemplateId: this.props.campaignTemplateId
        }
        this.setState({ loading: true })
        if (this.props.templateId) {
            axios.patch('/api/emails/' + this.props.templateId, data)
                .then(res => {
                    this.setState({ loading: false })
                    this.giveTemplateIdtoAttachment();
                    this.props.handleTabChange('next');
                })
                .catch(error => {
                    apiError(error)
                    this.setState({ loading: false })
                })
        } else {
            axios.post('/api/emails/', data)
                .then(res => {
                    this.setState({ loading: false })
                    this.props.handleTabChange('next');
                    this.giveTemplateIdtoAttachment();
                    this.props.handleTemplateId(res.data.template.id);
                    toast.success('Email template created successfully')
                })
                .catch(error => {
                    apiError(error)
                    this.setState({ loading: false })
                })
        }
    }

    giveTemplateIdtoAttachment = () => {
        if (this.state.attachmentKey && this.state.attachmentKey.length) {
            setTimeout(() => {
                axios.post('/api/attachment/key', {
                    templateId: this.props.templateId,
                    attachmentKey: this.state.attachmentKey
                })
                    .then(res => { this.loadAttachment(); })
                    .catch(error => { })  
            }, 1000);
        }
    }

    handleApiForAttachments = (formData)=>{
        axios.post('/api/attachment', formData, {
            headers: {
                "Content-type": "multipart/form-data",
            }
        })
            .then(res => {
                toast.success('Attachment uploaded');
                if(this.props.templateId){
                    this.loadAttachment()
                } else {
                    if(res.data?.attachment){
                        
                        let attachments = [...this.state.attachments, res.data.attachment];
                        let attachmentKey = this.state.attachmentKey;
                        attachmentKey.push(res.data?.attachment?.attachmentKey)

                        this.setState({ 
                            attachments,
                            attachmentKey
                        })
                    }
                }
            })
            .catch(error => apiError(error))
    }

    handleUploadAttachment = (e) => {
        let formData = new FormData();
        formData.append('files', e.target.files[0]);
        formData.append('templateId', this.props.templateId);
        this.setState({filename: e.target.files[0].name})
        this.handleApiForAttachments(formData);
    }

    handleDeleteAttachment = (attachmentId) => {
        this.fileRef.current.value = "";
        axios.delete('/api/attachment/' + attachmentId, {})
            .then(r => { 
                toast.success('Attachment deleted'); 
                this.loadAttachment();
            })
            .catch(error => apiError(error))
    }

    loadAttachment = () => {
        axios.get('/api/emails/' + this.props.templateId, {})
            .then(res => {
                this.setState({ attachments: res.data?.template?.attachments ?? [] })
            })
            .catch(err => {
                apiError(err)
            })
    }

    addTrackingImage = (e) => {
        if (e.target.checked) {
            let html = this.state.html;
            if (html.indexOf(new RegExp('{{.tracker}}', "gi")) === -1) {
                html = html.replace('</body>', '<p>{{.tracker}}</p></body>');
            }
            this.setState({ html: html, tracking: true });
        } else {
            let html = this.state.html.replace(new RegExp('<p>{{.tracker}}</p>', "gi"), '');
            this.setState({ html: html, tracking: false });
        }
    }

    handleImportEmail = async () => {
        if (this.state.emailContent.length === 0) {
            toast.error("please enter Original Email Content");
            return;
        }
        this.setState({ loadingImport: true });
        axios.post('/api/import/mail', {
            raw: this.state.emailContent,
            changeUrls: this.state.changeUrls ? 1 : 0
        })
            .then(res => {
                this.setState({ 
                    loadingImport: false, 
                    attachmentKey: res.data.attachmentKey,
                    html: res.data.html ? res.data.html : '', 
                    updateHtml: res.data.html ? res.data.html : '', 
                });
                this.handleImportEmailModal();
            })
            .catch(err => {
                apiError(err)
                this.setState({ loadingImport: false });
            })
    }

    handleImportEmailModal = () => {
        this.setState({ show: !this.state.show });
    }

    handleChangeUrl = () => {
        this.setState({ changeUrls: !this.state.changeUrls });
    }

    handleContent = (e) => {
        this.setState({
            emailContent: e.target.value
        })
    }

    handleLoadSelectedStoreTemplateHtml = (html, subject, emailTracking, attachments) => {
    this.setState({
        html: html,
        updateHtml: html,
        subject: subject,
        attachments: attachments.map((attachment, index) => ({ id: index, filename: attachment })),
        filename: attachments.length ? attachments[0] : '',
        tracking: emailTracking,
    });
}

    render() {
        return (
            <>
                <div className='email-template-wrap'>
                    <div className='rightSectionHeader'>
                        <div className="row justify-content-between align-items-center">
                            <div className="col-md-6 tab-right-header">
                                <h6>Email Template</h6>
                                <p>Create email template</p>
                            </div>
                                <div className="col-md-6 text-right">
                                    <OverlayTrigger
                                        placement='top'
                                        overlay={<Tooltip id='top'>This mode help you to Create a phishing template via different method </Tooltip>}>
                                        <Button variant="secondary" type="button" onClick={() => this.setState({ showImportModal: true })} className="mr-2">Import From Store</Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement='top'
                                        overlay={<Tooltip id='top'>This mode help you to Create a phishing template via different method </Tooltip>}>
                                        <Button variant="primary" type="button" onClick={this.handleImportEmailModal}><BsBoxArrowInRight className='btn-icon'/>Import Email</Button>
                                    </OverlayTrigger>
                                </div>
                            </div>
                        </div>
                        <div className='setMarginCss'>
                            <Form>
                                <Form.Group>
                                    <Form.Label>Email Subject</Form.Label>
                                    <Form.Control type="text" value={this.state.subject} onChange={this.handleChangeSubject} placeholder="Enter email subject" />
                                </Form.Group>
                            </Form>
                        </div>
                        <div className='setMarginCss'>
                            {this.state.loadHtml ?
                                <CKEditor
                                    config={{
                                        fullPage: true,
                                        allowedContent: true
                                    }}
                                    data={this.state.updateHtml ? this.state.updateHtml : this.state.html}
                                    onChange={this.handleChange}
                                ></CKEditor> : null
                            }
                        </div>
                        <div className='checkBoxWrap setMarginCss ml-1'>
                             <Form.Group controlId="formBasicCheckbox" className='mb-0'>
                                <Form.Check type="checkbox" label="Add Tracking Image" checked={this.state.tracking} onChange={this.addTrackingImage} />
                                <OverlayTrigger
                                    placement='top'
                                    overlay={<Tooltip id='top'>Track the user activity on the sent emails</Tooltip>}>
                                    <IoInformation className="tooltip-icon "></IoInformation>
                                </OverlayTrigger>
                             </Form.Group>
                        </div>
                        <div>
                            <Form className="emailAttachment">
                                <Form.Group>
                                        <Form.Label>Upload Email Attachment</Form.Label>
                                    <Form.File className='form-control' id="exampleFormControlFile1" ref={this.fileRef} onChange={this.handleUploadAttachment} />
                                </Form.Group>
                            </Form>
                            <div className="tableContainerWrapInner setWidth">
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>File Name</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.attachments.map((attachment, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{attachment.filename}</td>
                                                    <td><DeleteOutlineTwoToneIcon style={{ cursor: "pointer" }} color='error' onClick={e => this.handleDeleteAttachment(attachment.id)}></DeleteOutlineTwoToneIcon></td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </div> 
                </div>
                <div className="row mt-2">
                   <div className='footer-wrapper justify-content-end d-flex'>
                        <Button className="btn closeBtn mr-2" onClick={e => this.props.handleTabChange('prev')}><ArrowBackOutlinedIcon className="btn-icon"></ArrowBackOutlinedIcon> Previous</Button>
                        <Button className='btn btn-success' disabled={this.state.loading} type="button" onClick={this.handleCreateTemplate}><Spinner loading={this.state.loading}></Spinner><ArrowForwardOutlinedIcon className={this.state.loading ? "btn-icon d-none" : "btn-icon"}></ArrowForwardOutlinedIcon> Go To Next</Button>
                    </div>
                </div>
               
                <ImportEmail
                    show={this.state.show}
                    changeUrls={this.state.changeUrls}
                    handleContent={this.handleContent}
                    handleChangeUrl={this.handleChangeUrl}
                    emailContent={this.state.emailContent}
                    handleImportEmail={this.handleImportEmail}
                    handleImportEmailModal={this.handleImportEmailModal}
                ></ImportEmail>
                <ImportEmailFromStore
                    showImportModal={this.state.showImportModal}
                    handleClose={e => this.setState({showImportModal: false })}
                    handleLoadSelectedStoreTemplateHtml={this.handleLoadSelectedStoreTemplateHtml}
                ></ImportEmailFromStore>
                {this.state.loadingImport ? <Loader></Loader> : null}
            </>
        )
    }
}