import React, { Component } from "react";
import swal from 'sweetalert';
import { toast } from "react-toastify";
import Pagination from "react-js-pagination";
import AddIcon from '@material-ui/icons/Add';
import SearchSharpIcon from '@material-ui/icons/SearchSharp';
import CreateSharpIcon from '@material-ui/icons/CreateSharp';
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';
import AddSharpIcon from "@material-ui/icons/AddSharp";
import MoreVertSharpIcon from "@material-ui/icons/MoreVertSharp";
import { FormControl, Button, InputGroup, Card, Table, Breadcrumb, Form, Dropdown } from 'react-bootstrap';

import axiosRequest from 'axios';
import axios from '../../../axios';
import apiError from '../../../error' ;
import Loader from '../../../component/loader/loader';
import RightSideModal from '../../../component/rightside-modal/rightside-modal';
var cancelRequest = undefined, CancelToken = axiosRequest.CancelToken;

export default class UserList extends Component {
    constructor(props){
        super(props);
        this.state = {
            count: 0,
            limit: 10,
            search: '',
            show: false,
            loading: true,
            activePage: 1,
            groupTargets: [],
            checkedAll: false,
            statusCheck: false,
            checkedStatus: new Array(10).fill(false),

            targetId: 0,
            firstname: '',
            lastname: '',
            email: '',
            groupName: '',
            department: '',
            phone: '',
            position: '',
            location: '',
            updateEmail: false,
        }
    }

    componentDidMount = () => {
        this.load();
    }

    checkedGroup = (index) => {
        const checkedStatus = [...this.state.checkedStatus];
        checkedStatus[index] = !checkedStatus[index];
        this.setState({ checkedStatus: checkedStatus })
    }

    deleteTarget = (groupId, targetId) => {
        swal({
            title: "Do you want to delete user?",
            icon: "warning",
            buttons: [ 'No', 'Yes' ],
            dangerMode: true,
        })
        .then(willSuccess => {
            if (willSuccess) {
                axios.delete('/api/targets/'+targetId+'/'+groupId, {})
                .then(async(res) => {
                    this.load();
                    toast.success("User deleted successfully");
                    this.setState({search:""})
                })
                .catch(error => apiError(error))
            }
        });

    }

    load = async () => {
        // await this.setState({ loading: true })
        if(cancelRequest) cancelRequest();
        axios.get('/api/targets/all', {
            params: {
                page: this.state.activePage, 
                limit: this.state.limit, 
                search: this.state.search
            },
            cancelToken: new CancelToken(function executor(c) { cancelRequest = c })
        })
        .then(async(res) => {
            await this.setState({
                loading: false,
                count: res.data.count,
                groupTargets: res.data.groupTargets,
            })
        })
        .catch(error => {
            if(!axiosRequest.isCancel(error)){
                this.setState({ groupTargets: [], count: 0, loading: false })
                apiError(error);
            }
        })
    }

    render() {
        return (
            <div className="content-wrap">

                  {/**********************
             Breadcrumbbar
             **********************/}
        <div className="breadcrumbbar">
          <div className="flexRow row">
            <div className="col-md-4">
              <div className="page-head d-flex align-items-center justify-content-between">
                <h4 className="page-title">Users</h4>
              </div>
            </div>

            <div className="col-md-8 flexFilterButtons">
              <div className="btn-container float-right d-flex align-items-center">
                   <Button className="btn-primary" onClick={() => this.setState({show: true})}><AddIcon className="btn-icon"></AddIcon>Add User</Button>
              </div>
            </div>
          </div>
        </div>
        {/*******************
            Breadcrumbbar End
         *******************/}


          {/**********************
             Filter
            **********************/}
             <div className="filter-wrapper">
                <div className="alignItemsCenter row">
                    <div className="col-md-3 p-0">
                        <div className="positionSearchIcon"> 
                            <InputGroup className="search-wrap">
                            <FormControl type="text" placeholder="Search" value={this.state.search} onChange={e => this.setState({search: e.target.value, activePage: 1}, () =>{ this.load() })} className="inputSearch mr-sm-2 form-control" /><SearchSharpIcon className="search-icon"></SearchSharpIcon>
                            </InputGroup>  
                        </div>    
                     </div>

                     <div className="col-md-9 pr-0">
                         <div className="rightFilters">
                                 <Form.Group controlId="exampleForm.ControlSelect1" onChange={(e)=> this.setState({limit: parseInt(e.target.value, 10), activePage: 1}, ()=> this.load())} className="d-inline-block">
                                     <Form.Control as="select" className="mr-0 btn">
                                         <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                         <option value="100">100</option>
                                    </Form.Control>
                                 </Form.Group>
                             </div>
                         </div>
                </div>
             </div>
              {/**********************
                End
             **********************/}

                  <div className="contentbar tableContainerWrap appTable">
                            {this.state.groupTargets.length?
                             <div className="tableContainerWrapInner">
                                    <Table responsive>
                                        <thead className="bg-light">
                                            <tr>
                                                <th>Name / Email</th>
                                                <th>Group</th>
                                                <th>Phone</th>
                                                <th>Position</th>
                                                <th>Department</th>
                                                <th>Location</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.groupTargets.map((target, index) => {
                                                // console.log("this is target",JSON.stringify(target,2,null));
                                                // console.log("department",JSON.stringify(target.target.department,2,null));
                                                const groupName = target.group.name
                                                // let department = target.target.department.name
                                                // let location = target.target.location.name

                                                let groupId = target.groupId, targetId = target.targetId;
                                                target = target.target;
                                                //  console.log("dept",JSON.stringify(target.department.name,2,null));
                                                //  console.log("dept",JSON.stringify(target.location.name,2,null));
                                                return <tr key={index}>

                                                    {/* 
                                                        <td>
                                                            <Form.Group controlId="formBasicCheckbox">
                                                            <Form.Check type="checkbox" />
                                                            </Form.Group>
                                                        </td> 
                                                    */}

                                                    <td>
                                                        <h6>{target.firstname} {target.lastname?target.lastname:''}</h6>
                                                        <span>{target.email}</span>
                                                    </td>
                                                    <td>{groupName ? groupName : '---'}</td>
                                                    <td>{target.phone? (target.phone): '---'}</td>
                                                    <td>{target.position? target.position: '---'}</td>
                                                    <td>{target.department ? target.department.name: '---'}</td>
                                                    <td>{target.location!= null? target.location.name: '---'}</td>

                                                        <td>
                                                            <Dropdown
                                                            className="action-dropdown dropdownIcon"
                                                            ref={this.selectRef}
                                                            >
                                                            <Dropdown.Toggle
                                                                className="btn-only"
                                                                id="dropdown-basic"
                                                            >
                                                                <MoreVertSharpIcon className="dot-icon"></MoreVertSharpIcon>
                                                            </Dropdown.Toggle>
                                                                    <Dropdown.Menu>
                                                                        <Dropdown.Item
                                                                        onClick={() => {
                                                                                this.setState({
                                                                                            show: true,
                                                                                            targetId: target.id,
                                                                                            email: target.email,
                                                                                            groupName: groupName,
                                                                                            lastname: target.lastname,
                                                                                            firstname: target.firstname,
                                                                                            phone: target.phone? target.phone: '',
                                                                                            position: target.position? target.position: '',
                                                                                            location: target.location!=null? target.location.name: '',
                                                                                            department: target.department!=null? target.department.name: '',
                                                                                                        })
                                                                                    }}>
                                                                                Edit
                                                                        </Dropdown.Item>
                                                                    

                                                                <Dropdown.Item
                                                                onClick={e => this.deleteTarget(groupId, targetId)}
                                                                >
                                                                Delete
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                            </Dropdown>
                                                        </td>  
                                                </tr>
                                            })}
                                        </tbody>
                                </Table>

                                <div className="pagination-Wrap">
                            <div className="row">
                                <div className="col-md-6">
                                    {!this.state['loading']?
                                        <Pagination
                                            itemClass="page-item"
                                            linkClass="page-link"
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={this.state.limit}
                                            totalItemsCount={this.state.count}
                                            onChange={(pageNumber) => this.setState({activePage: pageNumber}, ()=> this.load())}
                                        />: <Loader/>
                                    }
                                    </div>

                                    <div className="col-md-6 text-right d-flex align-items-center footer-pg">
                                       
                                         <div className="w-100 pgCount"><p>Total No Of Users: {this.state.count} </p> </div>
                                    </div>

                                </div>
                        </div>
                                
                                </div>: <p style={{marginTop: '10px'}}>No User Found</p>
                                
                            }
                  
                     
                 </div>
                    <RightSideModal
                        load={this.load}
                        show={this.state.show}
                        phone={this.state.phone}
                        email={this.state.email}
                        groupName={this.state.groupName}
                        location={this.state.location}
                        department={this.state.department}
                        targetId={this.state.targetId}
                        lastname={this.state.lastname}
                        position={this.state.position}
                        firstname={this.state.firstname}
                        updateEmail={this.state.updateEmail}
                        handleModal={() => {this.setState({
                            show: !this.state.show,
                            targetId: '0',
                            firstname: '',
                            lastname: '',
                            email: '',
                            groupName: '',
                            phone: '',
                            position: '',
                            location: '',
                            department: '',
                            search:'',
                        })
                    this.load();
                        }}
                    ></RightSideModal>
        </div>
        );
    }
}