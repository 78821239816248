import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { Card, Accordion, Button, Alert } from 'react-bootstrap';
import CheckCircleSharpIcon from '@material-ui/icons/CheckCircleSharp';
import KeyboardArrowDownSharpIcon from '@material-ui/icons/KeyboardArrowDownSharp';

import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import LaunchModal from '../../component/launch-modal/launch-modal';

import axios from '../../axios';
import apiError from '../../error';
import { setCookie } from '../../function';
import './guide.css'

export default class Guide extends Component {
    constructor (props){
        super(props);
        this.state = { 
            domain: false,
            groups: false,
            activeTab: '-1',
            campaign: false,
            showModal: false,
            whiteList: false,
            campaignTemplate: false,
        }
        this.load();
        this.loadAttackType();
    }

    nextPath = path => this.props.history.push(path);

    showHandle = () => {
        this.setState({showModal: !this.state.showModal});
    }

    loadAttackType = () => {
        axios.get('/api/attacktypes/vector', {})
        .then(res => {
            for(let i=0; i<res.data.attackTypes.length; i++){
                let attack = res.data.attackTypes[i];
                setCookie(attack.name, true, 300000);
            }
        })
        .catch(err => {
            apiError(err);
        })
    }

    load = () => {
        axios.get('/api/guide', {})
        .then((res)=>{
            this.setState({ 
                domain: res.data.domain,
                groups: res.data.groupCount,
                whiteList: res.data.whiteList,
                campaign: res.data.campaignCount,
                campaignTemplate: res.data.campaignTemplateCount,
                activeTab: !res.data.domain? '0': !res.data.groupCount? '1': !res.data.campaignTemplateCount? '2': !res.data.whiteList? '3': !res.data.campaignCount? '4': '-1'
            })
        })
        .catch(error => {})
    }

    handleUpdate = () => {
        axios.post('/api/guide', {})
        .then((res)=>{ 
            this.setState({ whiteList: true})
            toast.success('whitelisting completed successfully');
        })
        .catch(error => {})
    }

    handleTabChange = (eventId) => {
        if(eventId === this.state.activeTab){
            this.setState({activeTab: '-1'})
        } else {
            this.setState({activeTab: eventId})
        }
    }
 
    render() {
        return (
            <div className="content-wrap bg-white">
                <div className="breadcrumbbar">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-head">
                                <h4 className="page-title mb-3">Getting Started!</h4>
                                <p>Begin your journey with TSAT...</p>
                            </div>
                        </div>
                    </div>
                </div>
                <Alert variant='warning' dismissible onClose={() => {
                    localStorage.setItem('isDashboard', true);
                    this.nextPath('/');
                }}>
                    skip, and go to Dashboard
                </Alert>
                <Card>
                    <Card.Body className="p-0">
                        <div className="page-content" id="accordion-block"> 
                            <Accordion activeKey={this.state.activeTab}>
                                <Card>
                                    <h5>{this.state.domain? <CheckCircleSharpIcon className="accord-icon"></CheckCircleSharpIcon>: null}<b>Step 1: </b> Add and Verify the Domain</h5>
                                    <Accordion.Toggle as={Card.Header} eventKey="0" onClick={e => this.handleTabChange('0')}>Add and verify a  domain for sending phishing emails to it<KeyboardArrowDownSharpIcon className="right-arrow"></KeyboardArrowDownSharpIcon></Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <Link to="/settings?tab=domain">
                                            <Button variant="primary">Add Domain</Button>
                                        </Link>
                                    </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <h5>{this.state.groups? <CheckCircleSharpIcon className="accord-icon"></CheckCircleSharpIcon>: null}<b>Step 2: </b> Create a Group of Users</h5>
                                    <Accordion.Toggle as={Card.Header} eventKey="1" onClick={e => this.handleTabChange('1')}>Create a new group by adding users either individually or by importing in bulk.<KeyboardArrowDownSharpIcon className="right-arrow"></KeyboardArrowDownSharpIcon></Accordion.Toggle>
                                    <Accordion.Collapse eventKey="1">
                                    <Card.Body>
                                        <Link to="/department/groups/0">
                                            <Button variant="primary">Create Group</Button>
                                        </Link>
                                    </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <h5>{this.state.campaignTemplate? <CheckCircleSharpIcon className="accord-icon"></CheckCircleSharpIcon>: null}<b>Step 3: </b> Create a Campaign Template</h5>
                                    <Accordion.Toggle as={Card.Header} eventKey="2"  onClick={e => this.handleTabChange('2')}>
                                        TSAT offers three types of Campaign Templates - System Templates, 
                                        Custom Templates and Community Templates. While creating a Custom Template you can add an email template, an attacker profile and a landing page as needed. 
                                         <KeyboardArrowDownSharpIcon className="right-arrow"></KeyboardArrowDownSharpIcon> 
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="2">
                                    <Card.Body>
                                        <Link to="/templates">
                                            <Button variant="primary" className="mr-2">Choose Campaign Template</Button>
                                        </Link>
                                        <Link to="/templates/0">
                                            <Button variant="primary">Create Campaign Template</Button>
                                        </Link>
                                    </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <h5>{this.state.whiteList? <CheckCircleSharpIcon className="accord-icon"></CheckCircleSharpIcon>: null}<b>Step 4: </b> Whitelist IPs, Hostnames and Domains</h5>
                                    <Accordion.Toggle as={Card.Header} eventKey="3" onClick={e => this.handleTabChange('3')}>
                                        You will have to whitelist TSAT’s hostnames, IP addresses or email domains to prevent the simulated phishing emails from being blocked by your email server or spam filters and make sure that your employees receive these emails.
                                         <KeyboardArrowDownSharpIcon className="right-arrow"></KeyboardArrowDownSharpIcon>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="3">
                                    <Card.Body>
                                        <Button variant="primary" href="https://helpdesk.kratikal.com/portal/en/kb/threatcop/whitelisting" target="_blank" rel="noopener noreferrer">Read more</Button>
                                        <Button variant="primary" disabled={this.state.whiteList} className="ml-2" onClick={this.handleUpdate}>I have done it !</Button>
                                        <OverlayTrigger
                                            placement='top'
                                            overlay={<Tooltip id='top'>You have to whitelist the TSAT's IPs to send the email to your user's inbox.</Tooltip>}>
                                            <Button variant="primary" disabled={this.state.whiteList} className="ml-2">Not now</Button>
                                        </OverlayTrigger>
                                    </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <h5>{this.state.campaign? <CheckCircleSharpIcon className="accord-icon"></CheckCircleSharpIcon>: null}<b>Step 5: </b> Create a Campaign</h5>
                                    <Accordion.Toggle as={Card.Header} eventKey="4" onClick={e => this.handleTabChange('4')}>
                                        Create a customizable dummy cyber attack campaign based on one of the five available attack vectors including Phishing, Smishing, Vishing, Ransomware and Cyber Scam.
                                         <KeyboardArrowDownSharpIcon className="right-arrow"></KeyboardArrowDownSharpIcon>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="4">
                                    <Card.Body>
                                        <Button onClick={this.showHandle} variant="primary">Create Campaign</Button> 
                                    </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </div>
                    </Card.Body>
                </Card>
                <LaunchModal showModal={this.state.showModal} showHandle={this.showHandle} nextPath={this.nextPath}></LaunchModal>
            </div>
        );
    }
}