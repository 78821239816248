import React, { Component } from 'react';
import { Card } from '@mui/material';
import { toast } from 'react-toastify';
import { Modal, Row } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroller';
import axios from '../../../axios';
import apiError from '../../../error';
import './import-landing-from-store.css';

export default class ImportLandingFromStore extends Component {
    constructor(props) {
        super(props);
        this.state = {
            templates: [],
            hasMore: true,
            page: 1,
            selectedTemplateId: null,
        };
    }

    loadLandingPagesFromStore = () => {
        axios.post(`/api/store/list-landingpages`, { page: this.state.page })
            .then(response => response.data)
            .then(data => {
                if (data && data.response) {
                    this.setState(prevState => ({
                        templates: prevState.page === 1 ? data.response : [...prevState.templates, ...data.response],
                        hasMore: data.response.length > 0,
                        page: prevState.page + 1
                    }));
                } else {
                    this.setState({ hasMore: false });
                }
            })
            .catch(apiError);
    }

    componentDidMount() {
        this.loadLandingPagesFromStore();
    }

    loadHtml = (landingPageTemplateId) => {
        axios.get(`/api/store/fetch-landing/${landingPageTemplateId}`)
            .then(response => response.data)
            .then(responseData => {
                console.log(responseData)
                if (responseData && responseData.response) {
                    this.props.handleLoadSelectedStoreTemplateHtml(responseData.response.html, responseData.response.name, responseData.response.capture_credentials, responseData.response.capture_passwords);
                    this.props.handleClose();
                } else {
                    toast.error('LandingPage HTML not found');
                }
            })
            .catch(apiError);
    }

    handleCardClick = (landingPageTemplateId) => {
        this.setState({ selectedTemplateId: landingPageTemplateId });
        this.loadHtml(landingPageTemplateId);
    }

    render() {
        const { templates, hasMore, selectedTemplateId } = this.state;
        const { showImportModal, handleClose } = this.props;

        return (
            <Modal className="info-filter emailRight right" style={{ width: '500px', maxHeight: '80vh', overflowY: 'auto' }} show={showImportModal} onHide={handleClose} autoFocus keyboard>
                <Modal.Header closeButton>
                    <Modal.Title>Landing Page Template</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={this.loadLandingPagesFromStore}
                        hasMore={hasMore}
                        loader={<div className="loader" key={0}>Infinite scroller loading ...</div>}
                        useWindow={false}
                    >
                        <Row className='landingPageCard'>
                            {templates.map((template, index) => (
                                <div key={index} className='col-md-4'>
                                    <Card 
                                        style={{ 
                                            position: 'relative', 
                                            cursor: 'pointer',
                                            boxShadow: selectedTemplateId === template.id ? '0 5px 10px rgba(0, 0, 0, 0.3)' : '0 1px 3px rgba(0, 0, 0, 0.2)',
                                            transform: selectedTemplateId === template.id ? 'scale(0.98)' : 'scale(1)',
                                            transition: 'transform 0.1s, box-shadow 0.1s'
                                        }} 
                                        onClick={() => this.handleCardClick(template.id)}
                                    >
                                        <img crossOrigin="anonymous" alt="no image" src={template.thumbnail} />
                                        <div style={{ marginTop: '8px' }}>
                                            <h5>{template.name}</h5>
                                            <p>Capture Credentials: {template.capture_credentials ? 'Yes' : 'No'}</p>
                                            <p>Capture Passwords: {template.capture_passwords ? 'Yes' : 'No'}</p>
                                        </div>
                                    </Card>
                                </div>
                            ))}
                        </Row>
                    </InfiniteScroll>
                </Modal.Body>
            </Modal>
        );
    }
}
