import React, { Component } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';

export default class TestEmailModal extends Component{
    
    render(){
        return (
            <Modal show={this.props.show} onHide={this.props.handleEmailModal} autoFocus keyboard>
                <Modal.Header closeButton>
                    <Modal.Title> Send Test Email </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type="email" value={this.props.email} onChange={this.props.handleEmail} placeholder="Please enter email Id" />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.handleEmailModal}>Close</Button>
                    <Button variant="primary" disabled={this.props.loading} onClick={this.props.sendTestMail}> { this.props.loading? <Spinner as="span" className="mr-2" animation="border" size="sm" role="status" aria-hidden="true" />: null } Send</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}