import React, { Component } from "react";
import ApexCharts from 'apexcharts'

export default class AnalyticChart extends Component {
	constructor(props){
		super(props);
		this.state = {
			chart: null
		}
	}
	
	componentDidMount = () => {
		this.load();
	}

	load = () => {
		let options = {
			series: this.props.series? this.props.series: [],
			labels: this.props.label? this.props.label: [],
			chart: {
				type: 'donut',
			},
			responsive: [{
				breakpoint: 480,
				options: {
					chart: {
						width: 200
					},
					legend: {
						position: 'bottom'
					}
				}
			}],
			dataLabels: {
				enabled: false
			},
			legend: {
				position: 'bottom',
				horizontalAlign: 'center',
			}
		}
		var chart = new ApexCharts(document.querySelector("#groupchart"), options);
		chart.render();
		this.setState({ chart: chart });
	}

	componentDidUpdate = (prevProps) => {
		if(this.props.label !== prevProps.label){
			if(this.state.chart) this.state.chart.destroy();
			this.load();
		}
	}

	render() {
		return (
			<div id="groupchart"></div>
		);
	}
}