import React, { Component } from "react";
import { Button, Form } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

import axios from '../../axios';
import apiError from "../../error";
import threatcop from '../../assets/threatcop-b.png';
import Spinner from '../../component/spinner/spinner';
import './resetpassword.css'

export default class ResetPassword extends Component {

    constructor(props){
        super(props);
        this.state = {
            password: '',
            loading: false,
            isVerify: false,
            confirmPassword: ''
        }
    }

    handlePassword = () => {
        if(this.state.password.length === 0){
            toast.error("Please enter password")
            return ;
        } else if(this.state.confirmPassword.length === 0){
            toast.error("Please enter confirm Password")
            return ;
        }
        if(this.state.password !== this.state.confirmPassword){
            toast.error("Password and confirm Password does not match")
            return ;
        }
        this.setState({ loading: true });
        axios.post('/api/auth/verify', {
            password: this.state.password,
            email: this.props.match.params.email,
            verf_key: this.props.match.params.token,
            confirmPassword: this.state.confirmPassword
        })
        .then(res => {
            this.setState({ isVerify: true, loading: false });
        })
        .catch(err => {
            this.setState({ loading: false });
            apiError(err)
        })
    }

    render() {
        return (
            <div className="login-wrap" id="auth-section">
                <div className="row m-0">
                    {/* <div className="col-sm-8 d-flex align-items-center left-wrap">
                        <div className="col-sm-9 mx-auto mid-blank">
                            <img src={loginImg} alt="email" className="login-img"></img>
                            <div className="text-wrap">
                                <h5>Digital Collection</h5>
                                <p>All important data and information of all  your tenants <br></br> in one place, completely digital.</p>
                            </div>
                        </div>
                    </div> */}
                    <div className="col-sm-4 right-wrap mx-auto">
                        <div className="col-sm-10 mx-auto p-0">
                        <div className="text-center">
                                <img src={threatcop} alt="email" className="threatcop-logo"></img>
                            </div>
                                <div className="content">
                                    {!this.state.isVerify?
                                        <div className="login-box">
                                            <h4>Create password for your TSAT account</h4>
                                            <Form.Group controlId="formBasicEmail">
                                             <label>Password</label>
                                                <Form.Control type="password" value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })}  placeholder="Password" />
                                            </Form.Group>
                                            <Form.Group controlId="formBasicEmail">
                                                <label>Confirm Password</label>
                                                <Form.Control type="password" value={this.state.confirmPassword} onChange={(e) => this.setState({ confirmPassword: e.target.value })}  placeholder="Confirm Password" />
                                            </Form.Group>
                                            <p style={{'fontSize': '12px', 'marginBottom': '10px'}}>Note: Password has to be between 8 to 15 characters which contain at least one numeric digit, one uppercase, one lowercase letter and one special character($@!%*#?&)</p>
                                            <Button className="btn-login w-100" disabled={this.state.loading} onClick={this.handlePassword}> <Spinner loading={this.state.loading}></Spinner> Update Password</Button>
                                        </div>: 
                                        <div className="forget-msg">
                                            <h5>Reset Password</h5>
                                            <h6>Your email id and password has been verified</h6>
                                            <p>Click Here to<Button className="btn-link ml-1" onClick={() => this.props.history.push('/login')}>Login</Button></p>
                                        </div>
                                    }
                                </div>
                        </div>
                    </div>
                </div>
                <ToastContainer position="top-right" autoClose={5000}/>
            </div>
        );
    }
}