import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Setting from './settings/settings';
import MobileAuth from './mobile-auth/mobile-auth';

export default class Settings extends Component {
	render() {	
        const { match } = this.props;
		return (
            <Switch>
                <Route path={`${match.path}/`} exact component={ Setting }/>
                <Route path={`${match.path}/auth`} exact component={ MobileAuth }/>
                <Route render={() => (<Redirect to="/invalid" />)}/>
            </Switch>
		);
	}
}