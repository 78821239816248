import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import DirectionsWalkOutlinedIcon from '@material-ui/icons/DirectionsWalkOutlined';

import './support-modal.css';
export default class SupportModal extends Component {
    render(){
        return (
            <Modal show={this.props.supportModal} className="suppot-modal right" onHide={() => this.props.supportHandle()} dialogClassName="modal-90w" aria-labelledby="example-custom-modal-styling-title">
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        <h5 className="mb-0">TSAT Assistant</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="custom-mode-setting">
                        <div className="help-block">
                            <h6>Need help from a live person?</h6>
                            <p>We’ll connect you to an expert who can help you streamline your processes.</p>
                            <Link to="#"><Button variant="primary" onClick={e => window.open('mailto:support@kratikal.com')}>Request Demo</Button></Link>
                        </div>
                        <div className="block">
                            <div className="help-head mb-3">
                                <h5>Getting Started</h5>
                                <span className="line"></span>
                            </div>
                            <Link to="/guide" onClick={this.props.supportHandle} className="d-block">
                                <div className="d-flex align-items-center mb-4">
                                    <DirectionsWalkOutlinedIcon className="assist-icon"></DirectionsWalkOutlinedIcon>
                                    <div>
                                        <h6>How to Get Started?</h6>
                                        <p>Learn how to start using TSAT</p>
                                    </div>
                                </div>
                            </Link>
                            <Link to="#" className="d-block" onClick={e => this.props.openModal(0)}>
                                <div className="d-flex align-items-center mb-4">
                                    <VideocamOutlinedIcon className="assist-icon"></VideocamOutlinedIcon>
                                    <div>
                                        <h6>Why TSAT?</h6>
                                        <p>TSAT educates employees in the basics of cyber security</p>
                                    </div>
                                </div>
                            </Link>
  					    </div>
                        <div className="block">
                            <div className="help-head mb-3">
                                <h5>Feature Guide</h5>
                                <span className="line"></span>
                            </div>
                            <Link to="#" className="d-block" onClick={e => window.open('https://helpdesk.kratikal.com/portal/en/kb/threatcop/whitelisting')}>
                                <div className="d-flex align-items-center mb-4">
                                    <DescriptionOutlinedIcon className="assist-icon"></DescriptionOutlinedIcon>
                                    <div>
                                        <h6>How to Whitelist TSAT?</h6>
                                        <p>Learn how to whitelist TSAT's IPs, domains and hostnames</p>
                                    </div>
                                </div>

                            </Link>
                            <Link to="#" onClick={e => window.open('https://helpdesk.kratikal.com/portal/en/kb/articles/what-are-campaign-templates')} className="d-block">
                                <div className="d-flex align-items-center mb-4">
                                    <DescriptionOutlinedIcon className="assist-icon"></DescriptionOutlinedIcon>
                                    <div>
                                        <h6>What are Campaign Templates?</h6>
                                        <p>Understand the importance of campaign templates </p>
                                    </div>
                                </div>
                            </Link>
                            <Link to="#" onClick={e => window.open('https://helpdesk.kratikal.com/portal/en/kb/articles/how-to-add-users-and-groups-in-threatcop')} className="d-block">
                                <div className="d-flex align-items-center mb-4">
                                    <DescriptionOutlinedIcon className="assist-icon"></DescriptionOutlinedIcon>
                                    <div>
                                        <h6>How to Add Users and Groups?</h6>
                                        <p>Learn how to create new groups and add users in TSAT</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="block">
                            <div className="help-head mb-3">
                                <h5 style={{'width':'65px'}}>Support</h5>
                                <span className="line"></span>
                            </div>
                            <Link to="#" className="d-block" onClick={e => window.open('https://helpdesk.kratikal.com/portal/en/kb/threatcop')}>
                                <div className="d-flex align-items-center mb-4">
                                    <DescriptionOutlinedIcon className="assist-icon"></DescriptionOutlinedIcon>
                                    <div>
                                        <h6>Knowledge Base</h6>
                                    </div>
                                </div>
                            </Link>
                            <Link to="#" className="d-block" onClick={e => window.open('https://www.facebook.com/groups/1364840587213835?notif_id=1612272648385692&notif_t=group_moderator_add&ref=notif')}>
                                <div className="d-flex align-items-center mb-4">
                                    <DescriptionOutlinedIcon className="assist-icon"></DescriptionOutlinedIcon >
                                    <div>
                                        <h6>User Community</h6>
                                    </div>
                                </div>
                            </Link>
                            <Link to="#" className="d-block" onClick={e => window.open('mailto:support@kratikal.com')}>
                                <div className="d-flex align-items-center mb-4">
                                    <PhoneOutlinedIcon className="assist-icon"></PhoneOutlinedIcon>
                                    <div>
                                        <h6>Contact Us</h6>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}