import React, { Component } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Modal, Accordion, Card, Button, Form, Tabs, Tab } from "react-bootstrap";
import Simulationattackimg from "../../../src/assets/simulationattackimg.png";
import Simulationtrainingimg from "../../../src/assets/simulationtrainingimg.png";
import Tooltip from "react-bootstrap/Tooltip";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";


import game from "../../assets/game.png";
import { IoInformation } from "react-icons/io5";
import choose from "../../assets/choose.png";
import vishing from "../../assets/Vishing.png";
import whatsapp from "../../assets/whatsapp.png";
import training from "../../assets/training.png";
import gamified from "../../assets/gamified.png";
import smishing from "../../assets/Smishing.png";
import phishing from "../../assets/Phishing.png";
import cyberScam from "../../assets/CyberScam.png";
import Attachment from "../../assets/attchment.png";
import ransomeware from "../../assets/Ransomeware.png";
import removalMedia from "../../assets/removalMedia.png";
import simulationAttack from "../../assets/SimulationAttack.png";
import simulationTraining from "../../assets/SimulationTraining.png";
import QRcode from "../../assets/qr-code_4299774 1.png";

import { getCookie } from "../../function";
import "./launch-modal.css";

export default class LaunchModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attacks: {},
      attackType: "Phishing",
      credentialHarvesting: true,
      simulationType: "Simulation Attack",
      activeAttachment: false,
      activeQRcode: false,
      vishing: getCookie("Vishing") ? true : false,
      smishing: getCookie("Smishing") ? true : false,
      phishing: getCookie("Phishing") ? true : false,
      ransomeware: getCookie("Ransomware") ? true : false,
      cyberScam: getCookie("Cyber Scam") ? true : false,
      removalMedia: getCookie("Removal Media") ? true : false,
      whatsapp: getCookie("WhatsApp") ? true : false,
      Attachment: getCookie("Attachment") ? true : false,
      QRcode:getCookie("QRCode") ? true : false,
      campaignType: "attack"
    };
  }

  handleNextPath = () => {
    if (this.state.attackType && this.state.attackType.length) {
      this.props.showHandle();
      this.props.nextPath("/templates/launch/" + this.state.simulationType + "/" + this.state.attackType + "/" + this.state.credentialHarvesting);
      this.setState({ attackType: "", credentialHarvesting: false, simulationType: "Simulation Attack" });
    } else {
      toast.error("Select Attack Type");
      return;
    }
  };


  componentDidUpdate = (prevProps) => {
    if (prevProps !== this.props) {
      this.setState({
        attackType: "Phishing",
        activeAttachment: false,
        activeQRcode:false,
        credentialHarvesting: true,
        simulationType: "Simulation Attack",
        vishing: getCookie("Vishing") ? true : false,
        smishing: getCookie("Smishing") ? true : false,
        phishing: getCookie("Phishing") ? true : false,
        ransomeware: getCookie("Ransomware") ? true : false,
	      cyberScam: getCookie("Cyber Scam") ? true : false,
        removalMedia: getCookie("Removal Media") ? true : false,
        whatsapp: getCookie("WhatsApp") ? true : false,
        Attachment: getCookie("Attachment") ? true : false,
        QRcode:getCookie("QRcode") ? true : false,
        campaignType: "attack"
      });
    }
  };

  render() {
    return (
      <div>
      <Modal
        show={this.props.showModal}
        className="chooseModal creatcampModal"
        onHide={() => this.props.showHandle()}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            <h5>Create Campaign</h5>
            <p>Choose your campaign</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <div className="simselectCard">
          <h6>Select Simulation type</h6>
          <div className="simmainFlex">
            <div className={this.state.campaignType == "attack" ? "active_cl simCard" : "simCard"} onClick={e => this.setState({ campaignType: "attack"}) }>
              <div className="simIcon">
                <img src={Simulationattackimg} className="img-fluid"/>
              </div>
              <div className="simContent">
                <h6>Simulation Attack</h6>
                <p>Run a dummy attack with an attack vector of your choice</p>
              </div>
            </div>

            <div className={this.state.campaignType == "attackTraining" ? "active_cl simCard" : "simCard"}  onClick={e => this.setState({ campaignType: "attackTraining" })} >
              <div className="simIcon">
                <img src={Simulationtrainingimg} className="img-fluid"/>
              </div>
              <div className="simContent">
                <h6>Simulation Attack + Training</h6>
                <p>Simulate a cyber attack along with providing security training to the user</p>
              </div>
            </div>
          </div>
         </div>

          {this.state.campaignType == "attack" ? 
            <div className="campattackCard">
              <h6>Choose Attack Vector</h6>
              <div className="row">
                
                  <div className="col-md-2 launch">
                    <div className="vectorBox">
                      <label>

                    <input
                      type="radio"
                      disabled={!this.state.phishing}
                      name="product"
                      checked={this.state.attackType === "Phishing"}
                      className="card-input-element"
                      onChange={(e) => {
                        this.setState({ activeAttachment: false, activeQRcode: false });
                        this.setState({ attackType: "Phishing" });
                      }}
                    />
                    <div className={!this.state.phishing ? "img-box card-input disabledCls" : "img-box card-input"}>
                      <img src={phishing} alt="template" className="img-fluid"></img>
                    </div>
                      <div className="campName">
                        <p className="img-head">Phishing</p>
                        <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="top">
                                  {this.state.phishing
                                    ? "Send an email posing as someone legitimate to lure users into providing sensitive information"
                                    : "Upgrade your plan"}
                                </Tooltip>
                              }>
                                <IoInformation className="tooltip-icon"></IoInformation>
                          </OverlayTrigger>
                      </div>
                      </label>
                    </div>
                   </div>

                    <div className="col-md-2 launch">
                      <div className="vectorBox">
                        <label>
                            <input
                              type="radio"
                              disabled={!this.state.ransomeware}
                              name="product"
                              checked={this.state.attackType === "Ransomware"}
                              className="card-input-element"
                              onChange={(e) => {
                                this.setState({ activeAttachment: false,  activeQRcode: false });
                                this.setState({ attackType: "Ransomware" });
                              }}
                            />
                            <div className={!this.state.ransomeware ? "img-box card-input disabledCls" : "img-box card-input"}>
                              <img src={ransomeware} alt="template" className="img-fluid"></img>
                            </div>

                            <div className="campName">
                              <p className="img-head">Ransomware</p>
                              <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id="top">
                                        {this.state.ransomeware
                                          ? "Send a malicious attachment to lock the screens of the users’ systems to prevent them from accessing their files/systems for a while."
                                          : "Upgrade Your Plan"}
                                      </Tooltip>
                                    }>
                                    <IoInformation className="tooltip-icon"></IoInformation>
                                </OverlayTrigger>
                            </div>
                        </label>
                        </div>
                    </div>

                      <div className="col-md-2 launch">
                          <div className="vectorBox">
                          <label>
                            <input
                              type="radio"
                              disabled={!this.state.smishing}
                              name="product"
                              checked={this.state.attackType === "Smishing"}
                              className="card-input-element"
                              onChange={(e) => {
                                this.setState({ activeAttachment: false, activeQRcode: false });
                                this.setState({ attackType: "Smishing" });
                              }}
                            />
                            <div className={!this.state.smishing ? "img-box card-input disabledCls" : "img-box card-input"}>
                              <img src={smishing} alt="template" className="img-fluid"></img>
                            </div>
                            <div className="campName">
                              <p className="img-head">Smishing</p>
                                  <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="top">
                                      {this.state.smishing
                                        ? "Send an SMS posing as someone legitimate to lure users into providing sensitive information"
                                        : "Upgrade Your Plan"}
                                    </Tooltip>
                                  }>
                                    <IoInformation className="tooltip-icon"></IoInformation>
                                    </OverlayTrigger>
                                    </div>
                                  </label>
                          </div>
                      </div>

                      <div className="col-md-2 launch">
                          <div className="vectorBox">
                          <label>
                            <input
                              type="radio"
                              disabled={!this.state.vishing}
                              name="product"
                              checked={this.state.attackType === "Vishing"}
                              className="card-input-element"
                              onChange={(e) => {
                                this.setState({ activeAttachment: false, activeQRcode: false });
                                this.setState({ attackType: "Vishing" });
                              }}
                            />
                            <div className={!this.state.vishing ? "img-box card-input disabledCls" : "img-box card-input"}>
                              <img src={vishing} alt="template" className="img-fluid"></img>
                            </div>
                            <div className="campName">
                            <p className="img-head">Vishing</p>
                            <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="top">
                              {this.state.vishing
                                ? "Send an IVR call posing as someone legitimate to lure users into providing sensitive information"
                                : "Upgrade Your Plan"}
                            </Tooltip>
                          }>
                            <IoInformation className="tooltip-icon"></IoInformation>
                          </OverlayTrigger>
                          </div>
                          </label>
                          </div>
                      </div>

                        <div className="col-md-2 launch">
                            <div className="vectorBox">
                              <label>
                                <input
                                  type="radio"
                                  disabled={!this.state.whatsapp}
                                  name="product"
                                  checked={this.state.attackType === "WhatsApp"}
                                  className="card-input-element"
                                  onChange={(e) => {
                                    this.setState({ activeAttachment: false, activeQRcode: false });
                                    this.setState({ attackType: "WhatsApp" });
                                  }}
                                />
                                <div className={!this.state.whatsapp ? "img-box card-input disabledCls" : "img-box card-input"}>
                                  <img src={whatsapp} alt="template" className="img-fluid"></img>
                                </div>
                                <div className="campName">
                                    <p className="img-head">WhatsApp</p>
                                    <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="top">
                                      {this.state.whatsapp
                                        ? "Send whatsapp message posing as someone legitimate to lure users into providing sensitive information"
                                        : "Upgrade Your Plan"}
                                    </Tooltip>
                                  }>
                                  <IoInformation className="tooltip-icon"></IoInformation>
                                  </OverlayTrigger>
                              </div>
                              </label>
                            </div>
                        </div>

                        <div className="col-md-2 launch">
                            <div className="vectorBox">
                              <label>
                              <input
                                type="radio"
                                disabled={!this.state.Attachment}
                                name="product"
                                checked={this.state.attackType === "Attachment"}
                                className="card-input-element"
                                onChange={(e) => {
                                  if (e.target.checked) this.setState({ activeAttachment: true, activeQRcode: false });
                                  this.setState({ attackType: "Attachment" });
                                }}
                              />
                                <div className={!this.state.Attachment ? "img-box card-input disabledCls" : "img-box card-input cardactive"}>
                                  <img src={Attachment} alt="template" className="img-fluid"></img>
                                </div>

                              <div className="campName">
                                  <p className="img-head">Attachment</p>
                                  <OverlayTrigger
                                        placement="top"
                                        overlay={
                                          <Tooltip id="top">
                                            {this.state.Attachment
                                              ? "Send an email with attachment posing as someone legitimate to lure users into providing sensitive information"
                                              : "Upgrade your plan"}
                                          </Tooltip>
                                        }
                                      >
                                        <IoInformation className="tooltip-icon"></IoInformation>
                                </OverlayTrigger>
                              </div>
                              </label>
                            </div>
                        </div>

                          <div className="col-md-2 launch">
                          <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id="top">
                                        {this.state.cyberScam
                                          ? "Trick the users into providing sensitive information over phishing sites or counterfeited web pages"
                                          : "Upgrade Your Plan"}
                                      </Tooltip>
                                    }
                                  >
                            <div className="vectorBox">
                              <label>
                                <input
                                  type="radio"
                                  disabled={!this.state.cyberScam}
                                  name="product"
                                  checked={this.state.attackType === "Cyber Scam"}
                                  className="card-input-element"
                                  onChange={(e) => {
                                    this.setState({ activeAttachment: false, activeQRcode: false });
                                    this.setState({ attackType: "Cyber Scam" });
                                  }}
                                />
                                <div className={!this.state.cyberScam ? "img-box card-input disabledCls" : "img-box card-input"}>
                                  <img src={cyberScam} alt="template" className="img-fluid"></img>
                                </div>
                                <div className="campName">
                                <p className="img-head">Cyber Scam</p>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id="top">
                                        {this.state.cyberScam
                                          ? "Trick the users into providing sensitive information over phishing sites or counterfeited web pages"
                                          : "Upgrade Your Plan"}
                                      </Tooltip>
                                    }
                                  >
                                  <IoInformation className="tooltip-icon"></IoInformation>
                                </OverlayTrigger>
                              </div>
                              </label>
                              </div>
                              </OverlayTrigger>
                          </div>

                          <div className="col-md-2 launch">
                          <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="top">
                                      {this.state.removalMedia
                                        ? "Trick the users into providing sensitive information over phishing sites or counterfeited web pages"
                                        : "Upgrade Your Plan"}
                                    </Tooltip>
                                  }
                                >
                              <div className="vectorBox">
                                <label>
                                <input
                                  type="radio"
                                  disabled={!this.state.removalMedia}
                                  name="product"
                                  checked={this.state.attackType === "Removal Media"}
                                  className="card-input-element"
                                  onChange={(e) => {
                                    this.setState({ activeAttachment: false, activeQRcode: false });
                                    this.setState({ attackType: "Removal Media" });
                                  }}
                                />
                                <div className={!this.state.removalMedia ? "img-box card-input disabledCls" : "img-box card-input"}>
                                  <img src={removalMedia} alt="template" className="img-fluid"></img>
                                </div>
                                <div className="campName">
                                <p className="img-head">Removal Media</p>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="top">
                                      {this.state.removalMedia
                                        ? "Trick the users into providing sensitive information over phishing sites or counterfeited web pages"
                                        : "Upgrade Your Plan"}
                                    </Tooltip>
                                  }
                                >
                                <IoInformation className="tooltip-icon"></IoInformation>
                                </OverlayTrigger>
                                </div>
                                </label>
                              </div>
                              </OverlayTrigger>
                          </div>

                          <div className="col-md-2 launch">
                              <div className="vectorBox">
                                <label>
                                <input
                                  type="radio"
                                  disabled={!this.state.QRcode}
                                  name="product"
                                  checked={this.state.attackType === "QRcode"}
                                  className="card-input-element"
                                  onChange={(e) => {
                                    this.setState({ activeAttachment: false });
                                    this.setState({ attackType: "QRcode" });
                                  }}
                                />
                                <div className={!this.state.QRcode ? "img-box card-input disabledCls" : "img-box card-input"}>
                                  <img src={QRcode} alt="template" className="img-fluid"></img>
                                </div>
                                <div className="campName">
                                  <p className="img-head">QRCode</p>
                                  <OverlayTrigger
                                        placement="top"
                                        overlay={
                                          <Tooltip id="top">
                                            {this.state.QRcode
                                              ? "Send an email with QRcode posing as someone legitimate to lure users into providing sensitive information"
                                              : "Upgrade Your Plan"}
                                          </Tooltip>
                                        }
                                      >
                                        <IoInformation className="tooltip-icon"></IoInformation>
                                  </OverlayTrigger>
                                </div>
                              </label>
                              </div>
                          </div>
                    </div>
            </div>: ""
            }



            {this.state.campaignType == "attackTraining" ?
              <div className="campattackCard">
                <h6>Choose Attack Vector</h6>
                <div className="row">
                <div className="col-md-2 launch">
                  <div className="vectorBox">
                    <label>
                      <input
                        type="radio"
                        disabled={!this.state.phishing}
                        name="product-1"
                        checked={this.state.attackType === "Phishing"}
                        className="card-input-element"
                        onChange={(e) => {
                          if (e.target.checked) this.setState({ activeAttachment: false, activeQRcode: false });
                          this.setState({ attackType: "Phishing" });
                        }}
                      />
                      <div className={!this.state.phishing ? "img-box card-input disabledCls" : "img-box card-input"}>
                        <img src={phishing} alt="template" className="img-fluid"></img>
                      </div>

                      <div className="campName">
                         <p className="img-head">Phishing</p>
                         <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="top">
                              {this.state.phishing
                                ? "Send an email posing as someone legitimate to lure users into providing sensitive information"
                                : "Upgrade your plan"}
                            </Tooltip>
                          }
                     >
                      <IoInformation className="tooltip-icon"></IoInformation>
                         </OverlayTrigger>
                      </div>
                      </label>
                    </div>
                </div>

                <div className="col-md-2 launch">
                 
                    <div className="vectorBox">
                      <label>
                      <input
                        type="radio"
                        disabled={!this.state.smishing}
                        name="product-1"
                        checked={this.state.attackType === "Smishing"}
                        className="card-input-element"
                        onChange={(e) => {
                          if (e.target.checked) this.setState({ activeAttachment: false, activeQRcode: false });
                          this.setState({ attackType: "Smishing" });
                        }}
                      />
                      <div className={!this.state.smishing ? "img-box card-input disabledCls" : "img-box card-input"}>
                        <img src={smishing} alt="template" className="img-fluid"></img>
                      </div>
                      <div className="campName">
                      <p className="img-head">Smishing</p>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="top">
                            {this.state.smishing
                              ? "Send an SMS posing as someone legitimate to lure users into providing sensitive information"
                              : "Upgrade Your Plan"}
                          </Tooltip>
                       }>
                      <IoInformation className="tooltip-icon"></IoInformation>
                      </OverlayTrigger>
                    </div>
                    </label>
                    </div>
                </div>

                <div className="col-md-2 launch">
               
                    <div className="vectorBox">
                      <label>
                      <input
                        type="radio"
                        disabled={!this.state.Attachment}
                        name="product"
                        checked={this.state.attackType === "Attachment"}
                        className="card-input-element"
                        onChange={(e) => {
                          if (e.target.checked) this.setState({ activeAttachment: true, activeQRcode: false  });
                        

                          this.setState({ attackType: "Attachment" });
                        }}
                      />
                      
                        <div className={!this.state.Attachment ? "img-box card-input disabledCls" : "img-box card-input"}>
                          <img src={Attachment} alt="template" className="img-fluid"></img>
                        </div>
                      <div className="campName">
                        <p className="img-head">Attachment</p>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="top">
                                {this.state.Attachment
                                  ? "Send an email with attachment posing as someone legitimate to lure users into providing sensitive information"
                                  : "Upgrade your plan"}
                              </Tooltip>
                            }
                          >
                             <IoInformation className="tooltip-icon"></IoInformation>
                        </OverlayTrigger>
                      </div>
                      </label>
                    </div>
                  
                </div>

                <div className="col-md-2 launch">
                        <div className="vectorBox">
                          <label>
                      <input
                        type="radio"
                        disabled={!this.state.QRcode}
                        name="product"
                        checked={this.state.attackType === "QRcode"}
                        className="card-input-element"
                        onChange={(e) => {
                          if (e.target.checked) this.setState({ activeQRcode: true });
                      
                          this.setState({ attackType: "QRcode" });
                        }}
                      />
                        <div className={!this.state.QRcode ? "img-box card-input disabledCls" : "img-box card-input"}>
                          <img src={QRcode} alt="template" className="img-fluid"></img>
                        </div>
                            <div className="campName">
                                <p className="img-head">QRcode</p>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id="top">
                                        {this.state.QRcode
                                          ? "Send an email with QRcode posing as someone legitimate to lure users into providing sensitive information"
                                          : "Upgrade your plan"}
                                      </Tooltip>
                                    }>
                                  <IoInformation className="tooltip-icon"></IoInformation>
                                  </OverlayTrigger>
                          </div>
                     </label>
                    </div>
                 
                </div>

                <div className="col-md-2 launch">
                <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="top">
                            {this.state.cyberScam
                              ? "Trick the users into providing sensitive information over phishing sites or counterfeited web pages"
                              : "Upgrade Your Plan"}
                          </Tooltip>
                        }
                     >
                   <div className="vectorBox">
                    <label>
                      <input
                        type="radio"
                        disabled={!this.state.cyberScam}
                        name="product-1"
                        checked={this.state.attackType === "Cyber Scam"}
                        className="card-input-element"
                        onChange={(e) => {
                          if (e.target.checked) this.setState({ activeAttachment: false, activeQRcode: false });
                          this.setState({ attackType: "Cyber Scam" });
                        }}
                      />
                      <div className={!this.state.cyberScam ? "img-box card-input disabledCls" : "img-box card-input"}>
                        <img src={cyberScam} alt="template" className="img-fluid"></img>
                      </div>
                      <div className="campName">
                      <p className="img-head">Cyber Scam</p>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="top">
                            {this.state.cyberScam
                              ? "Trick the users into providing sensitive information over phishing sites or counterfeited web pages"
                              : "Upgrade Your Plan"}
                          </Tooltip>
                        }
                     >
                     <IoInformation className="tooltip-icon"></IoInformation>
                       </OverlayTrigger>
                    </div>
                    </label>
                    </div>
                    </OverlayTrigger>
               
                </div>
                </div>
              </div>: ""
          }

{/* <h6>Choose Your Campaigns</h6>
<div className="choose-wrap">
  <Accordion defaultActiveKey="0">
    <Card className="accordian-card">
      <Accordion.Toggle
        as={Card.Header}
        eventKey="0"
        onClick={(e) => this.setState({ simulationType: "Simulation Attack", activeAttachment: false, activeQRcode:false, attackType: "Phishing" })}
      >
        <div className="col-12 d-flex align-items-center">
          <div className="img-wrap">
            <img src={simulationAttack} alt="template" className="img-fluid"></img>
          </div>
          <div className="accord-head">
            Simulation Attack
            <OverlayTrigger placement="top" overlay={<Tooltip id="top">Run a dummy attack with an attack vector of your choice</Tooltip>}>
              <HelpOutlineIcon
                className="tooltip-icon mt-1"
                style={{ position: "absolute", left: "195px", bottom: "7px", zIndex: "10" }}
              ></HelpOutlineIcon>
            </OverlayTrigger>
          </div>
        </div>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="0">
        <Card.Body>
          
        </Card.Body>
      </Accordion.Collapse>
    </Card>
    <Card className="accordian-card">
      <Accordion.Toggle
        as={Card.Header}
        eventKey="1"
        onClick={(e) => this.setState({ simulationType: "Simulation Attack + Training", activeAttachment: false, activeQRcode: false, attackType: "Phishing" })}
      >
        <div className="col-12 d-flex align-items-center">
          <div className="img-wrap">
            <img src={simulationTraining} alt="template" className="img-fluid"></img>
          </div>
          <div className="accord-head">
            {" "}
            Simulation Attack + Training
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="top">Simulate a cyber attack along with providing cyber security training to the users</Tooltip>}
            >
              <HelpOutlineIcon
                className="tooltip-icon mt-1"
                style={{ position: "absolute", left: "260px", bottom: "7px", zIndex: "10" }}
              ></HelpOutlineIcon>
            </OverlayTrigger>
          </div>
        </div>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="1">
        <Card.Body>
          
        </Card.Body>
      </Accordion.Collapse>
    </Card>
    <Card className="accordian-card d-none">
      <Accordion.Toggle as={Card.Header} eventKey="2" onClick={(e) => this.setState({ simulationType: "Training" })}>
        <div className="col-12 d-flex align-items-center">
          <div className="img-wrap">
            <img src={gamified} alt="template" className="img-fluid"></img>
          </div>
          <div className="accord-head">
            {" "}
            Training
            <OverlayTrigger placement="top" overlay={<Tooltip id="top">Provide cyber security training to the users</Tooltip>}>
              <HelpOutlineIcon
                className="tooltip-icon mt-1"
                style={{ position: "absolute", left: "195px", bottom: "7px", zIndex: "10" }}
              ></HelpOutlineIcon>
            </OverlayTrigger>
          </div>
        </div>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="2">
        <Card.Body>
          <div className="row">
            {getCookie("Game") ? (
              <div className="col-md-2 launch">
                <label>
                  <input
                    type="radio"
                    name="product"
                    className="card-input-element"
                    onChange={(e) => this.setState({ attackType: "Game" })}
                  />
                  <div className="img-box card-input">
                    <img src={game} alt="template" className="img-fluid"></img>
                  </div>
                  <p className="img-head">Game</p>
                </label>
              </div>
            ) : (
              ""
            )}
            {getCookie("Training + Quiz") ? (
              <div className="col-md-2 launch">
                <label>
                  <input
                    type="radio"
                    name="product"
                    className="card-input-element"
                    onChange={(e) => this.setState({ attackType: "Training + Quiz" })}
                  />
                  <div className="img-box card-input">
                    <img src={training} alt="template" className="img-fluid"></img>
                  </div>
                  <p className="img-head">Training + Quiz</p>
                </label>
              </div>
            ) : (
              ""
            )}
            {getCookie("Quiz") ? (
              <div className="col-md-2 launch">
                <label>
                  <input
                    type="radio"
                    name="product"
                    className="card-input-element"
                    onChange={(e) => this.setState({ attackType: "Quiz" })}
                  />
                  <div className="img-box card-input">
                    <img src={choose} alt="template" className="img-fluid"></img>
                  </div>
                  <p className="img-head">Quiz</p>
                </label>
              </div>
            ) : (
              ""
            )}
          </div>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  </Accordion>
  {this.state.attackType && this.state.attackType.length && this.state.attackType !== "Vishing" ? (
        <div className="checkBoxWrap">
        <Form.Group controlId="formBasicCheckbox" className="mt-3 d-flex">
          <Form.Check
            type="checkbox"
            checked={this.state.credentialHarvesting}
            onChange={(e) => this.setState({ credentialHarvesting: !this.state.credentialHarvesting })}
            label="Credential Harvesting"
          />
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="top">Redirection of the user to a landing page luring them to give away their credentials.</Tooltip>}
          >
            <IoInformation
              className="tooltip-icon"
            ></IoInformation>
          </OverlayTrigger>
        </Form.Group>
    </div>
  ) : (
    ""
  )}
</div> */}


        </Modal.Body>
        
        <Modal.Footer>
        <Link to="#">
            <Button variant="secondary" onClick={() => this.props.showHandle()}>Close</Button>
          </Link>
          <Link to="#" onClick={this.handleNextPath}>
            <Button variant="primary"> Next </Button>
          </Link>
        </Modal.Footer>
      </Modal>
      </div>
    );

  }
  
}
