import React, { Component } from 'react';
import {Button} from 'react-bootstrap';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';

import errLogo from '../../assets/error-img.png';
import './nopath.css';
export default class NoPath extends Component {
    render() {
        return (
           <div className="invalid-wrap">
               <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="text-center mb-3">
                                <h1 className="invalid-text">4 <CancelOutlinedIcon className="invalid-icon animation"/>4</h1>
                                <h4  className="text-uppercase">Sorry, page not found</h4>
                                <Button className="dash-btn" onClick={e => this.props.history.push('/dashboard')}>Back to Dashboard</Button>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-7 col-xl-7">
                            <img src={errLogo} className="img-fluid" alt="invalid-img"></img>
                        </div>
                    </div>
               </div>
           </div>
        );
    }
}