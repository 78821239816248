import { toast } from "react-toastify";
import React, { Component } from "react";
import { Button, Form } from "react-bootstrap";
import GroupList from "./groups";

import axios from "../../../axios";
import apiError from "../../../error";
import Loader from "../../../component/loader/loader";

export default class GoogleAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      googleClientId: "",
      googleAutoSyncEnabled: false,
      googleClientSecret: "",
      googleCustomerId: "",
      verificationState: "",
      groups: [],
      scheduled: "",
    };
  }

  componentDidMount = () => {
    this.loadGoogleAdmin();
    this.loadGoogleGroups();
  };

  validate = () => {
    let raiseFlag = false;
    if (!this.state.googleClientId && this.state.googleAutoSyncEnabled) {
      toast.error("Please enter ClientId");
      raiseFlag = true;
    }
    if (!this.state.googleClientSecret && this.state.googleAutoSyncEnabled) {
      toast.error("Please Enter Client Secret");
      raiseFlag = true;
    }
    if (!this.state.googleClientSecret && this.state.googleCustomerId) {
      toast.error("Please Enter Client Secret");
      raiseFlag = true;
    }

    return raiseFlag ? null : true;
  };

  loadGoogleAdmin = () => {
    axios.get("/api/googlesync/getGoogleAdminSettings").then((res) => {
      if (res.data && res.data.googleAutoSyncEnabled) {
        this.setState({
          googleAutoSyncEnabled: res.data.googleAutoSyncEnabled,
        });
      }
      if (res.data && res.data.googleClientId) {
        this.setState({ googleClientId: res.data.googleClientId });
      }
      if (res.data && res.data.googleClientSecret) {
        this.setState({ googleClientSecret: res.data.googleClientSecret });
      }
      if (res.data && res.data.googleCustomerId) {
        this.setState({ googleCustomerId: res.data.googleCustomerId });
      }
      if (res.data && res.data.verificationState) {
        this.setState({ verificationState: res.data.verificationState });
      }
    });
  };

  handleGoogleGroupAdmin = async () => {
    var data = this.validate();
    try {
      if (data) {
        await axios.post("/api/googlesync/setGoogleAdminSettings", {
          googleClientId: this.state.googleClientId,
          googleAutoSyncEnabled: this.state.googleAutoSyncEnabled,
          googleClientSecret: this.state.googleClientSecret,
          googleCustomerId: this.state.googleCustomerId,
        });
        toast.success("Google Admin Settings updated successfully");
        this.handleOAuthRedirection(
          this.state.googleClientId,
          this.state.verificationState
        );
      }
    } catch (err) {
      apiError(err);
    }
  };

  handleOAuthRedirection = async (googleClientId, state) => {
    const clientId = googleClientId;
    const redirectUri = `${process.env.REACT_APP_API_URL}/api/googlesync/oauth2redirect`;
    const scopes = encodeURIComponent(
      "https://www.googleapis.com/auth/admin.directory.user.readonly  https://www.googleapis.com/auth/admin.directory.group.readonly"
    );
    const accessType = "offline";
    const authorizationUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=${scopes}&access_type=${accessType}&prompt=consent&state=${state}`;
    window.open(authorizationUrl, "_blank").focus();
  };

  loadGoogleGroups = async () => {
    try {
      let googleGroupsResponse = await axios.get("api/groups/google-groups");
      this.setState({
        groups: googleGroupsResponse.data.groups,
        scheduled: googleGroupsResponse.data.scheduled,
      });
    } catch (err) {
      apiError(err);
    }
  };

  handleSaveGroups = async (selectedGroups, scheduled) => {
    await axios.post("/api/groups/google-groups", {
      groups: selectedGroups,
      scheduled,
    });
  };

  render() {
    return (
      <div className="tab-wrap">
        <div className="tabHeading">
          <h6>Google Admin Settings</h6>
          <p>Displays basic information associ </p>
        </div>

        <div className="col-md-4 pl-0">
          <div className="profile-form">
            <div className="compHeading">
              <span class="point">1</span>Google Admin Settings
            </div>

            <Form>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  label="Enable Auto-Sync"
                  checked={this.state.googleAutoSyncEnabled}
                  onChange={(e) =>
                    this.setState({ googleAutoSyncEnabled: e.target.checked })
                  }
                />
                </Form.Group>
                <Form.Group>
                        <Form.Label>Client ID</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder={this.state.googleClientId || "Client Id"}
                                  value={this.state.googleClientId}
                                  onChange={(e) =>
                                    this.setState({ googleClientId: e.target.value })
                                  }
                                  disabled={!this.state.googleAutoSyncEnabled} // This will disable the input when checkbox is not checked
                                />
                </Form.Group>
               

                <Form.Group>
                <Form.Label>Client Secret</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={this.state.googleClientSecret || "Client Secret"}
                  value={this.state.googleClientSecret}
                  onChange={(e) =>
                    this.setState({ googleClientSecret: e.target.value })
                  }
                  disabled={!this.state.googleAutoSyncEnabled} // This will disable the input when checkbox is not checked
                />
                </Form.Group>

                <Form.Group>
                <Form.Label>Customer ID</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={this.state.googleCustomerId || "Customer Id"}
                  value={this.state.googleCustomerId}
                  onChange={(e) =>
                    this.setState({ googleCustomerId: e.target.value })
                  }
                  disabled={!this.state.googleAutoSyncEnabled} // This will disable the input when checkbox is not checked
                />
              </Form.Group>

              <div>
                <Button
                  variant="success"
                  type="button"
                  onClick={this.handleGoogleGroupAdmin}
                >
                  Save
                </Button>
              </div>
            </Form>
          </div>
        </div>

        <hr className="unserLinecss"></hr>

        <div className="compHeading">
          <span class="point">2</span>Fetched Groups
        </div>

        <div className="col-md-4 pl-0">
          <Form>
            <div className="row">
              <div>
                <GroupList
                  groups={this.state.groups}
                  onSave={this.handleSaveGroups}
                />
                {/* create a new component that manages the groups to be synced */}
              </div>
              <div></div>
            </div>
          </Form>
        </div>

        {this.state.loading ? <Loader></Loader> : null}
      </div>
    );
  }
}
