import React, { Component } from "react";
import ApexCharts from 'apexcharts'

export default class ThreatScore extends Component {
	constructor(props){
		super(props);
		this.state = { chart: null }
	}

	componentDidMount = () => {
		this.load();
	}

	load = () => {
		let options = {
			series: [
				this.props.send? this.props.send: 0, 
				this.props.submitted? this.props.submitted: 0
			],
			labels: ['Attacks', 'Phish Risk'],
			colors: ['#6c81e7', '#f66174'],
			chart: {
				type: 'donut',
			},
			plotOptions: {
				pie: {
					startAngle: -90,
					endAngle: 90,
					offsetY: 10
				}
				},
				grid: {
				padding: {
					bottom: -80
				}
			},
			dataLabels: {
				enabled: false
			},
			responsive: [{
				breakpoint: 480,
				options: {
					chart: {
						width: 200
					},
					legend: {
						position: 'bottom'
					}
				}
			}],
			legend: {
				show: true,
				position: 'bottom'
			}
		}
		var chart = new ApexCharts(document.querySelector("#threat-score"), options);
		chart.render();
		this.setState({ chart: chart });
	}

	componentDidUpdate = (prevProps) => {
		if(this.props.send !== prevProps.send && this.props.submitted !== prevProps.submitted){
			if(this.state.chart) this.state.chart.destroy();
			this.load();
		}
	}

	render() {
		return (
			<div id="threat-score"></div>
		);
	}
}