import React, { Component } from "react";
import swal from "sweetalert";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import MoreVertSharpIcon from "@material-ui/icons/MoreVertSharp";
import GetAppSharpIcon from '@material-ui/icons/GetAppSharp';
import CreateSharpIcon from '@material-ui/icons/CreateSharp';
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';
import SearchSharpIcon from '@material-ui/icons/SearchSharp';
import ArrowBackSharpIcon from '@material-ui/icons/ArrowBackSharp';
import { PiCloudArrowDownLight, PiPlus } from "react-icons/pi";
import { Button, Card, Table, Breadcrumb, Form, Row, Col, InputGroup, Dropdown, FormControl } from 'react-bootstrap';

import axiosRequest from "axios";
import axios from "../../../axios";
import apiError from "../../../error";
import group from "../../../assets/group.png";
import Loader from "../../../component/loader/loader";
import CSVModal from "../../../component/rightside-modal/upload-csv-modal";
import RightSideModal from "../../../component/rightside-modal/rightside-modal-group";

import CSV from "../../../assets/depart-csv.png";
import User from "../../../assets/depart-user.png";
import "./department.css";
var cancelRequest = undefined,
    CancelToken = axiosRequest.CancelToken;

export default class DepartmentCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            count: 0,
            limit: 10,
            name: "",
            search: "",
            status: "",
            activePage: 1,
            loading: true,
            groupTarget: [],
            errorFile: null,
            statusCheck:false,
            groupId: this.props.match.params.id,
            updateGroupId:this.props.match.params.id, // to solve the toast message//
            emailIds: [],

            show: false,
            showCSV: false,
            isDisable: false,
            targetId: 0,
            firstname: "",
            lastname: "",
            email: "",
            phone: "",
            position: "",
            location: "",
            department: "",
            isManager: false,
            managerEmail: "",
            userCount: 0,
            
            groups: [],
            parentId: 0,
            updateEmail:false,
            isManagerInGroup: false,
        };
    }

    componentDidMount = () => {
        this.loadGroup();
    };

    loadGroup = async () => {
        if (this.state.groupId > 0) {
            axios
                .get("/api/groups/" + this.state.groupId, {})
                .then((res) => {
                    this.setState(
                        {
                            errorFile: res.data.path,
                            name: res.data.group.name,
                            status: res.data.group.status,
                            parentId: res.data.group.parentId ? res.data.group.parentId : 0,
                        },
                        () => {
                            this.loadTargets();
                            this.loadParentGroup();
                        }
                    );
                })
                .catch((error) => {
                    apiError(error);
                });
        } else {
            this.setState({ loading: false });
            this.loadParentGroup();
        }
    };

    loadParentGroup = () => {
        axios
            .get(`/api/groups/parentGroup/${this.state.groupId}`, {})
            .then(async (res) => {
                this.setState({ groups: res.data.groups });
            })
            .catch((error) => {
                apiError(error);
            });
    };

    CSVHandle = () => {
        if (!this.state.name || this.state.name.length === 0 || this.state.name.trim().length === 0) {
            toast.error("Please enter group name first");
        } else {
            this.setState({ showCSV: !this.state.showCSV });
        }
    };

    loadTargets = async () => {
        if (this.state.groupId > 0) {
            // await this.setState({ loading: true });
            if (cancelRequest) cancelRequest();
            axios
                .get("/api/targets", {
                    params: {
                        limit: this.state.limit,
                        search: this.state.search,
                        groupId: this.state.groupId,
                        page: this.state.activePage,
                    },
                    cancelToken: new CancelToken(function executor(c) {
                        cancelRequest = c;
                    }),
                })
                .then(async (res) => {
                    let emailIds = [];
                    for (let i = 0; i < res.data.targets.length; i++) {
                        emailIds.push(res.data.targets[i].target.email);
                    }
                    this.setState({
                        loading: false,
                        emailIds: emailIds,
                        count: res.data.count,
                        groupTarget: res.data.targets,
                        userCount: res.data.targets.length,
                        managerEmail: res.data.targets.managerEmail,
                    });
                })
                .catch((error) => {
                    if (!axiosRequest.isCancel(error)) {
                        this.setState({ groupTarget: [], count: 0, loading: false });
                        apiError(error);
                    }
                });
        } else {
            this.setState({ loading: false });
        }
    };

    handleDelete = (targetId) => {
        swal({
            title: "Do you want to delete user?",
            icon: "warning",
            buttons: ["No", "Yes"],
            dangerMode: true,
        }).then((willSuccess) => {
            if (willSuccess) {
                axios
                    .delete("/api/targets/" + targetId + "/" + this.state.groupId, {})
                    .then((res) => {
                        this.loadTargets();
                        this.setState({search:''})
                        toast.success("user deleted successfully");
                    })
                    .catch((err) => {
                        apiError(err);
                    });
            }
        });
    };

    loadDomain = () => {
        axios
            .get("/api/domain/", {})
            .then((res) => {
                if (res.data.domain && res.data.domain.data && res.data.domain.data.length) {
                    let domains = [];
                    for (var i = 0; i < res.data.domain.data.length; i++) {
                        if (res.data.domain.data[i].status === "approved") {
                            if (res.data.domain.data[i].domain && res.data.domain.data[i].domain.name) {
                                domains.push(res.data.domain.data[i].domain.name);
                            }
                        }
                    }
                    this.setState({ domains: domains });
                }
            })
            .catch((err) => {
                apiError(err);
            });
    };

    handleGroupName = () => {
        if (!this.state.name || this.state.name.length === 0 || this.state.name.trim().length === 0) {
            toast.error("Please enter group name first");
        }
        // else if(!this.state.isManagerInGroup){
        //     toast.error("Please assign one manager");
        // }
        else {
            let data = { name: this.state.name, parentId: this.state.parentId };
            if (this.state.updateGroupId && parseInt(this.state.updateGroupId, 10) !== 0) {
                axios
                    .patch("/api/groups/" + this.state.groupId, data, {})
                    .then((res) => {
                        this.props.history.push("/department/groups");
                        toast.success("Group updated successfully");
                    })
                    .catch((err) => {
                        apiError(err);
                    });
            } else {
                axios
                    // .post("/api/groups/", data, {})
                    .patch("/api/groups/"+this.state.groupId, data, {})
                    .then((res) => {
                        toast.success("Group created successfully");
                        this.setState({ groupId: res.data.groupId });
                        this.props.history.push("/department/groups");
                    })
                    .catch((err) => {
                        apiError(err);
                    });
            }
        }
    };

    handleOpenAddUserModal = () => {
        if (!this.state.name || this.state.name.length === 0 || this.state.name.trim().length === 0) {
            toast.error("Please enter group name first");
        } else {
            this.setState({ show: !this.state.show ,updateEmail:false})
        }
    };

    handleOpenAddCSVModal = () => {
        if (!this.state.name || this.state.name.length === 0 || this.state.name.trim().length === 0) {
            toast.error("Please enter group name first");
        } else {
            this.setState({ show: !this.state.show });
        }
    };

    nextPath = (path) => this.props.history.push(path);

    render() {
        return (
            <div className="content-wrap">
                <div className="breadcrumbbar">
                    <div className="flexRow row">
                        <div className="col-md-4">
                            <div className="page-head d-flex align-items-center justify-content-between">
                                <h4 className="page-title">
                                      <a onClick={e=> this.props.history.push('/department/groups')} className="bar_Icons mr-2"><ArrowBackSharpIcon className="btn-icon"/>
                                    </a>Create New Group</h4>
                            </div>
                        </div>
                    </div>
                 </div>


   {/**********************
             Filter
     **********************/}
     <div className="filter-wrapper d-flex align-items-center">
    <div className="row w-100 m-0">
        <div className="col-sm-6 d-flex align-items-center pl-0 setcolWidth_45">
            <p className="textMuted">Fill group details below</p>
        </div>
    </div>
    </div>
      
        {/**********************
                End
        **********************/}

             
                <div className="contentbar creatgroupSection">
                     <div className="cmpaigntemplateBox p-0">
                        <div className="row m-auto">
                            <div className="col-lg-12 p-0">
                             <div className="newCampCreate">
                                <div className="campBlock pt-0">
                                    <div className="compHeading">
                                        <span className="point">1</span>Group Information
                                     </div>

                                        <div className="row m-auto">
                                            <div className="col-md-4 pl-0">
                                                <Form.Group controlId="formGroupEmail" className="mb-0">
                                                    <Form.Label>Group Name<span className="red-star">*</span></Form.Label>
                                                    <Form.Control type="text" disabled={this.state.name === "Non Group Users"} placeholder="Group Name" value={this.state.name} onChange={e => this.setState({name: e.target.value})}/>
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-4">
                                                    <Form.Label>Sub Department of (Optional)</Form.Label>
                                                        <Form.Group controlId="exampleForm.ControlSelect2" className="mb-0">
                                                            <Form.Control as="select" value={this.state.parentId} onChange={(e)=> this.setState({parentId: parseInt(e.target.value, 10)})}>
                                                                <option value={0}>Select Group</option>
                                                                {this.state.groups.map((group, index) => {
                                                                    return <option key={index} value={group.id}>{group.name}</option>
                                                                })}
                                                            </Form.Control>
                                                    </Form.Group>
                                            </div>
                                        </div>
                                 </div>

                                 <div className="campBlock">
                                     <div class="compHeading">
                                        <span class="point">2</span>User Listing
                                     </div>

                                     <div className="row m-auto">
                                             <div className="col-md-3 pl-0">
                                            <InputGroup className="search-wrap"><FormControl  type="text" placeholder="Search" value={this.state.search} onChange={e => this.setState({search: e.target.value}, () => this.loadTargets())} className="inputSearch mr-sm-2" /><SearchSharpIcon className="search-icon"></SearchSharpIcon></InputGroup>
                                            </div>

                                            <div className="col-md-9 text-right pr-0">
                                                 <div className="rightFilters capmuserList">
                                                    <Dropdown className="dropdownIcon">
                                                        <Dropdown.Toggle id="dropdown-basic" className="btn btn-trans dropdown-toggle">
                                                        Upload User
                                                        </Dropdown.Toggle>
                                                             <Dropdown.Menu>
                                                                <Dropdown.Item onClick={this.handleOpenAddUserModal}>Add User</Dropdown.Item>
                                                                <Dropdown.Item onClick={this.CSVHandle}>Upload CSV</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                    </Dropdown>

                                                    <Form.Group controlId="exampleForm.ControlSelect1" onChange={(e)=> this.setState({limit: parseInt(e.target.value, 10), activePage: 1}, ()=> this.loadTargets())} className="d-inline-block mb-0">
                                                        <Form.Control as="select" className="mr-2 btn btn-outline-secondary">
                                                            <option value="show">Show</option>
                                                            <option value="10">10</option>
                                                            <option value="25">25</option>
                                                            <option value="50">50</option>
                                                            <option value="100">100</option>
                                                        </Form.Control>
                                                    </Form.Group>
                                                </div>
                                            </div>

                                            <div className="tableContainerWrapInner mt-2 p-0">
                                                    <Table responsive>
                                                            <thead className="bg-light">
                                                                <tr>
                                                                    <th>First Name</th>
                                                                    <th>Last Name</th>
                                                                    <th>Email</th>
                                                                    <th>Phone</th>
                                                                    <th>Position</th>
                                                                    <th>Department</th>
                                                                    <th>Location</th>
                                                                    <th>Manager's Email</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.groupTarget.map((group_target, index) => {
                                                                    let target = group_target.target;

                                                                    return <tr key={index}>
                                                                            <td>{target.firstname}</td>
                                                                            <td>{target.lastname?target.lastname:'---'}</td>
                                                                            <td>{target.email}</td>
                                                                            {/* <td>{target.email} {group_target.isManager? <span style={{color: 'green'}}>{` (Manager)`}</span>: ""}</td> */}
                                                                            <td>{target.phone?(target.phone):'---'}</td>
                                                                            <td>{target.position? target.position: '---'}</td>
                                                                            <td>{target.department!=null? target.department.name: '---'}</td>
                                                                            <td>{target.location!=null ? target.location.name: '---'}</td>
                                                                            <td>{group_target.managerEmail? group_target.managerEmail: '---'}</td>
                                                                            <td className="d-flex">


                                                                            <Dropdown
                                                            className="action-dropdown dropdownIcon"
                                                            ref={this.selectRef}
                                                            >
                                                            <Dropdown.Toggle
                                                                className="btn-only"
                                                                id="dropdown-basic"
                                                            >
                                                                <MoreVertSharpIcon className="dot-icon"></MoreVertSharpIcon>
                                                            </Dropdown.Toggle>
                                                                    <Dropdown.Menu>
                                                                        <Dropdown.Item
                                                                         onClick={() => {
                                                                            this.setState({
                                                                                show: true,
                                                                                targetId: target.id,
                                                                                firstname: target.firstname,
                                                                                lastname: target.lastname,
                                                                                email: target.email,
                                                                                phone: target.phone,
                                                                                position: target.position,
                                                                                department: target.department?target.department.name : "",
                                                                                location: target.location ? target.location.name : "",
                                                                                managerEmail: group_target.managerEmail,
                                                                                // isManager: group_target.isManager
                                                                            })
                                                                        }}>
                                                                                Edit
                                                                        </Dropdown.Item>
                                                                    

                                                                <Dropdown.Item
                                                               onClick={e => this.handleDelete(target.id)}>
                                                                Delete
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                            </Dropdown>

                                                                            </td>
                                                                        </tr>
                                                                })}
                                                            </tbody>
                                                    </Table>
                                                
                                            <div className="pagination-Wrap">
                                                <div className="row">
                                                     <div className="col-md-6">
                                                        {!this.state['loading']?
                                                            <Pagination
                                                                itemClass="page-item"
                                                                linkClass="page-link"
                                                                activePage={this.state.activePage}
                                                                itemsCountPerPage={this.state.limit}
                                                                totalItemsCount={this.state.count}
                                                                onChange={(pageNumber) => this.setState({activePage: pageNumber}, ()=> this.loadTargets())}
                                                            />: null
                                                        }
                                                    { this.state.loading? <Loader/>: null }
                                                    </div>
                                                    <div className="col-md-6 text-right d-flex align-items-center footer-pg">
                                                      <div className="w-100 pgCount"><p> {this.state.count} Templates Available</p> </div>
                                          </div>
                                            </div>
                                         </div>
                                      </div> 
                                      </div>
                                    
                                  </div>
                                </div>
                            </div>

                        </div>

                                
                         </div>
                    </div>

                        <div class="capFooter text-right">
                            <Button variant="success" className="mr-2" disabled={this.state.name === "Non Group Users" || this.state.userCount === 0 } onClick={this.handleGroupName}>Save Group</Button>
                                { this.state.errorFile && <Button variant="warning" target="_blank" href={this.state.errorFile} rel="noopener noreferrer"><GetAppSharpIcon className="btn-icon"></GetAppSharpIcon> Error File </Button>}
                        </div>
                  

                <RightSideModal
                    updateEmail={this.state.updateEmail}
                    show={this.state.show}
                    load={this.loadTargets}
                    phone={this.state.phone}
                    email={this.state.email}
                    groupId={this.state.groupId}
                    parentId={this.state.parentId}
                    emailIds={this.state.emailIds}
                    targetId={this.state.targetId}
                    lastname={this.state.lastname}
                    position={this.state.position}
                    firstname={this.state.firstname}
                    department={this.state.department}
                    location={this.state.location}
                    isManager={this.state.isManager}
                    managerEmail={this.state.managerEmail}
                    handleGroupName={this.handleGroupName}
                    handleModal={() =>{
                        this.setState({
                            show: !this.state.show,
                            targetId: "0",
                            firstname: "",
                            lastname: "",
                            email: "",
                            phone: "",
                            position: "",
                            location: "",
                            department: "",
                            managerEmail: "",
                            isManager: false,
                            search:'',
                            updateEmail:false,
                        })
                        this.loadGroup();
                    }
                    }
                    nextPath={this.nextPath}
                    groupName={this.state.name}
                    handleGroupId={(groupId) => this.setState({ groupId: groupId })}></RightSideModal>
                <CSVModal
                    nextPath={this.nextPath}
                    show={this.state.showCSV}
                    CSVHandle={this.CSVHandle}
                    groupName={this.state.name}
                    groupId={this.state.groupId}
                    parentId={this.state.parentId}
                    handleGroupId={(groupId) => this.setState({ groupId: groupId })}></CSVModal>
            </div>
        );
    }
}
