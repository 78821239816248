import { Component } from "react";
import ApexCharts from 'apexcharts'

export default class PhishRate extends Component {
	constructor(props){
		super(props);
		this.state = {
			chart: ''
		}
	}
	componentDidMount = () => {
		let options = {
            series: [{
                name: 'Net Profit',
                data: [44, 55, 57, 56]
              }, {
                name: 'Revenue',
                data: [76, 85, 101, 98]
              }],
                chart: {
                type: 'bar',
                height: 210,
                toolbar: {
                    show: false
                }
              },
              plotOptions: {
                bar: {
                  horizontal: false,
                  columnWidth: '65%',
                },
              },
              dataLabels: {
                enabled: false
              },
              legend: {
                show: false
              },
              stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
              },
              xaxis: {
                categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun'],
              },
              fill: {
                opacity: 1
              },
              grid: {
                show: false,
            }
        }
        var chart = new ApexCharts(document.querySelector("#phish-rate"), options);
        chart.render();
	}



	render() {
		return '';
	}
}