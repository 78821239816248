import React, { Component } from "react";
import { Link } from "react-router-dom";
import { TfiHome } from "react-icons/tfi";
import Accordion from "react-bootstrap/Accordion";
import DehazeIcon from "@material-ui/icons/Dehaze";
import ClearSharpIcon from "@material-ui/icons/ClearSharp";
import ReceiptSharpIcon from "@material-ui/icons/ReceiptSharp";
import NavigateNextSharpIcon from "@material-ui/icons/NavigateNextSharp";
import { PiHouse, PiUsers, PiTreeStructure, PiUserList, PiCalendarBlank, PiGlobe, PiBug, PiNote, PiHeartbeat, PiMegaphone, PiShapes } from "react-icons/pi";

import { getCookie } from "../../function";
import logo from "../../assets/threatcop.png";
import RespLogo from "../../assets/mini_logo.png";
import LaunchModal from "../../component/launch-modal/launch-modal";

import "./sidebar.css";
export default class SideBar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			myWindow: null,
			showModal: false,
			activeAttachment: false,
			userType: getCookie("userType"),
			billingType: getCookie("billingType"),
			accessLevel: getCookie("accessLevel"),

			activeClass: this.props.activeClass,
			activeSubClass: this.props.activeSubClass,

			activeCollapse1: this.props.activeClass === "department",
			activeCollapse2: this.props.activeClass === "reports",
			activeCollapse3: this.props.activeClass === "iam",
			activeCollapse4: this.props.activeClass === "settings",
		};
	}

	componentDidUpdate = (prevProps) => {
		if (
			this.props.activeClass !== prevProps.activeClass ||
			this.props.activeSubClass !== prevProps.activeSubClass
		) {
			this.setState({
				activeClass: this.props.activeClass,
				accessLevel: getCookie("accessLevel"),
				activeSubClass: this.props.activeSubClass,
			});
		}
	};

	showHandle = () => {
		this.setState({ showModal: !this.state.showModal });
	};

	render() {
		return (
			<div className={this.props["sidebar"] ? "leftbar" : "leftbar shrink"}>
				<div className="nav-bar">
					<div className="sidebar-logo">
						<div>
							<Link to="/home" className="logo">
								{" "}
								<img src={logo} alt=""></img>{" "}
							</Link>
							<Link to="/home" className="miniLogo d-none">
								{" "}
								<img src={RespLogo} alt=""></img>{" "}
							</Link>
						</div>
						<div className="mini-sidebar" onClick={(e) => this.props.sideBarFun()}>
							{this.props.show ? (
								<DehazeIcon></DehazeIcon>
							) : (
								<ClearSharpIcon></ClearSharpIcon>
							)}
						</div>
					</div>
					<ul className="sidebar-list">
						<span className="sbheading">Main Menu</span>
						<li
							className={
								this.state.activeClass === "dashboard" ||
									this.state.activeClass === "home"
									? "active"
									: ""
							}
						>
							<Link to="/dashboard">
								<PiHouse className="icon" />
								<span>Dashboard</span>{" "}
							</Link>
						</li>
						<li
							className={this.state.activeClass === "campaign" ? "active" : ""}
						>
							<Link to="/campaign">
								<PiMegaphone className="icon" />
								<span>Campaigns</span>
							</Link>
						</li>
						<li
							className={this.state.activeClass === "templates" ? "active" : ""}
						>
							<Link to="/templates">
								<PiShapes className="icon" />
								<span>Templates</span>
							</Link>
						</li>
						<li
							className={
								this.state.activeClass === "landing-url" ? "active" : ""
							}
						>
							<Link to="/landing-url">
								<PiGlobe className="icon" />
								<span>Landing URL</span>
							</Link>
						</li>
						<Accordion
							className="sub-menu"
							defaultActiveKey={
								this.state.activeClass === "department"? "0": 
								this.state.activeClass === "reports"? "1":
								this.state.activeClass === "iam"? "2":
								this.state.activeClass === "settings"? "3": "-1"
							}
						>
							<Accordion.Toggle
								eventKey="1"
								onClick={(e) =>
									this.setState({
										activeCollapse2: !this.state.activeCollapse2,
										activeCollapse1: false,
										activeCollapse3: false,
										activeCollapse4: false,
									})
								}
								className={
									this.state.activeCollapse2? "activeCollapse": "deactiveCollapse"
								}
							>
								<PiNote className="icon" />
								<span>
									Reports
									<NavigateNextSharpIcon className="down-arrow"></NavigateNextSharpIcon>
								</span>
							</Accordion.Toggle>
							<Accordion.Collapse className="accordion-li" eventKey="1">
								<div>
									<li
										className={
											this.state.activeSubClass === "analytics" ? "active" : ""
										}
									>
										<Link to="/reports/analytics">
											<span className="pl-2">
												<TfiHome className="icon"></TfiHome>Campaign Analytics
											</span>
										</Link>
									</li>
									<li
										className={
											this.state.activeSubClass === "clickreports"
												? "active"
												: ""
										}
									>
										<Link to="/reports/clickreports">
											<span className="pl-2">
												<TfiHome className="icon"></TfiHome>Click Reports
											</span>
										</Link>
									</li>
									<li
										className={
											this.state.activeSubClass === "breachincident"
												? "active"
												: ""
										}
									>
										<Link to="/reports/breachincident">
											<span className="pl-2">
												<TfiHome className="icon"></TfiHome>Breach / Incident
											</span>
										</Link>
									</li>
									<li
										className={
											this.state.activeSubClass === "repeatoffender"
												? "active"
												: ""
										}
									>
										<Link to="/reports/repeatoffender">
											<span className="pl-2">
												<TfiHome className="icon"></TfiHome>Repeat Offender
											</span>
										</Link>
									</li>
									<li>
										<Link onClick={this.props.handleCourseAnalytics} to={`#`}>
											<span className="pl-2">
												<TfiHome className="icon"></TfiHome>Course Analytics
											</span>
										</Link>
									</li>
								</div>
							</Accordion.Collapse>
						</Accordion>

						<span className="sbheading">User & Groups</span>
						<div>
							<li
								className={this.state.activeSubClass === "user" ? "active" : ""}
							>
								<Link to="/department/user">
									<span>
										<PiUsers className="icon" />
										Users
									</span>
								</Link>
							</li>
							<li
								className={
									this.state.activeSubClass === "groups" ? "active" : ""
								}
							>
								<Link to="/department/groups">
									<span>
										<PiTreeStructure className="icon" />
										Groups
									</span>
								</Link>
							</li>
						</div>

						<Accordion
							className="sub-menu"
							defaultActiveKey={
								this.state.activeClass === "department"? "0": 
								this.state.activeClass === "reports"? "1":
								this.state.activeClass === "iam"? "2":
								this.state.activeClass === "settings"? "3": "-1"
							}
						>
							{this.state.billingType === "Threatcop" &&
								this.state.userType === "root" ? (
								<>
									<Accordion.Toggle
										eventKey="2"
										onClick={(e) =>
											this.setState({
												activeCollapse3: !this.state.activeCollapse3,
												activeCollapse1: false,
												activeCollapse2: false,
												activeCollapse4: false,
											})
										}
										className={
											this.state.activeCollapse3
												? "activeCollapse"
												: "deactiveCollapse"
										}
									>
										<PiUserList className="icon" />
										<span>
											IAM{" "}
											<NavigateNextSharpIcon className="down-arrow"></NavigateNextSharpIcon>
										</span>
									</Accordion.Toggle>
									<Accordion.Collapse className="accordion-li" eventKey="2">
										<div>
											<li
												className={
													this.state.activeSubClass === "users" ? "active" : ""
												}
											>
												<Link to="/iam/users">
													<span className="pl-2">
														<PiUsers className="icon"></PiUsers>Users
													</span>
												</Link>
											</li>
											<li
												className={
													this.state.activeSubClass === "roles" ? "active" : ""
												}
											>
												<Link to="/iam/roles">
													<span className="pl-2">
														<TfiHome className="icon"></TfiHome>Roles
													</span>
												</Link>
											</li>
											<li
												className={
													this.state.activeSubClass === "policy" ? "active" : ""
												}
											>
												<Link to="/iam/policy">
													<span className="pl-2">
														<TfiHome className="icon"></TfiHome>Policies
													</span>
												</Link>
											</li>
										</div>
									</Accordion.Collapse>
								</>
							) : null}
						</Accordion>
						<span className="sbheading">Other Menu</span>
						<li
							className={this.state.activeClass === "calendar" ? "active" : ""}
						>
							<Link to="/calendar">
								<PiCalendarBlank className="icon" />
								<span>Calender</span>
							</Link>
						</li>
						{this.state.billingType === "Threatcop" || (this.state.billingType === "AppSumo" && this.state.accessLevel > 2) ? 
							<li className={this.state.activeClass === "evs" ? "active" : ""}>
								<Link to="/evs">
									<PiHeartbeat className="icon" />
									<span>EVS</span>
								</Link>
							</li>
						: null }
						<li
							className={
								this.state.activeClass === "hackrecord" ? "active" : ""
							}
						>
							<Link to="/hackrecord">
								<PiBug className="icon" />
								<span>Hack Record</span>
							</Link>
						</li>
						{this.state.billingType === "AppSumo" ? 
							<li
								className={this.state.activeClass === "billing" ? "active" : ""}
							>
								<Link to="/billing">
									<ReceiptSharpIcon className="icon"></ReceiptSharpIcon>
									<span>Billings</span>
								</Link>
							</li>
						: null }
					</ul>
				</div>
				<LaunchModal
					showModal={this.state.showModal}
					showHandle={this.showHandle}
					nextPath={this.props.nextPath}
				></LaunchModal>
			</div>
		);
	}
}
