import { Component } from "react";
import ApexCharts from 'apexcharts'

export default class AverageClick extends Component {
	constructor(props){
		super(props);
		this.state = {
			chart: ''
		}
	}
	componentDidMount = () => {
		let options = {
      series: [
        {
          name: "High - 2013",
          data: [28, 29, 33, 36, 32, 32, 33]
        },
        {
          name: "Low - 2013",
          data: [12, 11, 14, 18, 17, 13, 13]
        }
      ],
        chart: {
        height: 350,
        type: 'line',
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2
        },
        toolbar: {
          show: false
        }
      },
      colors: ['#667CE8', '#4FC68C'],
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: 'smooth'
      },
    
      grid: {
        show:true
      },
      markers: {
        size: 1
      },
      xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
        title: {
          text: 'Month'
        }
      },
      yaxis: {
        
        min: 5,
        max: 40
      },
      legend: {
        show:false
      }
        }
        var chart = new ApexCharts(document.querySelector("#click"), options);
        chart.render();
	}



	render() {
		return '';
	}
}