import React, { Component } from "react";
import EmailIcon from '@material-ui/icons/Email';
import { Card } from 'react-bootstrap';
import PhoneAndroidSharpIcon from '@material-ui/icons/PhoneAndroidSharp';

import '../evs.css'
export default class EVSGrid extends Component {
    render() {
        
        const evs = this.props.evs, score = parseInt(this.props.evs.percent);
        var fullname = evs.target.firstname + " ";
        fullname += evs.target.lastname ? evs.target.lastname : '';

        return (
            <div className="esvCard" onClick={e => this.props.nextPath('/evs/' + evs.id)}>
                <div className="circleProgress">
                    <div className="progress-C" data-percentage={Math.trunc(score)}>
                        <span className="progress-C-left">
                            <span className={score > 66 ? 'progress-C-bar danger' : score > 33 ? 'progress-C-bar warining' : 'progress-C-bar success'}></span>
                        </span>
                        <span className="progress-C-right">
                            <span className={score > 66 ? 'progress-C-bar danger' : score > 33 ? 'progress-C-bar warining' : 'progress-C-bar success'}></span>
                        </span>
                        <div className="progress-C-value">
                            <div>
                                <h6>Score</h6>
                                <p>{Math.trunc(score)}%</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="evs-content">
                    <h5>{fullname}</h5>
                    <p className="evsEmail">{evs.target.email}</p>
                    <p className="evsNumber">{evs.target.phone ? evs.target.phone : 'NA'}</p>

                </div>
            </div>
        );
    }
}