import React, { Component } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

export default class AddURL extends Component{
    
    render(){
        return (
            <Modal show={this.props.show} onHide={this.props.handleAddURL} autoFocus keyboard>
                <Modal.Header closeButton>
                    <Modal.Title>Add URL</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type="text" value={this.props.addURL} onChange={this.props.handleContent} placeholder="Please enter your landing url"/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.handleAddURL}>Close</Button>
                    <Button variant="primary" onClick={this.props.handleSubmit}>Save changes</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}