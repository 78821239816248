export default {
    "Simulation Attack": {
        'Phishing-tabs': {
            'campaignTemplateId': true,
            'templateId': true,
            'pageId': true,
            'senderId': true,
            'preview': true,
        },
        'QRcode-tabs': {
            'campaignTemplateId': true,
            'templateId': true,
            'pageId': true,
            'senderId': true,
            'preview': true,
        },
        'Smishing-tabs': {
            'campaignTemplateId': true,
            'templateId': true,
            'pageId': true,
            'senderId': true,
            'preview': true,
        },
        'Ransomware-tabs': {
            'campaignTemplateId': true,
            'templateId': true,
            'pageId': true,
            'senderId': true,
            'preview': true,
        },
        'Vishing-tabs': {
            'campaignTemplateId': true,
            'senderId': true,
            'preview': true,
        },
        'Cyber Scam-tabs': {
            'campaignTemplateId': true,
            'templateId': true,
            'pageId': true,
            'senderId': true,
            'preview': true,
        },
        'WhatsApp-tabs': {
            'campaignTemplateId': true,
            'templateId': true,
            'pageId': true,
            'senderId': true,
            'preview': true,
        },
        'Attachment-tabs': {
            'campaignTemplateId': true,
            'templateId': true,
            'pageId': true,
            'senderId': true,
            'preview': true,
        },
    },
    "Simulation Attack + Training": {
        'Phishing-tabs': {
            'campaignTemplateId': true,
            'templateId': true,
            'pageId': true,
            'trainingId': true,
            'senderId': true,
            'preview': true,
        },
        'QRcode-tabs': {
            'campaignTemplateId': true,
            'templateId': true,
            'pageId': true,
            'trainingId': true,
            'senderId': true,
            'preview': true,
        },
        'Smishing-tabs': {
            'campaignTemplateId': true,
            'templateId': true,
            'pageId': true,
            'trainingId': true,
            'senderId': true,
            'preview': true,
        },
        'Cyber Scam-tabs': {
            'campaignTemplateId': true,
            'templateId': true,
            'pageId': true,
            'trainingId': true,
            'senderId': true,
            'preview': true,
        },
        'Attachment-tabs': {
            'campaignTemplateId': true,
            'templateId': true,
            'pageId': true,
            'trainingId': true,
            'senderId': true,
            'preview': true,
        },
    },
    "Awareness Training": {
        'Training-tabs': {
            'campaignTemplateId': true,
            'trainingId': true,
            'senderId': true,
            'preview': true,
        },
        'Training + Quiz-tabs': {
            'campaignTemplateId': true,
            'senderId': true,
            'trainingId': true,
            'preview': true,
        },
        'Attachment-tabs': {
            'campaignTemplateId': true,
            'senderId': true,
            'trainingId': true,
            'preview': true,
        },
    },
    "Gamified": {
        'Game-tabs': {
            'campaignTemplateId': true,
            'templateId': true,
            'senderId': true,
            'trainingId': true,
            'preview': true,
        }
    }
}