import React, { Component } from 'react';
import { toast } from 'react-toastify';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import CheckSharpIcon from '@material-ui/icons/CheckSharp';
import { Button, Card, ProgressBar, Spinner, InputGroup, FormControl, Breadcrumb} from 'react-bootstrap';

import axios from '../../axios';
import apiError from '../../error';
// import CurrentPlan from '../../component/billing/current-plan';
// import BillinbModal from '../../component/billing/billing-modal';

import { setCookie } from '../../function';
import billing1 from '../../assets/billing1.png';
import billing2 from '../../assets/billing2.png'
import billing3 from '../../assets/billing3.png'
import './billing.css'

export default class Billing extends Component {
    constructor(props){
        super(props);
        this.state = {
            loading: false,
            redeemCode: '',

            level: 0,
            totalUser: 0,
            nextLevel: {},
            totalEmails: 0,
            currentUsers: 0,
            currentEmails: 0,
        }
        this.loadAccessLevel();
    }

    loadAccessLevel = () => {
        axios.get('/api/redeemCode', {})
        .then(res => {
            this.setState({
                level: res.data.level,
                totalUser: res.data.totalUser,
                nextLevel: res.data.nextLevel,
                totalEmails: res.data.totalEmails,
                currentUsers: res.data.currentUsers,
                currentEmails: res.data.currentEmails,
            })
            setCookie('accessLevel', res.data.level, 30000);
        })
        .catch(err => apiError(err));
    }

    updateRedeemCode = () => {
        if(this.state.redeemCode.length === 0){
            toast.error('Please enter Redeem Code')
            return ;
        }
        this.setState({ loading: true });
        axios.post('/api/redeemCode', {
            redeemCode: this.state.redeemCode
        })
        .then(res => {
            this.setState({ loading: false });
            toast.success('successfully updated redeem code')
            this.loadAccessLevel();
        })
        .catch(err => {
            apiError(err);
            this.setState({ loading: false });
        });
    }
 
    render() {
        return (
            <div className="content-wrap">
                <div className="breadcrumbbar">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-head d-flex align-items-center justify-content-between">
                                <h4 className="page-title">Billing (AppSumo Plan Overview)</h4>
                                <Breadcrumb className="mb-0">
                                    <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
                                    <Breadcrumb.Item href="#" active>Billing</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </div>
                    </div>
                </div>
                <Card id="billing-card">
                    <Card.Body>
                        <div className="page-content">
                            <h5>Current Plan</h5>
                            <span className="line"></span>
                            <p>You are on <b>Code {this.state.level}</b> plan which includes the below mentioned features.</p>
                            <div className="row mb-4">
                                <div className="col-md-6">
                                    <h5>Current Usage</h5>
                                    <span className="line"></span>
                                    <div className="row mt-3">
                                        <div className="col-md-6">
                                            <div className="top-wrap">
                                                <div className="current-plan w-75">
                                                    <h6>Emails Used</h6>
                                                    <p>{this.state.currentEmails}/{this.state.totalEmails}</p>
                                                    <ProgressBar variant="primary" now={Math.ceil((this.state.currentEmails*100)/this.state.totalEmails)} />
                                                </div>
                                                <img src={billing1} className="img-fluid icon" alt=""></img>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="top-wrap">
                                                <div className="current-plan w-75">
                                                    <h6>Users</h6>
                                                    <p>{this.state.currentUsers}/{this.state.totalUser}</p>
                                                    <ProgressBar variant="primary" now={Math.ceil((this.state.currentUsers*100)/this.state.totalUser)} />
                                                </div>
                                                <img src={billing2} className="img-fluid icon" alt=""></img>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.level > 2?
                                        <div className="col-md-12 p-0 instant-block">
                                                <div className="top-wrap">
                                                    <div>
                                                        <h5>Addon Feature</h5>
                                                        <h6>Instant security Awareness</h6>
                                                        <h6>Employee Vulnerability Score(EVS)</h6>
                                                    </div>
                                                </div>
                                        </div>:''
                                    }
                                    <div className="features">
                                        <h5>Features</h5>
                                        <span className="line"></span>
                                        <div className="row mt-3">
                                            <div className="col-md-6">
                                                <ul className="list-li">
                                                    <li><CheckSharpIcon className="list-icon"></CheckSharpIcon>Dummy replication of latest attacks</li>
                                                    <li><CheckSharpIcon className="list-icon"></CheckSharpIcon>Hack record of employees</li>
                                                    <li><CheckSharpIcon className="list-icon"></CheckSharpIcon>Geolocation tag</li>
                                                    <li><CheckSharpIcon className="list-icon"></CheckSharpIcon>Create/import/customize email templates</li>
                                                    <li><CheckSharpIcon className="list-icon"></CheckSharpIcon>Create/import/customize attacker profile</li>
                                                    <li><CheckSharpIcon className="list-icon"></CheckSharpIcon>Email support</li>
                                                    <li><CheckSharpIcon className="list-icon"></CheckSharpIcon>Predesigned Templates</li>
                                                    <li><CheckSharpIcon className="list-icon"></CheckSharpIcon>Predesigned Landing Pages</li>
                                                    <li><CheckSharpIcon className="list-icon"></CheckSharpIcon>Campaign breach time</li>
                                                </ul>
                                            </div>
                                            <div className="col-md-6">
                                                <ul className="list-li">
                                                    <li><CheckSharpIcon className="list-icon"></CheckSharpIcon>Comprehensive reporting</li>
                                                    <li><CheckSharpIcon className="list-icon"></CheckSharpIcon>Export features</li>
                                                    <li><CheckSharpIcon className="list-icon"></CheckSharpIcon>Realtime dashboard</li>
                                                    <li><CheckSharpIcon className="list-icon"></CheckSharpIcon>Campaign analytics</li>
                                                    <li><CheckSharpIcon className="list-icon"></CheckSharpIcon>Create/import/customize user groups</li>
                                                    <li><CheckSharpIcon className="list-icon"></CheckSharpIcon>Industry benchmarking</li>
                                                    <li><CheckSharpIcon className="list-icon"></CheckSharpIcon>Phish risk rate</li>
                                                    <li><CheckSharpIcon className="list-icon"></CheckSharpIcon>Top Offenders</li>
                                                    <li><CheckSharpIcon className="list-icon"></CheckSharpIcon>Simulation Risk Analytics</li>
                                                    <li><CheckSharpIcon className="list-icon"></CheckSharpIcon>Country wise click rate</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={(this.state.level === 5 || this.state.level === 0)? "col-md-6 d-none": "col-md-6"} style={{'marginTop': '50px'}}>
                                    <div className="top-wrap">
                                        <div className="row m-0 align-items-center">
                                            <div className="col-md-6 pl-0">
                                                <h5>Upgrade Plans</h5>
                                                <p>Upgrade your plan by redeeming a new AppSumo code in which you will get the below upgrades.</p>
                                                <ul className="list-li">
                                                    {this.state.nextLevel.email? <li><CheckSharpIcon className="list-icon"></CheckSharpIcon>{this.state.nextLevel.email} annual phishing attack emails</li>: ''}
                                                    {this.state.nextLevel.users? <li><CheckSharpIcon className="list-icon"></CheckSharpIcon>{this.state.nextLevel.users} Seats/Users</li>: ''}
                                                    <li><CheckSharpIcon className="list-icon"></CheckSharpIcon>Same features as code {this.state.level>3? '3': '1'} </li>
                                                </ul>
                                                <label htmlFor="exampleInputEmail1" className="mt-4">Redeem AppSumo Code</label>
                                                <InputGroup>
                                                    <FormControl placeholder="Enter Your Code" aria-label="Recipient's username" aria-describedby="basic-addon2" onChange={e => this.setState({redeemCode: e.target.value})}/>
                                                    <InputGroup.Append>
                                                        <Button variant="primary" disabled={this.state.loading} type="button" onClick={this.updateRedeemCode}>{this.state.loading?<Spinner as="span" className="mr-2" animation="border" size="sm" role="status" aria-hidden="true" />:<ArrowForwardOutlinedIcon className="btn-icon"></ArrowForwardOutlinedIcon>} Redeem</Button>
                                                    </InputGroup.Append>
                                                </InputGroup>
                                            </div>
                                            <div className="col-md-6 p-0">
                                                <img src={billing3} className="img-fluid" alt=""></img>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                       
                         
                        </div>
                    </Card.Body>
                </Card>
                {/* <BillinbModal 
                    package={this.state.plan}
                    show={this.state.show}
                    handleModal={this.handleModal}
                ></BillinbModal> */}
            </div>
        );
    }
}