import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import GavelSharpIcon from '@material-ui/icons/GavelSharp';
import TouchAppSharpIcon from '@material-ui/icons/TouchAppSharp';
import AssignmentSharpIcon from '@material-ui/icons/AssignmentSharp';
import DescriptionSharpIcon from '@material-ui/icons/DescriptionSharp';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import ErrorOutlineSharpIcon from '@material-ui/icons/ErrorOutlineSharp';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { Row, Nav, Tab, Col, OverlayTrigger, Popover } from 'react-bootstrap';
import RadioButtonCheckedSharpIcon from '@material-ui/icons/RadioButtonCheckedSharp';

import logo from '../../assets/threatcop-b.png';
import evs from '../../assets/product-guide/EVS.png';
import uNg from '../../assets/product-guide/u&g.png';
import evsImg from '../../assets/product-guide/evs-img.png';
import topOff from '../../assets/product-guide/top-off.png';
import reports from '../../assets/product-guide/Reports.png';
import preview from '../../assets/product-guide/Preview.png';
import avgRate from '../../assets/product-guide/avg-rate.png';
import avgTime from '../../assets/product-guide/avg-time.png';
import run from '../../assets/product-guide/Run Campaigns.png';
import dashboard from '../../assets/product-guide/Dashboard.png';
import userGrp from '../../assets/product-guide/User & Group.png';
import clickReport from '../../assets/product-guide/Click Report.jpg';
import emailTemp from '../../assets/product-guide/Email Template.png';
import landingPage from '../../assets/product-guide/Landing Page.png';
import repeatOff from '../../assets/product-guide/Repeat Offender.jpg';
import campTemp from '../../assets/product-guide/Campaign Templates.png';
import tempDetails from '../../assets/product-guide/Template Details.png';
import attackProfile from '../../assets/product-guide/Attack Profile.png';
import enterCamp from '../../assets/product-guide/Enter Campaign Details.png';
import campAnalytics from '../../assets/product-guide/Campaign Analytics.jpg';
import chooseAttack from '../../assets/product-guide/Choose Attack Vector.png';
import breach from '../../assets/product-guide/Breach Time-Incident Report.jpg';
import sendNow from '../../assets/product-guide/Send Now or Schedule for Later.png';
import ProductGuideModal from '../../component/product-guide-modal/product-guide-modal';

import './product-guide.css'
export default class ProductGuide extends Component {
    constructor(props){
        super(props);
        this.state = {
            show: true,
            index: 0,
            loading: true,
            campaginSteper: 1,
            campaignTempateSteper: 1,
            images: [dashboard, run, campTemp, userGrp, reports, evs],
            tabName: ['Dashboard', 'Run Campaign', 'Campaign Template', 'User & Group', 'Reports', 'EVS'],
            activeTab: ['first', 'second', 'third', 'fourth', 'fifth', 'sixth']
        }
    }
 
    render() {
        return (
            <div className="bg-white" id="product-container">
                <div className="bg-blue"></div>
                <div className="col-md-12">
                    <div className="white-main-container">
                        <div className="guide-header">
                            <img alt="" src={logo} className="img-fluid guide-logo"></img>
                            <Link to="/dashboard" className="round-btn dark-blue">Go to Dashboard</Link>
                        </div>
                        <Tab.Container id="left-tabs-example" activeKey={this.state.activeTab[this.state.index]}>
                            <Row className="tab-container-wrap">
                                <Col sm={3} className="pr-0">
                                    <Nav variant="pills" className="flex-column l-col">
                                        <Nav.Item onClick={e => this.setState({index: 0})}>
                                            <Nav.Link eventKey="first">
                                                <div className="nav-wrap mt-2">
                                                    <img alt="" src={dashboard} className="img-fluid nav-icon"></img><div className="nav-div ml-2">Dashboard<p>Introduction to TSAT</p></div>
                                                </div>
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item onClick={e => this.setState({index: 1})}>
                                            <Nav.Link eventKey="second">
                                                <div className="nav-wrap">
                                                    <img alt="" src={run} className="img-fluid nav-icon"></img><div className="nav-div ml-2">Run Campaign<p>How to initiate a simulated campaign</p></div>
                                                </div>
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item onClick={e => this.setState({index: 2})}>
                                            <Nav.Link eventKey="third">
                                                <div className="nav-wrap">
                                                    <img alt="" src={campTemp} className="img-fluid nav-icon"></img><div className="nav-div ml-2">Campaign Template<p>Design your simulated attack</p></div>
                                                </div>
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item onClick={e => this.setState({index: 3})}>
                                            <Nav.Link eventKey="fourth">
                                                <div className="nav-wrap">
                                                    <img alt="" src={userGrp} className="img-fluid nav-icon"></img><div className="nav-div ml-2">User & Group<p>Set up TSAT for Campaign</p></div>
                                                </div>
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item onClick={e => this.setState({index: 4})}>
                                            <Nav.Link eventKey="fifth">
                                                <div className="nav-wrap">
                                                    <img alt="" src={reports} className="img-fluid nav-icon"></img><div className="nav-div ml-2">Reports<p>Monitor and Analyse Results</p></div>
                                                </div>
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item onClick={e => this.setState({index: 5})}>
                                            <Nav.Link eventKey="sixth">
                                                <div className="nav-wrap">
                                                    <img alt="" src={evs} className="img-fluid nav-icon"></img><div className="nav-div ml-2">EVS<p>Identify the weakest links within</p></div>
                                                </div>
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                <Col sm={9} className="pl-0">
                                    <Tab.Content className="guide-tab-wrap">
                                        <Tab.Pane eventKey="first">
                                            <div className="tab-wrapper">
                                                <div className="tab-header">
                                                    <img alt="" src={dashboard} className="img-fluid tab-icon"></img>
                                                    <h5>Dashboard</h5>
                                                </div>
                                                <h5 className="head-one">Introduction to TSAT's Dashboard</h5>
                                                <p>TSAT dashboard gives you an overview of your employee’s performance in the overall campaigns initiated by you. 
                                                    It becomes an aid in analyzing the weak links of your organization. Here is a brief description of a few elements present in TSAT’s dashboard:</p>
                                                <div className="row justify-content-center">
                                                    <div className="col-md-12">
                                                        <div className="light-blue-bg"></div>
                                                    </div>
                                                    <div className="col-md-2 dash-col">
                                                        <div className="dash-box">
                                                            <GavelSharpIcon className="tab-font"></GavelSharpIcon>
                                                            <h6>Attacks</h6>
                                                            <p>It shows the total number of employees who were part of the phishing campaign.</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2 dash-col">
                                                        <div className="dash-box">
                                                            <TouchAppSharpIcon className="tab-font"></TouchAppSharpIcon>
                                                            <h6>Clicks</h6>
                                                            <p>Total number of employees who clicked on the link present in the email.</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2 dash-col">
                                                        <div className="dash-box">
                                                            <ErrorOutlineSharpIcon className="tab-font"></ErrorOutlineSharpIcon>
                                                            <h6>Hacks</h6>
                                                            <p>It displays the total number of employees who became a victim in a campaign.</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2 dash-col">
                                                        <div className="dash-box">
                                                            <AssignmentSharpIcon className="tab-font"></AssignmentSharpIcon>
                                                            <h6>Campaigns</h6>
                                                            <p>Total number of campaigns initiated by the organization.</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2 dash-col">
                                                        <div className="dash-box">
                                                            <DescriptionSharpIcon className="tab-font"></DescriptionSharpIcon>
                                                            <h6>Templates</h6>
                                                            <p>Number of templates available in the tool.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-5 text-center">
                                                        <div className="light-blue-bg p-3 text-left">
                                                            <h6>Average phish risk rate</h6>
                                                            <p>Current threat posture of the company indicated by the ratio of total email sent and data submitted</p>
                                                        </div>
                                                        <img alt="" src={avgRate} className="img-fluid img-1"></img>
                                                    </div>
                                                    <div className="col-md-7 d-flex">
                                                        <div className="col-md-6 text-center">
                                                            <img alt="" src={avgTime} className="img-fluid img-2"></img>
                                                            <div className="light-blue-bg p-3 text-left second-box">
                                                                <h6>Average breach time</h6>
                                                                <p>It denotes the time taken by the first employee to submit data.</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 text-center">
                                                            <div className="light-blue-bg p-3 text-left">
                                                                <h6>Top Offenders</h6>
                                                                <p>Name of employees who repeatedly became victims of the simulated attack.</p>
                                                            </div>
                                                            <img alt="" src={topOff} className="img-fluid img-3"></img>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="second">
                                            <div className="tab-wrapper">
                                                <div className="tab-header">
                                                    <img alt="" src={run} className="img-fluid tab-icon"></img>
                                                    <h5>Run Campaign</h5>
                                                </div>
                                                <h5 className="head-one">How to initiate a simulated campaign?</h5>
                                                <ul className="custom-timeline">
                                                    <li className={this.state.campaginSteper >= 1 ? "active step-timeline": "step-timeline"} onClick={e => this.setState({campaginSteper: 1})}><div>1</div>Choose Attack Vector</li>
                                                    <li className={this.state.campaginSteper >= 2 ? "active step-timeline": "step-timeline"} onClick={e => this.setState({campaginSteper: 2})}><div>2</div>Enter Campaign Details</li>
                                                    <li className={this.state.campaginSteper >= 3 ? "active step-timeline": "step-timeline"} onClick={e => this.setState({campaginSteper: 3})}><div>3</div>Send Now or Schedule for later</li>
                                                </ul>
                                                <div className="timeline-content">
                                                    {this.state.campaginSteper === 1?
                                                        <div className="step-timeline-stepper step-1">
                                                            <img alt="" src={chooseAttack} className="img-fluid"></img>
                                                        </div>:
                                                    this.state.campaginSteper === 2?
                                                        <div className="step-timeline-stepper step-2">
                                                            <img alt="" src={enterCamp} className="img-fluid"></img>
                                                            <OverlayTrigger
                                                                placement='bottom'
                                                                overlay={<Popover id='top'>Enter the name of the template you are creating</Popover>}>
                                                                <RadioButtonCheckedSharpIcon className="tooltip-icon mt-1"  style={{'position': 'absolute', 'left':'54%', 'top': '29%', 'zIndex': '10','color':' #0b6afc'}} ></RadioButtonCheckedSharpIcon>
                                                            </OverlayTrigger>
                                                            <OverlayTrigger
                                                                placement='right'
                                                                overlay={<Popover id='top'>Choose between system template, custom template, and community template. 
                                                                <br></br>-Templates designed or modified by you can be found in the custom template category.
                                                                <br></br>-On the other hand, the readily available templates can be found under the system template category. 
                                                                </Popover>}>
                                                                <RadioButtonCheckedSharpIcon className="tooltip-icon mt-1"  style={{'position': 'absolute', 'left':'54%', 'top': '38%', 'zIndex': '10','color':' #0b6afc'}} ></RadioButtonCheckedSharpIcon>
                                                            </OverlayTrigger>
                                                            <OverlayTrigger
                                                                placement='right'
                                                                overlay={<Popover id='top'>Choose between the two modes - single and random. <br></br>
                                                                -Single mode allows you to run a single template for all users or a group of users.<br></br>
                                                                -In the case of random mode, different templates belonging to the selected category will run on the frequency selected by you.
                                                                </Popover>}>
                                                                <RadioButtonCheckedSharpIcon className="tooltip-icon mt-1"  style={{'position': 'absolute', 'left':'29%', 'top': '43.5%', 'zIndex': '10','color':' #0b6afc'}} ></RadioButtonCheckedSharpIcon>
                                                            </OverlayTrigger>
                                                            <OverlayTrigger
                                                                placement='left'
                                                                overlay={<Popover id='top'>Select the user and groups you wish to target for the phishing simulation.</Popover>}>
                                                                <RadioButtonCheckedSharpIcon className="tooltip-icon mt-1"  style={{'position': 'absolute', 'right':'5%', 'top': '28.5%', 'zIndex': '10','color':' #0b6afc'}} ></RadioButtonCheckedSharpIcon>
                                                            </OverlayTrigger>
                                                            <OverlayTrigger
                                                                placement='left'
                                                                overlay={<Popover id='top'>Choose the category of the email template, eg - Insurance, finance, social media, etc</Popover>}>
                                                                <RadioButtonCheckedSharpIcon className="tooltip-icon mt-1"  style={{'position': 'absolute', 'right':'5%', 'top': '37.5%', 'zIndex': '10','color':' #0b6afc'}} ></RadioButtonCheckedSharpIcon>
                                                            </OverlayTrigger>
                                                            <OverlayTrigger
                                                                placement='left'
                                                                overlay={<Popover id='top'>Select the campaign template for your simulated phishing attack.</Popover>}>
                                                                <RadioButtonCheckedSharpIcon className="tooltip-icon mt-1"  style={{'position': 'absolute', 'right':'5%', 'top': '46.5%', 'zIndex': '10','color':' #0b6afc'}} ></RadioButtonCheckedSharpIcon>
                                                            </OverlayTrigger>
                                                            <OverlayTrigger
                                                                placement='top'
                                                                overlay={<Popover id='top'>After entering the accurate details above, you can immediately run the campaign or schedule it for a time later in the day, week, or month as per your convenience and requirements.
                                                                </Popover>}>
                                                                <RadioButtonCheckedSharpIcon className="tooltip-icon mt-1"  style={{'position': 'absolute', 'left':'36%', 'top': '53.5%', 'zIndex': '10','color':' #0b6afc'}} ></RadioButtonCheckedSharpIcon>
                                                            </OverlayTrigger>
                                                        </div>:
                                                        <div className="step-timeline-stepper step-3">
                                                            <img alt="" src={sendNow} className="img-fluid"></img>
                                                            <OverlayTrigger
                                                                placement='top'
                                                                overlay={<Popover id='top'>After entering the accurate details above, you can immediately run the campaign or schedule it for a time later in the day, week, or month as per your convenience and requirements.
                                                                </Popover>}>
                                                                <RadioButtonCheckedSharpIcon className="tooltip-icon mt-1"  style={{'position': 'absolute', 'left':'36%', 'top': '53.5%', 'zIndex': '10','color':' #0b6afc'}} ></RadioButtonCheckedSharpIcon>
                                                            </OverlayTrigger>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="third">
                                            <div className="tab-wrapper">
                                                <div className="tab-header">
                                                    <img alt="" src={campTemp} className="img-fluid tab-icon"></img>
                                                    <h5>Campaign Templates</h5>
                                                </div>
                                                <h5 className="head-one">Design your simulated attack</h5>
                                                <ul className="custom-timeline">
                                                    <li className={this.state.campaignTempateSteper >= 1? "active step-timeline": "step-timeline" } onClick={e => this.setState({campaignTempateSteper: 1})}><div>1</div>Template Details</li>
                                                    <li className={this.state.campaignTempateSteper >= 2? "active step-timeline": "step-timeline" } onClick={e => this.setState({campaignTempateSteper: 2})}><div>2</div>Email Template</li>
                                                    <li className={this.state.campaignTempateSteper >= 3? "active step-timeline": "step-timeline" } onClick={e => this.setState({campaignTempateSteper: 3})}><div>3</div>Landing Page</li>
                                                    <li className={this.state.campaignTempateSteper >= 4? "active step-timeline": "step-timeline" } onClick={e => this.setState({campaignTempateSteper: 4})}><div>4</div>Attack Profile</li>
                                                    <li className={this.state.campaignTempateSteper >= 5? "active step-timeline": "step-timeline" } onClick={e => this.setState({campaignTempateSteper: 5})}><div>5</div>Preview</li>

                                                </ul>
                                                <div className="timeline-content">
                                                    {this.state.campaignTempateSteper === 1? <div className="step-timeline-stepper step-1">
                                                        <img alt="" src={tempDetails} className="img-fluid"></img>
                                                        <OverlayTrigger
                                                            placement='top'
                                                            overlay={<Popover id='top'>Enter the name of the template you are creating</Popover>}>
                                                            <RadioButtonCheckedSharpIcon className="tooltip-icon mt-1"  style={{'position': 'absolute', 'left':'54%', 'top': '21%', 'zIndex': '10','color':' #0b6afc'}} ></RadioButtonCheckedSharpIcon>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger
                                                            placement='left'
                                                            overlay={<Popover id='top'>Select the category of the template you are creating from the list of categories. You can choose ‘others’ if the category of your template is not available from the list.</Popover>}>
                                                            <RadioButtonCheckedSharpIcon className="tooltip-icon mt-1"  style={{'position': 'absolute', 'right':'4%', 'top': '21%', 'zIndex': '10','color':' #0b6afc'}} ></RadioButtonCheckedSharpIcon>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger
                                                            placement='left'
                                                            overlay={<Popover id='top'>Select the language of your template from the dropdown list.
                                                            </Popover>}>
                                                            <RadioButtonCheckedSharpIcon className="tooltip-icon mt-1"  style={{'position': 'absolute', 'right':'4%', 'top': '30%', 'zIndex': '10','color':' #0b6afc'}} ></RadioButtonCheckedSharpIcon>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger
                                                            placement='right'
                                                            overlay={<Popover id='top'><b>Select the type of simulation from the list:</b><br></br>
                                                                <b>Simulation attack -</b> A dummy attack is initiated and the users are redirected to an advisory page on submitting their data.<br></br>
                                                                <b>Simulation attack + Training -</b> A training video is shared after the dummy attack defining the red flags present in the phishing email.<br></br>
                                                                <b>Awareness Training -</b>  A full-fledged cybersecurity training is provided to users in order to combat real-life cybersecurity threats.<br></br>
                                                                <b>Gamified -</b> Users are presented with a gamification module as part of cybersecurity training.
                                                            </Popover>}>
                                                            <RadioButtonCheckedSharpIcon className="tooltip-icon mt-1"  style={{'position': 'absolute', 'left':'54%', 'top': '39%', 'zIndex': '10','color':' #0b6afc'}} ></RadioButtonCheckedSharpIcon>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger
                                                            placement='left'
                                                            overlay={<Popover id='top'>Select from the attack vectors present in TSAT - Phishing, Smishing, Ransomware.</Popover>}>
                                                            <RadioButtonCheckedSharpIcon className="tooltip-icon mt-1"  style={{'position': 'absolute', 'right':'4%', 'top': '39%', 'zIndex': '10','color':' #0b6afc'}} ></RadioButtonCheckedSharpIcon>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger
                                                            placement='top'
                                                            overlay={<Popover id='top'>Check the box if your template will include a landing page and you wish to capture user credentials.</Popover>}>
                                                            <RadioButtonCheckedSharpIcon className="tooltip-icon mt-1"  style={{'position': 'absolute', 'left':'32%', 'top': '44.3%', 'zIndex': '10','color':' #0b6afc'}} ></RadioButtonCheckedSharpIcon>
                                                        </OverlayTrigger>
                                                    </div>: null
                                                    } { this.state.campaignTempateSteper === 2? <div className="step-timeline-stepper step-2">
                                                        <img alt="" src={emailTemp} className="img-fluid"></img>
                                                        <OverlayTrigger
                                                            placement='left'
                                                            overlay={<Popover id='top'>Enter a catchy subject line for the email. Eg - Incentive statement of the year.</Popover>}>
                                                            <RadioButtonCheckedSharpIcon className="tooltip-icon mt-1"  style={{'position': 'absolute', 'right':'4%', 'top': '26%', 'zIndex': '10','color':' #0b6afc'}} ></RadioButtonCheckedSharpIcon>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger
                                                            placement='top'
                                                            overlay={<Popover id='top'>You can design a template of your choice using HTML codes or continue with the predesigned templates available on TSAT.</Popover>}>
                                                            <RadioButtonCheckedSharpIcon className="tooltip-icon mt-1"  style={{'position': 'absolute', 'left':'38%', 'top': '30%', 'zIndex': '10','color':' #0b6afc'}} ></RadioButtonCheckedSharpIcon>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger
                                                            placement='left'
                                                            overlay={<Popover id='top'>You can import any email from your inbox and customize it to create a phishing email.
                                                            </Popover>}>
                                                            <RadioButtonCheckedSharpIcon className="tooltip-icon mt-1"  style={{'position': 'absolute', 'right':'11%', 'top': '17.5%', 'zIndex': '10','color':' #0b6afc'}} ></RadioButtonCheckedSharpIcon>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger
                                                            placement='top'
                                                            overlay={<Popover id='top'>You can upload a jpeg image in your email template here. .</Popover>}>
                                                            <RadioButtonCheckedSharpIcon className="tooltip-icon mt-1"  style={{'position': 'absolute', 'left':'50%', 'bottom': '25%', 'zIndex': '10','color':' #0b6afc'}} ></RadioButtonCheckedSharpIcon>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger
                                                            placement='top'
                                                            overlay={<Popover id='top'>This area will reflect the attached file name and provide an option to edit and delete the file.</Popover>}>
                                                            <RadioButtonCheckedSharpIcon className="tooltip-icon mt-1"  style={{'position': 'absolute', 'left':'68%', 'bottom': '17%', 'zIndex': '10','color':' #0b6afc'}} ></RadioButtonCheckedSharpIcon>
                                                        </OverlayTrigger>
                                                    </div>: null
                                                    } { this.state.campaignTempateSteper === 3? <div className="step-timeline-stepper step-3">
                                                        <img alt="" src={landingPage} className="img-fluid"></img>
                                                        <OverlayTrigger
                                                            placement='top'
                                                            overlay={<Popover id='top'>You can add the landing page URL of your choice here.</Popover>}>
                                                            <RadioButtonCheckedSharpIcon className="tooltip-icon mt-1"  style={{'position': 'absolute', 'right':'21%', 'top': '17.5%', 'zIndex': '10','color':' #0b6afc'}} ></RadioButtonCheckedSharpIcon>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger
                                                            placement='left'
                                                            overlay={<Popover id='top'> Enter any URL of a site you want to import. Your landing page will come out to be a copy of the site.</Popover>}>
                                                            <RadioButtonCheckedSharpIcon className="tooltip-icon mt-1"  style={{'position': 'absolute', 'right':'3%', 'top': '17.5%', 'zIndex': '10','color':' #0b6afc'}} ></RadioButtonCheckedSharpIcon>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger
                                                            placement='left'
                                                            overlay={<Popover id='top'> TSAT offers a set of URLs on which you can host your landing page. Choose any URL as appropriate to your landing page design.
                                                            </Popover>}>
                                                            <RadioButtonCheckedSharpIcon className="tooltip-icon mt-1"  style={{'position': 'absolute', 'right':'4%', 'top': '26%', 'zIndex': '10','color':' #0b6afc'}} ></RadioButtonCheckedSharpIcon>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger
                                                            placement='right'
                                                            overlay={<Popover id='top'> the content of your Landing page here. You can also attach an image or an HTML code if you wish.</Popover>}>
                                                            <RadioButtonCheckedSharpIcon className="tooltip-icon mt-1"  style={{'position': 'absolute', 'left':'38%', 'top': '30%', 'zIndex': '10','color':' #0b6afc'}} ></RadioButtonCheckedSharpIcon>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger
                                                            placement='right'
                                                            overlay={<Popover id='top'> this box if you would like to store username data entered by your employees. 
                                                            </Popover>}>
                                                            <RadioButtonCheckedSharpIcon className="tooltip-icon mt-1"  style={{'position': 'absolute', 'left':'56%', 'bottom': '32%', 'zIndex': '10','color':' #0b6afc'}} ></RadioButtonCheckedSharpIcon>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger
                                                            placement='right'
                                                            overlay={<Popover id='top'> this box if you would like to store password data entered by your employees. 
                                                            </Popover>}>
                                                            <RadioButtonCheckedSharpIcon className="tooltip-icon mt-1"  style={{'position': 'absolute', 'left':'56%', 'bottom': '27.5%', 'zIndex': '10','color':' #0b6afc'}} ></RadioButtonCheckedSharpIcon>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger
                                                            placement='right'
                                                            overlay={<Popover id='top'>Check this box if you would like to redirect your employees to the pre-built auto instant awareness page offered by TSAT, else you can add a URL of your choice to redirect your employees.
                                                            </Popover>}>
                                                            <RadioButtonCheckedSharpIcon className="tooltip-icon mt-1"  style={{'position': 'absolute', 'left':'56%', 'bottom': '23%', 'zIndex': '10','color':' #0b6afc'}} ></RadioButtonCheckedSharpIcon>
                                                        </OverlayTrigger>
                                                    </div>: null
                                                    } {this.state.campaignTempateSteper === 4? <div className="step-timeline-stepper step-4">
                                                        <img alt="" src={attackProfile} className="img-fluid"></img>
                                                        <OverlayTrigger
                                                            placement='left'
                                                            overlay={<Popover id='top'>Turn on custom if you would like to use your own SMTP server to launch the attacks, else you can use the default server made available by TSAT.
                                                            </Popover>}>
                                                            <RadioButtonCheckedSharpIcon className="tooltip-icon mt-1"  style={{'position': 'absolute', 'right':'20%', 'top': '17.5%', 'zIndex': '10','color':' #0b6afc'}} ></RadioButtonCheckedSharpIcon>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger
                                                            placement='left'
                                                            overlay={<Popover id='top'>You can send a test mail from here to test the mail delivery in your environment.
                                                            </Popover>}>
                                                            <RadioButtonCheckedSharpIcon className="tooltip-icon mt-1"  style={{'position': 'absolute', 'right':'2.5%', 'top': '17.5%', 'zIndex': '10','color':' #0b6afc'}} ></RadioButtonCheckedSharpIcon>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger
                                                            placement='top'
                                                            overlay={<Popover id='top'>Enter your sender ID with which you want to launch your attack.
                                                            </Popover>}>
                                                            <RadioButtonCheckedSharpIcon className="tooltip-icon mt-1"  style={{'position': 'absolute', 'left':'65%', 'top': '26%', 'zIndex': '10','color':' #0b6afc'}} ></RadioButtonCheckedSharpIcon>
                                                        </OverlayTrigger>
                                                    </div>: null
                                                    } { this.state.campaignTempateSteper === 5? <div className="step-timeline-stepper step-5">
                                                        <img alt="" src={preview} className="img-fluid"></img>
                                                        <OverlayTrigger
                                                            placement='bottom'
                                                            overlay={<Popover id='top'>After previewing your email template you can directly create a campaign and launch it on your employees.</Popover>}>
                                                            <RadioButtonCheckedSharpIcon className="tooltip-icon mt-1"  style={{'position': 'absolute', 'right':'20%', 'top': '17.5%', 'zIndex': '10','color':' #0b6afc'}} ></RadioButtonCheckedSharpIcon>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger
                                                            placement='left'
                                                            overlay={<Popover id='top'>Before launching the campaign, test the template by sending a test mail.</Popover>}>
                                                            <RadioButtonCheckedSharpIcon className="tooltip-icon mt-1"  style={{'position': 'absolute', 'right':'3%', 'top': '17.5%', 'zIndex': '10','color':' #0b6afc'}} ></RadioButtonCheckedSharpIcon>
                                                        </OverlayTrigger>
                                                    </div>: null
                                                    }
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="fourth">
                                            <div className="tab-wrapper">
                                                <div className="tab-header">
                                                    <img alt="" src={userGrp} className="img-fluid tab-icon"></img>
                                                    <h5>User & Group</h5>
                                                </div>
                                                <h5 className="head-one">Set Up TSAT for Campaign</h5>
                                                <p>This section contains the list of users and groups who will be receiving the phishing emails sent from the tool. 
                                                    You can add and remove your users through this section. 
                                                    TSAT allows you to add users and groups by uploading a CSV or manually entering all the information.</p>
                                                <img alt="" src={uNg} className="img-fluid"></img>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="fifth">
                                            <div className="tab-wrapper">
                                                <div className="tab-header">
                                                    <img alt="" src={reports} className="img-fluid tab-icon"></img>
                                                    <h5>Reports</h5>
                                                </div>
                                                <h5 className="head-one">Monitor and Analyze Results</h5> 
                                                <p>The section contains four parts that primarily allow you to identify the cyber security threat posture within your organization.</p>
                                                <div className="row align-items-center">
                                                    <div className="col-md-4">
                                                        <img alt="" src={campAnalytics} className="img-fluid"></img>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <div className="light-blue-bg p-3">
                                                            <h6>Campaign Analytics</h6>
                                                            <p>The Campaign analytics dashboard displays analytics of all the phishing campaigns you have initiated till date. However, if you wish to see metrics of any particular campaign, 
                                                                you can add a filter from the top right corner and the dashboard will display the report of that particular date/campaign.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row align-items-center">
                                                    
                                                    <div className="col-md-8">
                                                        <div className="light-blue-bg p-3 text-right right-block">
                                                            <h6>Click Reports</h6>
                                                            <p>Click reports refer to the total number of employees who clicked the link present in the simulated phishing email. 
                                                                You can also check the average click rate which is denoted by link clicked to total email sent.</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <img alt="" src={clickReport} className="img-fluid"></img>
                                                    </div>
                                                </div>
                                                <div className="row align-items-center">
                                                    <div className="col-md-4">
                                                        <img alt="" src={breach} className="img-fluid"></img>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <div className="light-blue-bg p-3">
                                                            <h6>Breach/Incident</h6>
                                                            <p>The Breach/ Incident contains two important elements - Average breach time and Incident report time. The average time taken by the first employee to submit data in the campaigns is denoted by average breach time. On the other hand,
                                                                incident report time displays the time taken by the first employee to report the simulated phishing email.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row align-items-center">
                                                
                                                    <div className="col-md-8">
                                                        <div className="light-blue-bg p-3 text-right right-block">
                                                            <h6>Repeat offenders</h6>
                                                            <p>It displays the number of employees who repeatedly submitted their data in a simulated attack. Along with that, you can also see the threat profile of each employee to analyze the weakest link within the organization.</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <img alt="" src={repeatOff} className="img-fluid"></img>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="sixth">
                                            <div className="tab-wrapper">
                                                <div className="tab-header">
                                                    <img alt="" src={evs} className="img-fluid tab-icon"></img>
                                                    <h5>Employee Vulnerability Score(EVS)</h5>
                                                </div>
                                                <h5 className="head-one">Set Up TSAT for Campaign</h5>
                                                <p>EVS or Employee Vulnerability Score allows you to analyze the security strength of each employee. The vulnerability score is provided out of 100, 
                                                    you can identify your weakest links and transform them into a strong defense by providing awareness training with simulated attacks.</p>
                                                <img alt="" src={evsImg} className="img-fluid"></img>
                                            </div>
                                        </Tab.Pane>
                                    </Tab.Content>
                                    <div className="guide-footer">
                                        {this.state.index > 0?
                                            <div className="l-count" onClick={e => this.setState({index: this.state.index - 1})}>
                                                <KeyboardArrowLeftIcon></KeyboardArrowLeftIcon>
                                                <img alt="" src={this.state.images[this.state.index-1]} className="img-fluid foot-icon"></img><div className="foot-text"><p>Previous</p>{this.state.tabName[this.state.index-1]}</div>
                                            </div>: null
                                        } {this.state.index < 5?
                                            <div className="r-count" onClick={e => this.setState({index: this.state.index + 1})}>
                                                <img alt="" src={this.state.images[this.state.index+1]} className="img-fluid foot-icon"></img><div className="foot-text"><p>Next</p>{this.state.tabName[this.state.index+1]}</div>
                                                <KeyboardArrowRightIcon></KeyboardArrowRightIcon>
                                            </div>: null
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </div>
                </div>
            
            <div className="main-footer">
                <div className="col-md-5 mx-auto text-center">
                    <div className="mid-head">
                        <Link to="">Privacy Policy</Link>
                        <Link to="">Terms & Condition</Link>
                        <Link to="">About Us</Link>
                    </div>
                    <p>Copyright © 2021 Kratikal Tech. Pvt. Ltd. All rights reserved.</p>
                </div>
            </div>
            
            <ProductGuideModal
                show={this.state.show}
                handleModal={e => this.setState({ show: false })}
            ></ProductGuideModal>
          </div>
        );
    }
}