import React, { Component } from 'react';
import HeaderRightImg from "../../assets/TLMS.jpeg";
import './change-log.css';

export default class ChangeLog extends Component {
    render() {
        return (
            <div className="content-wrap" id="changeLog">
                <h2>Change Log - @TSAT</h2>
                <div className="col-md-10 mx-auto">
                    <div className="mid-container">
                        <div className="first-head">
                            <img alt="" src={HeaderRightImg} className="fab-icon-img"></img>
                            <div className="head">
                                <h4>TSAT v4.0</h4>
                                <h6>Mon, 16 Aug 2021 11:20:12 IST</h6>
                            </div>
                        </div>
                        <div className="log-block">
                            <h3>Dashboard</h3>
                            <p>Gives you an overview of your employee’s performance in the overall campaigns initiated by you.</p>
                            <ul>
                                <li><b>Attack : </b> It shows the total number of employees who were part of the phishing campaign.</li>
                                <li><b>Clicks : </b> Total number of employees who clicked on the link present in the email.</li>
                                <li><b>Hacks : </b> It displays the total number of employees who became a victim in a campaign</li>
                                <li><b>Campaigns : </b> Total number of campaigns initiated by the organization.</li>
                                <li><b>Templates : </b> Number of templates available in the tool.</li>
                                <li><b>Average phish risk rate : </b> Current threat posture of the company indicated by the ratio of total email sent and data submitted</li>
                                <li><b>Average breach time : </b> It denotes the time taken by the first employee to submit data.</li>
                                <li><b>Top offenders : </b> Name of employees who repeatedly became victims of the simulated attack</li>
                            </ul>
                        </div>
                        <div className="log-block">
                            <h3>CAMPAIGNS</h3>
                            <p>Allows you to initiate simulation and training campaigns on particular set of users</p>
                            <ul>
                                <li><b>Campaign Name : </b> Enter the name of the campaign. Ex - Simulation 1, Test simulation, etc.</li>
                                <li><b>User and groups : </b> Select the user and groups you wish to target for the phishing simulation.</li>
                                <li><b>Campaign template type : </b>
                                    <p className="mb-1"> Choose between system template, custom template, and community template.</p>
                                    <ul>
                                        <li>Templates designed or modified by you can be found in the custom template category.</li>
                                        <li>On the other hand, the readily available templates can be found under the system template category. </li>
                                    </ul>
                                </li>
                                <li><b>Select campaign template category : </b> Choose the category of the email template, eg - Insurance, finance, social media, etc. </li>
                                <li><b>Campaign template : </b>Select the campaign template for your simulated phishing attack.</li>
                                <li><b>Campaign mode : </b>
                                    <p className="mb-1">Choose between the two modes - single and random.</p>
                                    <ul>
                                        <li>Single mode allows you to run a single template for all users or a group of users.</li>
                                        <li>In the case of random mode, different templates belonging to the selected category will run on the frequency selected by you.</li>
                                    </ul>
                                </li>
                                <li><b>Send now or schedule for later : </b> After entering the accurate details above, you can immediately run the campaign or schedule it for a time later in the day, week, or month as per your convenience and requirements. </li>
                            </ul>
                        </div>
                        <div className="log-block">
                            <h3>CAMPAIGN TEMPLATES</h3>
                            <p>You can use pre-built templates offered or create your own template here.</p>
                            <ul>
                                <li><b>Email Template : </b> Design your email template here with desired content, HTML code or images.</li>
                                <li><b>Landing Page : </b> Design the landing page where you will fetch employee’s credentials and host it on a URL.</li>
                                <li><b>Attacker Profile : </b>Enter your sender ID with which you want to launch your attack.</li>
                                <li><b>Preview : </b> View your entire campaign template here and send a test mail.</li>
                            </ul>
                        </div>
                        <div className="log-block">
                            <h3>USER & GROUP</h3>
                            <p>This section contains the list of users and groups who will be receiving the phishing emails sent from the tool.</p>
                        </div>
                        <div className="log-block">
                            <h3>REPORT</h3>
                            <p>The section contains four parts that primarily allow you to identify the cyber security threat posture within your organization. </p>
                            <ul>
                                <li><b>Campaign Analytics : </b> The Campaign analytics dashboard displays analytics of all the phishing campaigns you have initiated till date. </li>
                                <li><b>Click Reports : </b> Click reports refer to the total number of employees who clicked the link present in the simulated phishing email. </li>
                                <li><b>Breach/Incident : </b> The Breach/ Incident contains two important elements - Average breach time and Incident report time. </li>
                                <li><b>Repeat offenders : </b> It displays the number of employees who repeatedly submitted their data in a simulated attack. </li>
                            </ul>
                        </div>
                        <div className="log-block">
                            <h3>IAM</h3>
                            <p> Give access of the tool with required roles and policies. </p>
                        </div>
                        <div className="log-block">
                            <h3>EVS</h3>
                            <p> Employee Vulnerability Score allows you to analyze the security strength of each employee based on their performance in the campaigns you ran. </p>
                        </div>
                        <div className="log-block">
                            <h3>HACK RECORD</h3>
                            <p>Enter your employee’s email ID and know if their credentials have been compromised in past data breaches. </p>
                        </div>
                        <div className="log-block">
                            <h3>IAM</h3>
                            <p> Give access of the tool with required roles and policies. </p>
                        </div>
                        <div className="log-block">
                            <h3>LANDING PAGE URL</h3>
                            <p>You can view all the landing page URLs available from our end and see if any are detected deceptive.</p>
                        </div>
                        <div className="log-block">
                            <h3>CALENDAR</h3>
                            <p>View your scheduled campaigns here.</p>
                        </div>
                        <div className="log-block">
                            <h3>SETTING</h3>
                            <p> Account settings are updated here</p>
                        </div>
                        <div className="log-block">
                            <h3>PRODUCT GUIDE</h3>
                            <p>The guide is to help direct you through the tool and it’s processes.</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}