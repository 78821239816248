import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import EVSList from './evs-list/evs-list';
import EVSEdit from './evs-edit/evs-edit';

export default class EVS extends Component {
	render() {	
        const { match } = this.props;
		return (
            <Switch>
                <Route path={`${match.path}`} exact component={ EVSList }/>
                <Route path={`${match.path}/:id`} exact component={ EVSEdit }/>
                <Route render={() => (<Redirect to="/invalid" />)}/>
            </Switch>
		);
	}
}