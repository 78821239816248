import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import DoneAllSharpIcon from '@material-ui/icons/DoneAllSharp';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';

import Spinner from '../../spinner/spinner';
export default class TemplatesCall extends Component{
    constructor(props){
        super(props);
        this.state = {
           
        }
    }

    componentWillMount = () => {
        if(this.props.templateId){
            
        }
    }

    handleCreateTemplate = () => {
       
    }

    render(){
        return (
            <div className="tabContent-block">
                <div className="row justify-content-between d-flex align-items-center mb-3">
                    <div className="col-md-6">
                        <h6 className="tab-head">Create Call Template</h6>
                    </div>
                    <div className="col-md-6 text-right">
                        <Button variant="success" type="button" onClick={this.handleCreateTemplate}><DoneAllSharpIcon className="btn-icon"></DoneAllSharpIcon> Save </Button>
                    </div>
                </div> 

                <div className="email-template-wrap">
                    Discuss for template data
                </div>
                <div className="col-md-12 text-right mt-4">
                    <Button variant="secondary" className="mr-2" onClick={e => this.props.handleTabChange('prev')}><ArrowBackOutlinedIcon className="btn-icon"></ArrowBackOutlinedIcon> Previous</Button>
                    <Button variant="success" type="button" onClick={e => this.props.handleTabChange('next')}><Spinner loading={this.state.loading}></Spinner><ArrowForwardOutlinedIcon className={this.state.loading?"btn-icon d-none": "btn-icon"}></ArrowForwardOutlinedIcon> Go To Next</Button>
                </div>
            </div>
        )
    }
}