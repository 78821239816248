import { toast } from "react-toastify";
import React, { Component } from "react";
import { Button, Form } from 'react-bootstrap';

import axios from '../../../axios';
import apiError from '../../../error';
import Loader from '../../../component/loader/loader';

export default class Azure extends Component {
    constructor(props){
        super(props);
        this.state = {
            clientid:"",
            groupId: '',
            groupEnabled: false
        }
    }

    componentDidMount = () => {
        this.loadAzure();
    }
    
    validate = () => {
        if(!this.state.clientid){
            toast.error('Please enter ClientId');
            return null
        } 
        if(!this.state.groupId && this.state.groupEnabled){
            toast.error('Please enter GroupId');
            return null
        } 
        return true;
    }

    loadAzure = () => {
        axios.get('/api/company/getAzureSettings')
        .then(res => {
            if(res.data && res.data.clientId && res.data.clientId.length === 36){
                this.setState({clientid: res.data.clientId});
            }
            if(res.data && res.data.groupEnabled){
                this.setState({groupEnabled: res.data.groupEnabled});
            }
            if(res.data && res.data.groupId && res.data.groupId.length === 36){
                this.setState({groupId: res.data.groupId});
            }
        })
        .catch(err => {
            apiError(err);
        })
    }


    handleAzure = () => {
        var data = this.validate();
        if(data){
            axios.post('/api/company/setAzureSettings', {
                azureClientId: this.state.clientid,
                azureGroupEnabled: this.state.groupEnabled,
                azureGroupId: this.state.groupId,
            })
            .then(res => {
                toast.success("Azure Settings updated successfully");
            })
            .catch(err => {
                apiError(err);
            })
        }
    }


    render() {
        return (
            <div className="tab-wrap">
                <div className='tabHeading'>
                    <h6>Azure Settings</h6>
                    <p>Displays basic information associ </p>
                 </div>


                    <div className="col-md-4 pl-0">
                        <Form>
                         
                                    <Form.Group>
                                        <Form.Label>Client ID</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            placeholder={this.state.clientid || "Client Id"} 
                                            value={this.state.clientid} 
                                            onChange={e => this.setState({clientid: e.target.value})}
                                        />
                                    </Form.Group>
                                        <Form.Group>
                                        <Form.Switch type="switch" label="Enable group ID" checked={this.state.groupEnabled} 
                                                onChange={e => this.setState({groupEnabled: e.target.checked})}/>
                                            {/* <Form.Check 
                                                type="checkbox" 
                                                label="Enable Group ID" 
                                                checked={this.state.groupEnabled} 
                                                onChange={e => this.setState({groupEnabled: e.target.checked})}
                                            /> */}
                                            </Form.Group>
                                         <Form.Group>
                                        <Form.Label>Group ID</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            placeholder={this.state.groupId || "Group Id"} 
                                            value={this.state.groupId} 
                                            onChange={e => this.setState({groupId: e.target.value})}
                                            disabled={!this.state.groupEnabled} // This will disable the input when checkbox is not checked
                                        />
                                    </Form.Group>
                                    <Button variant="success" type="button" onClick={this.handleAzure}>Save</Button>
                               
                        </Form>
                    </div>
         
                {this.state.loading ? <Loader></Loader> : null}
            </div>
        );
    }
}