import React, {Component} from 'react';
import 'react-phone-input-2/lib/style.css';
import { Modal, Form, Button } from 'react-bootstrap';

export default class SubDomain extends Component {
    render() {
        const landingUrl = this.props.landingUrl;
        return (
            <Modal show={this.props.showModal} onHide={this.props.hideModal} className="commonModal">
                <Modal.Header closeButton>
                    <div>
                         <h5>Add SubDomain</h5>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Form.Label>Please Select Your Root Domain</Form.Label>
                    <Form.Group controlId="exampleForm.ControlSelect1" onChange={e => this.props.handleLandingUrlId(e.target.value)}>
                        <Form.Control as="select">
                            <option value={0}>Select Root Domain</option>
                            {landingUrl.map((url, index) => {
                                return <option key={`options-${index}`} value={url.id}>{url.name}</option>
                            })}
                        </Form.Control>
                    </Form.Group>
                    <div>
                        <Form.Label>Please enter sub domain (Without <span style={{color: 'green'}}>http://</span> or <span style={{color: 'green'}}>https://</span>)</Form.Label>
                        <Form.Control type="text" placeholder="sub_domain_name" value={this.props.subDomain} onChange={this.props.handleSubDomain}/>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn closeBtn' onClick={this.props.hideModal}>Close</Button>
                    <Button className='btn btn-success' onClick={this.props.handleSubmit}>Create</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}