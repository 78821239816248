import React, { Component } from "react";
import ApexCharts from 'apexcharts'

export default class CategoryClickResponse extends Component {
	constructor(props) {
		super(props);
		this.state = { chart: null }
	}
	componentDidMount = () => {
		let options = {
			series: [{
				name: 'Link Clicked',
				data: this.props.categoryClickRate['click']? this.props.categoryClickRate['click']: [],
			}],
			colors: ['#F9C546'],
			chart: {
				type: 'bar',
				height: 330,
				toolbar: {
					show: true
				}
			},
			plotOptions: {
				bar: {
					horizontal: false,
					dataLabels: {
						position: 'top',
					},
				}
			},
			dataLabels: {
				enabled: true,
				offsetX: -6,
				style: {
					fontSize: '12px',
					colors: ['#fff']
				}
			},
			stroke: {
				show: true,
				width: 1,
				colors: ['#fff']
			},
			xaxis: {
				labels: {
					show: false
				},
				categories: this.props.categoryClickRate['category']? this.props.categoryClickRate['category']: [],
			},
		}
		var chart = new ApexCharts(document.querySelector("#categoryClickResponce"), options);
		chart.render();
		this.setState({chart: chart})
	}

	componentDidUpdate = (prevProps) => {
		if(this.props.categoryClickRate !== prevProps.categoryClickRate){
			if(this.state.chart) this.state.chart.destroy();
			this.componentDidMount();
		}
	}

	render() {
		return (
			<div id="categoryClickResponce"></div>
		);
	}
}