import React, { Component } from 'react';
import { toast } from "react-toastify";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';

import axios from '../../../axios';
import apiError from '../../../error';
import Spinner from '../../spinner/spinner';

export default class TemplatesSMS extends Component{
    constructor(props){
        super(props);
        this.state = {
            brodcastName: '',
            templateName: '',
            loading: false,
            replaceCount: { 
                1: true 
            },
            data: {
                1: {
                    name: '',
                    value: ''
                }
            }
        }
        this.load();
    }

    load = () => {
        if(this.props.templateId){
            axios.get('/api/whatsapp-template/'+this.props.templateId, {})
            .then(res => {
                let template = res.data.template
                if(template){
                    this.setState({ 
                        brodcastName: template.brodcastName,
                        templateName: template.templateName
                    })
                    if(template.whatsapp_templates){
                        let data = {}, replaceCount = {};
                        for(let i=0; i<template.whatsapp_templates.length; i++){
                            data[i+1] = {
                                name: template.whatsapp_templates[i].name,
                                value: template.whatsapp_templates[i].value
                            }
                            replaceCount[i+1] = true;
                        }
                        this.setState({ data, replaceCount })
                    }
                }
            })
            .catch(err => {
                apiError(err)
            })
        }
    }

    handleCreateTemplate = () => {
        if(!this.state.templateName || this.state.templateName.length === 0){
            toast.error('Please enter Template Name');
            return ;
        }
        if(!this.state.brodcastName || this.state.brodcastName.length === 0){
            toast.error('Please enter Brodcast Name');
            return ;
        }
        const data = {
            data: [],
            brodcastName: this.state.brodcastName,
            templateName: this.state.templateName,
            campaignTemplateId: this.props.campaignTemplateId
        }
        Object.keys(this.state.replaceCount).forEach((key, index) => {
            if(this.state.replaceCount[key]){
                data['data'].push(this.state.data[key])
            }
        })
        this.setState({ loading: true })
        if(this.props.templateId){
            axios.patch('/api/whatsapp-template/'+this.props.templateId, data )
            .then(res => {
                this.setState({ loading: false })
                this.props.handleTabChange('next');
                toast.success('SMS template updated successfully') 
            })
            .catch(error => {
                apiError(error);
                this.setState({ loading: false });
            })
        } else {
            axios.post('/api/whatsapp-template/', data )
            .then(res => {
                this.setState({ loading: false });
                this.props.handleTabChange('next');
                this.props.handleTemplateId(res.data.template.id);
                toast.success('SMS template created successfully') 
            })
            .catch(error => {
                apiError(error);
                this.setState({ loading: false });
            })
        }
    }

    addRow = () => {
        this.setState({
            replaceCount: {...this.state.replaceCount, [Object.keys(this.state.replaceCount).length+1]: true },
            data: {...this.state.data, [Object.keys(this.state.replaceCount).length+1]: { name: '', value: ''}}
        })
    }

    deleteRow = (count) => {
        this.setState({
            replaceCount: {...this.state.replaceCount, [count]: false }
        })
    }

    render(){
        return (
            <>
            <div className="email-template-wrap">
                <div className='rightSectionHeader'>
                <div className="row justify-content-between d-flex align-items-center mb-3">
                    <div className="col-md-6">
                        <h6 className="tab-head">Create SMS Template</h6>
                    </div>
                </div> 
             </div>


             <Form.Group controlId="exampleForm.ControlTextarea1">
                <div className='row'>
                    <div className='col-md-6'>
                        <Form.Label>Template Name</Form.Label>
                        <Form.Control value={this.state.templateName} onChange={e => this.setState({templateName: e.target.value})} />
                    </div>

                    <div className='col-md-6'>
                         <Form.Label>Brodcast Name</Form.Label>
                        <Form.Control value={this.state.brodcastName} onChange={e => this.setState({brodcastName: e.target.value})} />
                    </div>
                    
                </div>



                <div className='attackCard  mt-3'>
                    <div className='midCard'>   
                      <Form.Label style={{ cursor: 'pointer'}}>Custom Params <AddIcon className="btn-icon" style={{color: 'blue'}} onClick={this.addRow}></AddIcon></Form.Label>
                        {Object.keys(this.state.replaceCount).map(((key, index) => {
                            if(this.state.replaceCount[key]){
                                return (
                                    <div className='row' key={index}>
                                        <div className='col-md-5'>
                                            <label>Name: </label>
                                            <Form.Control 
                                                value={this.state.data[key].name} 
                                                onChange={e => 
                                                    this.setState({
                                                        data: {
                                                            ...this.state.data, 
                                                            [key]: { 
                                                                name: e.target.value, 
                                                                value: this.state.data[key].value
                                                            }
                                                        } 
                                                    }
                                                )} 
                                            />
                                        </div>
                                        <div className='col-md-5'>
                                            <label>Value: </label>
                                            <Form.Control 
                                                value={this.state.data[key].value} 
                                                onChange={e => 
                                                    this.setState({
                                                        data: {
                                                            ...this.state.data, 
                                                            [key]: { 
                                                                value: e.target.value, 
                                                                name: this.state.data[key].name
                                                            }
                                                        } 
                                                    }
                                                )} 
                                            />
                                        </div>
                                        <div className='col-md-2 mt-2'>
                                            <label></label>
                                            <DeleteIcon className="btn-icon" style={{color: 'red', cursor: 'pointer'}} onClick={e => this.deleteRow(key)}></DeleteIcon>
                                        </div>
                                    </div>
                                )
                            } return null;
                        }))}
                    </div>
                </div>
            </Form.Group>
   
             </div>
                 <div className="row mt-2">
                        <div className='footer-wrapper justify-content-end d-flex'> 
                        <Button className="closeBtn mr-2" onClick={e => this.props.handleTabChange('prev')}><ArrowBackOutlinedIcon className="btn-icon"></ArrowBackOutlinedIcon> Previous</Button>
                        <Button variant="success" disabled={this.state.loading} type="button" onClick={this.handleCreateTemplate}><Spinner loading={this.state.loading}></Spinner><ArrowForwardOutlinedIcon className={this.state.loading?"btn-icon d-none": "btn-icon"}></ArrowForwardOutlinedIcon> Go To Next</Button>
                      </div>
                </div>
            </>
        )
    }
}