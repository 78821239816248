import { toast } from "react-toastify";
import React, { Component } from "react";
import { Button, Form } from 'react-bootstrap';

import axios from '../../../axios';
import apiError from '../../../error';
import Loader from '../../../component/loader/loader';

export default class SMTP extends Component {
    constructor(props){
        super(props);
        this.state = {
            delay: '',
            limit: '',
            ssl: false,
            pool: false,
            maxMessage: '',
            maxConnection: '',
            connectionTimeout: '',
        }
    }

    componentDidMount = () => {
        this.loadSmtp();
    }
    
    loadSmtp = () => {
        axios.get('/api/meta', {
            params: {
                keys: ['delay', 'limit', 'ssl', 'pool', 'maxMessage', 'maxConnection', 'connectionTimeout']
            }
        })
        .then(res => {
            var data = {};
            if(res.data && res.data.data && res.data.data.length){
                for(let i=0; i<res.data.data.length; i++){
                    if(res.data.data[i].key === 'ssl' || res.data.data[i].key === 'pool'){
                        data[res.data.data[i].key] = parseInt(res.data.data[i].value, 10) === 1? true: false;
                    } else {
                        data[res.data.data[i].key] = res.data.data[i].value;
                    }
                }
                this.setState(data);
            }
        })
        .catch(err => {
            apiError(err);
        })
    }

    validate = () => {
        if(!this.state.limit || this.state.limit.length === 0){
            toast.error('Please enter limit');
            return null
        } 
        if(!this.state.delay || this.state.delay.length === 0){
            toast.error('Please enter delay');
            return null
        } 
        return true;
    }

    handleSmtp = () => {
        var data = this.validate();
        if(data){
            axios.post('/api/meta', {
                delay: this.state.delay,
                limit: this.state.limit,
                ssl: this.state.ssl,
                pool: this.state.pool,
                maxMessage: this.state.maxMessage,
                maxConnection: this.state.maxConnection,
                connectionTimeout: this.state.connectionTimeout,
            })
            .then(res => {
                toast.success("SMTP updated successfully");
            })
            .catch(err => {
                apiError(err);
            })
        }
    }


    render() {
        return (
            <div className="tab-wrap">
                <div className='tabHeading'>
                    <h6>Advance Setting</h6>
                    <p>2FA enhances account security by preventing unauthorised access. </p>
                 </div>

                    <div className="col-md-4 pl-0">
                        <Form>
                           
                                    {/* <Form.Switch style={{marginBottom: '20px', marginTop: '20px'}} type="switch" id="custom-switch1" label="Pool" checked={this.state.pool} onChange={e => this.setState({pool: !this.state.pool })} />
                                    <Form.Group>
                                        <Form.Label>Max Message</Form.Label>
                                        <Form.Control type="text" placeholder="Max Message" value={this.state.maxMessage} onChange={e => this.setState({maxMessage: e.target.value})} />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Max Connections</Form.Label>
                                        <Form.Control type="text" placeholder="Max Connections" value={this.state.maxConnection} onChange={e => this.setState({ maxConnection: e.target.value})} />
                                    </Form.Group> */}
                                    <Form.Group>
                                        <Form.Label>Limit</Form.Label>
                                        <Form.Control type="number" placeholder="Limit" value={this.state.limit} onChange={e => this.setState({limit: e.target.value})}/>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Delay (In MiliSeconds)</Form.Label>
                                        <Form.Control type="number" placeholder="Delay" value={this.state.delay} onChange={e => this.setState({delay: e.target.value})}/>
                                    </Form.Group>
                                    <Button variant="success" type="button" onClick={this.handleSmtp} >Save</Button>
                               
                               
                                    {/* <Form.Switch style={{marginBottom: '20px', marginTop: '20px'}} type="switch" id="custom-switch2" label="SSL" checked={this.state.ssl} onChange={e => this.setState({ssl: !this.state.ssl })} />
                                    <Form.Group>
                                        <Form.Label>Connection Timeout (In MiliSeconds)</Form.Label>
                                        <Form.Control type="text" placeholder="Connection Timeout" value={this.state.connectionTimeout} onChange={e => this.setState({connectionTimeout: e.target.value})}/>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Delay (In MiliSeconds)</Form.Label>
                                        <Form.Control type="text" placeholder="Delay" value={this.state.delay} onChange={e => this.setState({delay: e.target.value})}/>
                                    </Form.Group>
                                    <Button variant="success" style={{marginTop: '30px'}} type="button" onClick={this.handleSmtp} >Save</Button> */}
                            
                          
                        </Form>
                    </div>
            
                {this.state.loading? <Loader></Loader>: null}
            </div>
        );
    }
}