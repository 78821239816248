import React, { Component } from 'react';
import swal from 'sweetalert';
import { toast } from "react-toastify";
import Tooltip from 'react-bootstrap/Tooltip';
import { GoChecklist } from "react-icons/go";
import AddSharpIcon from "@material-ui/icons/AddSharp";
import DoneSharpIcon from '@material-ui/icons/DoneSharp';
import ClearSharpIcon from '@material-ui/icons/ClearSharp';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import CropFreeSharpIcon from '@material-ui/icons/CropFreeSharp';
import { Button, Form, Badge, Table, Image } from 'react-bootstrap';
import VisibilitySharpIcon from '@material-ui/icons/VisibilitySharp';

import axios from '../../../axios';
import apiError from '../../../error';
import { getDate } from '../../../function';
import temp from '../../../assets/temp.png';
import PreviewThumbnail from '../../preview/preview';
import Loader from '../../../component/loader/loader';
import Spinner from '../../../component/spinner/spinner';
import TestEmails from '../../../component/test-campaign-email/test-campaign-email';

export default class Preview extends Component{
    constructor(props){
        super(props);
        this.state = {
            type: '',
            page: null,
            game: null,
            user: null,
            emails: '',
            categoryId: '',
            loading: false,
            template: null,
            category: null,
            language: null,
            subject: null,
            training: null,
            attackType: null,
            showModal: false,
            landing_url: null,
            campaignName: null,
            from_address: null,
            simulationType: null,
            showTestModal: false,
            refreshLoading: false,
            credential_harvesting: false,

            templateName: null,
            brodcastName: null,

            imageURL: '', 
            courseName: '', 
            previewURL: '', 
            lmsCourses: [], 
            courseIds: [],
            myWindow: null,
        }
    }

    componentDidMount = () => {
        this.handleRefresh();
    }

    handleRefresh = () =>{
        if(this.props.campaignTemplateId){
            this.setState({ refreshLoading: true })
            axios.get('/api/campaign/template/preview/'+this.props.campaignTemplateId, {})
            .then(res => {
                var campaignTemplate = res.data.campaign_template;
                this.setState({
                    loading :false,
                    user: res.data.user,
                    refreshLoading: false,
                    pageImg: res.data.pagePath,
                    game: campaignTemplate.game,
                    type : campaignTemplate.type,
                    templateImg: res.data.templatePath,
                    campaignName: campaignTemplate.name,
                    training: campaignTemplate.courseId,
                    awarenessPath: res.data.awarenessPath,
                    credential_harvesting : campaignTemplate.credential_harvesting,
                    from_address: campaignTemplate.sender ? campaignTemplate.sender.from_address: null,

                    subject: campaignTemplate.template? campaignTemplate.template.subject: null,
                    emailTracking: campaignTemplate.template? campaignTemplate.template.emailTracking: null,

                    templateName: campaignTemplate.template? campaignTemplate.template.templateName: null,
                    brodcastName: campaignTemplate.template? campaignTemplate.template.brodcastName: null,

                    category: campaignTemplate.category? campaignTemplate.category.name: null,
                    categoryId: campaignTemplate.category? campaignTemplate.category.id: null,
                    language: campaignTemplate.language? campaignTemplate.language.name: null,
                    attackType: campaignTemplate.attack_type? campaignTemplate.attack_type.name: null,
                    simulationType: campaignTemplate.simulation_type? campaignTemplate.simulation_type.name: {},

                    bluePage: campaignTemplate.page ? campaignTemplate.page.bluePage: null,
                    landing_url: campaignTemplate.page ? campaignTemplate.page.landing_url: null,
                    capture_passwords: campaignTemplate.page ? campaignTemplate.page.capture_passwords: null,
                    capture_credentials: campaignTemplate.page ? campaignTemplate.page.capture_credentials: null,
                    redirect_url: campaignTemplate && campaignTemplate.credential_harvesting && campaignTemplate.page ? campaignTemplate.page.redirect_url: res.data.awarenessUrl,

                    firstname: '',
                    lastname: '',
                })
                if(campaignTemplate.courseId){
                    var courseIds = [];
                    for(let i=0; i<campaignTemplate.courses.length; i++){
                        courseIds.push(campaignTemplate.courses[i].courseId)
                    }
                    this.setState({ courseIds: courseIds }, () => {
                        this.loadCourseLMS();
                    })
                }
            })
            .catch(error => {
                this.setState({ refreshLoading: false })
                apiError(error) 
            })
        }
    }

    shareCampaignTempalte = () => {
        swal({
            title: "Do you want to share this template?",
            icon: "info",
            buttons: [ 'Not Now!', 'Yes' ],
        })
        .then(willSuccess => {
            if (willSuccess) {
                if(this.props.campaignTemplateId){
                    axios.post('/api/campaign/template/share/'+this.props.campaignTemplateId, {})
                    .then(res => {
                        toast.success("Campaign tempate share successfully");
                    })
                    .catch(err => {
                        apiError();
                    })
                }
            }
        })
    }

    handleFinish = () => {
        swal({ 
            timer: 1000,
            buttons: false,
            icon: "success", 
            text: '---',
            title: "Campaign created successfully", 
        })
        setTimeout(() => { this.props.nextPath('/templates') }, 500)
    }

    showHandleThumbnail = (type) => {
        let previewSrc = '';
        if(type === 'template'){
            previewSrc = this.state['templateImg'];
        } else if(type === 'page'){
            previewSrc = this.state['pageImg'];
        } else if(type === 'awareness'){
            previewSrc = this.state['awarenessPath'];
        }
        this.setState({showModal: !this.state.showModal, previewSrc: previewSrc, previewType: type});
    }

    launchCampaignTemplate = () => {
        let url = '/templates/launch/'+this.state.simulationType+'/'+this.state.attackType+'/'+this.state.credential_harvesting;
        let search = '?type='+this.state.type+'&category='+this.state.categoryId+'&ctID='+this.props.campaignTemplateId;
        this.props.nextPath(url+search);
    }

    showHandleTestEmail = () => {
        this.setState({showTestModal: !this.state.showTestModal});
    }

    testCampaign = (targetDetails) => {
        if(this.props.campaignTemplateId && this.props.campaignTemplateId !== 0 && this.props.campaignTemplateId !== '0'){
            if(targetDetails.length && targetDetails[0].emails && targetDetails[0].emails.length){
                this.setState({loading: true})
                axios.post('/api/simulation/test', {
                    targetDetails,
                    campaignTemplateId: this.props.campaignTemplateId,
                })
                .then(res => {
                    this.showHandleTestEmail();
                    this.setState({ loading: false });
                    toast.success("Test campaign run successfully");
                    setTimeout(() => { window.open('/campaign/test-results/?attackTypeId='+this.state.attackType) }, 1500);
                })
                .catch(error => {
                    this.setState({loading: false})
                    apiError(error);
                })
            } else {
                if(this.state.attackType === "Phishing" || this.state.attackType === "Ransomware" || this.state.attackType === "Attachment") {
                    toast.error("Please enter emailId");
                } else {
                    toast.error("Please enter Phone No.");
                }
            }
        }
        else toast.error("invalid campaign template");
    }
    loadCourseLMS = async() => {
        if(this.state.courseIds && this.state.courseIds.length){
            await this.setState({ loading: true });
            axios.get('/api/lms/course-detail', {
                params: {
                    courseIds: this.state.courseIds
                }
            })
            .then(res => {
                var courseName = {};
                for(let i=0; i<res.data.courses.length; i++){
                    courseName[res.data.courses[i].id] = res.data.courses[i].name
                }
                this.setState({ 
                    loading: false,
                    courseName: courseName, 
                    imageURL: res.data.imageURL, 
                    lmsCourses: res.data.courses, 
                    previewURL: res.data.previewURL, 
                });
            })
            .catch(err => {
                this.setState({ loading: false });
                apiError(err)
            })
        }
    }

    handleLMS = async(courseId) => {
        document.body.style.cursor = "wait";
        axios.get('/api/lms', {
            params: {
                url: `${this.state.previewURL}${courseId}?r=Sw4s56g`
            }
        })
        .then(res => {
            document.body.style.cursor = "";
            window.open(res.data.url, '_blank');
        })
        .catch(err => {
            apiError(err);
            document.body.style.cursor = "";
        });
    }

    render(){
        return (
            <div className="tabContent-block email-template-wrap" id="preview">


            <div className='rightSectionHeader'>
                    <div className="row justify-content-between d-flex align-items-center">
                        <div className="col-md-3 tab-right-header">
                            <h6>Preview</h6>
                            <p>Create email template</p>
                        </div>
                        <div className="col-md-9 text-right">
                            <div className="btn-container justify-content-end d-flex align-items-center">
                            {this.state.attackType === "Smishing" || this.state.attackType === "WhatsApp" || this.state.attackType === "Vishing"? 
                                <div className="col-md-9 text-right pr-0">
                                {/* <Button className="text-right mr-2" disabled={this.state.refreshLoading} onClick={this.handleRefresh}><Spinner loading={this.state.refreshLoading}></Spinner> Refresh </Button> */}
                                <Button variant="primary" className="text-right mr-2" onClick={this.launchCampaignTemplate}><AddSharpIcon className="btn-icon"></AddSharpIcon> Create Campaign </Button>
                                <OverlayTrigger
                                    placement='top'
                                    overlay={<Tooltip id='top'>Overall view of campaign template</Tooltip>}>
                                    <Button variant="primary" type="button" onClick={this.showHandleTestEmail} ><GoChecklist className="btn-icon"></GoChecklist>Test Campaign Template Message</Button>
                                </OverlayTrigger>
                            </div>:
                            <div className="col-md-9 text-right pr-0">
                                  <div className="btn-container justify-content-end d-flex align-items-center">
                                {/* <Button className="text-right mr-2" disabled={this.state.refreshLoading} onClick={this.handleRefresh}><Spinner loading={this.state.refreshLoading}></Spinner> Refresh </Button> */}
                                <Button variant="primary" className="text-right mr-2" onClick={this.launchCampaignTemplate}><AddSharpIcon className="btn-icon"></AddSharpIcon> Create Campaign </Button>
                                <OverlayTrigger
                                    placement='top'
                                    overlay={<Tooltip id='top'>Overall view of campaign template</Tooltip>}>
                                    <Button  variant="primary" type="button" onClick={this.showHandleTestEmail} ><GoChecklist className="btn-icon"></GoChecklist> Test Campaign Template Email</Button>
                                </OverlayTrigger>
                                </div>
                            </div>}
                            </div>
                        </div>
                    </div>
                </div>  



                <div className='templateDetailsWrapper'>
                    <div className='row'>
                        <div className="col-md-6">
                            <div className='templateList'>
                                <Table>
                                    <tbody>
                                    {this.state.campaignName?
                                        <tr>
                                            <td className='keyHeading'>Template Name:</td>
                                            <td className='valueHeading'>{this.state.campaignName}</td>
                                        </tr>: ""
                                    }

                                    {this.state.category?
                                        <tr>
                                            <td className='keyHeading'>Template Category: </td>
                                            <td className='valueHeading'><span className="p-badge">{this.state.category}</span></td>
                                        </tr>:""
                                    }

                                        {this.state.from_address && this.state.attackType !== 'WhatsApp'?
                                        <tr>
                                            <td className='keyHeading'>From :</td>
                                            <td className='valueHeading'>{this.state.from_address}</td>
                                        </tr>:""
                                      }    

                                    </tbody>
                                </Table>
                                    
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className='templateList'>
                            <Table>
                                    <tbody>
                                         <tr>
                                            <td className='keyHeading'>Language :</td>
                                            <td className='valueHeading'>{this.state.language}</td>
                                        </tr> 

                                        <tr>
                                            <td className='keyHeading'>Type of Simulation :</td>
                                            <td className='valueHeading'>{this.state.simulationType}</td>
                                        </tr> 

                                        <tr>
                                            <td className='keyHeading'>Attack Vector :</td>
                                            <td className='valueHeading'>{this.state.attackType}</td>
                                        </tr> 
                                        <tr>
                                            {(this.state.attackType !== 'Vishing' && this.state.attackType !== 'Training + Quiz')?
                                                 <div>
                                                     <td className='keyHeading'>Credential Harvesting : </td>
                                                        <td className='valueHeading'>{this.state.credential_harvesting? <p className="yes-badge"><DoneSharpIcon className="btn-icon" />Yes</p>:<p className="no-badge"><ClearSharpIcon className="btn-icon" />No</p>}</td>
                                                        </div>:''
                                                          }
                                               </tr>
                                        
                                    </tbody>
                                </Table>
                                
                            </div>
                        </div>
                    </div>
                </div>














               {/* <div className="row justify-content-between d-flex align-items-center bg-light py-3 mb-3">
                    <div className="col-md-3">
                        <h6 className="tab-head mb-0">Preview</h6>
                    </div>
                    {this.state.attackType === "Smishing" || this.state.attackType === "WhatsApp" || this.state.attackType === "Vishing"? 
                        <div className="col-md-9 text-right">
                        <Button className="text-right mr-2" disabled={this.state.refreshLoading} onClick={this.handleRefresh}><Spinner loading={this.state.refreshLoading}></Spinner> Refresh </Button>
                        <Button className="text-right mr-2" onClick={this.launchCampaignTemplate}> Create Campaign </Button>
                        <OverlayTrigger
                            placement='top'
                            overlay={<Tooltip id='top'>Overall view of campaign template</Tooltip>}>
                            <Button variant="warning" type="button" onClick={this.showHandleTestEmail} > Test Campaign Template Message</Button>
                        </OverlayTrigger>
                    </div>:
                    <div className="col-md-9 text-right">
                        <Button className="text-right mr-2" disabled={this.state.refreshLoading} onClick={this.handleRefresh}><Spinner loading={this.state.refreshLoading}></Spinner> Refresh </Button>
                        <Button className="text-right mr-2" onClick={this.launchCampaignTemplate}> Create Campaign </Button>
                        <OverlayTrigger
                            placement='top'
                            overlay={<Tooltip id='top'>Overall view of campaign template</Tooltip>}>
                            <Button variant="warning" type="button" onClick={this.showHandleTestEmail} > Test Campaign Template Email</Button>
                        </OverlayTrigger>
                    </div>}
                </div>   */}
{/*                 
                {this.state.campaignName?
                    <div className="row">
                        <Form.Label className="col-md-5">Campaign Template Name: </Form.Label>
                        <p className="col-md-7">{this.state.campaignName}</p>
                    </div>: ''
                }
                {this.state.category?
                    <div className="row">
                        <Form.Label className="col-md-5">Campaign Template Category : </Form.Label>
                        <div className="col-md-7"><p className="p-badge">{this.state.category}</p> </div>
                    </div>: ''
                }
                {this.state.from_address && this.state.attackType !== 'WhatsApp'?
                    <div className="row">
                        <Form.Label className="col-md-5">From : </Form.Label>
                        <p className="col-md-7">{this.state.from_address}</p>
                    </div>: ''
                }


                <div className="row">
                    <Form.Label className="col-md-5">Language : </Form.Label>
                    <p className="col-md-7">{this.state.language}</p>
                </div>
                <div className="row">
                    <Form.Label className="col-md-5">Type of Simulation : </Form.Label>
                    <p className="col-md-7">{this.state.simulationType}</p>
                </div>
                <div className="row">
                    <Form.Label className="col-md-5">Attack Vector : </Form.Label>
                    <p className="col-md-7">{this.state.attackType}</p>
                </div> */}


                {(this.state.attackType !== 'Vishing' && this.state.attackType !== 'Training + Quiz')?
                    <>
                        <div>
                            <hr></hr>
                            <div className="row">
                                {this.state.attackType === "WhatsApp"?<Form.Label className="col-md-3">Message Template : </Form.Label>: <Form.Label className="col-md-3">Email Template : </Form.Label>}
                                <div className="col-md-5 template-wrap">
                                    {this.state.attackType === 'WhatsApp'?
                                        <>
                                            <div className="bg-light p-3 mb-3">
                                                    <h6>Template Name</h6>
                                                    <p className="mb-0">{this.state.templateName}</p>
                                                </div>
                                                <div className="bg-light p-3 mb-3">
                                                    <h6>Brodcast Name</h6>
                                                    <p className="mb-0">{this.state.brodcastName}</p>
                                                </div>
                                        </>:
                                        <div className="img-hover-wrap">
                                            <img src={this.state.templateImg} alt="template" className="img-fluid img"></img>
                                            <div className="hover-btn">
                                                <Button variant="dark" onClick={e => this.showHandleThumbnail('template')}><CropFreeSharpIcon className="btn-icon" />Expand</Button>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="col-md-4"> 
                                    <div className="side-wrap">
                                        {this.state.attackType !== "WhatsApp"?
                                            <>
                                                <div className="bg-light p-3 mb-3">
                                                    <h6>Subject</h6>
                                                    <p className="mb-0">{this.state.subject}</p>
                                                </div>
                                                <div className="bg-light p-3 mb-3">
                                                    <h6>Attacker Profile</h6>
                                                    <p className="mb-0">Sender ID : {this.state.from_address}</p>
                                                </div>
                                                <div className="d-flex">
                                                    <h6>Allow Mail Tracking : </h6>
                                                    {this.state.emailTracking? <p className="yes-badge ml-2"><DoneSharpIcon className="btn-icon" />Yes</p>: <p className="no-badge ml-2"><ClearSharpIcon className="btn-icon" />No</p>}
                                                </div>
                                            </>:null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.credential_harvesting? 
                            <div>
                                <hr></hr>
                                <div className="row">
                                    <Form.Label className="col-md-3">Landing Page : </Form.Label>
                                    <div className="col-md-5 template-wrap">
                                        <div className="img-hover-wrap">
                                            <img src={this.state.pageImg} alt="template" className="img-fluid img"></img>
                                            <div className="hover-btn">
                                                <Button variant="dark" onClick={e => this.showHandleThumbnail('page')}><CropFreeSharpIcon className="btn-icon" />Expand</Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="side-wrap">
                                            <div className="bg-light p-3 mb-3">
                                                <h6>URL</h6>
                                                <p className="mb-0">{this.state.landing_url}</p>
                                            </div>
                                            <div className="row">
                                                <h6 className="col-md-6">Capture Credential : </h6>
                                                <div className="col-md-6">
                                                    {this.state.capture_credentials? <p className="yes-badge"><DoneSharpIcon className="btn-icon" />Yes</p>: <p className="no-badge"><ClearSharpIcon className="btn-icon" />No</p>}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <h6 className="col-md-6">Capture Password : </h6>
                                                <div className="col-md-6">
                                                    {this.state.capture_passwords? <p className="yes-badge"><DoneSharpIcon className="btn-icon" />Yes</p>: <p className="no-badge"><ClearSharpIcon className="btn-icon" />No</p>}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <h6 className="col-md-6">Auto-Instant Awareness : </h6>
                                                <div className="col-md-6">
                                                    {this.state.bluePage? <p className="yes-badge"><DoneSharpIcon className="btn-icon" />Yes</p>: <p className="no-badge"><ClearSharpIcon className="btn-icon" />No</p>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>: null
                        }
                        <hr></hr>
                        <div className="row">
                            <Form.Label className="col-md-3">Awareness Page  : </Form.Label>
                            <div className="col-md-5 template-wrap">
                                <div className="img-hover-wrap">
                                    <img src={this.state.awarenessPath} alt="template" className="img-fluid img"></img>
                                        <div className="hover-btn">
                                            <Button variant="dark" onClick={e => {
                                                this.showHandleThumbnail('awareness')}}><CropFreeSharpIcon className="btn-icon" />Expand</Button>
                                        </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="bg-light p-3 mb-3">
                                    <h6>URL</h6>
                                    <p className="mb-0">{this.state.redirect_url}</p>
                                </div>
                            </div>
                        </div>
                    </>: null
                }
                {this.state.training?
                    <div>
                        <hr></hr>
                        <div className="row">
                            <div className="col-md-3">
                                <h6>Training : </h6>
                            </div>
                            <div className="col-md-9">
                                <Table className="training-table" borderless>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Course</th>
                                            <th>Lectures</th>
                                            <th>Language</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.lmsCourses.map((course, index) => {
                                            return <tr key={index}>
                                                <td>{index+1}</td>
                                                <td>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <Image src={this.state.imageURL+course.logo} alt="no image" className="training-img"></Image>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <h6>{course.name}</h6>
                                                            <p>{getDate(course.createdAt)}</p>
                                                            <p>Status: {course.status}</p>
                                                            <p>Course Type: {course.courseType}</p>
                                                            <p>Skills: {course.CourseSkills.map((skill, index) => {
                                                                return skill && skill.Skill? `${skill.Skill.name}, `: '';
                                                            })}</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>{course.totalLecture}</td>
                                                <td>{course.Language.name}</td>
                                                <td><VisibilitySharpIcon className="btn-icon training-preview-icon" onClick={e => this.handleLMS(course.id)} ></VisibilitySharpIcon></td>
                                            </tr>
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>: null
                }
                {this.state.game?
                    <div>
                        <hr></hr>  
                        <div className="row">
                            <Form.Label className="col-md-3">Game : </Form.Label>
                            <div className="col-md-9 template-wrap d-flex">
                                <div className="col-md-6">
                                    <div className="d-flex training-wrap">
                                        <img src={temp} alt="template" className="img-fluid media-img"></img>
                                        <div className="wrap">
                                            <h6 className="mb-0">HR Payroll Course Video</h6>
                                            <p className="mb-0">1 Hours, 10 min</p>
                                        </div>
                                        <Badge variant="primary"><VisibilitySharpIcon className="btn-icon"></VisibilitySharpIcon>Preview </Badge>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>: ''
                }
                <div className="col-sm-12 text-right">
                    <Button variant="primary" type="button" className="text-white mr-2" onClick={this.shareCampaignTempalte}>Share</Button>
                    <Button variant="success" type="button" className="text-white" onClick={this.handleFinish}>Finish</Button>
                </div>
                <PreviewThumbnail 
                    src={this.state.previewSrc}
                    showModal={this.state.showModal} 
                    attackType={this.state.attackType}
                    previewType={this.state.previewType}
                    showHandle={this.showHandleThumbnail} 
                    html={this.state.selectedTeplate && this.state.selectedTeplate.template? this.state.selectedTeplate.template.html: ''}
                ></PreviewThumbnail>

                <TestEmails 
                    attackType={this.state.attackType}
                    emails={this.state.emails} 
                    firstname={this.state.firstname}
                    lastname={this.state.lastname}
                    nextPath={this.props.nextPath}
                    testCampaign={this.testCampaign}
                    showModal={this.state.showTestModal} 
                    showHandle={this.showHandleTestEmail} 
                    handleEmails={(e) => this.setState({emails: e.target.value})}
                    handleFirstname={(e) => this.setState({firstname: e.target.value})}
                    handleLastname={(e) => this.setState({lastname: e.target.value})}
                ></TestEmails>
                {this.state['loading']? <Loader/>: ''}
            </div>
        )
    }
}