import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Reports from './reports/reports';
import TestResults from './test-results/test-results';
import CampaignList from './campaign-list/campaign-list';
import CampaignEdit from './campaign-edit/campaign-edit';

export default class Campaign extends Component {
	render() {	
        const { match } = this.props;
		return (
            <Switch>
                <Route path={`${match.path}/`} exact component={ CampaignList }/>
                <Route path={`${match.path}/test-results`} exact component={ TestResults }/>
                <Route path={`${match.path}/reports/:campaignId/:simulationId`} exact component={ Reports }/>
                <Route path={`${match.path}/:campaignId`} exact component={ CampaignEdit }/>
                <Route path={`${match.path}/:campaignId/:simulationId`} exact component={ CampaignEdit }/>
                <Route render={() => (<Redirect to="/invalid" />)}/>
            </Switch>
		);
	}
}